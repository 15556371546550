import PropTypes from "prop-types"
import React from "react"
const TextfieldComponent = props => {
  return (
    <div className="formSection">
      <label className="control-label">{props.label}</label>
      <input className="form-control" {...props} />
    </div>
  )
}

TextfieldComponent.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.string,
}

export default TextfieldComponent
