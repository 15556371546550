import TicketLogCard from "components/TicketLogCard/index"
import { getCustomerSupportLog } from "helpers/api_actions"
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"

function Logs(props) {
  const [data, setData] = useState([])
  const fetchLogs = () => {
    let payload = {
      select:
        "title,note,users.full_name as change_by,customer_support_log.created_at",
      include: `users&find[customer_support_id]=${props.parentID}`,
    }
    getCustomerSupportLog(payload).then(res => {
      setData(res?.data?.data)
    })
  }
  useEffect(() => {
    fetchLogs()
  }, [])

  return (
    <div>
      {" "}
      <Row style={{ overflow: "auto" }}>
        <Col lg="12">
          <Card className="m-0 detailBox">
            <CardBody className="p-3">
              <Row className="m-0">
                {data.map((item, k) => (
                  <Col xs={12} key={k}>
                    <TicketLogCard data={item} />
                  </Col>
                ))}
                {!data.length && (
                  <Col xs={12} className="text-center my-4">
                    <h5>No Logs Found!</h5>
                  </Col>
                )}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Logs
