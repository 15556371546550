import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { BrowserRouter as Router, Switch } from "react-router-dom"

// Import scss
import NonAuthLayout from "./components/NonAuthLayout"
import PushNotification from "./components/pushnotification/index"
// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import { getToken, onMessageListener } from "./firebaseInit"
// Import all middleware
import { getNotification, updateNotificationToken } from "./helpers/api_actions"
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"
import Authmiddleware from "./routes/route"
import { useNotificationStore } from "./store/notification/notificationStore"

import "./assets/scss/theme.scss"

function App(props) {
  const { addNotification, addNotificationData } = useNotificationStore()
  const [isTokenFound, setTokenFound] = useState(false)
  const [show, setShow] = useState(false)
  const [notification, setNotification] = useState({ title: "", body: "" })

  async function requestPermission() {
    const permission = await Notification.requestPermission()
    if (permission === "granted") {
      const token = await getToken(setTokenFound)
      const form = new FormData()
      form.append("fcm_token", token)
      updateNotificationToken(form)
    } else if (permission === "denied") {
      alert(
        "Notifications are currently blocked. Please enable it in your browser settings to recieve new alerts."
      )
    }
  }

  onMessageListener()
    .then(payload => {
      setShow(true)
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      })
      getNotification().then(res => {
        addNotification(res?.unread_count)
        addNotificationData(res?.data?.data)
      })
    })
    .catch(err => console.log("failed: ", err))
  const token = localStorage.getItem("access_token")
  useEffect(() => {
    if (token) requestPermission()
  }, [token])

  const Layout = VerticalLayout

  return (
    <React.Fragment>
      {show && (
        <PushNotification open={show} data={notification} onClose={setShow} />
      )}
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              isAdminOnly={route.isAdminOnly ?? false}
              exact
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
