import { TAB_LABELS } from "constants/label"

export const USER_TAB_LABELS = [
  {
    id: "user-details",
    label: TAB_LABELS.USER_DETAILS,
  },
  {
    id: "assigned-users",
    label: TAB_LABELS.ASSIGNED_USERS,
  },
]

export const USER_DETAILS_BREADCRUMB = {}
