import React, { useState, useEffect, lazy, Suspense } from "react"
import {
  changeLeadStatus,
  getLeadsList,
  getPlatformUsersList,
  getServicesList,
} from "helpers/api_actions"

import UserFilterComponent from "./filterComponent"
import { AUTH_ROUTES } from "constants/routes"
import CustomSpinner from "components/customSpinner"
import { withRouter, Link } from "react-router-dom"
import moment from "moment"
import PropTypes from "prop-types"
// import ManageStatus from "../../modal/manageStatus"
import { useToasts } from "react-toast-notifications"
const CustomTable = lazy(() => import("components/customTable"))

function ServiceListing(props) {
  const [pageSize, setPageSize] = useState(15)
  const [data, setData] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [pageNo, setPageNo] = useState(1)
  const [search, setSearch] = useState("")
  const [filters, setFilters] = useState({})
  const [sortKey, setSortKey] = useState("&sort_by[services.name]=ASC")
  const [appliedFilters, setAppliedFilters] = useState("")
  const [appliedSeach, setAppliedSearch] = useState("")
  const [isFetching, setIsFetching] = useState(false)
  const [manageStatus, setManageStatus] = useState(false)
  const [activeItem, setActiveItem] = useState({})
  const [submiting, setSubmiting] = useState(false)
  const { addToast } = useToasts()
  const navigateToDetails = (id, isEdit = false) => {
    props.history.push({
      pathname: `${AUTH_ROUTES.LEAD_DETAILS}${id}`,
      state: { edit: isEdit },
    })
  }
  const listUsers = params => {
    let basicData = {
      select: `services.id,services.name as Service Name,service_types.name as Service Type,service_ranks.name as Service Rank,Direct Amt,Referer Amt,Active Leads,Closed Leads,services.status as Status,services.created_at as Created On,services.updated_at as Updated On`,
      include: `service_categories,service_types,service_ranks&find[service_categories.id]=${props.parentID}&page=${pageNo}&per_page=${pageSize}`,
    }
    setIsFetching(true)
    getServicesList(basicData, appliedSeach, appliedFilters, sortKey)
      .then(res => {
        setData(res?.data?.data)
        setTotalCount(res?.data?.total || 0)
        setIsFetching(false)
      })
      .catch(err => {
        setIsFetching(false)
      })
  }
  useEffect(() => {
    listUsers()
  }, [pageSize, pageNo, appliedSeach, sortKey, appliedFilters])

  const handleOpenChangeStatus = rowData => {
    setActiveItem(rowData)
    setManageStatus(true)
  }
  const closeChangeStatus = () => {
    setSubmiting(false)
    setManageStatus(false)
  }
  const handleChangeStatus = data => {
    setSubmiting(true)
    changeLeadStatus(
      { lead_status_id: data?.lead_status?.value, lead_note: data?.lead_note },
      activeItem.id
    )
      .then(res => {
        addToast(res?.message, { appearance: "success" })
        listLead()
        setSubmiting(false)
        setManageStatus(false)
      })
      .catch(err => {
        setSubmiting(false)
        addToast(err.response?.data?.message, { appearance: "error" })
      })
  }

  const handleDelete = () => {}
  const handleTableMenu = (action, rowData) => {
    switch (action.action) {
      case "edit":
        navigateToDetails(rowData.id, true)
        break
      case "status":
        handleOpenChangeStatus(rowData)
        break

      case "delete":
        handleDelete(rowData)
        break
      default:
        break
    }
  }
  const [columns, setColumns] = useState([
    {
      title: "Service Name",
      field: "Service Name",
      sort_direction: "ASC",
      sort_key: "services.name",
      minWidth: 260,
      width: 260,
      wrap: true,
      render: (r, v) => (
        <Link
          className="text-link"
          to={{
            pathname: `${AUTH_ROUTES.SERVICE_MANAGEMENT_DETAILS}${r.id}`,
            state: { edit: false },
          }}
        >
          {v}
        </Link>
      ),
    },
    // {
    //   title: "Service Type",
    //   field: "Service Type",

    //   sort_key: "service_types.name",
    //   minWidth: 150,
    //   width:150,
    //   wrap:true,
    //   // align: "center",
    // },

    {
      title: "Service Rank",
      field: "Service Rank",
      minWidth: 150,
      width: 150,
      wrap: true,

      sort_key: "service_ranks.name",
      // align: "center",
    },
    {
      title: "Direct Amt.",
      field: "directamt",

      sort_key: "directamt",
      minWidth: 150,
      width: 150,
      wrap: true,
      align: "end",
      tooltip_text:
        "Commission amount (active at this time) for the user when the user gives the leads for a service",
      
    },
    {
      title: "Referer Amt.",
      field: "refereramt",

      sort_key: "refereramt",
      minWidth: 150,
      width: 150,
      wrap: true,
      align: "end",
      tooltip_text:
        "Commission amount (active at this time) for a referrer user when one of his referees gives the leads for a service",
    },
    {
      title: "Active Leads",
      field: "Active Leads",

      sort_key: "Active Leads",
      minWidth: 150,
      width: 150,
      wrap: true,
      align: "center",
      tooltip_text:
        "Total leads in the system which requested for this service which are under 'New', 'Working', 'Nurturing' status",
    },
    {
      title: "Completed Leads",

      field: "Closed Leads",

      sort_key: "Closed Leads",
      minWidth: 170,
      width: 170,
      wrap: true,
      align: "center",
      tooltip_text:
        "Total leads in the system which requested for this service which are under 'Completed' status",
    },
    {
      title: "Status",
      field: "Status",

      sort_key: "services.status",
      minWidth: 110,
      width: 110,
      wrap: true,
      align: "center",
    },
    {
      title: "Updated On",
      field: "Updated On",

      sort_key: "services.updated_at",
      minWidth: 165,
      width: 165,
      wrap: true,
      render: (row, v) => (
        <div>{v ? moment(v).format("DD-MMM-YYYY HH:mm") : "--"}</div>
      ),
      // align: "end",
    },
    {
      title: "Created On",
      field: "Created On",

      sort_key: "services.created_at",
      minWidth: 165,
      width: 165,
      wrap: true,
      // align: "end",
      render: (row, v) => (
        <div>{v ? moment(v).format("DD-MMM-YYYY HH:mm") : "--"}</div>
      ),
    },
  ])

  const handleSort = row => {
    let nextDirection = row.sort_direction
      ? row?.sort_direction === "ASC"
        ? "DESC"
        : "ASC"
      : "ASC"
    columns.forEach(col => {
      if (col.field === row.field) {
        col["sort_direction"] = nextDirection
      } else {
        col["sort_direction"] = undefined
      }
    })
    setColumns(() => columns)

    let sortkey = `&sort_by[${row.sort_key}]=${nextDirection}`
    setSortKey(sortkey)
  }
  const handleSearch = val => {
    setSearch(val)
    let searchString =
      val && val !== ""
        ? `&search[services.name]=${val}&search[service_categories.name]=${val}&search[services.display_id]=${val}`
        : undefined
    setPageNo(1)
    setAppliedSearch(searchString)
  }

  const onFilterSubmission = fil => {
    setFilters(fil)
    let filterString = undefined
    filterString = `${
      fil?.serviceTypes?.value
        ? "&filter[service_types.id]=" + fil?.serviceTypes?.value
        : ""
    }${
      fil?.serviceRanks?.value
        ? "&filter[service_ranks.id]=" + fil?.serviceRanks?.value
        : ""
    }${
      fil?.status?.value ? "&filter[services.status]=" + fil?.status?.value : ""
    }${
      fil?.updatedOn?.length === 2
        ? "&filter[services.updated_at]=" +
          moment(fil.updatedOn[0]).format("YYYY-MM-DD") +
          " - " +
          moment(fil.updatedOn[1]).format("YYYY-MM-DD")
        : ""
    }${
      fil?.createdOn?.length === 2
        ? "&filter[services.created_at]=" +
          moment(fil.createdOn[0]).format("YYYY-MM-DD") +
          " - " +
          moment(fil.createdOn[1]).format("YYYY-MM-DD")
        : ""
    }`
    setPageNo(1)
    setAppliedFilters(filterString ?? "")
  }
  const getTableActions = rowData => {
    return [
      {
        icon: <i className="mr-1" />,
        action: "edit",
        name: "View",
        onClick: handleTableMenu,
      },
      // {
      //   icon: <i className="mr-1" />,
      //   action: "status",
      //   name: "Change Status",
      //   onClick: handleTableMenu,
      // },
      // {
      //   icon: <i className="mr-1 text-danger" />,
      //   action: "delete",
      //   name: "Delete",
      //   class: "text-danger",
      //   onClick: handleTableMenu,
      // },
    ]
  }
  const handleOnPageChange = p => {
    setPageNo(p.page)
    setPageSize(p.pageSize)
  }
  if (isFetching) {
    return <CustomSpinner />
  }
  return (
    <>
      {/* {manageStatus && (
      <ManageStatus
        submiting={submiting}
        data={{
          lead_status: {
            value: activeItem.lead_status?.id,
            label: activeItem.lead_status?.name,
          },
        }}
        onCancelAction={closeChangeStatus}
        onSubmitAction={handleChangeStatus}
      />
    )} */}
      <Suspense fallback={<CustomSpinner />}>
        <CustomTable
          columns={columns}
          data={data}
          actions={rowData => {
            return getTableActions(rowData)
          }}
          currentPage={pageNo}
          totalCount={totalCount}
          pageSize={pageSize}
          search={search}
          filters={filters}
          onSearch={handleSearch}
          onFilterSubmission={onFilterSubmission}
          filterComponent={UserFilterComponent}
          pagination={{
            onPageChange: handleOnPageChange,
          }}
          isFetching={isFetching}
          onSort={handleSort}
          detailsTable={true}
        />
      </Suspense>
    </>
  )
}

ServiceListing.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ServiceListing)
