import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import { ToastProvider } from "react-toast-notifications"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"

import { Provider } from "react-redux"

import store from "./store"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <ToastProvider
    newestOnTop
    autoDismiss
    autoDismissTimeout={6000}
    placement="bottom-right"
  >
    <Provider store={store}>
      <React.Fragment>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.Fragment>
    </Provider>
  </ToastProvider>
)

// serviceWorker.unregister()
