import moment from "moment"
import React from "react"
import { useEffect, useRef } from "react"
import { useState } from "react"
import { Link } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import { Button, Col, Container, Row } from "reactstrap"

import Breadcrumb from "components/Common/Breadcrumb"
import { AUTH_ROUTES } from "constants/routes"
import { getUserTypeList, postUserCreate } from "helpers/api_actions"
import { emailValidator, generateErrorMessage, isValid, validate } from "helpers/utilities"

import UserManageSection from "../component/common/manageSection"

// Pages Components

const CreateUser = props => {
  const { addToast } = useToasts()
  const [formData, setFormData] = useState({
    address_type: { label: "Home", value: "Home" },
    country: { label: "India", name: "India", value: "India" },
  })
  const userRef = useRef(null)
  const [submitting, setsubmitting] = useState(false)
  const [inputError, setInputError] = useState({})
  const [userTypeId, setUserTypeId] = useState("")
  const fetchCurrentUserType = () => {
    let payload = { select: "id" }
    let filter = "&find[name]=PlatformUser&paginate=false"
    getUserTypeList(payload, filter).then(res => {
      let id = res.data[0]?.id
      setUserTypeId(id)
    })
  }
  useEffect(() => {
    fetchCurrentUserType()
  }, [])
  const handleSubmit = data => {
    setsubmitting(true)
    setFormData({ ...formData, ...data })

    if (emailValidator(data?.email)) {
      let payload = new FormData()

      if (isValid(data?.full_name)) {
        payload.append("full_name", data?.full_name)
      }
      if (isValid(userTypeId)) {
        payload.append("user_type_id", userTypeId)
      }
      if (isValid(data?.date_of_birth)) {
        payload.append(
          "date_of_birth",
          data?.date_of_birth
            ? moment(data?.date_of_birth[0]).format("YYYY-MM-DD")
            : ""
        )
      }
      if (isValid(data?.nominee_date_of_birth)) {
        payload["nominee_date_of_birth"] =
          data?.nominee_date_of_birth && data.nominee_date_of_birth.length > 0
            ? moment(data?.nominee_date_of_birth[0]).format("YYYY-MM-DD")
            : ""
      }
      if (isValid(data?.primary_phone)) {
        payload.append("primary_phone", `+91${data?.primary_phone}` ?? "")
      }
      if (isValid(data?.email)) {
        payload.append("email", data?.email)
      }
      if (isValid(data?.image)) {
        payload.append("image", data?.image ?? "")
      }
      if (isValid(data?.address_type)) {
        payload.append("address_type", data?.address_type?.value ?? "")
      }
      if (isValid(data?.gender)) {
        payload.append("gender", data?.gender?.value ?? "")
      }
      if (isValid(data?.working_as)) {
        payload.append("working_as", data?.working_as?.value ?? "")
      }
      if (isValid(data?.qualification)) {
        payload["qualification"] = data?.qualification?.value ?? ""
      }
      if (isValid(data?.address_line_1)) {
        payload.append("address_line_1", data?.address_line_1 ?? "")
      }
      if (isValid(data?.address_line_2)) {
        payload.append("address_line_2", data?.address_line_2 ?? "")
      }
      if (isValid(data?.city?.value)) {
        payload.append("city", data?.city?.value ?? "")
      }
      if (isValid(data?.state?.value)) {
        payload.append("state", data?.state?.value ?? "")
      }
      if (isValid(data?.country?.value)) {
        payload.append("country", data?.country?.value ?? "")
      }
      if (isValid(data?.pincode)) {
        payload.append("pincode", data?.pincode ?? "")
      }
      if (isValid(data?.refferer?.value)) {
        payload.append("refferer_id", data?.refferer?.value ?? "")
      }
      if (isValid(data?.job)) {
        payload.append("job", data?.job.value ?? "")
      }
      if (isValid(data?.rank)) {
        payload.append("rank_id", data?.rank.id ?? "")
      }
      if (isValid(data?.business_name)) {
        payload.append("business_name", data?.business_name ?? "")
      }
      if (isValid(data?.nominee_name)) {
        payload.append("nominee_name", data?.nominee_name ?? "")
      }
      if (isValid(data?.nominee_relation)) {
        payload.append("nominee_relation", data?.nominee_relation ?? "")
      }
      if (isValid(data?.password)) {
        payload.append("password", data?.password ?? "")

        payload.append("confirm_password", data?.password ?? "")
      }

      postUserCreate(payload)
        .then(res => {
          setsubmitting(false)
          addToast(res?.message, { appearance: "success" })
          props.history.push(AUTH_ROUTES.USERS)
        })
        .catch(err => {
          setsubmitting(false)
          addToast(generateErrorMessage(err?.response?.data), {
            appearance: "error",
          })
        })
    } else {
      setsubmitting(false)
      addToast("Please enter a Valid Email", { appearance: "error" })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <div className="page-title-left">
                  <Breadcrumb title="Users" breadcrumbItem="Create User" />
                  <h4 className="mb-0 font-size-18">Create User</h4>
                </div>
                <div className="page-title-right">
                  <Button
                    className="btn btn-secondary"
                    onClick={() => {
                      props.history.push(AUTH_ROUTES.USERS)
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn btn-primary"
                    disabled={submitting}
                    onClick={() => {
                      userRef.current && userRef.current.onSubmitAction()
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <form
            onSubmit={e => userRef.current && userRef.current.onSubmitAction(e)}
          >
            <UserManageSection
              ref={userRef}
              data={formData}
              // errors={inputError}
              isReadOnly={false}
              handleSubmit={handleSubmit}
            />
          </form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CreateUser
