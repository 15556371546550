import React from "react"
import { useEffect } from "react"
import { useState } from "react"
import { Button, Col, Row } from "reactstrap"

import SelectfieldComponent from "components/Common/SelectfieldComponent"
import CustomCalander from "components/CustomCalendar"
import SearchSelect from "components/CustomSearchSelect"
import CustomSelect from "components/CustomSelect/index"
import {
  getCitiesList,
  getLeadStatusesList,
  getServicesList,
  getUserList,
} from "helpers/api_actions"
import { getCreUsers } from "helpers/dropdown_helper"

const LeadFilterComponent = props => {
  const [selectedCity, setSelectedCity] = useState(props.filters?.city)
  const [selectedStatus, setSelectedStatus] = useState(props.filters?.status)
  const [selectedService, setSelectedService] = useState(props.filters?.service)
  const [statuses, setStatuses] = useState([])
  const [services, setServices] = useState([])
  const [handledBy, setHandledBy] = useState(props.filters?.handledBy)
  const [leadOwner, setLeadOwner] = useState(props.filters?.leadOwner)
  const [selectedCreatedOn, setSelectedCreatedOn] = useState(
    props.filters?.createdOn
  )
  const [selectedUpdatedOn, setSelectedUpdatedOn] = useState(
    props.filters?.updatedOn
  )
  const defaultSelection = null
  const onApplyFilter = () => {
    let filters = {
      city: selectedCity,
      status: selectedStatus,
      service: selectedService,
      updatedOn: selectedUpdatedOn,
      createdOn: selectedCreatedOn,
      handledBy: handledBy,
      leadOwner: leadOwner,
    }
    props.onFilterSubmission(filters)
  }
  const onCancelFilter = () => {
    setSelectedCity(undefined)
    setSelectedCreatedOn(undefined)
    setSelectedService(undefined)
    setSelectedUpdatedOn(undefined)
    setSelectedStatus(undefined)
    setHandledBy(undefined)
    setLeadOwner(undefined)
    props.onFilterSubmission({
      city: undefined,
      rank: undefined,
      status: undefined,
      updatedOn: undefined,
      createdOn: undefined,
      handledBy: undefined,
      leadOwner: undefined,
    })
  }
  const getCities = async v => {
    let payload = { select: "cities.name as name" }
    let filter = `&search[cities.name]=${v}`
    const res = await getCitiesList(payload, filter)
    const data = res?.data?.data || []
    data.forEach(item => {
      item["label"] = item.name
      item["value"] = item.name
    })
    return data
  }
  const getUsers = async v => {
    let payload = {
      select: "users.id,full_name",
      include: `user_type&find[user_type.name]=AdminUser&search[full_name]=${v}`,
    }

    const res = await getUserList(payload)
    const data = res?.data?.data || []
    data.forEach(item => {
      item["label"] = `${item["full_name"]}`
      item["value"] = item.id
    })
    return data
  }
  const getLeadOwners = async v => {
    let payload = {
      select: `users.id,full_name&search[full_name]=${v}`,
    }

    const res = await getUserList(payload)
    const data = res?.data?.data || []
    data.forEach(item => {
      item["label"] = `${item["full_name"]}`
      item["value"] = item.id
    })
    return data
  }
  const getLeadStatus = () => {
    let payload = { select: "id,name", paginate: false }

    getLeadStatusesList(payload).then(res => {
      let data = res?.data || []
      data.forEach(item => {
        item["label"] = item.name
        item["value"] = item.id
      })

      setStatuses(data)
    })
  }
  const getServices = async v => {
    let payload = {
      select: `id,name&search[name]=${v}`,
    }

    return getServicesList(payload).then(res => {
      let data = res?.data.data || []
      data.forEach(item => {
        item["label"] = item.name
        item["value"] = item.id
      })
      setServices(data)

      return data
    })
  }
  useEffect(() => {
    getLeadStatus()
    getServices()
  }, [])
  return (
    <>
      <Row>
        <Col lg={3} md={4} sm={6} xs={12}>
          <SearchSelect
            getData={getCities}
            isMulti={false}
            label="City"
            setData={v => {
              setSelectedCity(v)
            }}
            value={selectedCity ?? defaultSelection}
          />
        </Col>
      
        <Col lg={3} md={4} sm={6} xs={12}>
          <SearchSelect
            getData={getServices}
            isMulti={false}
            label="Service"
            autoload={true}
            setData={v => {
              setSelectedService(v)
            }}
            value={selectedService ?? defaultSelection}
          />
        </Col>
        <Col lg={3} md={4} sm={6} xs={12}>
          <CustomCalander
            dateRange
            value={selectedUpdatedOn}
            onChange={date => setSelectedUpdatedOn(date)}
            label="Updated Between"
            placeholder={"Start Date - End Date"}
            maxDate={"today"}
          />
        </Col>
        <Col lg={3} md={4} sm={6} xs={12}>
          <CustomCalander
            dateRange
            maxDate={"today"}
            placeholder={"Start Date - End Date"}
            value={selectedCreatedOn}
            onChange={date => setSelectedCreatedOn(date)}
            label="Created Between"
          />
        </Col>
        <Col lg={3} md={4} sm={6} xs={12}>
          <SearchSelect
            getData={getCreUsers}
            isMulti={false}
            label="Handled By"
            autoload={false}
            setData={v => {
              setHandledBy(v)
            }}
            value={handledBy ?? null}
          />
        </Col>
        <Col lg={3} md={4} sm={6} xs={12}>
          <SearchSelect
            getData={getLeadOwners}
            isMulti={false}
            label="Lead Owner"
            autoload={false}
            setData={v => {
              setLeadOwner(v)
            }}
            value={leadOwner ?? null}
          />
        </Col>
        <Col
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className="d-flex align-items-end justify-content-start pt-3"
        >
          <div className="me-2">
            <Button
              className="btn btn-sm btn-primary btn-success text-white"
              onClick={() => onApplyFilter()}
            >
              Apply
            </Button>
          </div>
          <div>
            <Button
              className="btn btn-sm btn-danger"
              onClick={() => onCancelFilter()}
            >
              Reset
            </Button>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default LeadFilterComponent
