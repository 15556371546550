import { Path } from "leaflet"
import create from "zustand"

import { menuData } from "./menudatas"

export const useSideMenuStore = create(set => ({
  sideMenuData: [...menuData],

  toggleSubmenus: id =>
    set(state => ({
      sideMenuData: state.sideMenuData.map((item, index) =>
        index === id ? { ...item, expand: !item.expand } : item
      ),
    })),

  reverseToggleSubmenus: () =>
    set(state => ({
      sideMenuData: state.sideMenuData.map(item => ({
        ...item,
        expand: false, 
      })),
    })),

}))
