import React, { useEffect, useState } from "react"
import { useToasts } from "react-toast-notifications"
import { Col, Input, Modal, Row } from "reactstrap"

import SearchSelect from "components/CustomSearchSelect/index"
import CustomSelect from "components/CustomSelect/index"
import CustomTextField from "components/CustomTextField/index"
import { getCreUsersList, getLeadStatusesList, getPreSaletatusesList } from "helpers/api_actions"
import { validateStatus } from "helpers/utilities"

const required = ["status"]
const ManageStatus = props => {
  const [formData, setFormData] = useState(props.data || {})
  const [statuses, setStatuses] = useState([])
  const [errs, setErrs] = useState({})
  const { addToast } = useToasts()
    const [activeItem, setActiveItem] = useState({})


  const handleChange = (v, k) => {
    const error = validateStatus(required, { ...formData, [k]: v }, "")
    if (k === "status" && v?.label !== "Converted") {
      setFormData(f => ({ ...f, [k]: v }))
    }
  }
  const handleSubmit = () => {
    const errors = validateStatus(required, formData, "")
    setErrs(errors)
    if (Object.keys(errors).length === 0) {
      props.onSubmitAction(formData)
    }
  }
  const getStatus = () => {
    let payload = { select: "id,name", paginate: false }

    getPreSaletatusesList(payload).then(res => {
      let data = res?.data || []
      data = data.filter(item => item.name !== "Converted");
      data.forEach(item => {
        item["label"] = item.name
        item["value"] = item.id
      })
      setStatuses(data)
    })
  }
  useEffect(() => {
    getStatus()
  }, [])

  useEffect(() => {
    if(!props.isDetailsFlag){
    if(formData.pre_sale_status){
      let data = {"label":formData.pre_sale_status,"id": formData.pre_sale_status_id,"value": formData.pre_sale_status_id}
      setActiveItem(data)
    }  }
    else{
      let data = {"label":formData.pre_sale_status.name,"id": formData.pre_sale_status.id,"value": formData.pre_sale_status_id}
      setActiveItem(data)
    }
    
  }, [])

  useEffect(() => {
    if (activeItem) {
      setFormData(prevState => ({
        ...prevState,
        status: activeItem
      }));
    }
  }, [activeItem]);
 

  return (
    <Modal isOpen={true} toggle={props.onCancelAction} centered={true}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          Change Presale Lead Status
        </h5>
        <button
          type="button"
          onClick={() => {
            props.onCancelAction()
          }}
          className="btn-close"
        ></button>
      </div>
      <div className="modal-body">
        <form>
          <div className=" mb-3">
            <CustomSelect
              label="Status"
              required
              options={statuses}
              setData={v => {
                handleChange(v, "status")
              }}
              value={formData.status ? formData.status: activeItem}
              isClearable={false}
            />
            {errs?.status && (
              <p className="text-danger"> {errs?.status}</p>
            )}
          </div>
          
          {/* <div className=" mb-3">
            <CustomTextField
              label="Remarks"
              name="presale_note"
              type="textarea"
              // required
              value={formData?.presale_note ?? ""}
              placeholder="Enter Remarks"
              onChange={(v, k, e) => handleChange(v, k, e)}
            />
          </div> */}
        </form>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          data-bs-dismiss="modal"
          onClick={() => {
            props.onCancelAction()
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={e => handleSubmit(e)}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}
export default ManageStatus
