import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"

function SidebarItems({
  menu,
  toggleSubmenus,
  reverseToggleSubmenus,
  pathname,
}) {
  const [iconClass, setIconClass] = useState("")

  useEffect(() => {
    setIconClass(
      menu?.submenus && menu?.expand
        ? "fa fa-caret-up ms-2"
        : "fa fa-caret-down ms-2"
    )
  }, [menu?.submenus, menu?.expand])

  const items = pathname.split("/")
  const itemPath = "/" + items[1]

  return (
    <li>
      {menu?.submenus ? (
        <React.Fragment>
          <a
            onClick={() => {
              toggleSubmenus()
            }}
          >
            <i className={menu?.icon} />
            <span>{menu?.label}</span>
            <i className={iconClass} />
          </a>
          {menu?.expand && menu?.submenus && (
            <ul className="sub-menu">
              {menu?.submenus.map((submenu, i) => (
                <li
                  key={i}
                  className={
                    submenu.path == itemPath
                      ? "submenu-background-color text-white"
                      : ""
                  }
                >
                  <Link to={submenu.path}>
                    <i className={submenu.icon} />
                    <span>{submenu.label}</span>
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </React.Fragment>
      ) : (
        <>
          <Link
            to={menu.path}
            className=""
            onClick={() => {
              reverseToggleSubmenus()
            }}
          >
            <i className={menu.icon} />
            <span>{menu.label}</span>
          </Link>
        </>
      )}
      {menu?.border && (
        <hr
          style={{
            width: "100%",
            borderTop: "1px solid gray",
          }}
        />
      )}
    </li>
  )
}

export default SidebarItems
