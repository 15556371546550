import { TAB_LABELS } from "constants/label"

export const PARTNERSHIP_TAB_LABELS = [
  {
    id: "partnership-details",
    label: TAB_LABELS.PARTNERSHIP_DETAILS,
  },
  {
    id: "partnership-logs",
    label: TAB_LABELS.PARTNERSHIP_REQUEST_LOGS,
  },
]

export const PARTNERSHIP_STATUS_OPTIONS = [
  { label: "New", value: "New" },
  { label: "Processing", value: "Processing" },
  { label: "Closed", value: "Closed" },
  { label: "Resolved", value: "Resolved" },
]
