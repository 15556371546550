import moment from "moment"
import React, {
  forwardRef,
  lazy,
  Suspense,
  useEffect,
  useImperativeHandle,
  useState,
} from "react"
import { useToasts } from "react-toast-notifications"
import Swal from "sweetalert2"

import CustomSpinner from "components/customSpinner"
import { AUTH_ROUTES } from "constants/routes"
import { deleteCustomerService, getServicesList,getCustomerService } from "helpers/api_actions"

import UserFilterComponent from "./filterComponent"
import { Link,useHistory } from "react-router-dom"
import { isAdmin } from "helpers/utilities"
const CustomTable = lazy(() => import("components/customTable"))
const CustomerService = forwardRef((props, ref) => {
  CustomerService.displayName = "Services"
  const { addToast } = useToasts()
  const [pageSize, setPageSize] = useState(15)
  const [data, setData] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [pageNo, setPageNo] = useState(1)
  const [search, setSearch] = useState("")
  const [filters, setFilters] = useState({})
  const [sortKey, setSortKey] = useState("&sort_by[services.name]=ASC")
  const [appliedFilters, setAppliedFilters] = useState("")
  const [appliedSeach, setAppliedSearch] = useState("")
  const [isFetching, setIsFetching] = useState(false)
  const history = useHistory();
  const navigateToDetails = (id, isEdit = false) => {
      const url = `${AUTH_ROUTES.SERVICE_MANAGEMENT_DETAILS}${id}`;
      history.push(url);
  }
  const listServices = params => {
    let basicData = {
      include: `services,service_categories,service_types,service_ranks`,
      select: `customer_services.id,services.id as service_id,services.name as Service Name,service_categories.name as Category Name,service_types.name as Service Type,service_ranks.name as Service Rank,Direct Amt,Referer Amt,Active Leads,Closed Leads,services.status as Status,services.created_at as Created On,services.updated_at as Updated On&find[lead_id]=${props.parentID}&page=${pageNo}&per_page=${pageSize}`,
    }
    setIsFetching(true)
    getCustomerService(basicData, appliedSeach, appliedFilters, sortKey)
      .then(res => {
        setData(res?.data?.data)
        setTotalCount(res?.data?.total || 0)
        setIsFetching(false)
      })
      .catch(err => {
        setIsFetching(false)
      })
  }
  useImperativeHandle(ref, () => ({
    refetch() {
      listServices()
    },
  }))
  useEffect(() => {
    listServices()
  }, [pageSize, pageNo, appliedSeach, sortKey, appliedFilters])

  const handleDelete = ro => {
    deleteCustomerService(ro.id)
    .then(res => {
      addToast(res?.message, { appearance: "success" })
      listServices()
    })
    .catch(err => {
      addToast(err.response?.data?.message, { appearance: "error" })
    })
  }
  const handleTableMenu = (action, rowData) => {
    switch (action.action) {
      case "edit":
        navigateToDetails(rowData.service_id, true)
        break

      case "delete":
        handleDelete(rowData)
        break
      default:
        break
    }
  }
  const [columns, setColumns] = useState([
    {
      title: "Service Name",
      field: "Service Name",
      sort_direction: "ASC",
      sort_key: "services.name",
      minWidth: 260,
      width: 260,
      wrap: true,
      render: (r, v) => {
        const isAdminUser = isAdmin();
      
        if (isAdminUser) {
          return (
            <Link
              className="text-link"
              to={{
                pathname: `${AUTH_ROUTES.SERVICE_MANAGEMENT_DETAILS}${r.service_id}`,
                state: { edit: false },
              }}
            >
              {v}
            </Link>
          );
        } else {
          return <span>{v}</span>;
        }
      },
    },
    {
      title: "Category Name",
      // sort_key: "users.primary_phone",
      field: "Category Name",

      sort_key: "service_categories.name",

      minWidth: 260,
      width: 260,
      wrap: true,
    },
    // {
    //   title: "Service Type",
    //   field: "Service Type",

    //   sort_key: "service_types.name",
    //   minWidth: 150,
    //   width:150,
    //   wrap:true,
    //   // align: "center",
    // },

    {
      title: "Service Rank",
      field: "Service Rank",
      minWidth: 150,
      width: 150,
      wrap: true,

      sort_key: "service_ranks.name",
      // align: "center",
    },
    {
      title: "Direct Amt.",
      field: "directamt",

      sort_key: "directamt",
      minWidth: 150,
      width: 150,
      wrap: true,
      align: "end",
      tooltip_text:
        "Commission amount (active at this time) for the user when the user gives the leads for a service",
    },
    {
      title: "Referer Amt.",
      field: "refereramt",

      sort_key: "refereramt",
      minWidth: 150,
      width: 150,
      wrap: true,
      align: "end",
      tooltip_text:
        "Commission amount (active at this time) for a referrer user when one of his referees gives the leads for a service",
    },
    {
      title: "Active Leads",
      field: "Active Leads",

      sort_key: "Active Leads",
      minWidth: 150,
      width: 150,
      wrap: true,
      align: "center",
      tooltip_text:
        "Total leads in the system which requested for this service which are under 'New', 'Working', 'Nurturing' status",
    },
    {
      title: "Completed Leads",

      field: "Closed Leads",

      sort_key: "Closed Leads",
      minWidth: 170,
      width: 170,
      wrap: true,
      align: "center",
      tooltip_text:
        "Total leads in the system which requested for this service which are under 'Completed' status",
    },
    {
      title: "Status",
      field: "Status",

      sort_key: "services.status",
      minWidth: 110,
      width: 110,
      wrap: true,
      align: "center",
    },
    {
      title: "Updated On",
      field: "Updated On",

      sort_key: "services.updated_at",
      minWidth: 165,
      width: 165,
      wrap: true,
      render: (row, v) => (
        <div>{v ? moment(v).format("DD-MMM-YYYY HH:mm") : "--"}</div>
      ),
      // align: "end",
    },
    {
      title: "Created On",
      field: "Created On",

      sort_key: "services.created_at",
      minWidth: 165,
      width: 165,
      wrap: true,
      // align: "end",
      render: (row, v) => (
        <div>{v ? moment(v).format("DD-MMM-YYYY HH:mm") : "--"}</div>
      ),
    },
  ])

  const handleSort = row => {
    let nextDirection = row.sort_direction
      ? row?.sort_direction === "ASC"
        ? "DESC"
        : "ASC"
      : "ASC"
    columns.forEach(col => {
      if (col.field === row.field) {
        col["sort_direction"] = nextDirection
      } else {
        col["sort_direction"] = undefined
      }
    })
    setColumns(() => columns)

    let sortkey = `&sort_by[${row.sort_key}]=${nextDirection}`
    setSortKey(sortkey)
  }
  const handleSearch = val => {
    setSearch(val)
    let searchString =
      val && val !== ""
        ? `&search[services.name]=${val}&search[service_categories.name]=${val}&search[services.display_id]=${val}`
        : undefined
    setPageNo(1)
    setAppliedSearch(searchString)
  }

  const onFilterSubmission = fil => {
    setFilters(fil)
    let filterString = undefined
    filterString = `${
      fil?.serviceCategories?.value
        ? "&filter[service_categories.id]=" + fil?.serviceCategories?.value
        : ""
    }${
      fil?.serviceTypes?.value
        ? "&filter[service_types.id]=" + fil?.serviceTypes?.value
        : ""
    }${
      fil?.serviceRanks?.value
        ? "&filter[service_ranks.id]=" + fil?.serviceRanks?.value
        : ""
    }${
      fil?.status?.value ? "&filter[services.status]=" + fil?.status?.value : ""
    }${
      fil?.updatedOn?.length === 2
        ? "&filter[services.updated_at]=" +
          moment(fil.updatedOn[0]).format("YYYY-MM-DD") +
          " - " +
          moment(fil.updatedOn[1]).format("YYYY-MM-DD")
        : ""
    }${
      fil?.createdOn?.length === 2
        ? "&filter[services.created_at]=" +
          moment(fil.createdOn[0]).format("YYYY-MM-DD") +
          " - " +
          moment(fil.createdOn[1]).format("YYYY-MM-DD")
        : ""
    }`
    setPageNo(1)
    setAppliedFilters(filterString ?? "")
  }
  const getTableActions = rowData => {
    const isAdminUser = isAdmin();
    const actions = [];
      if (isAdminUser) {
      actions.push({
        icon: <i className="mr-1" />,
        action: "edit",
        name: "View",
        onClick: handleTableMenu,
      });
      actions.push({
        icon: <i className="mr-1 text-danger" />,
        action: "delete",
        name: "Delete",
        class: "text-danger",
        onClick: handleTableMenu,
      });
    }
  
    return actions;
  }
  const handleOnPageChange = p => {
    setPageNo(p.page)
    setPageSize(p.pageSize)
  }

  return (
    <Suspense fallback={<CustomSpinner />}>
      <CustomTable
        columns={columns}
        data={data}
        actions={rowData => {
          return getTableActions(rowData)
        }}
        currentPage={pageNo}
        totalCount={totalCount}
        pageSize={pageSize}
        search={search}
        filters={filters}
        onSearch={handleSearch}
        onFilterSubmission={onFilterSubmission}
        filterComponent={UserFilterComponent}
        pagination={{
          onPageChange: handleOnPageChange,
        }}
        isFetching={isFetching}
        onSort={handleSort}
      />
    </Suspense>
  )
})

export default CustomerService
