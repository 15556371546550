import React from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import moment from "moment"
import ReactHtmlParser from "react-html-parser"
import { Link } from "react-router-dom"
function UserActivityLogCard({ data = {} }) {
  const getAddOns = key => {
    let value = "--"
    if (data?.additional_data) {
      let addon = JSON.parse(data.additional_data)
      value = addon[key] || "--"
    }
    return value
  }
  return (
    <div className="w-100 my-2 log-card">
      <Row className="bg-white white-card">
        <Col xs={2} className="left">
          <div className="d-flex flex-column justify-content-center h-100">
            <div className="label">Created on </div>
            <div className="label">
              {data?.created_at
                ? moment(data.created_at).format("DD-MM-YYYY HH:mm zz")
                : "--"}{" "}
            </div>
          </div>
        </Col>
        <Col xs={10} className="right">
          <div className="d-flex flex-column align-items-between ">
            <div className="py-1 event d-flex align-items-center text-primary">
              {data?.activity_type ?? "--"}
            </div>
            <div className="py-1 d-flex align-items-center desc">
              <p className="m-0 ">
                {data?.additional_data && (
                  <div>
                    <ul>
                    {
                      data?.activity_type == "Added UPI Account" && (
                        <>
                        <li>UPI ID: {getAddOns("upi_id")}</li>
                        <li>Primary Account: {getAddOns("primary_account") === true ? "Yes" : "No"}</li>
                        </>
                      )
                    }
                    {
                      data?.activity_type == "Added Bank Account" && (
                        <>
                        <li>Account Number: {getAddOns("account_number")}</li>
                        <li>Account Type: {getAddOns("account_type")}</li>
                        <li>Account Name: {getAddOns("account_name")}</li>
                        <li>Bank Name: {getAddOns("bank_name")}</li>
                        <li>Branch Name: {getAddOns("branch_name")}</li>
                        <li>IFSC Code: {getAddOns("ifsc_code")}</li>
                        <li>Primary Account: {getAddOns("primary_account") === true ? "Yes" : "No"}</li>
                        </>
                      )
                    }
                    {
                      data?.activity_type == "Invited Team Member" && (
                        <>
                        <li>Name: {getAddOns("name")}</li>
                        <li>Phone: {getAddOns("phone")}</li>
                        <li>Email: {getAddOns("email")}</li>
                        </>
                      )
                    }
                    {
                      data?.activity_type == "Service Visited" && (
                        <>
                        <li>Service Name: <Link to={`/services/view/${getAddOns("id")}`} target="_blank">{getAddOns("name")}</Link></li>
                        </>
                      )
                    }
                    {
                      data?.activity_type == "Lead Visited" && (
                        <>
                        <li>Service Name: <Link to={`/leads/view/${getAddOns("id")}`} target="_blank">{getAddOns("name")}</Link></li>
                        </>
                      )
                    }
                    </ul>
                  </div>
                )}
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default UserActivityLogCard
