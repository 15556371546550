import moment from "moment"
import React from "react"
import { forwardRef } from "react"
import { useImperativeHandle, useRef } from "react"
import { useToasts } from "react-toast-notifications"

import LeadManagement from "components/Leads/lead_form"
import { postLeadCreate, putLeadUpdate } from "helpers/api_actions"
import { emailValidator, isValid, validate } from "helpers/utilities"

const LeadDetails = forwardRef((props, ref) => {
  LeadDetails.displayName = "GeneralLead Details"
  const { addToast } = useToasts()
  const editRef = useRef(null)
  useImperativeHandle(ref, () => ({
    update(e) {
      editRef.current.onSubmitAction()
    },
  }))
  const handleSubmit = data => {
    props.setSubmiting(true)
    if (
      (data.primary_phone && data.primary_phone.length) ||
      (data.primary_email && data.primary_email.length)
    ) {
      if (
        data.primary_email &&
        data.primary_email.length &&
        !emailValidator(data?.primary_email)
      ) {
        props.setSubmiting(false)
        addToast("Please enter a Valid Email", { appearance: "error" })
      } else if (data.primary_phone && data.primary_phone.length !== 10) {
        props.setSubmiting(false)
        addToast("Phone number should have 10 digits", {
          appearance: "error",
        })
      } else {
        let payload = {
          lead_type_id: data?.lead_type?.value ?? null,
          primary_email: data?.primary_email ?? null,
          name: data?.name ?? null,
          owner: data?.owner?.value ?? null,
          primary_phone: data?.primary_phone
            ? `+91${data?.primary_phone}`
            : null,
          service_id: data?.service?.value ?? null,
          address_type: data?.address_type?.value ?? null,
          address_line_1: data?.address_line_1 ?? null,
          address_line_2: data?.address_line_2 ?? null,
          city: data?.city?.label ?? null,
          state: data?.state?.label ?? null,
          country: data?.country?.label ?? null,
          pincode: data?.pincode ?? null,
          refferer_id: data?.refferer?.value ?? null,
          job: data?.job ?? null,
          business_name: data?.business_name ?? null,
          processed_by: data?.processed_by?.id ?? null,
        }
        putLeadUpdate(data.id, payload)
          .then(res => {
            addToast(res?.message, { appearance: "success" })
            props.refresh()
            props.setSubmiting(false)
          })
          .catch(err => {
            props.setSubmiting(false)
            // toastr.error(err.response.data.message)
            addToast(err?.response?.data?.message, { appearance: "error" })
          })
      }
    } else {
      props.setSubmiting(false)
      addToast("Please enter primary Email/Phone ", { appearance: "error" })
    }
  }
  let commision = {
    Own: props.data?.Own_commision,
    Team: props.data?.Team_commision,
  }
  const data = {
    id: props.data?.id,
    lead_type: {
      label: props?.data?.lead_type?.display_name,
      value: props?.data?.lead_type?.id,
    },
    phone: props?.data?.owner?.primary_phone,
    rank: props?.data?.lead_owner_rank,
    owner: {
      label: props?.data?.owner?.full_name,
      value: props?.data?.owner?.id,
    },
    ///////////////////////
    created_by: props?.data?.created_by?.full_name,
    handled_by: props?.data?.cre_user_name
      ? props?.data?.cre_user_name
      : undefined,
    updated_at: props?.data?.updated_at,
    created_at: props?.data?.created_at,
    processed_by: {
      label: props?.data?.processed_by?.full_name,
      value: props?.data?.processed_by?.id,
    },
    name: props.data?.name,
    business_name: props.data?.business_name,
    primary_email: props.data?.primary_email,

    primary_phone: props?.data?.primary_phone
      ? props.data?.primary_phone?.replace("+91", "")
      : null,

    email: props?.data?.email,
    user_type_id: props?.data?.user_type_id,
    address_line_1: props?.data?.primary_address?.address_line_1,
    address_line_2: props?.data?.primary_address?.address_line_2,

    job: props?.data?.platform_user_details?.job,
    pincode: props?.data?.primary_address?.pincode,
    address_type: {
      label: props?.data?.primary_address?.address_type,
      value: props?.data?.primary_address?.address_type,
    },
    service: {
      label: props?.data?.service?.name,
      value: props?.data?.service?.id,
    },
    city: props?.data?.city?.city
      ? {
          label: props?.data?.city?.city,
          value: props?.data?.city?.city,
        }
      : null,
    state: props?.data?.primary_address?.state
      ? {
          label: props?.data?.primary_address?.state,
          value: props?.data?.primary_address?.state,
        }
      : null,
    country: props?.data?.primary_address?.country
      ? {
          label: props?.data?.primary_address?.country,
          value: props?.data?.primary_address?.country,
        }
      : null,
  }
  return (
    <div>
      <LeadManagement
        data={data}
        ref={editRef}
        commision={commision}
        isReadOnly={!props.edit}
        edit={props.edit}
        handleSubmit={handleSubmit}
      />
    </div>
  )
})

export default LeadDetails
