import React, { useState, useEffect } from "react"
import { Col, Input, Modal, Row } from "reactstrap"

import CustomTextField from "components/CustomTextField/index"
import { validate } from "helpers/utilities"
import CustomSelect from "components/CustomSelect/index"
import { getPayoutModesList } from "helpers/api_actions"
let required = ["status"]
const GenerateCreditReport = props => {
  const handleSubmit = () => {
    if (props.onSubmitAction) {
      props.onSubmitAction()
    }
  }

  return (
    <Modal isOpen={true} toggle={props.onCancelAction} centered={true}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {props.title || "Generate Credit Report"}
        </h5>
        <button
          type="button"
          disabled={props.submiting}
          onClick={() => {
            props.onCancelAction()
          }}
          className="btn-close"
        ></button>
      </div>
      <div className="modal-body">
        <p>Are you sure you want to generate the credit report?</p>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          data-bs-dismiss="modal"
          disabled={props.submiting}
          onClick={() => {
            props.onCancelAction()
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          disabled={props.submiting}
          className="btn btn-primary"
          onClick={e => handleSubmit()}
        >
          Generate
        </button>
      </div>
    </Modal>
  )
}
export default GenerateCreditReport
