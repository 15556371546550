import CustomSpinner from "components/customSpinner"
import HeaderTitle from "components/Common/headerTitleComponent"
import { HEADER_LABELS, BUTTON_LABELS } from "constants/label"
import { AUTH_ROUTES } from "constants/routes"
import { DEFAULT_CLASSES } from "constants/styles"
import React, { Suspense, lazy, useState } from "react"
import { withRouter } from "react-router-dom"
import { Col, Container, Input, Modal, Row } from "reactstrap"
import PropTypes from "prop-types"
import {
  getPayoutList,
  getPayoutListExport,
  postPayoutImport,
} from "helpers/api_actions"
import {
  emailValidator,
  isValid,
  validate,
  generateErrorMessage,
} from "helpers/utilities"
import { useToasts } from "react-toast-notifications"
import moment from "moment"

//Import Breadcrumb
const PayoutListing = lazy(() => import("./listing"))
const Users = props => {
  const [isImportModalOpen, setIsImportModalOpen] = useState(false)
  const [formData, setFormData] = useState({})
  const [filters, setAppliedFilters] = useState("")
  const { addToast } = useToasts()
  const exportPayout = () => {
    let basicData = {
      select: `payout_requests.created_at as Request Date,payout_requests.request_number as Request ID,users.full_name as requested_by as Requested By,ranks.name as rank_name as User Rank,payout_requests.request_amount as Requested Amount,payout_requests.status as Status,commision_payments.amount_paid as Amount Paid,commision_payments.tds_deduction as TDS Deduction,commision_payments.payment_date as Payment Date,commision_payments.amount_paid as Amount Paid,commision_payments.transaction_id as Transaction ID,user_modified.full_name as Updated By,payout_requests.modified_at as Updated on,payout_requests.note as Request Remarks,commision_payments.note as Payout Note,commision_payments.mode_of_transfer_id AS Transfer Mode`,
      include: `users,platform_users,ranks,commision_payments,users as user_modified&export=true`,
    }
    getPayoutListExport("", filters)
      .then(res => {
        const outputFilename = `Payout_${moment().format("DDMMYYYY")}.csv`

        // If you want to download file automatically using link attribute.
        //   var blob = new Blob([res], {
        //     type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        // });
        var blob = new Blob([res], {
          type: "text/csv",
        })
        var link = document.createElement("a")
        link.href = window.URL.createObjectURL(blob)
        link.download = outputFilename

        document.body.appendChild(link)

        link.click()
        document.body.removeChild(link)
      })
      .catch(err => {
        setIsFetching(false)
      })
  }
  const headerActions = [
    {
      type: "button",
      label: BUTTON_LABELS.EXPORT,
      className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
      action: () => {
        exportPayout()
      },
    },
    {
      type: "button",
      label: BUTTON_LABELS.IMPORT,
      className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
      action: () => {
        setIsImportModalOpen(true)
      },
    },
    {
      type: "button",
      label: BUTTON_LABELS.IMPORT_HISTORY,
      className: DEFAULT_CLASSES.HEADER_BUTTON_PRIMARY,
      action: () => {
        props.history.push(AUTH_ROUTES.PAYOUT_IMPORT_HISTORY)
      },
    },
  ]

  const toggleImportModal = () => {
    setIsImportModalOpen(!isImportModalOpen)
    // this.removeBodyCss()
  }

  const handleImportFileChange = e => {
    let value = e.target.files[0]
    if (value.type.includes("text/csv")) {
      setFormData({ ...formData, file: value })
    }
  }

  const setFilters = filters => {
    setAppliedFilters(filters)
  }

  const submitData = () => {
    let payload = {}
    if (isValid(formData?.file)) {
      payload["file"] = formData?.file
    }
    postPayoutImport(payload)
      .then(res => {
        addToast(res?.message, { appearance: "success" })
      })
      .catch(err => {
        // toastr.error(err.response.data.message)
        addToast(generateErrorMessage(err?.response?.data), {
          appearance: "error",
        })
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <HeaderTitle title={HEADER_LABELS.PAYOUT} actions={headerActions} />
          </Row>
          <Row>
            <Col lg="12">
              <Suspense fallback={<CustomSpinner />}>
                <PayoutListing setFilters={setFilters} {...props} />
              </Suspense>
            </Col>
          </Row>
        </Container>
        <Modal
          isOpen={isImportModalOpen}
          toggle={toggleImportModal}
          centered={true}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Import Payout Data
            </h5>
            <button
              type="button"
              onClick={() => {
                setIsImportModalOpen(false)
              }}
              className="btn-close"
            ></button>
          </div>
          <div className="modal-body">
            <form>
              <div className=" mb-3">
                <Input
                  className="form-control"
                  type="file"
                  accept="text/csv"
                  // disabled={props.isReadOnly}
                  id="formFile"
                  onChange={handleImportFileChange}
                />
              </div>
              <div className="mb-3">
                <p>
                  Maximum upload file size: <strong>2 MB</strong>{" "}
                </p>
                <p>
                  File types supported: <strong>csv</strong>{" "}
                </p>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={() => {
                setIsImportModalOpen(false)
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={() => {
                submitData()
              }}
            >
              Save
            </button>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

Users.propTypes = {
  history: PropTypes.object,
}

export default withRouter(Users)
