import React, { useEffect, useRef, useState } from "react"
import { CSSTransition } from "react-transition-group"
import { Button, Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, InputGroup, Row, UncontrolledDropdown } from "reactstrap"

import tooltipImg from "assets/admin-images/tooltip.png"
import CustomCheckbox from "components/CustomCheckbox/index"

import TableIcons from "./components/icons/tableIcon"
import TableSkelton from "./components/skelton/tableSkelton"
import Table from "./components/table/Table"
import TableBody from "./components/table/TableBody"
import TableCell from "./components/table/TableCell"
import TableHead from "./components/table/TableHead"
import TableRow from "./components/table/TableRow"
import Pagination from "./pagination"

function DynamicTable({
  filterComponent,
  onFilterSubmission,
  filters,

  ...props
}) {
  const [showFilter, setShowFilter] = useState(false)
  const [columns, setColumns] = useState(props.columns)
  const [data, setData] = useState(props.data)
  const [pageSize, setPageSize] = useState(props.pageSize)
  const [isFetching, setIsFetching] = useState(props.isFetching)
  const [currentPage, setCurrentPage] = useState(props.currentPage)
  const [rowCount, setRowCount] = useState(props.rowCount)
  const [columnCount, setColumnCount] = useState(props.columnCount)
  const [totalCount, setTotalCount] = useState(props.totalCount)
  const [search, setSearch] = useState(props.search)
  const [selectAll, setSelectAll] = useState(false)
  const [selectedList, setSelectedList] = useState([])
  const [unselectedList, setUnselectedList] = useState([])
  const [finalRow, setFinalRow] = useState(props?.totalSalesForDate || [])
  const Filter = filterComponent
  const onPageNoChange = data => {
    props.pagination.onPageChange(data)
  }
  useEffect(() => {
    setColumns(props.columns)
    setData(props.data)
    setPageSize(props.pageSize)
    setIsFetching(props.isFetching)
    setCurrentPage(props.currentPage)
    setTotalCount(props.totalCount)
    setRowCount(props.rowCount)
    setColumnCount(props.columnCount)
  }, [
    props.columns,
    props.data,
    props.pageSize,
    props.isFetching,
    props.currentPage,
    props.totalCount,
  ])
  const valueRenderer = (row, col) => {
    let fieldrow = row[col.field]

    if (col.render) {
      return col.render(row, fieldrow || null)
    }
    return fieldrow
  }
  ///////
  const staticRowvalueRenderer = (row, value) => {
    let fieldrow = row[index]

    // if (col.render) {
    return render(row, fieldrow || null)
    // }
    return fieldrow
  }
  ///////////
  const filterref = useRef()
  const onSort = (cell, key) => {
    props.onSort(cell, key)
  }
  const isValid = value => {
    if (value !== null && value !== "" && value !== undefined) {
      return true
    }
    return false
  }

  useEffect(() => {
    setShowFilter(props.showFilter ?? false)
  }, [props.showFilter])

  const actionCellRenderer = (rowData, rowKey) => {
    return (
      <TableCell {...props} className={"action-columns"}>
        <UncontrolledDropdown className=" position-relative">
          <DropdownToggle
            tag="button"
            className="btn  btn-link p-0 arrow-none text-muted maxActionHeight"
          >
            <p className="btnbtn-sm btn-light m-0" color="primary">
              {props.actions(rowData) && props.actions(rowData).length > 0 && (
                <TableIcons icon="menu" />
              )}
            </p>
          </DropdownToggle>
          {props.actions(rowData) && props.actions(rowData).length > 0 && (
            <DropdownMenu end className="bg-white w-100">
              {props.actions(rowData).map((act, i) => (
                <DropdownItem
                  key={i}
                  className="position-relative bg-white w-100"
                  onClick={() => {
                    selectedAction(act, rowData, i, rowKey)
                  }}
                >
                  {act.icon}
                  <span className={`ml-1 ${act.class}`}>{act.name}</span>
                </DropdownItem>
              ))}
            </DropdownMenu>
          )}
        </UncontrolledDropdown>
      </TableCell>
    )
  }
  function selectedAction(action, key, rowData, rowKey) {
    action.onClick(action, key, rowData, rowKey)
  }
  function AlignmentContainer({ children, rowCell }) {
    return (
      <div
        className={`d-flex position-relative w-100 ${
          rowCell?.align
            ? "justify-content-" + rowCell?.align
            : rowCell?.headerAlign
            ? "justify-content-" + rowCell?.headerAlign
            : "justify-content-start"
        }`}
      >
        {children}
      </div>
    )
  }

  const handleSearchChange = e => {
    if (e.target.value) {
      setSearch(e.target.value)
    } else {
      setSearch("")
      clearSelection()
      props.onSearch("")
    }
  }
  const handleKeyDown = e => {
    if (e.key === "Enter") {
      clearSelection()
      props.onSearch(search)
    }
  }

  function onSelectAllClick() {
    if (!selectAll) {
      setSelectedList([])
    } else {
      setUnselectedList([])
    }
    props.onSelectAll(!selectAll)
    setSelectAll(selectAll => !selectAll)
  }

  useEffect(() => {
    if (props.onRowSelectionChange) {
      props?.onRowSelectionChange(selectedList, unselectedList)
    }
  }, [selectedList, unselectedList])

  function onRowSelectionClick(isChecked, rowData) {
    if (selectAll) {
      if (isChecked) {
        setUnselectedList(unselectedList =>
          unselectedList.filter(
            item => item !== rowData[props.selectRowIdentifierKey]
          )
        )
      } else {
        setUnselectedList(unselectedList => [
          ...unselectedList,
          rowData[props.selectRowIdentifierKey],
        ])
      }
    } else {
      if (isChecked) {
        setSelectedList(selectedList => [
          ...selectedList,
          rowData[props.selectRowIdentifierKey],
        ])
      } else {
        setSelectedList(selectedList =>
          selectedList.filter(
            item => item !== rowData[props.selectRowIdentifierKey]
          )
        )
      }
    }
  }

  function clearSelection() {
    setSelectAll(false)
    setSelectedList([])
    setUnselectedList([])
  }

  return (
    <Card className="m-0">
      {isFetching ? (
        <div className="table-loader"></div>
      ) : (
        <CardBody className="px-0 py-2">
          <div>
            {props.header && (
              <div>{props.header.title && <div>{props.header.title}</div>}</div>
            )}
            <div className="p-2">
              <Row>
                {props.onSearch && (
                  <Col xs={3}>
                    <InputGroup>
                      <input
                        type="search"
                        id="searchbox"
                        className="form-control"
                        placeholder="Search"
                        name="search"
                        value={search ?? ""}
                        onKeyDown={handleKeyDown}
                        onChange={e => {
                          handleSearchChange(e)
                        }}
                      />
                      <button
                        onClick={() => {
                          clearSelection()
                          props.onSearch(search)
                        }}
                        className="btn btn-light searchbtn"
                      >
                        <i className="fa fa-search"></i>
                      </button>
                    </InputGroup>
                  </Col>
                )}
                {filterComponent && (
                  <Col xs={6} className="d-flex justify-content-between ">
                    <div className="d-flex align-items-center">
                      <Button onClick={e => setShowFilter(f => !f)}>
                        <i className="bx bx-filter"></i> Filter
                      </Button>
                    </div>
                  </Col>
                )}
              </Row>

              <CSSTransition
                // nodeRef={filterref}
                in={showFilter}
                timeout={400}
                classNames="list-transition"
                unmountOnExit
                appear
              >
                <div ref={filterref}>
                  <Row>
                    <Col>
                      <Filter
                        filters={filters}
                        onFilterSubmission={fil => {
                          clearSelection()
                          onFilterSubmission(fil)
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </CSSTransition>
            </div>
            <div
              className={`table-responsive react-table ${
                props.detailsTable ? "innerTableHeight" : ""
              } ${props.detailsNoSortTable ? "innerTableHeightNoSort" : ""}`}
            >
              <Table id="dynamic-table">
                <TableHead>
                  <TableRow>
                    {props.selection === true && (
                      <TableCell key={`table-head-selection`}>
                        <CustomCheckbox
                          onChange={onSelectAllClick}
                          value={selectAll}
                        />
                      </TableCell>
                    )}
                    {columns.map((theadCells, key) => {
                      return theadCells.fixed ? (
                        <TableCell
                          key={`table-head-${key}`}
                          {...props}
                          cellProps={theadCells}
                          className={"cell-sticky"}
                        >
                          <div
                            className={`d-flex position-relative pointer w-100 ${
                              theadCells.headerAlign
                                ? "justify-content-" + theadCells.headerAlign
                                : theadCells.align
                                ? "justify-content-" + theadCells.align
                                : "justify-content-start"
                            }`}
                          >
                            <div
                              onClick={e =>
                                theadCells?.sort_key
                                  ? onSort(theadCells, key)
                                  : null
                              }
                            >
                              {theadCells.title}
                            </div>
                            {/* //////////// Sort icon Start///////////// */}
                            {/* {theadCells.sort !== false && ( */}
                            <div>
                              <TableIcons
                                icon={
                                  theadCells.sort_direction === "DESC"
                                    ? "sort-des"
                                    : "sort-asc"
                                }
                                iconClass={
                                  theadCells.sort_direction
                                    ? "sort-icon"
                                    : "opacity-0"
                                }
                              />
                            </div>
                            {theadCells.tooltip_text && (
                              <div className="tooltipBlock">
                                <div
                                  className="tooltipBox"
                                  title={theadCells.tooltip_text}
                                >
                                  <img src={tooltipImg} />
                                </div>
                              </div>
                            )}
                            {/* )} */}
                            {/* //////////// Sort icon End///////////// */}
                          </div>
                        </TableCell>
                      ) : (
                        <TableCell
                          key={`table-head-${key}`}
                          {...props}
                          cellProps={theadCells}
                        >
                          <div
                            className={`d-flex position-relative pointer w-100 ${
                              theadCells.headerAlign
                                ? "justify-content-" + theadCells.headerAlign
                                : theadCells.align
                                ? "justify-content-" + theadCells.align
                                : "justify-content-start"
                            }`}
                          >
                            <div
                              onClick={e =>
                                theadCells?.sort_key
                                  ? onSort(theadCells, key)
                                  : null
                              }
                            >
                              {theadCells.title}
                            </div>
                            {/* //////////// Sort icon Start///////////// */}
                            {/* {theadCells.sort !== false && ( */}
                            <div>
                              <TableIcons
                                icon={
                                  theadCells.sort_direction === "DESC"
                                    ? "sort-des"
                                    : "sort-asc"
                                }
                                iconClass={
                                  theadCells.sort_direction
                                    ? "sort-icon"
                                    : "opacity-0"
                                }
                              />
                            </div>
                            {theadCells.tooltip_text && (
                              <div className="tooltipBlock">
                                <div
                                  className="tooltipBox"
                                  title={theadCells.tooltip_text}
                                >
                                  <img src={tooltipImg} />
                                </div>
                              </div>
                            )}
                            {/* )} */}
                            {/* //////////// Sort icon End///////////// */}
                          </div>
                        </TableCell>
                      )
                    })}
                    {/* //////////////////////////// Action Column Header Start//////////////////////////////////////////// */}
                    {props.actions && (
                      <TableCell {...props} className={"action-columns"}>
                        <div className="d-flex justify-content-center">
                          <div>
                            {props.actionHeaderIcon ? (
                              <TableIcons icon={props.actionHeaderIcon} />
                            ) : props.actions.hideTitle ? (
                              ""
                            ) : (
                              "Actions"
                            )}
                          </div>
                        </div>
                      </TableCell>
                    )}
                    {/* //////////////////////// Action Column Header End /////////////////////////////////////////////////// */}
                  </TableRow>
                </TableHead>
                {/* ///////////////////////////////////////Table Head Section End/////////////////////////////////////////////////// */}
                {/* ///////////////////////////////////////Table Body Section Start/////////////////////////////////////////////////// */}
                <TableBody>
                  <>
                    {isFetching ? (
                      <>
                        {/* {Array.apply(null, { length: 10 || pageSize })
                        .map(Number.call, Number)
                        .map((row, rowKey) => ( */}
                        <TableRow
                        // key={`table-row-${rowKey}`} {...props}
                        >
                          <TableCell
                            colSpan={columns.length + (props.actions ? 1 : 0)}
                            className="skelton-columns"
                          >
                            <TableSkelton />
                          </TableCell>
                        </TableRow>
                        {/* ))} */}
                      </>
                    ) : (
                      <>
                        {data.length > 0 ? (
                          <>
                            {data.map((rowData, rowKey) => (
                              <React.Fragment
                                key={`table-row-expansion${rowKey}`}
                              >
                                <TableRow
                                  key={`table-row-${rowKey}`}
                                  {...props}
                                >
                                  {props.selection === true && (
                                    <TableCell
                                      key={`table-row-${rowKey}-selection`}
                                      className="checkBox-width"
                                      {...props}
                                    >
                                      <CustomCheckbox
                                        onChange={isChecked =>
                                          onRowSelectionClick(
                                            isChecked,
                                            rowData
                                          )
                                        }
                                        value={
                                          (selectAll &&
                                            !unselectedList.includes(
                                              rowData[
                                                props.selectRowIdentifierKey
                                              ]
                                            )) ||
                                          selectedList.includes(
                                            rowData[
                                              props.selectRowIdentifierKey
                                            ]
                                          )
                                        }
                                      />
                                    </TableCell>
                                  )}
                                  {columns.map((rowCell, rowCellKey) => (
                                    <TableCell
                                      key={`table-row-${rowKey}-cell-${rowCellKey}`}
                                      {...props}
                                      className={
                                        (rowCellKey === 0 || 1) && rowCell.fixed
                                          ? "cell-sticky"
                                          : ""
                                      }
                                    >
                                      <div className="d-flex position-relative table-exp-right-margin">
                                        <AlignmentContainer rowCell={rowCell}>
                                          <p
                                            className="textWrap"
                                            style={{
                                              overflow: "hidden",
                                              whiteSpace: "nowrap",
                                              margin: 0,
                                              textOverflow: "ellipsis",
                                            }}
                                            {...(typeof valueRenderer(
                                              rowData,
                                              rowCell
                                            ) === "string" ||
                                            typeof valueRenderer(
                                              rowData,
                                              rowCell
                                            ) === "number"
                                              ? {
                                                  title: valueRenderer(
                                                    rowData,
                                                    rowCell
                                                  ),
                                                }
                                              : {})}
                                          >
                                            {valueRenderer(rowData, rowCell)}
                                          </p>
                                        </AlignmentContainer>
                                      </div>
                                    </TableCell>
                                  ))}
                                  {props.actions &&
                                    actionCellRenderer(rowData, rowKey)}
                                </TableRow>
                              </React.Fragment>
                            ))}
                          </>
                        ) : (
                          <TableRow>
                            <TableCell
                              className=""
                              rowSpan={5}
                              colSpan={
                                props.actions
                                  ? columns.length + 1
                                  : columns.length
                              }
                            >
                              <div
                                className="d-flex align-items-center justify-content-center"
                                style={{
                                  minHeight: 250,
                                }}
                              >
                                No Data Found
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    )}
                  </>

                  {props?.customDetailsFlag &&
                    props?.customDetails?.length > 0 && (
                      <TableRow>
                        {props?.customDetails?.map((rowCell, rowCellKey) => (
                          <TableCell
                            key={`table-row-${rowCellKey}-cell-${rowCellKey}`}
                            {...props}
                            className={
                              rowCellKey == 0 || 1
                                ? "cell-sticky pe-none text-danger"
                                : ""
                            }
                          >
                            <div className="d-flex position-relative table-exp-right-margin">
                              <AlignmentContainer rowCell={rowCell}>
                                <p
                                  className="textWrap"
                                  style={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    margin: 0,
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {rowCell}
                                </p>
                              </AlignmentContainer>
                            </div>
                          </TableCell>
                        ))}
                      </TableRow>
                    )}
                </TableBody>
                {/* ///////////////////////////////////////Table Body Section End/////////////////////////////////////////////////// */}
              </Table>
            </div>
          </div>
          {/* {!isFetching && ( */}
          {data.length > 0 && (
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={totalCount}
              isFetching={isFetching}
              pageSize={pageSize}
              onPageChange={data => onPageNoChange(data)}
              rowCount={rowCount}
              columnCount={columnCount}
            />
          )}
          {/* )} */}
        </CardBody>
      )}
    </Card>
  )
}

export default DynamicTable
