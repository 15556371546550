import React, { useState, useEffect } from "react"
import { Col, Input, Modal, Row } from "reactstrap"

import CustomTextField from "components/CustomTextField/index"
import { validate } from "helpers/utilities"
import CustomSelect from "components/CustomSelect/index"
import { getPayoutModesList, getServiceStatusesList } from "helpers/api_actions"
let required = [
  "percentage",
]
const ChangeReferralCommission = props => {
  const [data, setData] = useState({ percentage: props.userData?.referrer_commision ?? null})
  const [errors, setErrors] = useState( {})
  const { userData } = props

  const handleChange = (v, k) => {
    let error = validate(required, {...data,[k]:v}, "")
    setData((d)=>({ ...d, [k]: v }))
    if(Object.keys(error).length){
      setErrors(error)
    } else {
      if (k === "percentage" && v < 0 || v > 100)
      {
        setErrors({
          ...errors,
          [k]: "Referrer Commision percentage should be between 0 and 100"
        })
      } else {
        setErrors({})
      }
    }
  }

  const handleSubmit=()=>{
    let error = validate(required, data, "")
    if (Object.keys(error).length === 0) {
      if (data.percentage < 0 || data.percentage > 100)
      {
        setErrors({
          ...errors,
          percentage: "Referrer Commision percentage should be between 0 and 100"
        })
      } else {
        let submitData = {
          percentage: data?.percentage,
        }
        props.onSubmitAction(submitData)
      }
    } else {
      setErrors(error)
    }
  }

  return (
    <Modal isOpen={true} toggle={props.onCancelAction} centered={true}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {props.title || "Change Referrer Commision"}
        </h5>
        <button
          type="button"
          disabled={props.submiting}
          onClick={() => {
            props.onCancelAction()
          }}
          className="btn-close"
        ></button>
      </div>
      <div className="modal-body">
        <form>
          <div className="mb-3">
            <CustomTextField
              label="Referrer Commision Percentage"
              type="number"
              placeholder="Enter Referrer Commision"
              name="percentage"
              value={data?.percentage ?? ""}
              onChange={(v, k, e) => handleChange(v, k, e)}
            />
                 {errors?.percentage && (
                          <p className="text-danger"> {errors?.percentage}</p>
                        )}
          </div>
        </form>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          data-bs-dismiss="modal"
          disabled={props.submiting}
          onClick={() => {
            props.onCancelAction()
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          disabled={props.submiting}
          className="btn btn-primary"
          onClick={e => handleSubmit()}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}
export default ChangeReferralCommission
