import React, { Suspense, useRef } from "react"
import { Col, Container, Row } from "reactstrap"

import HeaderTitle from "components/Common/headerTitleComponent"
import CustomSpinner from "components/customSpinner"
import { BUTTON_LABELS, HEADER_LABELS } from "constants/label"
import { DEFAULT_CLASSES } from "constants/styles"
import { isAdmin } from "helpers/utilities"

import SalesReport from "./SalesReports/index"

const Report = props => {
  const listRef = useRef(null)
  const headerActions = isAdmin()
    ? [
        {
          type: "button",
          label: BUTTON_LABELS.EXPORT,
          className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
          action: () => {
            listRef.current.exportReport()
          },
        },
      ]
    : []

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <HeaderTitle
              title={HEADER_LABELS.REPORTS_SALES}
              actions={headerActions}
            />
          </Row>
          <Row>
            <Col lg="12">
              <Suspense fallback={<CustomSpinner />}>
                <SalesReport ref={listRef} {...props} />
              </Suspense>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Report.propTypes = {}

export default Report
