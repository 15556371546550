import React, { useState, useEffect } from "react"
import { Col, Input, Modal, Row } from "reactstrap"
import { isValid, validate } from "helpers/utilities"
import CustomTextField from "components/CustomTextField/index"

const ResetPassword = props => {
  const [data, setData] = useState(props.data || {})

  const handleChange = (v, k) => {
    setData({ ...data, [k]: v })
  }

  return (
    <Modal isOpen={true} toggle={props.onCancelAction} centered={true}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {props.title || "Reset New Password"}
        </h5>
        <button
          type="button"
          disabled={props.submiting}
          onClick={() => {
            props.onCancelAction()
          }}
          className="btn-close"
        ></button>
      </div>
      <div className="modal-body">
        <form>
          <div className=" mb-3">
            <CustomTextField
              label="New Password"
              type="pin"
              placeholder="Enter New Password"
              name="password"
              maxLength={4}
              value={data?.title ?? ""}
              required
              onChange={(v, k, e) => handleChange(v, k, e)}
            />
          </div>
          <div className=" mb-3">
            <CustomTextField
              label="Confirm New Password"
              name="confirm_password"
              type="pin"
              maxLength={4}
              required
              value={data?.note ?? ""}
              placeholder="Enter New Password"
              onChange={(v, k, e) => handleChange(v, k, e)}
            />
          </div>
        </form>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          data-bs-dismiss="modal"
          disabled={props.submiting}
          onClick={() => {
            props.onCancelAction()
          }}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={props.submiting}
          className="btn btn-primary"
          onClick={e => props.onSubmitAction(data)}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}
export default ResetPassword
