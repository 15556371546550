import { useDispatch } from "react-redux"
import { call, put, takeEvery } from "redux-saga/effects"

//Include Both Helper File with needed methods

import { postAuthLogin } from "../../../helpers/api_actions"
import { apiError, loginSuccess } from "./actions"
// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { logoutCurrentUser } from "helpers/utilities"

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postAuthLogin, {
      phone: user.phone,
      password: user.password,
      login_type: "admin",
    })
    // setAuthenticated(true)
    localStorage.setItem("userData", JSON.stringify(response.data))
    localStorage.setItem("access_token", response?.data?.token)
    localStorage.setItem("refresh_token", response?.data?.refresh_token)
    localStorage.setItem("user_type", response?.data?.user_type)

    yield put(loginSuccess(response.data))

    history.push("/dashboard")
  } catch (error) {
    yield put(apiError(error.response.data.message))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    logoutCurrentUser()
    // localStorage.clear()

    // history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)

  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
