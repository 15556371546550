import classnames from "classnames"
import React, { useEffect, useRef, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import { Card, CardBody, CardText, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap"

import HeaderTitle from "components/Common/headerTitleComponent"
import CustomSpinner from "components/customSpinner"
import { BUTTON_LABELS, MENU_LABELS, NAV_LABELS, STATUS_LABELS } from "constants/label"
import { AUTH_ROUTES } from "constants/routes"
import { DEFAULT_CLASSES } from "constants/styles"
import { changeUserStatus, getAdminProfile, getUserDetails, getUserProfile, postResetPasscode } from "helpers/api_actions"
import { generateClassNameByValue, isValid, validate } from "helpers/utilities"

import UserDetailsComponent from "./manage/components/tabs/userDetails"

const AdminProfile = props => {
  const state = props.location?.state?.edit
  const [userData, setUserData] = useState({})
  const [edit, setEdit] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [resetpassword, setResetpassword] = useState(false)
  const [manageStatus, setManageStatus] = useState(false)
  const [submiting, setSubmiting] = useState(false)
  const { addToast } = useToasts()
  const param = useParams()
  const detailRef = useRef(null)

  const headerActions = [
    ...(edit
      ? [
          {
            type: "button",
            label: BUTTON_LABELS.CANCEL,
            className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
            action: () => {
              setEdit(false)
            },
            show: ["admin-profile"],
          },
          {
            type: "button",
            label: BUTTON_LABELS.SAVE,
            className: DEFAULT_CLASSES.HEADER_BUTTON_PRIMARY,
            action: () => {
              detailRef.current.update()
            },
            show: ["admin-profile"],
          },
        ]
      : [
          {
            type: "button",
            label: BUTTON_LABELS.EDIT,
            className: DEFAULT_CLASSES.HEADER_BUTTON_PRIMARY,
            action: () => {
              setEdit(true)
            },
            show: ["admin-profile"],
          },
        ]),
  ]

  const breadcrumb = []

  const tags = []

  const fetchUserDetails = () => {
    setIsLoading(true)
    const obj = JSON.parse(localStorage.getItem("userData"))
    if (obj.user_type == "AdminUser") {
    getAdminProfile()
      .then(res => {
        let data = res.data
        setUserData(data)
        setIsLoading(false)
      })

      .catch(e => {
        setIsLoading(false)
      })
    } else {
        getUserProfile()
        .then(res => {
            let data = res.data
            setUserData(data)
            setIsLoading(false)
          })
    
          .catch(e => {
            setIsLoading(false)
          })
    }
  }

  useEffect(() => {
    if (state === true) {
      setEdit(true)
    }
    fetchUserDetails()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <HeaderTitle
              title={userData?.full_name}
              actions={headerActions}
              tags={tags}
              disabled={submiting}
              activeTab="admin-profile"
              breadcrumb={breadcrumb}
            />
          </Row>
          <Row>
            <React.Fragment>
              <Col lg="12">
                <Card className="mb-0">
                  <CardBody className="p-0">
                    <Nav tabs className="nav-tabs-custom ">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: true,
                            })}
                          >
                            <span className="">Profile</span>
                          </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab="admin-profile" className="text-muted">
                      {isLoading ? (
                        <CustomSpinner />
                      ) : (
                        <>
                          {/* USER_DETAILS */}
                          <TabPane tabId="admin-profile">
                            <UserDetailsComponent
                              isReadOnly={!edit}
                              parentID={param.id}
                              data={userData}
                              ref={detailRef}
                              setEdit={setEdit}
                              setsubmitting={v => setSubmiting(v)}
                              edit={edit}
                              refresh={fetchUserDetails}
                            />
                          </TabPane>
                        </>
                      )}
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </React.Fragment>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AdminProfile
