import React, { useEffect, useState } from "react"
import Select from "react-select"

import CustomTextField from "components/CustomTextField"

function CustomSelect(props) {
  const [defaultOptions, setDefaultOptions] = useState(props.value)

  useEffect(() => {
    if (props.value) {
      setDefaultOptions(props.value)
    }
  }, [props.value])

  const styles = {
    option: (styles, state) => ({
      ...styles,
      backgroundColor: state.isSelected ? "#55c595" : "",

      "&:hover": {
        ...styles,
        backgroundColor: "",
        cursor: "pointer",
      },
    }),
  }
  return (
    <>
      {props.disabled ? (
        <>
          <CustomTextField
            label={props.label}
            disabled
            value={defaultOptions?.label}
            placeholder={props.placeholder ?? "Select"}
          />
        </>
      ) : (
        <>
          {props.label && (
            <p className="mb-1 mt-3 font-weight-bold">{props.label}</p>
          )}
          <Select
            options={props.options}
            className={props.required ? "required" : ""}
            isDisabled={props.disabled}
            placeholder={props.placeholder ?? "Select"}
            clearValue={() => props.setData(null)}
            isMulti={props.isMulti ?? false}
            isClearable={props.isClearable ?? true}
            value={defaultOptions}
            styles={styles}
            onChange={value => props.setData(value)}
            {...props}
          />
        </>
      )}
    </>
  )
}

export default CustomSelect
