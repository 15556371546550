//i18n
import moment from "moment"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useEffect } from "react"
import { withTranslation } from "react-i18next"
import { AiOutlineNotification } from "react-icons/ai"
import { Link, useHistory  } from "react-router-dom"
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from "reactstrap"
import SimpleBar from "simplebar-react"
import Switch from "react-switch";
//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg"
import avatar4 from "../../../assets/images/users/avatar-4.jpg"
import { getNotification } from "../../../helpers/api_actions"
import { useNotificationStore } from "../../../store/notification/notificationStore"
import NotificationToggle from "./NotificationToggle";

const NotificationDropdown = props => {
  const history = useHistory(); // Access the history object
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const [expand, setExpand] = useState(false)

  const notificationsCount = useNotificationStore(
    state => state.notificationsCount
  )
  const notificationsData = useNotificationStore(
    state => state.notificationsData
  )
  const { addNotification, addNotificationData } = useNotificationStore()
  const handleExpand = () => {
    setExpand(!expand)
  }
  const handleGet = () => {
    getNotification().then(res => {
      addNotification(res?.unread_count)
      addNotificationData(res?.data?.data)
    })
  }
  useEffect(() => {
    handleGet()
  }, [])

  return (
    <React.Fragment>
      <div className="d-inline-block dropdown">
        <NotificationToggle />
      </div>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className=" dropdown d-inline-block"
        tag="li"
      >
      
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada" />
          <span className="badge bg-danger rounded-pill">
            {notificationsCount}
          </span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
            </Row>
          </div>

          <SimpleBar
            style={{ height: expand ? `calc(100Vh - 200px)` : "230px" }}
          >
            {notificationsData?.map(item => (
              <a
                key={item.id}
                href={`/${item.model}/view/${item.model_id}`}
                className="text-reset notification-item"
              >
                <div className="d-flex">
                  <AiOutlineNotification className="me-3 rounded-circle avatar-xs" />
                  <div className="flex-grow-1" style={{ maxWidth: 230 }}>
                    <h6 className="mt-0 mb-1">{item.title}</h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">{item.text}</p>
                      <p className="mb-0">
                        <i className="mdi mdi-clock-outline" />
                        {item?.created_at? moment(item.created_at).format("DD-MMM-YYYY hh:mm a") : "--"}{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            ))}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 text-center"
              to="#"
              onClick={() => handleExpand()}
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
              <span key="t-view-more">
                {" "}
                {props.t(expand ? "View Less.." : "View More..")}
              </span>
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any,
}
