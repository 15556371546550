import HeaderTitle from "components/Common/headerTitleComponent"
import CustomSpinner from "components/customSpinner"
import { HEADER_LABELS, BUTTON_LABELS } from "constants/label"
import { AUTH_ROUTES } from "constants/routes"
import { DEFAULT_CLASSES } from "constants/styles"
import React, { Suspense, useRef } from "react"

import { Col, Container, Row } from "reactstrap"
import CustomerSupportListing from "./listing"

const CustomerSupport = props => {
  const listRef = useRef(null)
  const headerActions = [
    // {
    //   type: "button",
    //   label: BUTTON_LABELS.EXPORT,
    //   className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
    //   action: () => {},
    // },
    // {
    //   type: "button",
    //   label: BUTTON_LABELS.PARTNERSHIP_REQUESTS,
    //   className: DEFAULT_CLASSES.HEADER_BUTTON_PRIMARY,
    //   action: () => {
    //     props.history.push(AUTH_ROUTES.PARTNERSHIP_REQUESTS)
    //   },
    // },
    // {
    //   type: "button",
    //   label: BUTTON_LABELS.TICKETS,
    //   className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
    //   action: () => {
    //     props.history.push(AUTH_ROUTES.TICKETS)
    //   },
    // },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <HeaderTitle
              title={HEADER_LABELS.CUSTOMER_SUPPORT}
              actions={headerActions}
            />
          </Row>
          <Row>
            <Col lg="12">
              <Suspense fallback={<CustomSpinner />}>
                <CustomerSupportListing {...props} />
              </Suspense>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

CustomerSupport.propTypes = {}

export default CustomerSupport
