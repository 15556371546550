import React, { useEffect, useImperativeHandle } from "react"
import { useState } from "react"
import { forwardRef } from "react"
import { Button, Card, CardBody, CardTitle, Col, Input, Row } from "reactstrap"

import uploadplaceholder from "assets/admin-images/upload-placeholder.png"
import CustomCalander from "components/CustomCalendar"
import SearchSelect from "components/CustomSearchSelect"
import CustomSelect from "components/CustomSelect"
import CustomTextField from "components/CustomTextField"
import {
  getCitiesList,
  getCountriesList,
  getQualificationList,
  getStatesList,
  getUserList,
} from "helpers/api_actions"
import { emailValidator, validate } from "helpers/utilities"

let required = [
  "full_name",
  "primary_phone",
  "state",
  "city",
  "country",
  "address_type",
]
const UserManageSection = forwardRef((props, ref) => {
  UserManageSection.displayName = "User Manage Section"
  var dobMax = new Date()
  dobMax.setFullYear(dobMax.getFullYear() - 18)
  const [formData, setFormData] = useState(props.data)

  const [errors, setErrors] = useState({})
  const [qualifications, setQualifications] = useState([])
  const [hasValidEmail, setHasValidEmail] = useState(true)
  useEffect(() => {
    // setFormData(props.data)
    getQualification()
  }, [])
  useImperativeHandle(ref, () => ({
    onSubmitAction(e) {
      const error = validate(required, formData, "")
      setErrors(error)
      setTimeout(() => {
        if (Object.keys(error).length === 0) {
          props.handleSubmit(formData)
        }
      }, 150)
    },
    onCancelAction(e) {
      setFormData(null)
      document.getElementById("profilePickViewer").src = uploadplaceholder
    },
  }))
  const handleChange = (v, k) => {
    const error = validate(required, { ...formData, [k]: v }, "")
    setFormData(f => ({
      ...f,
      [k]: v,
      ...(k === "country" && { state: null, city: null }),
      ...(k === "state" && { city: null }),
      ...(k === "state" && { city: null }),
    }))
    if (k === "email") {
      setHasValidEmail(emailValidator(v))
    }
    if (Object.keys(errors).length) {
      setErrors(error)
    }
  }
  const getCities = async v => {
    let payload = { select: "cities.name as name", include: "states" }
    let filter = `&seaarch[cities.name]=${v}${
      formData?.state?.value
        ? "&filter[states.name]=" + formData?.state?.value
        : ""
    }`
    const res = await getCitiesList(payload, filter)
    const data = res?.data?.data || []
    data.forEach(item => {
      item["label"] = item.name
      item["value"] = item.name
    })
    return data
  }
  const getQualification = () => {
    let payload = { select: "id,name" }
    // let filter = `?filter[name]=${v}`
    getQualificationList(payload).then(res => {
      let data = res?.data?.data || []
      data.forEach(item => {
        item["label"] = item.name
        item["value"] = item.name
      })
      setQualifications(data)
    })
  }
  const getCountries = async v => {
    let payload = { select: "name" }
    let filter = `&filter[name]=${v}`
    const res = await getCountriesList(payload, filter)
    const data = res?.data?.data || []
    data.forEach(item => {
      item["label"] = item.name
      item["value"] = item.name
    })
    return data
  }
  const getStates = async v => {
    let payload = { select: "states.name as name" }
    let filter = `&filter[states.name]=${v}`
    const res = await getStatesList(payload, filter)
    const data = res?.data?.data || []
    data.forEach(item => {
      item["label"] = item.name
      item["value"] = item.name
    })
    return data
  }
  const getUsers = async v => {
    let payload = {
      select: `full_name,users.id as id,display_id&find[user_type.name]=PlatformUser&search[full_name]=${v}`,
      include: "user_type",
    }
    const res = await getUserList(payload)
    const data = res?.data?.data || []
    data.forEach(item => {
      item["label"] = `${item.full_name} (${item.display_id})`
      item["value"] = item.id
    })
    return data
  }
  const previewImage = img => {
    var oFReader = new FileReader()
    oFReader.readAsDataURL(img)

    oFReader.onload = function (oFREvent) {
      document.getElementById("profilePickViewer").src = oFREvent.target.result
    }
  }

  const handleImageChange = e => {
    let value = e.target.files[0]
    if (value.type.includes("image")) {
      setFormData({ ...formData, image: value })
    }
    previewImage(value)
  }
  return (
    <Row style={{ overflow: "auto" }}>
      <Col lg="12">
        <Card className="mb-0">
          <CardBody>
            <Row>
              <Col lg="12" className="">
                <CardTitle className="mt-0 mb-3">Basic Information</CardTitle>
                <Row>
                  <Col md="4">
                    <div className="mb-3">
                      <CustomTextField
                        label="Full Name"
                        required
                        disabled={props.isReadOnly}
                        placeholder="Enter Full Name"
                        type="text"
                        name="full_name"
                        onChange={(v, k, e) => handleChange(v, k, e)}
                        value={formData?.full_name ?? ""}
                      />
                      {errors?.full_name && (
                        <p className="text-danger"> {errors?.full_name}</p>
                      )}
                    </div>
                  </Col>
                  <Col md="4">
                    <div className=" mb-3">
                      <CustomTextField
                        label="Phone"
                        required
                        disabled={props.isReadOnly}
                        startAdorement="+91"
                        maxLength={10}
                        minLength={10}
                        placeholder="Enter Phone"
                        type="phone"
                        name="primary_phone"
                        onChange={(v, k, e) => handleChange(v, k, e)}
                        value={formData?.primary_phone ?? ""}
                      />
                      {errors?.primary_phone && (
                        <p className="text-danger"> {errors?.primary_phone}</p>
                      )}
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="mb-3">
                      <CustomTextField
                        label="Email"
                        disabled={props.isReadOnly}
                        placeholder="Enter Email ID"
                        type="email"
                        name="email"
                        onChange={(v, k, e) => handleChange(v, k, e)}
                        value={formData?.email ?? ""}
                      />
                      {(errors?.email || !hasValidEmail) && (
                        <p className="text-danger">
                          {!hasValidEmail
                            ? "Provide a valid email id"
                            : errors?.email}
                        </p>
                      )}
                    </div>
                  </Col>
                  {localStorage.getItem("user_type") !== "CRE" && (
                    <Col md="4">
                      <div className=" mb-3">
                        <CustomCalander
                          label="Date of Birth"
                          maxDate={dobMax}
                          disabled={props.isReadOnly}
                          placeholder="Select Date"
                          value={formData?.date_of_birth ?? ""}
                          onChange={d => handleChange(d, "date_of_birth")}
                        />
                        {errors?.date_of_birth && (
                          <p className="text-danger">
                            {" "}
                            {errors?.date_of_birth}
                          </p>
                        )}
                      </div>
                    </Col>
                  )}
                  <Col md="4">
                    <div className="mb-3">
                      <CustomTextField
                        label="Passcode"
                        disabled={props.isReadOnly}
                        placeholder="Enter Passcode"
                        type="password"
                        name="password"
                        onChange={(v, k, e) => handleChange(v, k, e)}
                        value={formData?.password ?? ""}
                      />
                      {errors?.password && (
                        <p className="text-danger"> {errors?.password}</p>
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
              {/* <Col lg="3">
                <div className="mb-3">
                  <CardTitle className="mt-0 mb-3">Profile Image</CardTitle>
                  <div className="imageUpload" id="imageupload">
                    <Input
                      className="form-control"
                      type="file"
                      accept="image/*"
                      // disabled={props.isReadOnly}
                      id="formFile"
                      onChange={handleImageChange}
                      onClick={(event) => {event.preventDefault()}}
                    />
                    <div className="defaultBlock">
                      <img
                        className="profile-image-preview"
                        src={
                          formData?.imageUrl
                            ? `${formData?.imageUrl}`
                            : uploadplaceholder
                        }
                        id="profilePickViewer"
                        // width={"100%"}
                        // height={"100%"}
                      />
                    </div>
                  </div>
                </div>
              </Col> */}
              <></>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
})

export default UserManageSection
