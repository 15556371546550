import classnames from "classnames"
import moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import { Button, Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap"

import HeaderTitle from "components/Common/headerTitleComponent"
import CustomSpinner from "components/customSpinner"
import { BUTTON_LABELS, NAV_LABELS } from "constants/label"
import { AUTH_ROUTES } from "constants/routes"
import { DEFAULT_CLASSES } from "constants/styles"
import { getServiceDetails, postCommisionCreate, postSaleCreate } from "helpers/api_actions"
import { generateClassNameByValue, generateClassNameByValueForStatus, generateErrorMessage, validate } from "helpers/utilities"

import { USER_TAB_LABELS } from "./dataManager"
import AddCommision from "./manage/components/modal/addCommision"
import AddSale from "./manage/components/modal/addSale"
import CommisionComponent from "./manage/components/tabs/commision"
import LeadListing from "./manage/components/tabs/leads"
import LogComponent from "./manage/components/tabs/logs"
import Overview from "./manage/components/tabs/overview"
import SalesListing from "./manage/components/tabs/sales/index"
import ServiceDetails from "./manage/components/tabs/serviceDetails"

const UserDetails = props => {
  const state = props.location?.state?.edit
  const tab = props.location?.state?.tab

  const [userData, setUserData] = useState({})
  const [submiting, setSubmiting] = useState(false)
  const [edit, setEdit] = useState(false)
  const [commisionCreate, setCommisionCreate] = useState(false)
  const [saleCreate, setSaleCreate] = useState(false)
  const [activeTab, setActiveTab] = useState(USER_TAB_LABELS[0].id)
  const [isLoading, setIsLoading] = useState(true)
  const { addToast } = useToasts()
  const param = useParams()
  const commisionRef = useRef(null)
  const salesRef = useRef(null)
  const handleRedirect = () => {
    if (tab) {
      switch (tab) {
        case "commision":
          setActiveTab(USER_TAB_LABELS[2].id)
          break
        case "service-leads":
          setActiveTab(USER_TAB_LABELS[4].id)
      }
    }
  }
  useEffect(() => {
    handleRedirect()
  }, [])
  let headerActions = [
    ...(edit
      ? [
          {
            type: "button",
            label: BUTTON_LABELS.CANCEL,
            className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
            action: () => {
              setEdit(false)
              fetchServiceDetails()
            },
            show: [USER_TAB_LABELS[1].id],
          },
          {
            type: "button",
            label: BUTTON_LABELS.SAVE,
            className: DEFAULT_CLASSES.HEADER_BUTTON_PRIMARY,
            action: () => {
              commisionRef.current.update()
            },
            show: [USER_TAB_LABELS[1].id],
          },
        ]
      : [
          {
            type: "button",
            label: BUTTON_LABELS.EDIT,
            className: DEFAULT_CLASSES.HEADER_BUTTON_PRIMARY,
            action: () => {
              setEdit(true)
            },
            show: [USER_TAB_LABELS[1].id],
          },
        ]),
  ]
  const breadcrumb = props?.location?.state?.frompath
    ? [
        {
          label: props?.location?.state?.fromlabel,
          path: props?.location?.state?.frompath,
        },
        {
          label: userData?.name,
          path: `${AUTH_ROUTES.SERVICE_MANAGEMENT_DETAILS}${param.id}`,
        },
      ]
    : [
        {
          label: NAV_LABELS.SERVICE_MANAGEMENT,
          path: AUTH_ROUTES.SERVICE_MANAGEMENT,
        },
        {
          label: userData?.name,
          path: `${AUTH_ROUTES.SERVICE_MANAGEMENT_DETAILS}${param.id}`,
        },
      ]
  const tags = [
    {
      label: userData?.status || "Unknown",
      className: generateClassNameByValueForStatus(userData?.status),
    },
    // {
    //   label: userData?.platform_user_details?.rank?.name,
    //   className: generateClassNameByValue(
    //     userData?.platform_user_details?.rank?.name
    //   ),
    // },
    {
      label: userData?.display_id || "n/a",
      className: "display_id",
    },
  ]

  const fetchServiceDetails = () => {
    setIsLoading(true)
    getServiceDetails(param.id)
      .then(res => {
        setUserData(res.data)
        setIsLoading(false)
      })
      .catch(e => {
        setIsLoading(false)
      })
  }

  const openCreateCommision = () => {
    setCommisionCreate(true)
  }
  const closeCreateCommision = () => {
    setCommisionCreate(false)
  }
  const openCreateSale = () => {
    setSaleCreate(true)
  }
  const closeCreateSale = () => {
    setSaleCreate(false)
  }
  const initial_data = {
    status: null,
    start_date: null,
    end_date: null,
    own_commision: null,
    calculation:
      userData?.earning_process === "Loan Flow"
        ? { label: "Percentage", value: "Percentage" }
        : { label: "Fixed", value: "Fixed" },
    service_id: param.id,
  }
  const initial_sales_data = {
    start_date: null,
    end_date: null,
    value: null,
    service_id: param.id,
  }
  const handleSaveCommision = data => {
    console.log(data?.start_date)
    let payload = {
      // status: data?.status?.value ?? null,
      start_date: data?.start_date
        ? moment(data.start_date[0]).format("YYYY-MM-DD")
        : null,
      end_date: data?.end_date
        ? moment(data.end_date[0]).format("YYYY-MM-DD")
        : null,
      own_commision: data?.own_commision ?? null,
      calculation: data?.calculation?.value ?? null,
      service_id: param.id,
    }
    setSubmiting(true)

    let required = ["start_date", "end_date", "own_commision", "calculation"]
    const errors = validate(required, payload, "")
    if (Object.keys(errors).length === 0) {
      if (
        data?.calculation?.value === "Percentage" &&
        (data?.own_commision < 1 || data?.own_commision > 100)
      ) {
        setSubmiting(false)
        addToast("Lead Owner Commission should be between 1 and 100", {
          appearance: "error",
        })
      } else {
        postCommisionCreate(payload)
          .then(res => {
            addToast(res?.message, { appearance: "success" })
            commisionRef.current.refetch()
            setSubmiting(false)
            setCommisionCreate(false)
          })
          .catch(err => {
            setSubmiting(false)
            addToast(generateErrorMessage(err.response?.data), {
              appearance: "error",
            })
          })
      }
    } else {
      setSubmiting(false)
      addToast("Please fill all required fields", { appearance: "error" })
    }
  }

  const handleSaveSale = data => {

    let payload = {
      // status: data?.status?.value ?? null,
      start_date: data?.start_date
        ? moment(data.start_date[0]).format("YYYY-MM-DD")
        : null,
      end_date: data?.end_date
        ? moment(data.end_date[0]).format("YYYY-MM-DD")
        : null,
      value: data?.value ?? null,
      service_id: param.id,
    }
    setSubmiting(true)
    let required = ["start_date", "end_date", "value"]
    const errors = validate(required, payload, "")
    if (Object.keys(errors).length === 0) {
      if (data?.value < 1 || data?.value > 100) {
        setSubmiting(false)
        addToast("Sales Revenue Percentage should be between 1 and 100", {
          appearance: "error",
        })
      } else {
        postSaleCreate(payload)
          .then(res => {
            addToast(res?.message, { appearance: "success" })
            salesRef.current.refetch()
            setSubmiting(false)
            setSaleCreate(false)
          })
          .catch(err => {
            setSubmiting(false)
            addToast(generateErrorMessage(err.response?.data), {
              appearance: "error",
            })
          })
      }
    } else {
      setSubmiting(false)
      addToast("Please fill all required fields", { appearance: "error" })
    }
  }
  const toggleCustom = tab => {
    if (activeTab !== tab) {
      fetchTabData(tab)
    }
  }
  const fetchTabData = tab => {
    setActiveTab(tab)
    setIsLoading(true)
    switch (tab) {
      // USER_OVERVIEW
      case USER_TAB_LABELS[0].id:
        setIsLoading(false)
        break
      // USER_DETAILS
      case USER_TAB_LABELS[1].id:
        fetchServiceDetails()
        setIsLoading(false)
        break
      // USER_LEADS
      case USER_TAB_LABELS[2].id:
        setIsLoading(false)
        break
      // USER_TEAM_MEMBERS
      case USER_TAB_LABELS[3].id:
        setIsLoading(false)
        break
      // USER_PAYOUT_REQUEST
      case USER_TAB_LABELS[4].id:
        setIsLoading(false)
        break
      case USER_TAB_LABELS[5].id:
        setIsLoading(false)
        break
      default:
        break
    }
  }
  useEffect(() => {
    if (state === true) {
      setActiveTab(USER_TAB_LABELS[1].id)
      setEdit(true)
    }
    fetchServiceDetails()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <HeaderTitle
              title={userData?.full_name}
              actions={headerActions}
              tags={tags}
              loading={isLoading}
              disabled={submiting}
              activeTab={activeTab}
              breadcrumb={breadcrumb}
            />
          </Row>
          <Row>
            <React.Fragment>
              <Col lg="12">
                <Card className="m-0">
                  <CardBody className="p-0">
                    <Row>
                      <Col xs={9}>
                        <Nav tabs className="nav-tabs-custom ">
                          {USER_TAB_LABELS.filter(
                            tabData =>
                              !(
                                userData?.earning_process !== "Loan Flow" &&
                                tabData.id === "service-sales"
                              )
                          ).map((tabitem, tabkey) => (
                            <NavItem key={tabkey}>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: activeTab === tabitem.id,
                                })}
                                onClick={() => {
                                  toggleCustom(tabitem.id)
                                }}
                              >
                                <span className="">{tabitem.label}</span>
                              </NavLink>
                            </NavItem>
                          ))}
                        </Nav>
                      </Col>
                      <Col xs={3}>
                        <div className="d-flex align-items-center justify-content-end h-100 px-2">
                          {activeTab === "service-commission" && (
                            <Button
                              className={"btn btn-primary text-white"}
                              onClick={e => openCreateCommision()}
                            >
                              {BUTTON_LABELS.CREATE_COMMISION}
                            </Button>
                          )}
                          {activeTab === USER_TAB_LABELS[3].id && (
                            <Button
                              className={"btn btn-primary text-white"}
                              onClick={e => openCreateSale()}
                            >
                              {BUTTON_LABELS.CREATE_SALES}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <TabContent activeTab={activeTab} className="text-muted">
                      {isLoading ? (
                        <CustomSpinner />
                      ) : (
                        <>
                          {/* USER_OVERVIEW */}
                          <TabPane tabId={USER_TAB_LABELS[0].id}>
                            {activeTab === USER_TAB_LABELS[0].id && (
                              <Overview parentID={param.id} />
                            )}
                          </TabPane>
                          {/* USER_DETAILS */}
                          <TabPane tabId={USER_TAB_LABELS[1].id}>
                            {activeTab === USER_TAB_LABELS[1].id && (
                              <ServiceDetails
                                parentID={param.id}
                                data={userData}
                                setEdit={setEdit}
                                setSubmiting={setSubmiting}
                                fetchServiceDetails={fetchServiceDetails}
                                edit={edit}
                                ref={commisionRef}
                              />
                            )}
                          </TabPane>
                          {/* USER_LEADS */}
                          <TabPane tabId={USER_TAB_LABELS[2].id}>
                            {activeTab === USER_TAB_LABELS[2].id && (
                              <CommisionComponent
                                parentID={param.id}
                                serviceData={userData}
                                ref={commisionRef}
                              />
                            )}
                          </TabPane>
                          {/* SALES_MANAGEMENT */}
                          <TabPane tabId={USER_TAB_LABELS[3].id}>
                            {activeTab === USER_TAB_LABELS[3].id && (
                              <SalesListing
                                parentID={param.id}
                                serviceData={userData}
                                ref={salesRef}
                              />
                            )}
                          </TabPane>
                          {/* USER_TEAM_MEMBERS */}
                          <TabPane tabId={USER_TAB_LABELS[4].id}>
                            {activeTab === USER_TAB_LABELS[4].id && (
                              <LeadListing parentID={param.id} />
                            )}
                          </TabPane>
                          {/* USER_PAYOUT_REQUEST */}
                          <TabPane tabId={USER_TAB_LABELS[5].id}>
                            {activeTab === USER_TAB_LABELS[5].id && (
                              <LogComponent parentID={param.id} />
                            )}
                          </TabPane>
                        </>
                      )}
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </React.Fragment>
          </Row>
        </Container>
      </div>
      {commisionCreate && (
        <AddCommision
          serviceData={userData}
          name={userData?.name}
          submiting={submiting}
          data={initial_data}
          onCancelAction={closeCreateCommision}
          onSubmitAction={handleSaveCommision}
        />
      )}
      {saleCreate && (
        <AddSale
          serviceData={userData}
          name={userData?.name}
          submiting={submiting}
          data={initial_sales_data}
          onCancelAction={closeCreateSale}
          onSubmitAction={handleSaveSale}
        />
      )}
    </React.Fragment>
  )
}

export default UserDetails
