import React from "react"
import { useEffect, useState } from "react"
import { Button, Col, Row } from "reactstrap"

import CustomCalander from "components/CustomCalendar"
import SearchSelect from "components/CustomSearchSelect"
import { getCitiesList, getServicesList, getStatesList, getUserList, getLeadList } from "helpers/api_actions"
import CustomSelect from "components/CustomSelect"

const ReportFilterComponent = props => {
  const [selectedCity, setSelectedCity] = useState(props.filters?.city)
  const [selectedState, setSelectedState] = useState(props.filters?.state)
  const [cities, setCities] = useState([]);
  const [selectedCustomer, setSelectedCustomer]  = useState(props.filters?.customer)
  const [customerdata, setCustomerData] = useState([])
  // const [selectedService, setSelectedService] = useState(props.filters?.service)
  const [selectedService, setSelectedService] = useState([])
  const [services, setServices] = useState([])
  const [handledBy, setHandledBy] = useState([])
  const [leadOwner, setLeadOwner] = useState(props.filters?.leadOwner)
  const [selectedCreatedOn, setSelectedCreatedOn] = useState(
    props.filters?.createdOn
  )
  const [dateType, selectedType] = useState('year');
  const [type, setDateType] = useState({ label: "Years", value: "year" });
  const [selectedYear, setSelectedYear] = useState(props.filters?.flryear)
  const defaultSelection = null
  const onApplyFilter = () => {
    let filters = {
      city: selectedCity,
      state:selectedState,
      service: selectedService,
      createdOn: selectedCreatedOn,
      handledBy: handledBy,
      leadOwner: leadOwner,
      customer: selectedCustomer,
      flryear: selectedYear,
    }
    props.onFilterSubmission(filters)
  }
  const onCancelFilter = () => {
    setSelectedCity(undefined)
    setSelectedState(undefined)
    setSelectedCreatedOn(undefined)
    setSelectedService([])
    setHandledBy(undefined)
    setLeadOwner(undefined)
    setSelectedCustomer(undefined)
    setSelectedYear(undefined)
    props.onFilterSubmission({
      city: undefined,
      state: undefined,
      updatedOn: undefined,
      createdOn: undefined,
      handledBy: undefined,
      leadOwner: undefined,
      customer: undefined,
      service: undefined,
      flryear: undefined,
    })
    handleService([])
  }
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  const generateYearOptions = () => {
    const currentYear = getCurrentYear();
    const years = [];
    for (let lyear = currentYear; lyear >= 2022; lyear--) {
          years.push({ label: lyear, value: lyear });
    }
    return years;
  };
  const handleDateTypeChange = (selectedOption) => {
    setSelectedCreatedOn(undefined)
    setSelectedYear(undefined)
    selectedType(selectedOption?.value || null);
    setDateType(selectedOption);
  };

  const getStates = async v => {
    let payload = { select: "states.name as name" }
    let filter = `&filter[states.name]=${v}`
    const res = await getStatesList(payload, filter)
    const data = res?.data?.data || []
    data.forEach(item => {
      item["label"] = item.name
      item["value"] = item.name
    })
    return data
  }
  const getCities = async v => {
    let payload = { select: "cities.name as name", include: "states" }
    let filter = `&search[cities.name]=${v}${
      selectedState
        ? "&filter[states.name]=" + selectedState.name
        : ""
    }`

    const res = await getCitiesList(payload, filter)

    const data = res?.data?.data || []
    data.forEach(item => {
      item["label"] = item.name
      item["value"] = item.name
    })
    return data
  }
  const getUsers = async v => {
    let payload = {
      select: "users.id,full_name",
      include: `user_type&find[user_type.name]=CRE&search[full_name]=${v}`,
    }

    const res = await getUserList(payload)
    const data = res?.data?.data || []
    data.forEach(item => {
      item["label"] = `${item["full_name"]}`
      item["value"] = item.id
    })
    return data
  }
  // const getLeadOwners = async v => {
  //   let payload = {
  //     select: `users.id,full_name&search[full_name]=${v}`,
  //   }

  //   const res = await getUserList(payload)
  //   const data = res?.data?.data || []
  //   data.forEach(item => {
  //     item["label"] = `${item["full_name"]}`
  //     item["value"] = item.id
  //   })
  //   return data
  // }
  const getLeadOwners = async v => {

    let payload = { select: "lead_owner_filter,u1.full_name", include: "users as u1" }
    let filter = `sort_by[u1.full_name]=asc&search[u1.full_name]=${v || ""}${
      selectedService
        ? "&filter[leads.service_id]=" + selectedService?.map(item => item.id).join(",")
        : ""
    }`
  
    const res = await getLeadList(payload, filter)

    const data = res?.data?.data || []
    data.forEach(item => {
      item["label"] = `${item["full_name"]}`
      item["value"] = item.id
    })
    console.log(data)
    console.log(selectedService)
    return data
    }
  const getCustomers = async v => {

    let payload = { select: "leads.id,leads.name", include: "lead_statuses" }
    let filter = `&find[lead_statuses.name]=Completed&search[leads.name]=${v || ""}${
      selectedService
        ? "&filter[leads.service_id]=" + selectedService?.map(item => item.id).join(",")
        : ""
    }`
  
    const res = await getLeadList(payload, filter)

    const data = res?.data?.data || []
    data.forEach(item => {
      item["label"] = item.name
      item["value"] = item.id
    })
    console.log(data)
    console.log(selectedService)
    return data
    }
  const getServices = async v => {
    let payload = {
      select: `id,name&search[name]=${v}`,
    }
    return getServicesList(payload).then(res => {
      let data = res?.data.data || []
      data.forEach(item => {
        item["label"] = item.name
        item["value"] = item.id
      })
      setServices(data)

      return data
    })
  }
  useEffect(() => {
    setSelectedCustomer("")
    setLeadOwner("")
    getCustomers()
    getLeadOwners()
    
  }, [selectedService])
  // useEffect(() => {
  //   getServices()
    
  // }, [selectedState])
 
  const handleState=(v)=>{
    setSelectedState("")
    setSelectedCity("")
    setTimeout(() => {
      setSelectedState(v)
    }, 200);
  }
  const handleCity=(v)=>{
    setSelectedCity(v)
  }
  const handleService=(v)=>{
     setSelectedService("")
     setLeadOwner("")
    setSelectedCustomer("")

    setTimeout(() => {
       setSelectedService(v)

    }, 200);
  }
  const handleCustomer=(v)=>{
    setSelectedCustomer(v)
  }

  return (
    <>
      <Row>
    
        <Col lg={3} md={4} sm={6} xs={12}>
          <SearchSelect
            getData={getServices}
            // menuPlacement={"top"}
            isMulti={true}
            label="Service"
            autoload={true}
            setData={v => {
              handleService(v)
            }}
            value={selectedService ?? null}
            disableSearchWhenNotEmpty={selectedService ? true: false}
          />
        </Col>
        <Col lg={3} md={4} sm={6} xs={12}>
          <SearchSelect
            getData={getUsers}
            isMulti={true}
            label="Handled By"
            autoload={false}
            setData={v => {
              setHandledBy(v)
            }}
            value={handledBy ?? null}
          />
        </Col>
        <Col lg={3} md={4} sm={6} xs={12}>
          <SearchSelect
            getData={getLeadOwners}
            isMulti={true}
            label="Lead Owner"
            autoload={false}
            setData={v => {
              setLeadOwner(v)
            }}
            value={leadOwner ?? null}
            disabled={selectedService ? false : true}
          />
        </Col>
        <Col lg={3} md={4} sm={6} xs={12}>
          
          <SearchSelect
            getData={getCustomers}
            isMulti={true}
            label="Customer"
            setData={v => {
              handleCustomer(v)
            }}
            value={selectedCustomer ?? defaultSelection}
            disabled={selectedService ? false : true}
          />
        </Col>
       
        <Col lg={3} md={4} sm={6} xs={12}>
          <SearchSelect
            getData={getStates}
            isMulti={false}
            label="State"
            // menuPlacement={"top"}
            setData={v => {
              handleState(v)
              
            }}
            value={selectedState ?? defaultSelection}
            disableSearchWhenNotEmpty={selectedState ? true: false}
          />
        </Col>
        <Col lg={3} md={4} sm={6} xs={12}>
          <SearchSelect
            getData={getCities}
            isMulti={true}
            label="City"
            // menuPlacement={"top"}
            setData={v => {
              
              handleCity(v)
            }}
            value={selectedCity ?? defaultSelection}
            disabled={selectedState ? false : true}
          />
        </Col>
        <Col lg={3} md={4} sm={6} xs={12}>
        <CustomSelect
            value={type}
            label="Choose Date Type"
            options={[
              { label: "Custom Date Range", value: "custom" },
              { label: "Years", value: "year" },
            ]}
            onChange={handleDateTypeChange}
         />
         
        </Col>
        <Col lg={3} md={4} sm={6} xs={12}>
        {dateType === 'custom' && (
         <CustomCalander
                   dateRange
                   maxDate={"today"}
                   placeholder={"Start Date - End Date"}
                   value={selectedCreatedOn}
                   onChange={date => setSelectedCreatedOn(date)}
                   label="Date"
                 />
        )}

        {dateType === 'year' && (
           <CustomSelect
              label="Years"
              options={generateYearOptions()}
              value={selectedYear ?? { label: "2023", value: "2023" }}
              onChange={date => setSelectedYear(date)}
            />
          
        )}
         
        </Col>
        <Col
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className="d-flex align-items-end justify-content-start pt-3"
        >
          <div className="me-2">
            <Button
              className="btn btn-sm btn-primary btn-success text-white"
              onClick={() => onApplyFilter()}
            >
              Apply
            </Button>
          </div>
          <div>
            <Button
              className="btn btn-sm btn-danger"
              onClick={() => onCancelFilter()}
            >
              Reset
            </Button>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default ReportFilterComponent
