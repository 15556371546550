import CustomSpinner from "components/customSpinner"
import HeaderTitle from "components/Common/headerTitleComponent"
import { HEADER_LABELS, BUTTON_LABELS } from "constants/label"
import { AUTH_ROUTES } from "constants/routes"
import { DEFAULT_CLASSES } from "constants/styles"
import React, { Suspense, lazy, useRef } from "react"
import { withRouter } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import PropTypes from "prop-types"

//Import Breadcrumb
const ServiceCategoryListing = lazy(() => import("./listing"))
const Users = props => {
  const listRef = useRef(null)
  const headerActions = [
    {
      type: "button",
      label: BUTTON_LABELS.CREATE_SERVICE_CATEGORY,
      className: DEFAULT_CLASSES.HEADER_BUTTON_PRIMARY,
      action: () => {
        props.history.push(AUTH_ROUTES.CREATE_SERVICE_CATEGORY)
      },
    },
    {
      type: "button",
      label: BUTTON_LABELS.EXPORT,
      className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
      action: () => {
        listRef.current.exportServiceCategories()
      },
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <HeaderTitle
              title={HEADER_LABELS.SERVICE_CATEGORY}
              actions={headerActions}
            />
          </Row>
          <Row>
            <Col lg="12">
              <Suspense fallback={<CustomSpinner />}>
                <ServiceCategoryListing ref={listRef} {...props} />
              </Suspense>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Users.propTypes = {
  history: PropTypes.object,
}

export default withRouter(Users)
