import moment from "moment"
import React, { forwardRef, lazy, Suspense, useEffect, useState } from "react"
import { Link } from "react-router-dom"

import CustomSpinner from "components/customSpinner"
import { AUTH_ROUTES } from "constants/routes"
import { getPresaleImportList } from "helpers/api_actions"
import { generateClassNameByValueForStatus } from "helpers/utilities"

import ImportLogFilterComponent from "../filterComponent"

const CustomTable = lazy(() => import("components/customTable"))
const ImportLogList = forwardRef((props, ref) => {
  ImportLogList.displayName = "PreSales Import Logs"
  const [pageSize, setPageSize] = useState(15)
  const [data, setData] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [pageNo, setPageNo] = useState(1)
  const [search, setSearch] = useState("")
  const [filters, setFilters] = useState({})
  const [appliedFilters, setAppliedFilters] = useState("")
  const [appliedSeach, setAppliedSearch] = useState("")
  const [isFetching, setIsFetching] = useState(false)
  const [sortKey, setSortKey] = useState("")

  const [columns, setColumns] = useState([
    {
      title: "File Name",
      field: "file_name",
      minWidth: 300,
      width: 700,
      wrap: true,
      maxWidth: 700,
      render: (r, v) => (
        <Link className="text-link" onClick={() => handleDownload(r)}>
          {v}
        </Link>
      ),
    },
    {
      title: "Status",
      field: "status",
      minWidth: 130,
      width: 220,
      wrap: true,
      maxWidth: 220,
      render: (r, v) => (
        <span
          className={`ms-2 chips ${v && generateClassNameByValueForStatus(v)}`}
        >
          {v}
        </span>
      ),
    },
    {
      title: "Total Rows",
      field: "total_rows",
      minWidth: 100,
      width: 170,
      wrap: true,
      align: "center",
      maxWidth: 170,
    },

    {
      title: "Success Count",
      field: "success_count",
      minWidth: 100,
      width: 170,
      wrap: true,
      align: "center",
      maxWidth: 170,
    },
    {
      title: "Error Count",
      field: "error_count",
      minWidth: 100,
      width: 170,
      wrap: true,
      align: "center",
      maxWidth: 170,
    },
    {
      title: "Created By",
      field: "created_by",
      minWidth: 180,
      width: 230,
      maxWidth: 230,
      wrap: true,
      render: row => <div>{row?.created_by}</div>,
    },
    {
      title: "Created On",
      sort_key: "",
      field: "created_at",
      minWidth: 180,
      width: 240,
      maxWidth: 240,
      wrap: true,
      render: (row, v) => (
        <div>{v ? moment(v).format("DD-MMM-YYYY") : "--"}</div>
      ),
    },
  ])

  const handleSearch = val => {
    setSearch(val)
    let searchString =
      val && val !== ""
        ? `
        &search[pre_sales_import_log.file_name]=${val}        
     `
        : undefined
    setPageNo(1)
    setAppliedSearch(searchString)
  }

  const onFilterSubmission = fil => {
    setFilters(fil)
    let filterString = undefined
    filterString = `${
      fil?.status
        ? "&filter[pre_sales_import_log.status]=" + fil?.status.label
        : ""
    }${
      fil?.createdOn?.length === 2
        ? "&filter[pre_sales_import_log.created_at]=" +
          moment(fil.createdOn[0]).format("YYYY-MM-DD") +
          " - " +
          moment(fil.createdOn[1]).format("YYYY-MM-DD")
        : ""
    }`

    setPageNo(1)
    setAppliedFilters(filterString ?? "")
  }

  const handleTableMenu = (action, rowData) => {
    switch (action.action) {
      case "download":
        handleDownload(rowData)
        break
      default:
        break
    }
  }
  const handleDownload = rowData => {
    var link = document.createElement("a")
    link.href = rowData.result_file_name
    link.click()
  }
  const getTableActions = rowData => {
    if (localStorage.getItem("user_type") === "AdminUser") {
      return [
        {
          icon: <i className="mr-1" />,
          action: "download",
          name: "Download",
          onClick: handleTableMenu,
        },
      ]
    }
  }

  const handleOnPageChange = p => {
    setPageNo(p.page)
    setPageSize(p.pageSize)
  }
  const importLogList = params => {
    let basicData = {
      select: `pre_sales_import_log.*,u1.full_name as created_by`,
      include: `users as u1&page=${pageNo}&per_page=${pageSize}`,
    }
    setIsFetching(true)
    getPresaleImportList(basicData, appliedSeach, appliedFilters, sortKey)
      .then(res => {
        setData(res?.data?.data)
        setTotalCount(res?.data?.total || 0)
        setIsFetching(false)
      })
      .catch(err => {
        setIsFetching(false)
      })
  }
  useEffect(() => {
    importLogList()
  }, [pageSize, pageNo, appliedSeach, sortKey, appliedFilters])

  const handleSort = row => {
    let nextDirection = row.sort_direction
      ? row?.sort_direction === "ASC"
        ? "DESC"
        : "ASC"
      : "ASC"
    columns.forEach(col => {
      if (col.field === row.field) {
        col["sort_direction"] = nextDirection
      } else {
        col["sort_direction"] = undefined
      }
    })
    setColumns(() => columns)

    let sortkey = `&sort_by[${row.sort_key}]=${nextDirection}`
    setSortKey(sortkey)
  }

  return (
    <>
      <Suspense fallback={<CustomSpinner />}>
        <CustomTable
          columns={columns}
          data={data}
          actions={rowData => {
            return getTableActions(rowData)
          }}
          currentPage={pageNo}
          totalCount={totalCount}
          pageSize={pageSize}
          search={search}
          filters={filters}
          onSearch={handleSearch}
          onFilterSubmission={onFilterSubmission}
          pagination={{
            onPageChange: handleOnPageChange,
          }}
          isFetching={isFetching}
          onSort={handleSort}
          filterComponent={ImportLogFilterComponent}
        />
      </Suspense>
    </>
  )
})

export default ImportLogList
