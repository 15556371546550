import React, { useState, useEffect } from "react"
import { getUserDashboardDetails } from "helpers/api_actions"
import { Card, CardBody, Col, Row, Container } from "reactstrap"

const UserUserOverview = props => {
  const [userDashboradData, setUserDashboradData] = useState()
  const [userBusinessLeadData, setUserBusinessLeadData] = useState()
  const [userContactLeadData, setUserContactLeadData] = useState()
  const [userTeamMembersData, setUserTeamMembersData] = useState()
  const [userPayoutRequestData, setUserPayoutRequestData] = useState()
  const [userTotalEarningsData, setUserTotalEarningsData] = useState()
  const [userOwnEarningsData, setUserOwnEarningsData] = useState()
  const [userTeamEarningsData, setUserTeamEarningsData] = useState()
  const [userEarningsWithdrawnData, setUserEarningsWithdrawnData] = useState()
  const [userReadyWithdrawalData, setUserReadyWithdrawalData] = useState()
  const [userNotReadyWithdrawlData, setUserNotReadyWithdrawlData] = useState()

  const getdashboardhUserDetails = () => {
    let payload = { user_id: props.parentID }

    getUserDashboardDetails(payload)
      .then(res => {
        let data = res.data
        const {
          GeneralLeadConverted: { count: Glcount },
          BusinessLeadConverted: { count: BLcount },
          ContactLeadConverted: { count: CLcount },
          TeamMembers: { count: TMcount },
          PayoutRequest: { count: PRcount },
          Earnings: {
            TotalEarnings: TOTALearning,
            OwnEarnings: OWNearning,
            TeamEarnings: TEAMearning,
          },
          Payout: {
            EarningsWithdrawn: EARINGwithdrawn,
            ReadyWithdrawal: READYwithdrawal,
            NotReadyWithdrawl: NOTReadyWithdrawl,
          },
        } = data

        setUserDashboradData(Glcount)
        setUserBusinessLeadData(BLcount)
        setUserContactLeadData(CLcount)
        setUserTeamMembersData(TMcount)
        setUserPayoutRequestData(PRcount)
        setUserTotalEarningsData(TOTALearning)
        setUserOwnEarningsData(OWNearning)
        setUserTeamEarningsData(TEAMearning)
        setUserEarningsWithdrawnData(EARINGwithdrawn)
        setUserReadyWithdrawalData(READYwithdrawal)
        setUserNotReadyWithdrawlData(NOTReadyWithdrawl)
      })
      .catch(e => {})
  }

  useEffect(() => {
    getdashboardhUserDetails()
  }, [])

  let dashData = {
    reports: [
      {
        title: "General Leads",
        iconClass: "bx-archive-in",
        count: userDashboradData,
      },
      {
        title: "Businesses Leads",
        iconClass: "bx-archive-in",
        count: userBusinessLeadData,
      },
      {
        title: "Contact Leads",
        iconClass: "bx-purchase-tag-alt",
        count: userContactLeadData,
      },
      {
        title: "Team Members",
        iconClass: "bx-purchase-tag-alt",
        count: userTeamMembersData,
      },
      {
        title: "Payout Request",
        iconClass: "bx-purchase-tag-alt",
        count: userPayoutRequestData,
      },
    ],
    earnings: [
      {
        title: "Own Earnings",
        iconClass: "bx-archive-in",
        count: "Rs" + userOwnEarningsData,
      },
      {
        title: "Team Earnings ",
        iconClass: "bx-archive-in",
        count: "Rs" + userTeamEarningsData,
      },
    ],
    payouts: [
      {
        title: "Ready for Withdrawal",
        iconClass: "bx-archive-in",
        count: "Rs" + userReadyWithdrawalData,
      },
      {
        title: "Not Ready for Withdrawl ",
        iconClass: "bx-archive-in",
        count: "Rs" + userNotReadyWithdrawlData,
      },
    ],
  }

  const columnsize = {
    width: "240px",
  }

  return (
    <div className="detailBox">
      <Row className="m-0 pt-3">
        {dashData.reports.map((report, key) => (
          <Col key={"_col_" + key} style={columnsize}>
            <Card className="mini-stats-wid mb-3">
              <CardBody className="height-90">
                <Row>
                  <Col lg="8" className="border-0">
                    <div className="flex-grow-1">
                      <p className="text-muted fw-medium font-size-14  mb-2">
                        {report.title}
                      </p>
                      <p className="text-muted fw-medium  mb-0">
                        <b>{report.count}</b>
                      </p>
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="mini-stat-icon avatar-xs rounded-circle bg-primary align-self-center">
                      <span className="avatar-title">
                        <i
                          className={"bx " + report.iconClass + " font-size-16"}
                        />
                      </span>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>

      <Row className="m-0">
        <Col lg="6">
          <Card className="mini-stats-wid mb-3">
            <CardBody>
              <Row>
                <Col lg="12" className="mb-4">
                  <div className="d-flex align-items-end">
                    <div className="flex-grow-1">
                      <p className="text-muted fw-medium mb-2">
                        <b>Earnings</b>
                      </p>
                      <p className="text-muted fw-medium  mb-0">
                        Total Earnings
                      </p>
                    </div>
                    <div className="">
                      <p className="text-muted fw-medium mb-0">
                        <b> Rs {parseFloat(userTotalEarningsData).toLocaleString('en-IN')}</b>
                      </p>
                    </div>
                  </div>
                </Col>

                <Col lg="6">
                  <Card className="mini-stats-wid">
                    <CardBody className="subCard-body">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium mb-2">
                            Own Earnings
                          </p>
                          <p className="text-muted fw-medium  mb-0">
                            <b>Rs {parseFloat(userOwnEarningsData).toLocaleString('en-IN')}</b>
                          </p>
                        </div>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <i className="bx bx-archive-in font-size-24" />
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6">
                  <Card className="mini-stats-wid">
                    <CardBody className="subCard-body">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium mb-2">
                            Team Earnings
                          </p>
                          <p className="text-muted fw-medium  mb-0">
                            <b>Rs {parseFloat(userTeamEarningsData).toLocaleString('en-IN')}</b>
                          </p>
                        </div>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <i className="bx bx-archive-in font-size-24" />
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>

        <Col lg="6">
          <Card className="mini-stats-wid">
            <CardBody>
              <Row>
                <Col lg="12" className="mb-4">
                  <div className="d-flex align-items-end">
                    <div className="flex-grow-1">
                      <p className="text-muted fw-medium mb-2">
                        <b>Payouts</b>
                      </p>
                      <p className="text-muted fw-medium  mb-0">
                        Earnings Withdrawn
                      </p>
                    </div>
                    <div className="">
                      <p className="text-muted fw-medium mb-0">
                        <b> Rs {parseFloat(userEarningsWithdrawnData).toLocaleString('en-IN')}</b>
                      </p>
                    </div>
                  </div>
                </Col>

                <Col lg="6">
                  <Card className="mini-stats-wid">
                    <CardBody className="subCard-body">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium mb-2">
                            Ready for Withdrawal
                          </p>
                          <p className="text-muted fw-medium  mb-0">
                            <b>Rs {parseFloat(userReadyWithdrawalData).toLocaleString('en-IN')}</b>
                          </p>
                        </div>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <i className="bx bx-archive-in font-size-24" />
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6">
                  <Card className="mini-stats-wid">
                    <CardBody className="subCard-body">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium mb-2">
                            Not Ready for Withdrawal
                          </p>
                          <p className="text-muted fw-medium  mb-0">
                            <b>Rs {parseFloat(userNotReadyWithdrawlData).toLocaleString('en-IN')}</b>
                          </p>
                        </div>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <i className="bx bx-archive-in font-size-24" />
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default UserUserOverview
