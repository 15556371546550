import classnames from "classnames"
import React, { useEffect, useRef, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import {
  Button,
  Card,
  CardBody,
  CardText,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import HeaderTitle from "components/Common/headerTitleComponent"
import CustomSpinner from "components/customSpinner"
import {
  BUTTON_LABELS,
  MENU_LABELS,
  NAV_LABELS,
  STATUS_LABELS,
} from "constants/label"
import { AUTH_ROUTES } from "constants/routes"
import { DEFAULT_CLASSES } from "constants/styles"
import {
  changeLeadStatus,
  createAttachment,
  createNote,
  AssignProspects,
  getLeadDetails,
  submitChangePaymentStatus,
  submitChangeServiceStatus,
  submitPaymentRequest,
  submitGenerateCreditReport,
} from "helpers/api_actions"
import { generateClassNameByValue } from "helpers/utilities"

import { LEAD_TAB_LABELS } from "./dataManager"
import LeadDetails from "components/Leads/manage/components/tabs/leadDetails/index"
import Notes from "components/Leads/manage/components/tabs/notes/index"
import Attachments from "components/Leads/manage/components/tabs/attachments/index"
import Logs from "components/Leads/manage/components/tabs/logs/index"
import Payments from "components/Leads/manage/components/tabs/payments/index"
import Duplicates from "./component/tabs/leads/index"
import CustomerService from "./component/tabs/prospects/index"
import ManageNote from "components/Leads/manage/components/modal/manageNote"
import ManageProspects from "./component/manageProspects"
import RequestPayment from "components/Leads/manage/components/modal/requestPayment"
import ChangePaymentStatus from "components/Leads/manage/components/modal/changePaymentStatus"
import ChangeServiceStatus from "components/Leads/manage/components/modal/changeServiceStatus"
import ManageStatus from "components/Leads/manage/components/modal/manageStatus"
import ManageUnqualifyLead from "components/Leads/manage/components/modal/manageUnqualifyLead"
import GenerateCreditReport from "components/Leads/manage/components/modal/generateCreditReport"
import ViewPaymentUrl from "components/Leads/manage/components/modal/viewPaymentUrl"
import CreditReport from "../Leads/manage/components/tabs/creditReport/index"
import { isAdmin } from "helpers/utilities"

const UserDetails = props => {
  const state = props.history.state
  const [edit, setEdit] = useState(false)
  const [submiting, setSubmiting] = useState(false)
  const [manageStatus, setManageStatus] = useState(false)
  const [addNote, setAddNote] = useState(false)
  const [addProspects, setAddProspects] = useState(false)
  const [requestPayment, setRequestPayment] = useState(false)
  const [viewPaymentUrl, setPaymentUrl] = useState(false)
  const [changePaymentStatus, setChangePaymentStatus] = useState(false)
  const [changeServiceStatus, setChangeServiceStatus] = useState(false)
  const [generateCreditReport, setGenerateCreditReport] = useState(false)
  const [addAttachment, setAddAttachment] = useState(false)
  const [leadData, setLeadData] = useState({})
  const [activeTab, setActiveTab] = useState(LEAD_TAB_LABELS[0].id)
  const [isLoading, setIsLoading] = useState(true)
  const [unqualifyLead, setUnqualifyLead] = useState(false)
  const param = useParams()
  const { addToast } = useToasts()
  const noteRef = useRef(null)
  const prospectRef = useRef(null)
  const attachmentRef = useRef(null)
  const attachmentFieldRef = useRef(null)
  const detailRef = useRef(null)
  const headerActions =
    Object.keys(leadData).length === 0 ||
    leadData.lead_status?.name === "Completed"
      ? [
          ...(leadData?.payment_status === "Requested"
            ? [
                {
                  type: "button",
                  label: BUTTON_LABELS.CHANGE_PAYMENT_STATUS,
                  className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
                  action: () => {
                    setChangePaymentStatus(true)
                  },
                  show: [
                    LEAD_TAB_LABELS[0].id,
                    LEAD_TAB_LABELS[1].id,
                    LEAD_TAB_LABELS[2].id,
                    LEAD_TAB_LABELS[3].id,
                    LEAD_TAB_LABELS[4].id,
                    LEAD_TAB_LABELS[6].id,
                    LEAD_TAB_LABELS[7].id,
                  ],
                },
                {
                  type: "button",
                  label: BUTTON_LABELS.GET_PAYMENT_LINK,
                  className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
                  action: () => {
                    setPaymentUrl(true)
                  },
                  show: [
                    LEAD_TAB_LABELS[0].id,
                    LEAD_TAB_LABELS[1].id,
                    LEAD_TAB_LABELS[2].id,
                    LEAD_TAB_LABELS[3].id,
                    LEAD_TAB_LABELS[4].id,
                    LEAD_TAB_LABELS[6].id,
                    LEAD_TAB_LABELS[7].id,
                  ],
                },
                ...(isAdmin()
                  ? [
                      {
                        type: "button",
                        label: BUTTON_LABELS.ADD_PROSPECTS,
                        className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
                        action: () => {
                          setAddProspects(true)
                        },
                        show: [LEAD_TAB_LABELS[7].id],
                      },
                    ]
                  : []),
              ]
            : [
                ...(isAdmin()
                  ? [
                      {
                        type: "button",
                        label: BUTTON_LABELS.ADD_PROSPECTS,
                        className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
                        action: () => {
                          setAddProspects(true)
                        },
                        show: [LEAD_TAB_LABELS[7].id],
                      },
                    ]
                  : []),
              ]),
        ]
      : leadData.lead_status?.name === "Unqualified"
      ? [
          ...(leadData.change_lead_status
            ? [
                {
                  type: "button",
                  label: BUTTON_LABELS.CHANGE_STATUS,
                  className: DEFAULT_CLASSES.HEADER_BUTTON_PRIMARY,
                  action: () => {
                    setManageStatus(true)
                  },
                  show: [
                    LEAD_TAB_LABELS[0].id,
                    LEAD_TAB_LABELS[1].id,
                    LEAD_TAB_LABELS[2].id,
                    LEAD_TAB_LABELS[3].id,
                    LEAD_TAB_LABELS[4].id,
                  ],
                },
              ]
            : []),
        ]
      : leadData.lead_status?.name === "Converted"
      ? [
          ...((leadData?.payment_status === null ||
            leadData?.payment_status === "Paid") &&
          leadData.service?.is_paid === true
            ? [
                {
                  type: "button",
                  label: BUTTON_LABELS.REQUEST_PAYMENT,
                  className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
                  action: () => {
                    setRequestPayment(true)
                  },
                  show: [
                    LEAD_TAB_LABELS[0].id,
                    LEAD_TAB_LABELS[1].id,
                    LEAD_TAB_LABELS[2].id,
                    LEAD_TAB_LABELS[3].id,
                    LEAD_TAB_LABELS[4].id,
                  ],
                },
              ]
            : []),
          ...(leadData?.payment_status === "Requested"
            ? [
                {
                  type: "button",
                  label: BUTTON_LABELS.CHANGE_PAYMENT_STATUS,
                  className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
                  action: () => {
                    setChangePaymentStatus(true)
                  },
                  show: [
                    LEAD_TAB_LABELS[0].id,
                    LEAD_TAB_LABELS[1].id,
                    LEAD_TAB_LABELS[2].id,
                    LEAD_TAB_LABELS[3].id,
                    LEAD_TAB_LABELS[4].id,
                  ],
                },
                {
                  type: "button",
                  label: BUTTON_LABELS.GET_PAYMENT_LINK,
                  className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
                  action: () => {
                    setPaymentUrl(true)
                  },
                  show: [
                    LEAD_TAB_LABELS[0].id,
                    LEAD_TAB_LABELS[1].id,
                    LEAD_TAB_LABELS[2].id,
                    LEAD_TAB_LABELS[3].id,
                    LEAD_TAB_LABELS[4].id,
                  ],
                },
              ]
            : []),
          {
            type: "button",
            label: BUTTON_LABELS.ADD_NOTE,
            className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
            action: () => {
              setAddNote(true)
            },
            show: [LEAD_TAB_LABELS[1].id],
          },
          {
            type: "button",
            label: BUTTON_LABELS.ADD_ATTACHMENT,
            className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
            action: () => {
              attachmentFieldRef.current.click()
            },
            show: [LEAD_TAB_LABELS[2].id],
          },

          ...(leadData?.change_service_status
            ? [
                {
                  type: "button",
                  label: BUTTON_LABELS.CHANGE_SERVICE_STATUS,
                  className: DEFAULT_CLASSES.HEADER_BUTTON_PRIMARY,
                  action: () => {
                    setChangeServiceStatus(true)
                  },
                  show: [
                    LEAD_TAB_LABELS[0].id,
                    LEAD_TAB_LABELS[1].id,
                    LEAD_TAB_LABELS[2].id,
                    LEAD_TAB_LABELS[3].id,
                    LEAD_TAB_LABELS[4].id,
                  ],
                },
              ]
            : []),
          ...(edit
            ? [
                {
                  type: "button",
                  label: BUTTON_LABELS.CANCEL,
                  className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
                  action: () => {
                    setEdit(false)
                    fetchUserDetails()
                  },
                  show: [LEAD_TAB_LABELS[0].id],
                },
                {
                  type: "button",
                  label: BUTTON_LABELS.SAVE,
                  className: DEFAULT_CLASSES.HEADER_BUTTON_PRIMARY,
                  action: () => {
                    detailRef.current.update()
                  },
                  show: [LEAD_TAB_LABELS[0].id],
                },
              ]
            : [
                {
                  type: "button",
                  label: BUTTON_LABELS.EDIT,
                  className: DEFAULT_CLASSES.HEADER_BUTTON_PRIMARY,
                  action: () => {
                    setEdit(true)
                  },
                  show: [LEAD_TAB_LABELS[0].id],
                },
              ]),
        ]
      : [
          ...((leadData?.payment_status === null ||
            leadData?.payment_status === "Paid") &&
          leadData.service?.is_paid === true
            ? [
                {
                  type: "button",
                  label: BUTTON_LABELS.REQUEST_PAYMENT,
                  className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
                  action: () => {
                    setRequestPayment(true)
                  },
                  show: [
                    LEAD_TAB_LABELS[0].id,
                    LEAD_TAB_LABELS[1].id,
                    LEAD_TAB_LABELS[2].id,
                    LEAD_TAB_LABELS[3].id,
                    LEAD_TAB_LABELS[4].id,
                  ],
                },
              ]
            : []),
          ...(leadData?.payment_status === "Requested"
            ? [
                {
                  type: "button",
                  label: BUTTON_LABELS.CHANGE_PAYMENT_STATUS,
                  className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
                  action: () => {
                    setChangePaymentStatus(true)
                  },
                  show: [
                    LEAD_TAB_LABELS[0].id,
                    LEAD_TAB_LABELS[1].id,
                    LEAD_TAB_LABELS[2].id,
                    LEAD_TAB_LABELS[3].id,
                    LEAD_TAB_LABELS[4].id,
                  ],
                },
                {
                  type: "button",
                  label: BUTTON_LABELS.GET_PAYMENT_LINK,
                  className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
                  action: () => {
                    setPaymentUrl(true)
                  },
                  show: [
                    LEAD_TAB_LABELS[0].id,
                    LEAD_TAB_LABELS[1].id,
                    LEAD_TAB_LABELS[2].id,
                    LEAD_TAB_LABELS[3].id,
                    LEAD_TAB_LABELS[4].id,
                  ],
                },
              ]
            : []),
          {
            type: "button",
            label: BUTTON_LABELS.ADD_NOTE,
            className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
            action: () => {
              setAddNote(true)
            },
            show: [LEAD_TAB_LABELS[1].id],
          },
          {
            type: "button",
            label: BUTTON_LABELS.ADD_ATTACHMENT,
            className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
            action: () => {
              attachmentFieldRef.current.click()
            },
            show: [LEAD_TAB_LABELS[2].id],
          },

          ...(leadData.change_lead_status
            ? [
                {
                  type: "button",
                  label: BUTTON_LABELS.CHANGE_STATUS,
                  className: DEFAULT_CLASSES.HEADER_BUTTON_PRIMARY,
                  action: () => {
                    setManageStatus(true)
                  },
                  show: [
                    LEAD_TAB_LABELS[0].id,
                    LEAD_TAB_LABELS[1].id,
                    LEAD_TAB_LABELS[2].id,
                    LEAD_TAB_LABELS[3].id,
                    LEAD_TAB_LABELS[4].id,
                  ],
                },
              ]
            : []),

          ...(edit
            ? [
                {
                  type: "button",
                  label: BUTTON_LABELS.CANCEL,
                  className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
                  action: () => {
                    setEdit(false)
                    fetchUserDetails()
                  },
                  show: [LEAD_TAB_LABELS[0].id],
                },
                {
                  type: "button",
                  label: BUTTON_LABELS.SAVE,
                  className: DEFAULT_CLASSES.HEADER_BUTTON_PRIMARY,
                  action: () => {
                    detailRef.current.update()
                  },
                  show: [LEAD_TAB_LABELS[0].id],
                },
              ]
            : [
                {
                  type: "button",
                  label: BUTTON_LABELS.EDIT,
                  className: DEFAULT_CLASSES.HEADER_BUTTON_PRIMARY,
                  action: () => {
                    setEdit(true)
                  },
                  show: [LEAD_TAB_LABELS[0].id],
                },
              ]),
        ]

  // if (leadData?.primary_phone && leadData?.primary_email) {
  //   headerActions.push({
  //     type: "button",
  //     label: BUTTON_LABELS.GENERATE_CREDIT_REPORT,
  //     className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
  //     action: () => {
  //       setGenerateCreditReport(true)
  //     },
  //     show: [
  //       LEAD_TAB_LABELS[0].id,
  //       LEAD_TAB_LABELS[1].id,
  //       LEAD_TAB_LABELS[2].id,
  //       LEAD_TAB_LABELS[3].id,
  //       LEAD_TAB_LABELS[4].id,

  //     ],
  //   })
  // }

  const breadcrumb = [
    { label: NAV_LABELS.LEADS, path: AUTH_ROUTES.LEADS },
    {
      label: leadData?.name,
      path: `${AUTH_ROUTES.LEAD_DETAILS}${param.id}`,
    },
  ]
  const tags = [
    {
      label: leadData?.lead_status?.name || "Unknown",
      className: generateClassNameByValue(leadData?.lead_status?.name),
    },
    // {
    //   label: leadData?.lead_owner_rank,
    //   className: generateClassNameByValue(leadData?.lead_owner_rank),
    // },
    {
      label: leadData?.display_id || "n/a",
      className: "display_id",
    },
    ...(leadData?.is_qualified &&
    leadData.service_id &&
    leadData?.service_status?.service_lead_status
      ? [
          {
            label: `Service Status: ${
              leadData?.service_status?.service_lead_status || "--"
            }`,
          },
        ]
      : []),
    ...(leadData?.payment_status
      ? [
          {
            label: `Payment Status: ${leadData?.payment_status}`,
          },
        ]
      : []),
  ]

  const fetchUserDetails = () => {
    setIsLoading(true)
    getLeadDetails(param.id)
      .then(res => {
        setLeadData(res.data)
        setIsLoading(false)
        setEdit(false)
      })
      .catch(e => {
        setIsLoading(false)
      })
  }
  const toggleCustom = tab => {
    if (activeTab !== tab) {
      fetchTabData(tab)
    }
  }

  const fetchTabData = tab => {
    setActiveTab(tab)
    setIsLoading(true)
    switch (tab) {
      // USER_OVERVIEW
      case LEAD_TAB_LABELS[0].id:
        setIsLoading(false)
        break
      // USER_DETAILS
      case LEAD_TAB_LABELS[1].id:
        fetchUserDetails()
        setIsLoading(false)
        break
      // USER_LEADS
      case LEAD_TAB_LABELS[2].id:
        setIsLoading(false)
        break
      // USER_TEAM_MEMBERS
      case LEAD_TAB_LABELS[3].id:
        setIsLoading(false)
        break
      case LEAD_TAB_LABELS[4].id:
        setIsLoading(false)
        break
      case LEAD_TAB_LABELS[5].id:
        setIsLoading(false)
        break
      case LEAD_TAB_LABELS[6].id:
        setIsLoading(false)
        break
      case LEAD_TAB_LABELS[7].id:
        setIsLoading(false)
        break
      default:
        break
    }
  }
  useEffect(() => {
    fetchUserDetails()
  }, [])
  const closeCreateNote = () => {
    setSubmiting(false)
    setAddNote(false)
  }
  const closeCreateProspects = () => {
    setSubmiting(false)
    setAddProspects(false)
  }
  const closeRequestPayment = () => {
    setSubmiting(false)
    setRequestPayment(false)
  }
  const closeChangePaymentStatus = () => {
    setSubmiting(false)
    setChangePaymentStatus(false)
  }
  const closeGenerateCreditReport = () => {
    setSubmiting(false)
    setGenerateCreditReport(false)
  }
  const closeChangeServiceStatus = () => {
    setSubmiting(false)
    setChangeServiceStatus(false)
  }
  const closeChangeStatus = () => {
    setSubmiting(false)
    setManageStatus(false)
  }
  const closePaymentUrl = () => {
    setSubmiting(false)
    setPaymentUrl(false)
  }
  const closeUnqualifyLead = () => {
    setSubmiting(false)
    setUnqualifyLead(false)
  }
  const handleChangeStatus = data => {
    setSubmiting(true)
    let payload = {
      lead_status_id: data?.lead_status?.value,
      lead_note: data?.lead_note,
    }
    if (
      data?.lead_status?.label === "Converted" &&
      leadData?.service?.earning_process === "Normal Flow"
    ) {
      payload = {
        ...payload,
        amount_earned: data?.amount_earned,
      }

      if (leadData?.is_calculate_commission) {
        payload = {
          ...payload,
          loan_amount: data?.loan_amount,
        }
      }
    }
    changeLeadStatus(payload, param.id)
      .then(res => {
        addToast(res?.message, { appearance: "success" })
        fetchUserDetails()
        setSubmiting(false)
        setManageStatus(false)
        setUnqualifyLead(false)
      })
      .catch(err => {
        setSubmiting(false)
        addToast(err.response?.data?.message, { appearance: "error" })
      })
  }
  const handleSaveNote = data => {
    setSubmiting(true)
    createNote({ ...data, model: "Lead", model_id: param.id })
      .then(res => {
        addToast(res?.message, { appearance: "success" })
        noteRef.current.refetch()
        setSubmiting(false)
        setAddNote(false)
      })
      .catch(err => {
        setSubmiting(false)
        addToast(err.response?.data?.message, { appearance: "error" })
      })
  }
  const handleSaveProspects = data => {
    setSubmiting(true)
    AssignProspects({ ...data, lead_id: param.id })
      .then(res => {
        addToast(res?.message, { appearance: "success" })
        console.log(prospectRef.current)
        prospectRef.current.refetch()
        setSubmiting(false)
        setAddProspects(false)
      })
      .catch(err => {
        setSubmiting(false)
        addToast(err.response?.data?.message, { appearance: "error" })
      })
  }
  const handleRequestPayment = data => {
    setSubmiting(true)
    submitPaymentRequest(param.id, data)
      .then(res => {
        addToast(res?.message, { appearance: "success" })
        setSubmiting(false)
        setRequestPayment(false)
        fetchUserDetails()
      })
      .catch(err => {
        setSubmiting(false)
        addToast(err.response?.data?.message, { appearance: "error" })
      })
  }
  const handleChangePaymentStatus = data => {
    setSubmiting(true)
    submitChangePaymentStatus(param.id, data)
      .then(res => {
        addToast(res?.message, { appearance: "success" })
        setSubmiting(false)
        setChangePaymentStatus(false)
        fetchUserDetails()
      })
      .catch(err => {
        setSubmiting(false)
        addToast(err.response?.data?.message, { appearance: "error" })
      })
  }
  const handleGenerateCreditReport = data => {
    setSubmiting(true)
    submitGenerateCreditReport(param.id)
      .then(res => {
        addToast(res?.message, { appearance: "success" })
        setSubmiting(false)
        setGenerateCreditReport(false)
      })
      .catch(err => {
        setSubmiting(false)
        addToast(err.response?.data?.message, { appearance: "error" })
      })
  }
  const handleChangeServiceStatus = data => {
    setSubmiting(true)
    submitChangeServiceStatus(param.id, data)
      .then(res => {
        addToast(res?.message, { appearance: "success" })
        setSubmiting(false)
        setChangeServiceStatus(false)
        fetchUserDetails()
      })
      .catch(err => {
        setSubmiting(false)
        addToast(err.response?.data?.message, { appearance: "error" })
      })
  }
  const handleSubmitFiles = files => {
    let payload = new FormData()
    Object.keys(files).forEach(key => {
      payload.append(`attachments[${key}]`, files[key])
    })
    payload.append("model", "Lead")
    payload.append("model_id", param.id)

    createAttachment(payload)
      .then(res => {
        addToast(res?.message, { appearance: "success" })
        attachmentRef.current.refetch()
        setSubmiting(false)
        setAddNote(false)
        setAddProspects(false)
      })
      .catch(err => {
        setSubmiting(false)
        addToast(err.response?.data?.message, { appearance: "error" })
      })
  }
  return (
    <React.Fragment>
      {addNote && (
        <ManageNote
          submiting={submiting}
          onCancelAction={closeCreateNote}
          onSubmitAction={handleSaveNote}
        />
      )}
      {addProspects && (
        <ManageProspects
          submiting={submiting}
          onCancelAction={closeCreateProspects}
          onSubmitAction={handleSaveProspects}
          leadData={leadData}
        />
      )}
      {requestPayment && (
        <RequestPayment
          submiting={submiting}
          onCancelAction={closeRequestPayment}
          onSubmitAction={handleRequestPayment}
          service={leadData.service}
        />
      )}
      {changePaymentStatus && (
        <ChangePaymentStatus
          submiting={submiting}
          onCancelAction={closeChangePaymentStatus}
          onSubmitAction={handleChangePaymentStatus}
          leadData={leadData}
        />
      )}
      {generateCreditReport && (
        <GenerateCreditReport
          submiting={submiting}
          onCancelAction={closeGenerateCreditReport}
          onSubmitAction={handleGenerateCreditReport}
        />
      )}
      {changeServiceStatus && (
        <ChangeServiceStatus
          submiting={submiting}
          onCancelAction={closeChangeServiceStatus}
          onSubmitAction={handleChangeServiceStatus}
          leadData={leadData}
        />
      )}
      {viewPaymentUrl && (
        <ViewPaymentUrl
          submiting={submiting}
          onCancelAction={closePaymentUrl}
          // onSubmitAction={handlePaymentUrl}
          leadData={leadData}
        />
      )}
      {manageStatus && (
        <ManageStatus
          submiting={submiting}
          leadData={leadData}
          data={{
            lead_status: {
              value: leadData.lead_status?.id,
              label: leadData.lead_status?.name,
            },
          }}
          activeStatus={leadData.lead_status?.name}
          onCancelAction={closeChangeStatus}
          onSubmitAction={handleChangeStatus}
        />
      )}
      {/* {unqualifyLead && (
        <ManageUnqualifyLead
          submiting={submiting}
          leadData={leadData}
          onCancelAction={closeUnqualifyLead}
          onSubmitAction={handleChangeStatus}
        />
      )} */}
      <div className="page-content">
        <Container fluid>
          <Row>
            <HeaderTitle
              title={leadData?.name}
              actions={headerActions}
              tags={tags}
              disabled={submiting}
              loading={isLoading}
              activeTab={activeTab}
              breadcrumb={breadcrumb}
            />
          </Row>
          <Row>
            <React.Fragment>
              <Col lg="12">
                <Card className="m-0">
                  <CardBody className="p-0">
                    <Row>
                      <Col xs={12}>
                        <Nav tabs className="nav-tabs-custom ">
                          {LEAD_TAB_LABELS.filter(
                            tabItem =>
                              tabItem.id != LEAD_TAB_LABELS[5].id ||
                              (tabItem.id == LEAD_TAB_LABELS[5].id &&
                                leadData?.cibil_id &&
                                leadData?.cibil_status &&
                                leadData?.cibil_status?.toLowerCase() ==
                                  "success")
                          ).map((tabitem, tabkey) => (
                            <NavItem key={tabkey}>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: activeTab === tabitem.id,
                                })}
                                onClick={() => {
                                  toggleCustom(tabitem.id)
                                }}
                              >
                                <span className="">{tabitem.label}</span>
                              </NavLink>
                            </NavItem>
                          ))}
                        </Nav>
                      </Col>
                    </Row>

                    <TabContent activeTab={activeTab} className="text-muted">
                      {isLoading ? (
                        <CustomSpinner />
                      ) : (
                        <>
                          {/*LEAD DETAILS*/}
                          <TabPane tabId={LEAD_TAB_LABELS[0].id}>
                            <LeadDetails
                              parentID={param.id}
                              data={leadData}
                              edit={edit}
                              ref={detailRef}
                              setSubmiting={setSubmiting}
                              refresh={fetchUserDetails}
                            />
                          </TabPane>
                          {/* NOTES */}
                          <TabPane tabId={LEAD_TAB_LABELS[1].id}>
                            <Notes parentID={param.id} ref={noteRef} />
                          </TabPane>
                          {/* ATTACHMENTS */}
                          <TabPane tabId={LEAD_TAB_LABELS[2].id}>
                            <Attachments
                              parentID={param.id}
                              ref={attachmentRef}
                            />
                          </TabPane>
                          {/* LOGS */}
                          {activeTab === LEAD_TAB_LABELS[3].id && (
                            <TabPane tabId={LEAD_TAB_LABELS[3].id}>
                              <Logs parentID={param.id} />
                            </TabPane>
                          )}
                          {/* PAYMENTS */}
                          <TabPane tabId={LEAD_TAB_LABELS[4].id}>
                            <Payments parentID={param.id} />
                          </TabPane>
                          {/* DUPLICATE */}
                          <TabPane tabId={LEAD_TAB_LABELS[6].id}>
                            <Duplicates
                              parentID={leadData?.primary_phone}
                              data={leadData}
                            />
                          </TabPane>
                          {/* PROSPECTS */}
                          <TabPane tabId={LEAD_TAB_LABELS[7].id}>
                            <CustomerService
                              parentID={param.id}
                              ref={prospectRef}
                              data={leadData}
                            />
                          </TabPane>
                          {/* Credit Report */}
                          {leadData?.cibil_id &&
                            leadData?.cibil_status &&
                            leadData?.cibil_status?.toLowerCase() ==
                              "success" && (
                              <TabPane tabId={LEAD_TAB_LABELS[5].id}>
                                <CreditReport parentID={param.id} />
                              </TabPane>
                            )}
                        </>
                      )}
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </React.Fragment>
          </Row>
        </Container>
      </div>
      <div className="d-none">
        <input
          type="file"
          multiple
          ref={attachmentFieldRef}
          onChange={e => handleSubmitFiles(e.target.files)}
        />
      </div>
    </React.Fragment>
  )
}

export default UserDetails
