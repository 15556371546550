import SelectfieldComponent from "components/Common/SelectfieldComponent"
import CustomCalander from "components/CustomCalendar/index"
import SearchSelect from "components/CustomSearchSelect"
import CustomSelect from "components/CustomSelect/index"
import { getPayoutModesList } from "helpers/api_actions"
import React, { useEffect } from "react"
import { useState } from "react"
import { forwardRef, useImperativeHandle } from "react"
import { Row, Col, Button } from "reactstrap"

const UserFilterComponent = forwardRef((props, ref) => {
  UserFilterComponent.displayName = "User Filter"
  const [selectUser, setselectUser] = useState(props.filters?.user)
  const [selectedStatus, setSelectedStatus] = useState(props.filters?.status)
  const [selectedCreatedOn, setSelectedCreatedOn] = useState(
    props.filters?.createdOn
  )
  const [selectedUpdatedOn, setSelectedUpdatedOn] = useState(
    props.filters?.updatedOn
  )
  const [selectedPaymentDate, setSelectedPaymentDate] = useState(
    props.filters?.paymentDate
  )
  const [selectedTransferMode, setSelectedTransferMode] = useState(
    props.filters?.transferMode
  )
  const [selectedPaymentType, setSelectedPaymentType] = useState(
    props.filters?.paymentType
  )
  const [transferModes, setTransferModes] = useState([])
  const defaultSelection = null

  useEffect(() => {
    let payload = { select: "id,display_name" }
    getPayoutModesList(payload).then(res => {
      let transferModesData = res?.data?.data || []
      if (transferModesData && transferModesData.length) {
        transferModesData.forEach(item => {
          item["label"] = item.display_name
          item["value"] = item.id
        })
        setTransferModes(transferModesData)
      }
    })
  }, [])

  const onApplyFilter = () => {
    let filters = {
      user: selectUser,
      transferMode: selectedTransferMode,
      paymentType: selectedPaymentType,
      status: selectedStatus,
      updatedOn: selectedUpdatedOn,
      createdOn: selectedCreatedOn,
      paymentDate: selectedPaymentDate,
    }
    props.onFilterSubmission(filters)
  }
  const onCancelFilter = () => {
    setselectUser(undefined)
    setSelectedCreatedOn(undefined)
    setSelectedTransferMode(undefined)
    setSelectedPaymentType(undefined)
    setSelectedUpdatedOn(undefined)
    setSelectedStatus(undefined)
    setSelectedPaymentDate(undefined)
    props.onFilterSubmission({
      user: undefined,
      transferMode: undefined,
      paymentType: undefined,
      status: undefined,
      updatedOn: undefined,
      createdOn: undefined,
      paymentDate: undefined,
    })
  }

  const statusList = [
    { label: "Requested", value: "Requested" },
    { label: "Processing", value: "Processing" },
    { label: "Completed", value: "Completed" },
  ]
  const paymentTypesList = [
    { label: "Partial Payment", value: "Partial Payment" },
    { label: "Full Payment", value: "Full Payment" },
  ]
  const getUser = async v => {
    let payload = {
      select: "users.id,full_name",
      include: `user_type&find[user_type.name]=AdminUser&search[full_name]=${v}`,
    }

    const res = await getUserList(payload)
    const data = res?.data?.data || []
    data.forEach(item => {
      item["label"] = item.full_name
      item["value"] = item.id
    })
    return data
  }
  const getTransferMode = async v => {
    let payload = { select: "id,display_name" }
    let filter = `?filter[display_name]=${v}`
    const res = await getPayoutModesList(payload, filter)
    const data = res?.data?.data || []
    data.forEach(item => {
      item["label"] = item.display_name
      item["value"] = item.id
    })
    return data
  }

  return (
    <>
      <Row>
        <Col lg={3} md={4} sm={6} xs={12}>
          <SearchSelect
            getData={getUser}
            isMulti={false}
            label="Updated By"
            setData={v => {
              setselectUser(v)
            }}
            value={selectUser ?? defaultSelection}
          />
        </Col>
        <Col lg={3} md={4} sm={6} xs={12}>
          <CustomSelect
            options={transferModes}
            isMulti={false}
            clearable
            label="Mode of Transfer"
            setData={v => {
              setSelectedTransferMode(v)
            }}
            value={selectedTransferMode ?? defaultSelection}
          />
        </Col>
        <Col lg={3} md={4} sm={6} xs={12}>
          <CustomSelect
            options={paymentTypesList}
            isMulti={false}
            clearable
            label="Payment Type"
            setData={v => {
              setSelectedPaymentType(v)
            }}
            value={selectedPaymentType ?? defaultSelection}
          />
        </Col>

        <Col lg={3} md={4} sm={6} xs={12}>
          <CustomSelect
            options={statusList}
            isMulti={false}
            clearable
            label="Status"
            setData={v => {
              setSelectedStatus(v)
            }}
            value={selectedStatus ?? defaultSelection}
          />
        </Col>
        <Col lg={3} md={4} sm={6} xs={12}>
          <CustomCalander
            dateRange
            maxDate={"today"}
            placeholder={"Start Date - End Date"}
            value={selectedPaymentDate}
            onChange={date => setSelectedPaymentDate(date)}
            label="Paid Between"
          />
        </Col>
        <Col lg={3} md={4} sm={6} xs={12}>
          <CustomCalander
            dateRange
            value={selectedUpdatedOn}
            onChange={date => setSelectedUpdatedOn(date)}
            label="Updated Between"
            placeholder={"Start Date - End Date"}
            maxDate={"today"}
          />
        </Col>
        <Col lg={3} md={4} sm={6} xs={12}>
          <CustomCalander
            dateRange
            maxDate={"today"}
            placeholder={"Start Date - End Date"}
            value={selectedCreatedOn}
            onChange={date => setSelectedCreatedOn(date)}
            label="Created Between"
          />
        </Col>
        <Col
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className="d-flex align-items-end justify-content-end pt-3"
        >
          <div className="me-2">
            <Button
              className="btn btn-sm btn-primary btn-success text-white"
              onClick={() => onApplyFilter()}
            >
              Apply
            </Button>
          </div>
          <div>
            <Button
              className="btn btn-sm btn-danger"
              onClick={() => onCancelFilter()}
            >
              Reset
            </Button>
          </div>
        </Col>
      </Row>
    </>
  )
})

export default UserFilterComponent
