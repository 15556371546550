import moment from "moment"
import React, { useEffect, useState } from "react"
import { Col, Input, Modal, Row } from "reactstrap"

import { getEarningHistory } from "helpers/api_actions"

const Earninghistory = props => {
  const [formData, setFormData] = useState(props.data || {})
  const [datas, setDatas] = useState([])

  const handleChange = (v, k) => {
    setFormData({ ...formData, [k]: v })
  }
  const getLeadStatus = () => {
    let payload = {
      select: `title,note,users.full_name as change_by,earnings_log.status as status,earnings_log.created_at&include=users&find[earnings_log.earning_id}}=${props.data.id}`,
      include: "users",
    }

    getEarningHistory(payload).then(res => {
      let data = res?.data || []
      setDatas(data)
    })
  }
  useEffect(() => {
    getLeadStatus()
  }, [props.data.id])
  const handleDate = data => {
    return moment(data).format("h:mm A | DD/MM/YYYY")
  }
  return (
    <Modal isOpen={true} toggle={props.onCancelAction} centered={true}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          Earning History
        </h5>
        <button
          type="button"
          disabled={props.submiting}
          onClick={() => {
            props.onCancelAction()
          }}
          className="btn-close"
        ></button>
      </div>
      <div className="modal-body histcontainer">
        <form>
          {datas?.data?.map(item => (
            <div key={item.id} className="histcontainer">
              <div className="history_list_cont">
                <div className="history_list_title">{item.title}</div>
                <div className="history_list_sub">
                  {`${handleDate(item.created_at)} by ${item.change_by}`}
                </div>
                <div className="status">
                  <div className="labelcolor">Status</div>:
                  <div className="textcolor">{item.status}</div>
                </div>
                {item.note && (
                  <div className="history_list_sub">{item.note}</div>
                )}
                <div className="border_list"></div>
              </div>
            </div>
          ))}
        </form>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          data-bs-dismiss="modal"
          disabled={props.submiting}
          onClick={() => {
            props.onCancelAction()
          }}
        >
          Cancel
        </button>
      </div>
    </Modal>
  )
}
export default Earninghistory
