import moment from "moment"
import React, { lazy, Suspense, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useToasts } from "react-toast-notifications"

import CustomSpinner from "components/customSpinner"
import { AUTH_ROUTES } from "constants/routes"
import { getPlatformUsersList, getTeamMembers, postCreUserUnassign } from "helpers/api_actions"

import UnassignUser from "../../modal/unassignUser"
import UserFilterComponent from "./filterComponent"

const CustomTable = lazy(() => import("components/customTable"))
function AssignedUsersListing(props) {
  const [pageSize, setPageSize] = useState(15)
  const [data, setData] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [pageNo, setPageNo] = useState(1)
  const [search, setSearch] = useState("")
  const [filters, setFilters] = useState({})
  const [sortKey, setSortKey] = useState("&sort_by[users.full_name]=ASC")
  const [appliedFilters, setAppliedFilters] = useState("")
  const [appliedSeach, setAppliedSearch] = useState("")
  const [isFetching, setIsFetching] = useState(false)
  const [unassignUser, setUnassignUser] = useState(false)
  const [selectedRow, setSelectedRow] = useState()
  const [submiting, setSubmiting] = useState(false)
  const { addToast } = useToasts()
  const navigateToDetails = (id, isEdit = false) => {
    props.history.push({
      pathname: `${AUTH_ROUTES.USER_DETAILS}${id}`,
      state: { edit: isEdit },
    })
  }
  const listUsers = params => {
    let basicData = {
      select: `users.id as ID,users.full_name as Full Name,cre_user.full_name as Handled By,users.primary_phone as Phone,user_addresses.city as City,ranks.name as Rank Name,users.status as Status,LeadCount,Prospect,SaleCount,TeamCount,Requestcount,team_earnings,my_earnings,Requestcount,platform_users.created_at as Created At,platform_users.updated_at as Updated At`,
      include: `users,ranks,user_addresses,users as cre_user&find[platform_users.cre_user_id]=${props.creId}&page=${pageNo}&per_page=${pageSize}`,
    }
    setIsFetching(true)
    getPlatformUsersList(basicData, appliedSeach, appliedFilters, sortKey)
      .then(res => {
        setData(res?.data?.data)
        setTotalCount(res?.data?.total || 0)
        setIsFetching(false)
      })
      .catch(err => {
        setIsFetching(false)
      })
  }
  useEffect(() => {
    listUsers()
  }, [pageSize, pageNo, appliedSeach, sortKey, appliedFilters])

  const handleUnassignConfirm = rowData => {
    setUnassignUser(true)
    setSelectedRow(rowData)
  }
  const handleTableMenu = (action, rowData) => {
    switch (action.action) {
      case "edit":
        navigateToDetails(rowData.ID, true)
        break

      case "unassign":
        handleUnassignConfirm(rowData)
        break
      default:
        break
    }
  }
  const [columns, setColumns] = useState([
    {
      title: "Name",
      field: "Full Name",
      minWidth: 180,
      width: 180,
      wrap: true,
      sort_direction: "ASC",
      sort_key: "users.full_name",
      render: (r, v) => (
        <Link
          className="text-link"
          to={{
            pathname: `${AUTH_ROUTES.USER_DETAILS}${r.ID}`,
            state: { edit: false },
          }}
        >
          {v}
        </Link>
      ),
    },
    {
      title: "Rank",
      sort_key: "Rank Name",
      field: "Rank Name",
      align:"start",
      minWidth: 150,
      width: 150,
      wrap:true,
    },
    {
      title: "Phone",
      sort_key: "users.primary_phone",
      field: "Phone",
      align: "start",
      minWidth: 150,
      width: 150,
      wrap: true,
    },
    {
      title: "City",
      sort_key: "user_addresses.city",
      field: "City",

      minWidth: 150,
      width: 150,
      wrap: true,
    },
    {
      title: "Leads",
      field: "leadcount",
      minWidth: 90,
      width: 90,
      wrap: true,
      sort_key: "leadcount",
      align: "center",
      tooltip_text: "Shows the count of 'Closed Leads / Total Leads'",
    },
    {
      title: "Sale Generated",
      field: "salecount",
      minWidth: 90,
      width: 90,
      wrap: true,
      sort_key: "SaleCount",
      align: "center",
      tooltip_text: "Shows the count of 'Converted & Completed Leads'",
    },
    {
      title: "Prospect value",
      field: "prospect",
      minWidth: 90,
      width: 90,
      wrap: true,
      sort_key: "Prospect",
      align: "center",
      tooltip_text: "Prospect value",
    },
    {
      title: "My Team",
      field: "teamcount",
      sort_key: "teamcount",
      minWidth: 130,
      width: 130,
      wrap: true,
      align: "center",
      tooltip_text: "Shows the number of team members",
    },
    {
      title: "Own Earnings",
      field: "my_earnings",
      sort_key: "my_earnings",
      minWidth: 165,
      width: 165,
      wrap: true,
      align: "center",
      tooltip_text: "Shows the lifetime commisisons generated by the user",
      render: (row, val) => (
        <div>
          {val ? parseFloat(val).toLocaleString('en-IN') : "--"}
        </div>
      ),
    },
    {
      title: "Team Earnings",
      field: "team_earnings",
      sort_key: "team_earnings",
      minWidth: 170,
      width: 170,
      wrap: true,
      align: "center",
      tooltip_text:
        "Shows the lifetime commisisons generated by the team members",
        render: (row, val) => (
          <div>
            {val ? parseFloat(val).toLocaleString('en-IN') : "--"}
          </div>
        ),
    },
    {
      title: "Requests",
      field: "requestcount",
      minWidth: 110,
      width: 110,
      wrap: true,
      sort_key: "requestcount",
      align: "center",
      tooltip_text: "Shows the count of 'Processed Requests  / Total Requests'",
    },
    {
      title: "Status",
      field: "Status",
      sort_key: "users.status",
      minWidth: 80,
      width: 80,
      wrap: true,
      align: "start",
    },
    {
      title: "Updated On",
      field: "Updated At",
      minWidth: 135,
      width: 135,
      wrap: true,
      align: "start",
      //sort_direction: "ASC",
      sort_key: "platform_users.updated_at",
      render: (row, val) => (
        <div>{val ? moment(val).format("DD-MMM-YYYY") : "--"}</div>
      ),
    },
    {
      title: "Created On",
      field: "Created At",
      minWidth: 130,
      width: 130,
      wrap: true,
      align: "start",
      //sort_direction: "ASC",
      sort_key: "platform_users.created_at",
      render: (row, val) => (
        <div>{val ? moment(val).format("DD-MMM-YYYY") : "--"}</div>
      ),
    },
  ])

  const handleSort = row => {
    let nextDirection = row.sort_direction
      ? row?.sort_direction === "ASC"
        ? "DESC"
        : "ASC"
      : "ASC"
    columns.forEach(col => {
      if (col.field === row.field) {
        col["sort_direction"] = nextDirection
      } else {
        col["sort_direction"] = undefined
      }
    })
    setColumns(() => columns)

    let sortkey = `&sort_by[${row.sort_key}]=${nextDirection}`
    setSortKey(sortkey)
  }
  const handleSearch = val => {
    setSearch(val)
    let searchString =
      val && val !== ""
        ? `&search[users.full_name]=${val}&search[users.primary_phone]=${val}&search[user_addresses.city]=${val}&search[platform_users.secondary_phone]=${val}&search[platform_users.secondary_email]=${val}&search[platform_users.job]=${val}&search[platform_users.business_name]=${val}&search[user_addresses.address_line_1]=${val}&search[user_addresses.address_line_2]=${val}&search[user_addresses.state]=${val}&search[user_addresses.pincode]=${val}&search[user_addresses.country]=${val}&search[users.display_id]=${val}`
        : undefined
    setAppliedSearch(searchString)
  }

  const onFilterSubmission = fil => {
    setFilters(fil)
    let filterString = undefined
    filterString = `${
      fil?.city?.value ? "&filter[user_addresses.city]=" + fil?.city?.value : ""
    }${fil?.rank?.value ? "&filter[ranks.id]=" + fil?.rank?.value : ""}${
      fil?.status?.value ? "&filter[users.status]=" + fil?.status?.value : ""
    }${
      fil?.updatedOn?.length === 2
        ? "&filter[platform_users.updated_at]=" +
          moment(fil.updatedOn[0]).format("YYYY-MM-DD") +
          " - " +
          moment(fil.updatedOn[1]).format("YYYY-MM-DD")
        : ""
    }${
      fil?.createdOn?.length === 2
        ? "&filter[platform_users.created_at]=" +
          moment(fil.createdOn[0]).format("YYYY-MM-DD") +
          " - " +
          moment(fil.createdOn[1]).format("YYYY-MM-DD")
        : ""
    }`
    setAppliedFilters(filterString ?? "")
  }
  const getTableActions = rowData => {
    return [
      {
        icon: <i className="mr-1 text-danger" />,
        action: "unassign",
        name: "Unassign",
        class: "text-danger",
        onClick: handleTableMenu,
      },
    ]
  }
  const handleOnPageChange = p => {
    setPageNo(p.page)
    setPageSize(p.pageSize)
  }
  function closeUnassignUser() {
    setUnassignUser(false)
    setSelectedRow(undefined)
  }

  function handleUnassign() {
    if (selectedRow) {
      let payload = {
        user_id: selectedRow.ID,
      }
      postCreUserUnassign(props.creId, payload)
        .then(res => {
          setUnassignUser(false)
          addToast(res?.message, { appearance: "success" })
          listUsers()
        })
        .catch(err => {
          // toastr.error(err.response.data.message)
          addToast(generateErrorMessage(err?.response?.data), {
            appearance: "error",
          })
        })
    }
  }
  if (isFetching) {
    return <CustomSpinner />
  }
  return (
    <Suspense fallback={<CustomSpinner />}>
      {unassignUser && (
        <UnassignUser
          submiting={submiting}
          onCancelAction={closeUnassignUser}
          onSubmitAction={handleUnassign}
        />
      )}
      <CustomTable
        columns={columns}
        data={data}
        actions={rowData => {
          return getTableActions(rowData)
        }}
        currentPage={pageNo}
        totalCount={totalCount}
        pageSize={pageSize}
        search={search}
        filters={filters}
        onSearch={handleSearch}
        onFilterSubmission={onFilterSubmission}
        filterComponent={UserFilterComponent}
        pagination={{
          onPageChange: handleOnPageChange,
        }}
        isFetching={isFetching}
        onSort={handleSort}
        detailsTable={true}
      />
    </Suspense>
  )
}

export default AssignedUsersListing
