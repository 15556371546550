import moment from "moment"
import React, { useEffect, useImperativeHandle } from "react"
import { useState } from "react"
import { forwardRef } from "react"
import { Card, CardBody, CardTitle, Col, Container, Input, Row } from "reactstrap"

import SearchSelect from "components/CustomSearchSelect"
import CustomSelect from "components/CustomSelect"
import CustomTextField from "components/CustomTextField"
import { fetchCommissionData, getCitiesList, getCountriesList, getLeadTypeList, getPlatformUsersList, getServicesList, getStatesList, getUserList } from "helpers/api_actions"
import { emailValidator, validate } from "helpers/utilities"

let required = [
  "name",
  "service",
  "lead_type",
  "primary_phone",
  "primary_email",
  "owner",
  "country",
  "state",
  "city",
]
const LeadManagement = forwardRef((props, ref) => {
  LeadManagement.displayName = "Lead Manage Section"
  const [formData, setFormData] = useState(props.data)
  const [hasValidEmail, setHasValidEmail] = useState(true)

  const [services, setServices] = useState([])
  const [users, setUsers] = useState([])
  const [errors, setErrors] = useState({})
  const [commissions, setCommissions] = useState(props.commision || {})
  // const [keyVal,setKeyVal]=useState(0)
  useEffect(() => {
    setFormData({ ...props.data })
  }, [props.data])
  useEffect(() => {
    getServices()
    getUsers()
  }, [])
  useImperativeHandle(ref, () => ({
    onSubmitAction(e) {
      const error = validate(required, formData, "")
      setErrors(error)
      if (Object.keys(error).length === 0) {
        props.handleSubmit(formData)
      }
    },
    onCancelAction(e) {
      setFormData(null)
    },
  }))
  const handleChange = (v, k) => {
    const error = validate(required, { ...formData, [k]: v }, "")
    setFormData(f => ({
      ...formData,
      ...(k === "country" && { state: null, city: null }),
      ...(k === "state" && { city: null }),

      ...(k === "owner" && {
        rank: v && v["Rank Name"] ? v["Rank Name"] : null,
        phone: v?.Phone ?? null,
      }),
      [k]: v,
    }))
    if (k === "service") {
      fetchCommisionDataBasedOnService(v.value)
    }
    if (k === "primary_email") {
      setHasValidEmail(emailValidator(v))
    }
    if (Object.keys(errors).length) {
      setErrors(error)
    }
    // setKeyVal(keyVal+1)
  }

  const fetchCommisionDataBasedOnService = id => {
    setCommissions({})
    if (id) {
      fetchCommissionData({ service_id: id }).then(res =>
        setCommissions(res?.data)
      )
    }
  }
  const getCities = async v => {
    let payload = { select: "cities.name as name", include: "states" }
    let filter = `&search[cities.name]=${v}${
      formData?.state?.value
        ? "&filter[states.name]=" + formData?.state?.value
        : ""
    }`
    const res = await getCitiesList(payload, filter)
    const data = res?.data?.data || []
    data.forEach(item => {
      item["label"] = item.name
      item["value"] = item.name
    })
    return data
  }
  const getCountries = async v => {
    let payload = { select: "name" }
    let filter = `&filter[name]=${v}`
    const res = await getCountriesList(payload, filter)
    const data = res?.data?.data || []
    data.forEach(item => {
      item["label"] = item.name
      item["value"] = item.name
    })
    return data
  }
  const getStates = async v => {
    let payload = { select: "states.name as name" }
    let filter = `&filter[states.name]=${v}`
    const res = await getStatesList(payload, filter)
    const data = res?.data?.data || []
    data.forEach(item => {
      item["label"] = item.name
      item["value"] = item.name
    })
    return data
  }
  const getUsers = async v => {
    let payload = {
      select:
        "users.id,users.full_name as Full Name,users.primary_phone as Phone,users.display_id as User Id,ranks.name as Rank Name",
      include: `users,ranks&filter[full_name]=${v}`,
    }

    const res = await getPlatformUsersList(payload)
    const data = res?.data?.data || []
    data.forEach(item => {
      item["label"] = `${item["Full Name"]} (${item["User Id"]})`
      item["value"] = item.id
    })
    setUsers(data)

    return data
  }
  const getAdminUsers = async v => {
    let payload = {
      select: "users.id,full_name",
      include: `user_type&find[user_type.name]=AdminUser&search[full_name]=${v}`,
    }

    const res = await getUserList(payload)
    const data = res?.data?.data || []
    data.forEach(item => {
      item["label"] = `${item["full_name"]}`
      item["value"] = item.id
    })
    return data
  }
  const getServices = async v => {
    let payload = {
      select: `id,name&find[status]=Active&search[name]=${v}`,
    }

    const res = await getServicesList(payload)
    const data = res?.data?.data || []
    let activeItem = { label: null, value: null }
    data.forEach((item, i) => {
      item["label"] = item.name
      item["value"] = item.id
    })
    setServices(data)

    return data
  }

  return (
    <React.Fragment>
      <Row style={{ overflow: "auto" }}>
        <Col lg="12">
          <Card className="m-0">
            <CardBody>
              <Row>
                <Col lg="9" className={formData?.id ? "" : "border-right-none"}>
                  <CardTitle className="mt-0 mb-3">Basic Information</CardTitle>
                  <Row>
                    <Col md="4">
                      <div className="mb-3">
                        <CustomSelect
                          label="Lead Type"
                          required
                          disabled
                          options={props.leadTypes}
                          value={formData?.lead_type ?? null}
                          setData={data => handleChange(data, "lead_type")}
                        />
                        {errors?.lead_type && (
                          <p className="text-danger"> {errors?.lead_type}</p>
                        )}
                      </div>
                    </Col>
                    <Col md="4">
                      <div className=" mb-3">
                        <CustomTextField
                          disabled={props.isReadOnly}
                          label="Lead Name"
                          required
                          placeholder="Enter Lead Name"
                          type="text"
                          name="name"
                          onChange={(v, k, e) => handleChange(v, k, e)}
                          value={formData?.name ?? ""}
                        />
                        {errors?.name && (
                          <p className="text-danger"> {errors?.name}</p>
                        )}
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="mb-3">
                        <CustomTextField
                          disabled={props.isReadOnly}
                          label="Business Name"
                          placeholder="Enter Business Name"
                          type="text"
                          name="business_name"
                          onChange={(v, k, e) => handleChange(v, k, e)}
                          value={formData?.business_name ?? ""}
                        />
                        {errors?.business_name && (
                          <p className="text-danger">
                            {" "}
                            {errors?.business_name}
                          </p>
                        )}
                      </div>
                    </Col>
                    <Col md="4">
                      <div className=" mb-3">
                        <CustomTextField
                          label="Email"
                          required
                          disabled={props.isReadOnly}
                          placeholder="Enter Email"
                          type="email"
                          name="primary_email"
                          onChange={(v, k, e) => handleChange(v, k, e)}
                          value={formData?.primary_email ?? ""}
                        />
                        {(errors?.primary_email || !hasValidEmail) && (
                          <p className="text-danger">
                            {!hasValidEmail
                              ? "Provide a valid email id"
                              : errors?.primary_email}
                          </p>
                        )}{" "}
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="mb-3">
                        <CustomTextField
                          label="Phone"
                          required
                          disabled={props.isReadOnly}
                          startAdorement="+91"
                          maxLength={10}
                          minLength={10}
                          placeholder="Enter Phone"
                          type="phone"
                          name="primary_phone"
                          onChange={(v, k, e) => handleChange(v, k, e)}
                          value={formData?.primary_phone ?? ""}
                        />
                        {errors?.primary_phone && (
                          <p className="text-danger">
                            {" "}
                            {errors?.primary_phone}
                          </p>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <CardTitle className="mt-0 mb-3">Service Details</CardTitle>
                  <Row>
                    <Col md="4">
                      <div className="mb-3">
                        <SearchSelect
                          required={true}
                          getData={getServices}
                          menuPlacement={"top"}
                          isMulti={false}
                          label="Service Requested"
                          disabled={props.isReadOnly}
                          autoload={false}
                          setData={data => handleChange(data, "service")}
                          value={formData?.service ?? null}
                        />
                        {errors?.service && (
                          <p className="text-danger"> {errors?.service}</p>
                        )}
                      </div>
                    </Col>
                    <Col md="4">
                      <div className=" mb-3">
                        <CustomTextField
                          label="Lead Owner Commission"
                          // required
                          disabled
                          placeholder="Enter Lead Owner Commission"
                          value={formData?.service ? commissions?.Own ?? "0" : "0"}
                        />
                        {/* {errors?.full_name&&<p className="text-danger"> {errors?.full_name}</p>} */}
                      </div>
                    </Col>
                    <Col md="4">
                      <div className=" mb-3">
                        <CustomTextField
                          label="Referrer Commission"
                          // required
                          disabled
                          placeholder="Enter Referrer Commission"
                          value={formData?.service ? commissions?.Team ?? "0":"0"}
                        />
                        {/* {errors?.full_name&&<p className="text-danger"> {errors?.full_name}</p>} */}
                      </div>
                    </Col>
                  </Row>
                  <CardTitle className="mt-0 mb-3">Primary Address</CardTitle>
                  <Row>
                    <Col md="4">
                      <div className="mb-3">
                        <CustomSelect
                          label="Address Type"
                          disabled={props.isReadOnly}
                          options={[
                            { label: "Home", value: "Home" },
                            { label: "Office", value: "Office" },
                            { label: "Other", value: "Other" },
                          ]}
                          value={formData?.address_type ?? null}
                          setData={data => handleChange(data, "address_type")}
                        />
                        {errors?.address_type && (
                          <p className="text-danger"> {errors?.address_type}</p>
                        )}
                      </div>
                    </Col>
                    <Col md="8">
                      <div className=" mb-3">
                        <CustomTextField
                          disabled={props.isReadOnly}
                          label="Address Line 1"
                          placeholder="Enter Address Line 1"
                          type="text"
                          name="address_line_1"
                          onChange={(v, k, e) => handleChange(v, k, e)}
                          value={formData?.address_line_1 ?? ""}
                        />
                        {errors?.address_line_1 && (
                          <p className="text-danger">
                            {" "}
                            {errors?.address_line_1}
                          </p>
                        )}
                      </div>
                    </Col>

                    <Col md="8">
                      <div className="mb-3">
                        <CustomTextField
                          disabled={props.isReadOnly}
                          label="Address Line 2"
                          placeholder="Enter Address Line 2"
                          type="text"
                          name="address_line_2"
                          onChange={(v, k, e) => handleChange(v, k, e)}
                          value={formData?.address_line_2 ?? ""}
                        />
                        {errors?.address_line_2 && (
                          <p className="text-danger">
                            {" "}
                            {errors?.address_line_2}
                          </p>
                        )}
                      </div>
                    </Col>
                    <Col md="4">
                      <div className=" mb-3">
                        <SearchSelect
                          getData={getCountries}
                          isMulti={false}
                          disabled={props.isReadOnly}
                          label="Country"
                          required
                          autoload={false}
                          setData={v => {
                            handleChange(v, "country")
                          }}
                          value={formData?.country ?? null}
                        />
                        {errors?.country && (
                          <p className="text-danger"> {errors?.country}</p>
                        )}
                      </div>
                    </Col>
                    <Col md="4">
                      <div className=" mb-3">
                        <SearchSelect
                          getData={formData?.country ? getStates : null}
                          menuPlacement={"top"}
                          isMulti={false}
                          label="State"
                          required
                          disabled={
                            props.isReadOnly
                              ? true
                              : formData?.country?.value
                              ? false
                              : true
                          }
                          autoload={false}
                          setData={v => {
                            handleChange(v, "state")
                          }}
                          value={
                            formData?.state && formData?.state?.label != "null"
                              ? formData?.state
                              : null
                          }
                        />
                        {errors?.state && (
                          <p className="text-danger"> {errors?.state}</p>
                        )}
                      </div>
                    </Col>
                    <Col md="4">
                      <div className=" mb-3">
                        <SearchSelect
                          getData={formData?.state ? getCities : null}
                          isMulti={false}
                          menuPlacement={"top"}
                          label="City"
                          disabled={
                            props.isReadOnly
                              ? true
                              : formData?.state
                              ? false
                              : true
                          }
                          autoload={false}
                          required
                          setData={v => {
                            handleChange(v, "city")
                          }}
                          value={
                            formData?.city && formData?.city?.label != "null"
                              ? formData?.city
                              : null
                          }
                        />
                        {errors?.city && (
                          <p className="text-danger"> {errors?.city}</p>
                        )}
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="mb-3">
                        <CustomTextField
                          label="Pincode"
                          disabled={props.isReadOnly}
                          placeholder="Enter Pincode"
                          type="number"
                          name="pincode"
                          onChange={(v, k, e) => handleChange(v, k, e)}
                          value={formData?.pincode ?? ""}
                        />
                        {errors?.pincode && (
                          <p className="text-danger"> {errors?.pincode}</p>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <CardTitle className="mt-0 mb-3">Lead Owner</CardTitle>
                  <Row>
                    <Col md="4">
                      <div className=" mb-3">
                        <SearchSelect
                          getData={getUsers}
                          menuPlacement={"top"}
                          isMulti={false}
                          label="Lead Owner Name"
                          disabled={props.isReadOnly}
                          autoload={false}
                          required
                          setData={data => handleChange(data, "owner")}
                          value={formData?.owner ?? null}
                        />
                        {errors?.owner && (
                          <p className="text-danger"> {errors?.owner}</p>
                        )}
                      </div>
                    </Col>
                    <Col md="4">
                      <div className=" mb-3">
                        <CustomTextField
                          label="Lead Owner Rank"
                          disabled
                          placeholder="Lead Owner Rank"
                          value={formData?.rank ?? ""}
                        />
                        {/* {errors?.full_name&&<p className="text-danger"> {errors?.full_name}</p>} */}
                      </div>
                    </Col>
                    <Col md="4">
                      <div className=" mb-3">
                        <CustomTextField
                          label="Lead Owner Phone"
                          disabled
                          placeholder="Lead Owner Phone"
                          value={formData?.phone ?? ""}
                        />
                        {/* {errors?.full_name&&<p className="text-danger"> {errors?.full_name}</p>} */}
                      </div>
                    </Col>
                  </Row>
                </Col>
                {formData?.id && (
                  <Col lg="3">
                    <div className="mb-3">
                      <CardTitle className="mt-0 mb-3">Overview</CardTitle>
                    </div>

                    <label className="control-label">Lead Owner</label>

                    <div className="footBox">
                      <p>{formData?.owner?.label ?? "--"}</p>
                    </div>

                    <label className="control-label">Handled By</label>

                    <div className="footBox">
                      <p>{formData?.handled_by ?? "--"}</p>
                    </div>

                    {/* <SearchSelect
                      getData={getAdminUsers}
                      isMulti={false}
                      disabled={props.isReadOnly}
                      label="Handled By"
                      autoload={false}
                      setData={v => {
                        handleChange(v, "processed_by")
                      }}
                      value={formData?.processed_by ?? null}
                    /> */}

                    <div className=" mb-3">
                      <CustomTextField
                        label="Last Updated on"
                        disabled
                        value={
                          formData?.updated_at
                            ? moment(formData?.updated_at).format(
                                "DD-MMM-YYYY HH:mm"
                              )
                            : "--"
                        }
                      />
                    </div>

                    <div className=" mb-3">
                      <CustomTextField
                        label="Created on"
                        disabled
                        value={
                          formData?.updated_at
                            ? moment(formData?.created_at).format(
                                "DD-MMM-YYYY HH:mm"
                              )
                            : "--"
                        }
                      />
                    </div>

                    <div className=" mb-3">
                      <CustomTextField
                        label="Created by"
                        disabled
                        value={formData?.created_by ?? ""}
                      />
                    </div>
                  </Col>
                )}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
})

export default LeadManagement
