import React, { useEffect, useState } from "react"
import { Modal } from "reactstrap"
import { Col, Row } from "reactstrap"

import CustomCalander from "components/CustomCalendar/index"
import CustomSelect from "components/CustomSelect/index"
import CustomTextField from "components/CustomTextField/index"
import moment from 'moment';

const AddCommision = props => {
  const [data, setData] = useState({ ...props.data })
  const [errors, setErrors] = useState({})
  const statuses = [
    { label: "Active", value: "Active" },
    { label: "InActive", value: "InActive" },
  ]
  const serviceData = props.serviceData
  const [minDate, setMinDate] = useState("today");
  const [initialEndDate, setInitialEndDate] = useState(data?.end_date ? data?.end_date[0] : null);

  useEffect(() => {
    if (props.edit && initialEndDate && moment(initialEndDate).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
      setMinDate("today");
    } else {
      setMinDate(moment().add(1, 'days').format('YYYY-MM-DD'));
    }
  }, [props.edit, initialEndDate]);

  const handleChange = (v, k, e) => {
    if (k === 'end_date' && !initialEndDate) {
      setInitialEndDate(v);
    }
    // if (k === "own_commision" && data?.calculation?.value === "Percentage" && (v < 1 || v > 100))
    // {
    //   setErrors({
    //     ...errors,
    //     [k]: "Commission percentage should be between 1 and 100"
    //   })
    // } else if (k === "calculation" && v?.value === "Percentage" && (data?.own_commision < 1 || data?.own_commision > 100))
    // {
    //   setErrors({
    //     ...errors,
    //     own_commision: "Commission percentage should be between 1 and 100"
    //   })
    // } else if (k === "calculation" || k === "own_commision")
    // {
    //   setErrors({})
    // }
    setData({ ...data, [k]: v })
  }

  const earningProcessOptions = serviceData?.earning_process === "Loan Flow" ? [
    { label: "Percentage", value: "Percentage" },
  ] : [
    { label: "Fixed", value: "Fixed" },
    { label: "Percentage", value: "Percentage" },
  ]

  const defaultEarningProcess = serviceData?.earning_process === "Loan Flow" ? { label: "Percentage", value: "Percentage" } : { label: "Fixed", value: "Fixed" }

  return (
    <Modal
      isOpen={true}
      toggle={props.onCancelAction}
      centered={true}
      size={props.edit ? "lg" : "md"}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {props.edit ? "Edit Commission Rate" : "Create Commission Rate"}
        </h5>
        <button
          type="button"
          disabled={props.submiting}
          onClick={() => {
            props.onCancelAction()
          }}
          className="btn-close"
        ></button>
      </div>
      <div className="modal-body">
        <form>
          <Row>
            {props.edit && (
              <>
                <Col xs={"6"}>
                  <div className="mb-3">
                    <CustomTextField
                      label="Commision ID"
                      disabled
                      value={data?.display_id}
                    />
                  </div>
                </Col>
                <Col xs={"6"}>
                  <div className="mb-3">
                    <CustomTextField
                      label="Created By"
                      disabled
                      value={data?.created_by}
                    />
                  </div>
                </Col>
                <Col xs={"6"}>
                  <div className="mb-3">
                    <CustomTextField
                      label="Last Updated By"
                      disabled
                      value={data?.modified_by}
                    />
                  </div>
                </Col>
              </>
            )}
            <Col xs={props.edit ? "6" : "12"}>
              <div className="mb-3">
                <CustomTextField
                  label="Service Name"
                  disabled
                  value={props.name}
                />
              </div>
            </Col>
            <Col xs={props.edit ? "6" : "12"}>
              <div className="mb-3">
                <CustomCalander
                  label="Start Date"
                  required
                  placeholder="Select Date"
                  name="start_date"
                  value={data?.start_date ?? null}
                  onChange={date => handleChange(date, "start_date")}
                  minDate={"today"}
                  disabled={props.edit && data?.start_date && moment(data?.start_date[0]).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")} 
                />
              </div>
            </Col>{" "}
             <Col xs={props.edit ? "6" : "12"}>
              <div className="mb-3">
                <CustomCalander
                  label="End Date"
                  required
                  // maxDate={data?.start_date}
                  placeholder="Select Date"
                  value={data?.end_date ?? null}
                  onChange={date => handleChange(date, "end_date")}
                  minDate={minDate}
                />
              </div>
            </Col>{" "}
            <Col xs={props.edit ? "6" : "12"}>
              <div className=" mb-3">
              <CustomSelect
                        label="Calculation Type"
                        placeholder="Calculation Type"
                        required
                        disabled={props.edit ? true : false} 
                        options={earningProcessOptions}
                        value={data?.calculation ?? null}
                        setData={data => handleChange(data, "calculation")}
                      />
              </div>
            </Col>{" "}
            <Col xs={props.edit ? "6" : "12"}>
              <div className=" mb-3">
                <CustomTextField
                  label={`Lead Owner Commission ${data?.calculation?.value === 'Percentage' ? ' (%)' : ' (Rs.)'}`}
                  placeholder="Enter Lead Owner Commission"
                  name="own_commision"
                  required
                  disabled={props.edit ? true : false} 
                  type="decimal"
                  onChange={(v, k, e) => handleChange(v, k, e)}
                  value={data?.own_commision ?? ""}
                />
              </div>
            </Col>{" "}
            {/* <Col xs={props.edit ? "6" : "12"}>
              <div className=" mb-3">
                <CustomSelect
                  label="Status"
                  options={statuses}
                  required
                  setData={v => {
                    handleChange(v, "status")
                  }}
                  value={data?.status ?? null}
                />
              </div>
            </Col> */}
          </Row>
        </form>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          data-bs-dismiss="modal"
          disabled={props.submiting}
          onClick={() => {
            props.onCancelAction()
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          disabled={props.submiting}
          className="btn btn-primary"
          onClick={e => props.onSubmitAction(data)}
        >
          {props.edit ? "Update" : "Save"}
        </button>
      </div>
    </Modal>
  )
}
export default AddCommision
