import MetisMenu from "metismenujs"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { withRouter } from "react-router-dom"
import SimpleBar from "simplebar-react"

import { isAdmin } from "helpers/utilities"
import { useSideMenuStore } from "store/navMenuStore"

import { Flag } from "../../../node_modules/react-feather/dist/index"
import SidebarItems from "./components/sidebarItems"
import { menuData } from "./datas/menudatas"

const SidebarContent = ({ location, type }) => {
  const [flag, setFlag] = useState(false)
  const refDiv = useRef(null)
  const { sideMenuData, toggleSubmenus, reverseToggleSubmenus } =
    useSideMenuStore()

  useEffect(() => {
    initMenu()
  }, [type, location.pathname, toggleSubmenus, flag])

  const items = location.pathname.split("/")
  const itemPath = "/" + items[1]

  const initMenu = () => {
    new MetisMenu("#side-menu")
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")

    for (let i = 0; i < items.length; ++i) {
      if (itemPath == items[i]?.pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }

  const scrollElement = item => {
    setTimeout(() => {
      if (refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop
          if (currentPosition > window.innerHeight) {
            if (refDiv.current)
              refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300
          }
        }
      }
    }, 300)
  }

  const activateParentDropdown = item => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show")

        const parent3 = parent2.parentElement

        if (parent3) {
          parent3.classList.add("mm-active")
          parent3.childNodes[0].classList.add("mm-active")
          const parent4 = parent3.parentElement
          if (parent4) {
            parent4.classList.add("mm-show")
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show")
              parent5.childNodes[0].classList.add("mm-active")
            }
          }
        }
      }
      return scrollElement(item), false
    }
    return scrollElement(item), false
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={refDiv}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {sideMenuData
              .filter(menu => !(!isAdmin() && menu.isAdminOnly === true))
              .map((menu, idx) => (
                <SidebarItems
                  key={idx}
                  menu={menu}
                  toggleSubmenus={() => toggleSubmenus(idx)}
                  reverseToggleSubmenus={() => reverseToggleSubmenus()}
                  pathname={location.pathname}
                />
              ))}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  type: PropTypes.string,
}

export default withRouter(SidebarContent)
