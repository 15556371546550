// Import the messaging object from your Firebase config file
import React, { useState, useEffect } from 'react';
import Switch from "react-switch";
import { messaging,getToken } from './../../../firebaseInit';  // Import the messaging object from your Firebase config file
import { postAuthFCMClear,postAuthFCMSave, postAuthFCMCheck } from "./../../../helpers/api_actions"

const NotificationToggle = () => {
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);

  useEffect(() => {
    // This function encapsulates the logic to get the FCM token
    async function getToken() {
      try {
        const token = await messaging.getToken();
        return token;
      } catch (err) {
        console.error('Error while getting FCM token:', err);
        return null;
      }
    }

    // Function to check notification status after getting FCM token
    async function checkNotificationStatus() {
      try {
        const token = await getToken();
        if (token) {
          let payload = { fcm_token: token };
          const response = await postAuthFCMCheck(payload);
          if (response.data && response.data.status) {
            setNotificationsEnabled(true);
          } else {
            setNotificationsEnabled(false);
          }
        } else {
          setNotificationsEnabled(false);
        }
      } catch (err) {
        console.error('An error occurred while checking notification status:', err);
      }
    }

    checkNotificationStatus(); // Call the function within useEffect
  }, []);

  const handleToggleNotifications = async () => { 
    if (!notificationsEnabled) {  const permission = await Notification.requestPermission()
    if (permission === "granted") {

      const token = await getToken(function (tokenFound) {
        hasFcmToken = tokenFound
      })
      if (token) {
        setNotificationsEnabled(true);
        let payload = {
          fcm_token: token,
        }
        postAuthFCMSave(payload).finally(() => {
        })
      }

    } else if (permission === "denied") {
      alert(
        "Notifications are currently blocked. Please enable it in your browser settings to recieve new alerts."
      )
    }
      
      
    } else {
      setNotificationsEnabled(false);
      const token = await getToken(function (tokenFound) {
        hasFcmToken = tokenFound
      })
      if (token) {
        let payload = {
          fcm_token: token,
        }
  
        postAuthFCMClear(payload).finally(() => {
      
        })
      } 
      // messaging
      //   .deleteToken()
      //   .then(() => {
      //     setNotificationsEnabled(false);
      //   })
      //   .catch((err) => {
      //     console.error('Unable to unsubscribe from notifications:', err);
      //   });
    }
  };

  return (
    <div className="btn header-item noti-icon position-relative">
      <div className="btn header-item" style={{ marginTop: 9 }}>
        <label>
          <span className="d-none d-xl-inline-block ms-2 me-1"> Get Notifications? </span>
        </label>
        </div>
        <Switch
          onChange={handleToggleNotifications}
          checked={notificationsEnabled}
          onColor="#0088cc" // Customize colors as needed
          offColor="#cccccc"
        />
      
    </div>
  );
};

export default NotificationToggle;
