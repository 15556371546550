import JoditEditor from "jodit-react"
import React, { useMemo, useRef } from "react"

const RichTextEditor = ({
  value = "",
  onChange,
  disabled = false,
}) => {
  const editor = useRef(null)
  const config = {
    buttons: [
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "|",
      "ul",
      "ol",
      "|",
      "center",
      "left",
      "right",
      "justify",
      "|",
      "link",
      "image",
    ],
    uploader: { insertImageAsBase64URI: true },
    removeButtons: ["brush", "file"],
    showXPathInStatusbar: false,
    showCharsCounter: false,
    showWordsCounter: false,
    toolbarAdaptive: false,
  }

  return useMemo(
    () => (
      <JoditEditor
        ref={editor}
        value={value ?? ""}
        config={config}
        onChange={newContent => onChange(newContent)}
      />
    ),
    []
  )
}
export default RichTextEditor
