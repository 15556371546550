import HeaderTitle from "components/Common/headerTitleComponent"
import CustomSpinner from "components/customSpinner"
import { HEADER_LABELS, BUTTON_LABELS } from "constants/label"
import { AUTH_ROUTES } from "constants/routes"
import { DEFAULT_CLASSES } from "constants/styles"
import React, { Suspense, useRef } from "react"

import { Col, Container, Row } from "reactstrap"
import { isAdmin } from "helpers/utilities"
import LeadListing from "components/Leads/listing"

const Leads = props => {
  const listRef = useRef(null)
  const headerActions = isAdmin()
    ? [
        // {
        //   type: "button",
        //   label: BUTTON_LABELS.EXPORT,
        //   className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
        //   action: () => {},
        // },
        {
          type: "button",
          label: BUTTON_LABELS.CREATE_NEW_LEAD,
          className: DEFAULT_CLASSES.HEADER_BUTTON_PRIMARY,
          action: () => {
            props.history.push(AUTH_ROUTES.CREATE_NEW_LEAD)
          },
        },
        {
          type: "button",
          label: BUTTON_LABELS.EXPORT,
          className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
          action: () => {
            listRef.current.exportLeads()
          },
        },
      ]
    : []

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <HeaderTitle title={HEADER_LABELS.LEADS} actions={headerActions} />
          </Row>
          <Row>
            <Col lg="12">
              <Suspense fallback={<CustomSpinner />}>
                <LeadListing leadType="General Lead" ref={listRef} {...props} />
              </Suspense>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Leads.propTypes = {}

export default Leads
