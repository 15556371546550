import moment from "moment"
import React, { lazy, Suspense, useEffect, useState } from "react"

import CustomSpinner from "components/customSpinner"
import { AUTH_ROUTES } from "constants/routes"
import { getPayoutList } from "helpers/api_actions"

import UserFilterComponent from "./filterComponent"

const CustomTable = lazy(() => import("components/customTable"))
function PayOutRequestListing(props) {
  const [pageSize, setPageSize] = useState(15)
  const [data, setData] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [pageNo, setPageNo] = useState(1)
  const [search, setSearch] = useState("")
  const [filters, setFilters] = useState({})
  const [sortKey, setSortKey] = useState("")
  const [appliedFilters, setAppliedFilters] = useState("")
  const [appliedSeach, setAppliedSearch] = useState("")
  const [isFetching, setIsFetching] = useState(false)
  const navigateToDetails = (id, isEdit = false) => {
    props.history.push({
      pathname: `${AUTH_ROUTES.USER_DETAILS}${id}`,
      state: { edit: isEdit },
    })
  }
  const listUsers = params => {
    let basicData = {
      select: `payout_requests.id,payout_requests.created_at as Request Date,payout_requests.request_number as Request ID,users.id as user_id,users.full_name as Requested By,users.display_id as Requested By ID,ranks.name as User Rank,payout_requests.request_amount as Requested Amount,payout_requests.status as Status,commision_payments.payment_date as Payment Date,commision_payments.amount_paid as Amount Paid,commision_payments.transaction_id as Transaction ID,user_modified.full_name as Updated By,payout_requests.modified_at as Updated on`,
      include: `users,platform_users,ranks,commision_payments,users as user_modified&find[payout_requests.user_id]=${props.parentID}`,
    }
    setIsFetching(true)
    getPayoutList(basicData, appliedSeach, appliedFilters, sortKey)
      .then(res => {
        setData(res?.data?.data)
        setTotalCount(res?.data?.total || 0)
        setIsFetching(false)
      })
      .catch(err => {
        setIsFetching(false)
      })
  }
  useEffect(() => {
    listUsers()
  }, [pageSize, pageNo, appliedSeach, sortKey, appliedFilters])

  const handleDelete = () => {}
  const handleTableMenu = (action, rowData) => {
    switch (action.action) {
      case "edit":
        navigateToDetails(rowData.ID, true)
        break

      case "delete":
        handleDelete(rowData)
        break
      default:
        break
    }
  }
  const [columns, setColumns] = useState([
    {
      title: "Request Date",
      field: "Request Date",
      minWidth: 140,
      width: 140,
      wrap:true,
      render: (row, val) => (
        <div>{val ? moment(val).format("DD-MMM-YYYY") : "--"}</div>
      ),
      sort_direction: "ASC",
      sort_key: "payout_requests.created_at",
      // render:(row)=>
      // sort_key: "users.full_name",
    },
    {
      title: "Request ID",
      // sort_key: "users.primary_phone",
      field: "Request ID",

      minWidth: 130,
      width: 130,
      wrap:true,
      sort_key: "users.full_name",
    },

    {
      title: "Requested Amount",
      field: "Requested Amount",
      minWidth: 180,
      width: 180,
      wrap:true,
      sort_key: "payout_requests.request_amount",
      align: "center",
      render: (row, val) => (
        <div>
          {val ? parseFloat(val).toLocaleString('en-IN') : "--"}
        </div>
      ),
    },
    {
      title: "Status",
      field: "Status",
      minWidth: 120,
      width: 120,
      wrap:true,
      align: "center",
      sort_key: "payout_requests.status",
    },
    {
      title: "Payment Date",
      field: "Payment Date",
      minWidth: 150,
      width: 150,
      wrap:true,
      sort_key: "commision_payments.payment_date",
      render: (row, val) => (
        <div>{val ? moment(val).format("DD-MMM-YYYY") : "--"}</div>
      ),
    },
    {
      title: "Amount Paid",
      field: "Amount Paid",
      minWidth: 150,
      width: 150,
      wrap:true,
      sort_key: "commision_payments.amount_paid",
      align: "center",
      render: (row, val) => (
        <div>
          {val ? parseFloat(val).toLocaleString('en-IN') : "--"}
        </div>
      ),
    },
    {
      title: "Transaction ID",
      field: "Transaction ID",
      minWidth: 160,
      width: 160,
      wrap:true,
      sort_key: "commision_payments.transaction_id",
    },
    {
      title: "Updated By",
      field: "Updated By",
      minWidth: 160,
      width: 160,
      wrap:true,
      sort_key: "user_modified.full_name",
    },
    {
      title: "Updated On",
      field: "Updated On",
      minWidth: 160,
      width: 160,
      wrap:true,
      sort_key: "payout_requests.modified_at",
      render: (row, val) => (
        <div>{val ? moment(val).format("DD-MMM-YYYY") : "--"}</div>
      ),
    },
  ])

  const handleSort = row => {
    let nextDirection = row.sort_direction
      ? row?.sort_direction === "ASC"
        ? "DESC"
        : "ASC"
      : "ASC"
    columns.forEach(col => {
      if (col.field === row.field) {
        col["sort_direction"] = nextDirection
      } else {
        col["sort_direction"] = undefined
      }
    })
    setColumns(() => columns)

    let sortkey = `&sort_by[${row.sort_key}]=${nextDirection}`
    setSortKey(sortkey)
  }
  const handleSearch = val => {
    setSearch(val)
    let searchString =
      val && val !== ""
        ? `&search[payout_requests.request_number]=${val}`
        : undefined        
    setAppliedSearch(searchString)   
   
  }

  const onFilterSubmission = fil => {
    setFilters(fil)
    let filterString = undefined
    filterString = `${
      fil?.transferMode?.value ? "&filter[commision_payments.mode_of_transfer_id]=" + fil?.transferMode?.value : ""
    }${
      fil?.paymentType?.value ? "&filter[commision_payments.payment_type]=" + fil?.paymentType?.value : ""
    }${
      fil?.user?.label
        ? "&filter[user_modified.full_name]]=" + fil?.user.label
        : ""
    }${
      fil?.status?.value
        ? "&filter[payout_requests.status]=" + fil?.status?.value
        : ""
    }${
      fil?.paymentDate?.length === 2
        ? "&filter[commision_payments.payment_date]=" +
          moment(fil.paymentDate[0]).format("YYYY-MM-DD") +
          " - " +
          moment(fil.paymentDate[1]).format("YYYY-MM-DD")
        : ""
    }${
      fil?.updatedOn?.length === 2
        ? "&filter[payout_requests.modified_at]=" +
          moment(fil.updatedOn[0]).format("YYYY-MM-DD") +
          " - " +
          moment(fil.updatedOn[1]).format("YYYY-MM-DD")
        : ""
    }${
      fil?.createdOn?.length === 2
        ? "&filter[payout_requests.created_at]=" +
          moment(fil.createdOn[0]).format("YYYY-MM-DD") +
          " - " +
          moment(fil.createdOn[1]).format("YYYY-MM-DD")
        : ""
    }`
    setAppliedFilters(filterString ?? "")
  }
  const getTableActions = rowData => {
    return [
      {
        icon: <i className="mr-1" />,
        action: "edit",
        name: "Edit",
        onClick: handleTableMenu,
      },
      {
        icon: <i className="mr-1 text-danger" />,
        action: "delete",
        name: "Delete",
        class: "text-danger",
        onClick: handleTableMenu,
      },
    ]
  }
  const handleOnPageChange = p => {
    setPageNo(p.page)
    setPageSize(p.pageSize)
  }
  if (isFetching) {
    return <CustomSpinner />
  }
  return (
    <Suspense fallback={<CustomSpinner />}>
      <CustomTable
        columns={columns}
        data={data}
        // actions={rowData => {
        //   return getTableActions(rowData)
        // }}
        currentPage={pageNo}
        totalCount={totalCount}
        pageSize={pageSize}
        search={search}
        filters={filters}
        onSearch={handleSearch}
        onFilterSubmission={onFilterSubmission}
        filterComponent={UserFilterComponent}
        pagination={{
          onPageChange: handleOnPageChange,
        }}
        isFetching={isFetching}
        onSort={handleSort}
        detailsNoSortTable={true}
      />
    </Suspense>
  )
}

export default PayOutRequestListing
