import React, { useState, useEffect } from "react"
import { Col, Input, Modal, Row } from "reactstrap"
import SearchSelect from "components/CustomSearchSelect"
import {  getServicesList } from "helpers/api_actions"

const ManageProspects = props => {
  const [services, setServices] = useState([])
  const [selectedService, setSelectedService] = useState([])
  const [data, setData] = useState(props.data || {})
  const [errors, setErrors] = useState( {})
  const { leadData } = props
  const getServices = async v => {
    console.log(leadData);
    let payload = {
      select: `id,name&find[customer_service_lead_id]=${leadData?.id}&search[name]=${v}`,
    }
    return getServicesList(payload).then(res => {
      let data = res?.data.data || []
      data.forEach(item => {
        item["label"] = item.name
        item["value"] = item.id
      })
      setServices(data)

      return data
    })
  }

  const handleSubmit = () => {
    if (!selectedService || selectedService.length === 0) {
      // Show an error message or handle the case where the SearchSelect is empty
      setErrors({ service: "Please select at least one service." });
      return;
    }
  
    // Clear any previous error messages
    setErrors({});
  
    // Proceed with your submission logic
    let data = {
      service_ids: selectedService.map(item => item.id).join(","),
    };
  
    props.onSubmitAction(data);
  };

  return (
    <Modal isOpen={true} toggle={props.onCancelAction} centered={true}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {props.title || "Assign Prospects"}
        </h5>
        <button
          type="button"
          disabled={props.submiting}
          onClick={() => {
            props.onCancelAction()
          }}
          className="btn-close"
        ></button>
      </div>
      <div className="modal-body">
        <form>
          <div className=" mb-3">
          <SearchSelect
            getData={getServices}
            // menuPlacement={"top"}
            isMulti={true}
            label="Service"
            autoload={true}
            setData={v => {
              setSelectedService(v)
            }}
            value={selectedService ?? null}
            disableSearchWhenNotEmpty={selectedService ? true: false}
          />
            {errors.service && (
              <div className="text-danger">{errors.service}</div>
            )}
          </div>
        </form>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          data-bs-dismiss="modal"
          disabled={props.submiting}
          onClick={() => {
            props.onCancelAction()
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          disabled={props.submiting}
          className="btn btn-primary"
          onClick={e => handleSubmit()}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}
export default ManageProspects
