import CustomSelect from "components/CustomSelect"
import SearchSelect from "components/CustomSearchSelect"
import { getCitiesList, getUserRankList } from "helpers/api_actions"
import React from "react"
import { useState, useEffect } from "react"

import { Row, Col, Button } from "reactstrap"

const UserFilterComponent = props => {
  const [ranks, setRanks] = useState([])
  const [selectedCity, setSelectedCity] = useState(props.filters?.city)
  const [selectedRank, setSelectedRank] = useState(props.filters?.rank)
  const defaultSelection = { label: "", value: "" }

  const onApplyFilter = () => {
    let filters = {
      city: selectedCity,
      rank: selectedRank,
    }
    props.onFilterSubmission(filters)
  }
  const onCancelFilter = () => {
    setSelectedCity(undefined)
    setSelectedRank(undefined)
    props.onFilterSubmission({ city: undefined, rank: undefined })
  }

  const getCities = async v => {
    let payload = { select: "cities.name as name" }
    let filter = `&search[cities.name]=${v}`
    const res = await getCitiesList(payload, filter)
    const data = res?.data?.data || []
    data.forEach(item => {
      item["label"] = item.name
      item["value"] = item.name
    })
    return data
  }
  const getRanks = () => {
    let payload = { select: "id,name" }
    // let filter = `?filter[name]=${v}`
    getUserRankList(payload).then(res => {
      let data = res?.data?.data || []
      data.forEach(item => {
        item["label"] = item.name
        item["value"] = item.id
      })
      setRanks(data)
    })
  }
  useEffect(() => {
    getRanks()
  }, [])
  return (
    <>
      <Row>
        <Col lg={3} md={4} sm={6} xs={12}>
          <SearchSelect
            getData={getCities}
            isMulti={false}
            isClearable
            label="City"
            setData={v => {
              setSelectedCity(v)
            }}
            value={selectedCity ?? defaultSelection}
          />
        </Col>
        <Col lg={3} md={4} sm={6} xs={12}>
          <CustomSelect
            options={ranks}
            isMulti={false}
            clearable
            label="Rank"
            setData={v => {
              setSelectedRank(v)
            }}
            value={selectedRank ?? defaultSelection}
          />
        </Col>
        <Col
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className="d-flex align-items-end justify-content-end pt-3"
        >
          <div className="me-2">
            <Button
              className="btn btn-sm btn-primary btn-success text-white"
              onClick={() => onApplyFilter()}
            >
              Apply
            </Button>
          </div>
          <div>
            <Button
              className="btn btn-sm btn-danger"
              onClick={() => onCancelFilter()}
            >
              Reset
            </Button>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default UserFilterComponent
