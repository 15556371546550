import { getToken } from "firebaseInit"

import { postAuthLogout } from "./api_actions"

export const emailVal = new RegExp("^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,9})+$")
export const emailValidator = value => {
  let result = true
  if (value && value !== "") {
    result = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i.test(value)
  }

  return result
}
function hasWhiteSpace(s) {
  let flag = /^\s*$/.test(s)
  return flag
}
export const validateNote = (req, values, fieldName = "") => {
  const errors = {}
  req.forEach(field => {
    if (!values[field] || hasWhiteSpace(values[field])) {
      errors[field] =
        field === "note"
          ? "Please enter description"
          : field === "followup_date"
          ? "Please enter followup date"
          : `Please enter ${field}`
    }
  })
  return errors
}

export const validate = (req, values, fieldName = "") => {
  const errors = {}
  req.forEach(field => {
    if (
      field === "primary_phone" &&
      values[field] &&
      values[field].length !== 10
    ) {
      errors[field] = "Phone number should have 10 digits"
    }
    if (fieldName === "") {
      if (!values[field]) {
        errors[field] = "Please enter details"
      }
    } else {
      if (field === fieldName) {
        if (!values[field]) {
          errors[field] = "Please enter details"
        }
      }
    }
  })
  return errors
}

export const validatePresaleItems = (req, values) => {
  const emailFormat = /^\S+@\S+\.\S+$/
  const errors = {}
  req.forEach(field => {
    if (field === "primary_phone") {
      if (!values[field]) {
        errors[field] = "Phone number is required"
      } else if (values[field].length !== 10) {
        errors[field] = "Phone number should have 10 digits"
      }
    } else if (field === "secondary_phone") {
      if (values[field] && values[field].length !== 10) {
        errors[field] = "Phone number should have 10 digits"
      }
    } else if (field === "primary_email") {
      if (!values[field]) {
        errors[field] = "Primary email is required"
      } else if (!emailFormat.test(values[field])) {
        errors[field] = "Enter a valid email address"
      }
    } else if (field === "secondary_email") {
      if (values[field] && !emailFormat.test(values[field])) {
        errors[field] = "Enter a valid email address"
      } else if (values[field] && hasWhiteSpace(values[field])) {
        errors[field] = "Enter a valid email address"
      }
    } else if (field === "name" && !values[field]) {
      errors[field] = "Name is required"
    } else if (field === "name" && hasWhiteSpace(values[field])) {
      errors[field] = "Name is required"
    } else if (field === "name" && values[field].length < 2) {
      errors[field] = "The name must be at least 2 characters."
    } else if (
      field === "business_name" &&
      values[field] &&
      hasWhiteSpace(values[field])
    ) {
      errors[field] = "Enter valid data"
    } else if (
      field === "address_line_1" &&
      values[field] &&
      hasWhiteSpace(values[field])
    ) {
      errors[field] = "Enter valid data"
    } else if (
      field === "address_line_2" &&
      values[field] &&
      hasWhiteSpace(values[field])
    ) {
      errors[field] = "Enter valid data"
    }
  })
  return errors
}

export const validateStatus = (req, values, fieldName = "") => {
  const errors = {}
  req.forEach(field => {
    if (!values[field]) {
      errors[field] = `Please select ${field}`
    }
  })
  return errors
}
export const urlValidator = url => {
  let valid = true
  if (url && url !== "") {
    if (
      /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(
        url
      )
    ) {
      valid = true
    } else {
      valid = false
    }
  }
  return valid
}
export const isValid = (data, key) => {
  if (data && data !== "") {
    return true
  }

  return false
}

export const generateClassNameByValue = value => {
  let classname = "bg-primary"
  if (value) {
    classname = value.toLowerCase().replaceAll(" ", "").replace(",", "")
  }
  return classname
}
export const generateClassNameByValueForStatus = value => {
  let classname = ""

  if (value) {
    switch (value) {
      case "New":
        classname = "new"
        break
      case "In Progress":
        classname = "processing"
        break
      case "Processing":
        classname = "processing"
        break
      case "Converted":
        classname = "resolved"
        break
      case "Qualified":
        classname = "closed"
        break
      case "Resolved":
        classname = "resolved"
        break
      case "Unqualified":
        classname = "bg-danger"
        break

      case "Failed":
        classname = "closed"
        break
      case "Completed":
        classname = "resolved"
        break
      case "Incomplete":
        classname = "bg-danger"
        break

      case "Requested":
        classname = "new"
        break
      case "Not Connected":
        classname = "closed"
        break
      case "Closed":
        classname = "closed"
        break
      case "Paid":
        classname = "resolved"
        break
      case "Cancelled":
        classname = "bg-danger"
        break
      case "Active":
        classname = "resolved"
        break
      case "Inactive":
        classname = "bg-danger"
        break
      case "InActive":
        classname = "bg-danger"
        break
      case "Completed":
        classname = "resolved"
        break
      default:
        break
    }
  }
  return classname
}

export const generateErrorMessage = data => {
  let msg = ""
  if (data.error && Object.keys(data.error).length) {
    Object.keys(data.error).forEach(key => {
      msg = `${msg} ${data.error[key]}`
    })
  } else {
    msg = data.message
  }
  return msg
}

export const isAdmin = () => {
  if (localStorage.getItem("user_type") === "AdminUser") {
    return true
  }
  return false
}

export const isCreUser = () => {
  if (localStorage.getItem("user_type") === "CRE") {
    return true
  }
  return false
}
export const isCurrentUser = id => {
  var userDataString = localStorage.getItem("userData")
  var userData = JSON.parse(userDataString)
  var userId = userData.user_id

  if (userId == id) {
    return true
  }
  return false
}
export async function logoutCurrentUser() {
  let hasFcmToken = false
  try {
    const token = await getToken(function (tokenFound) {
      hasFcmToken = tokenFound
    })
    if (token) {
      let payload = {
        fcm_token: token,
      }

      postAuthLogout(payload).finally(() => {
        localStorage.clear()

        window.location.replace("/login")
      })
    } else {
      localStorage.clear()
      window.location.replace("/login")
    }
  } catch (exception) {
  } finally {
    localStorage.clear()
    window.location.replace("/login")
  }
}
