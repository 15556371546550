import LogCard from "components/LogCard"
import { getLeadLog } from "helpers/api_actions"
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"

function Logs(props) {
  const [data, setData] = useState([])
  const fetchLogs = () => {
    let payload = {
      select: `log_title,log_description,additional_data,created_at&find[model]=Lead&find[model_id]=${props.parentID}`,
    }
    getLeadLog(payload).then(res => {
      setData(res?.data?.data)
    })
  }
  useEffect(() => {
    fetchLogs()
  }, [])

  return (
    <div>
      {" "}
      <Row style={{ overflow: "auto" }}>
        <Col lg="12">
          <Card>
            <CardBody className="common-bg py-1">
              <Row>
                {data.map((item, k) => (
                  <Col xs={12} key={k}>
                    <LogCard data={item} />
                  </Col>
                ))}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Logs
