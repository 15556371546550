export const NAV_LABELS = {
  DASHBOARD: "Dashboard",
  USERS: "Users",
  USERS_ASSOCIATES: "Users/Associates",
  CRE_USERS: "CRE Users",
  CUSTOMER: "Customers",
  LEADS: "General Leads",
  BUSINESS: "Business Leads",
  CONTACTS_MANAGEMENT: "Contacts Management",
  SERVICE_CATEGORY: "Service Category",
  SERVICE_MANAGEMENT: "Service Management",
  COMMISSION_MANAGEMENT: "Commission Management",
  PAYOUT: "Payout",
  PAYOUTMANAGEMENT: "Payout Management",
  CUSTOMER_SUPPORT: "Customer Support",
  PARTNERSHIP_REQUESTS: "Partnership Requests",
  TICKETS: "Website Tickets",
  REPORTS: "Reports",
  REPORTS_SALES: "Service Sales",
  REPORTS_COMISSION: "Service Commission",
  REPORTS_SALES_USER: "User Sales",
  REPORTS_COMISSION_USER: "User Commission",
  SUPPORT_REQUESTS: "Support & Requests",
  SETTINGS: "Settings",
  PAYMENT_COLLECTION: "Payment Collection",
  PRESALES: "Presale Leads",
  IMPORT_LOG: "Presale Import Logs",
}

export const HEADER_LABELS = {
  DASHBOARD: "Dashboard",
  USERS: "Users",
  CRE_USERS: "CRE Users",
  CUSTOMER: "Customers",
  LEADS: "General Leads",
  BUSINESS: "Business Leads",
  CONTACTS: "Contacts",

  CONTACTS_MANAGEMENT: "Contacts Management",
  SERVICE_CATEGORY: "Service Category",
  SERVICE_MANAGEMENT: "Service Management",
  COMMISSION_MANAGEMENT: "Commission Management",
  PAYOUT: "Payout",
  PAYOUT_IMPORT_HISTORY: "Payout Import History",
  CUSTOMER_SUPPORT: "Customer Support",
  PARTNERSHIP_REQUESTS: "Partnership Requests",
  TICKETS: "Website Tickets",
  REPORTS: "Reports",
  REPORTS_SALES: "Service Sales Report",
  REPORTS_COMISSION: "Service Commission Report",
  REPORTS_SALES_USER: "User Sales Report",
  REPORTS_COMISSION_USER: "User Commission Report",
  PAYMENT_COLLECTION: "Payment Collection",
  PRESALES: "Presale Leads",
  IMPORT_LOG: "Presale Import Logs",
}

export const BUTTON_LABELS = {
  //common
  EXPORT: "Export",
  SAVE: "Save",
  CANCEL: "Cancel",
  EDIT: "Edit",
  CHANGE_STATUS: "Change Status",
  CHANGE_SERVICE_STATUS: "Change Service Status",
  ADD_NOTE: "Add Note",
  ADD_PROSPECTS: "Add Prospects",
  ADD_ATTACHMENT: "Add Documents",
  IMPORT: "Import",
  IMPORT_HISTORY: "Import History",
  CONVERT: "Convert",
  CREATE_COMMISION: "Create Commission",
  CREATE_SALES: "Create Sales",
  PRESALE_FOLLWOUP_NOTES: "Add Followup Note",
  //User
  CREATE_NEW_USER: "Create New User",
  CREATE_NEW_LEAD: "Create General Lead",
  CREATE_NEW_SERVICE: "Create New Service",
  CREATE_BUSINESS_LEAD: "Create Business Lead",
  CREATE_CONTACT_LEAD: "Create Contact Lead",
  CREATE_SERVICE_CATEGORY: "Create Service Category",
  BLOCK_USER: "Block User",
  UNBLOCK_USER: "UnBlock User",
  //Leads

  CHANGE_STATUS: "Change Status",
  CUSTOMER_SUPPORT: "Customer Support",
  PARTNERSHIP_REQUESTS: "Partnership Requests",
  TICKETS: "Tickets",
  REQUEST_PAYMENT: "Request Payment",
  CHANGE_PAYMENT_STATUS: "Change Payment Status",
  GET_PAYMENT_LINK: "Get Payment Link",
  ASSIGN_USERS: "Assign Users",
  CHANGE_CRE_USER: "Change CRE User",
  ASSIGN_CRE_USER: "Assign CRE User",
  CHANGE_CRE_USER: "Change CRE User",
  UNQUALIFY_LEAD: "Unqualify Lead",
  GENERATE_CREDIT_REPORT: "Generate Credit Report",
}
export const TAB_LABELS = {
  //User
  USER_OVERVIEW: "Overview",
  USER_DETAILS: "User Details",
  USER_LEADS: "Leads",
  USER_CONTACTS: "Contacts",
  USER_TEAM_MEMBERS: "Team Members",
  USER_PAYOUT_REQUEST: "Payout Request",
  USER_EARNINGS: "Earnings",
  USER_LOGS: "Logs",
  //CRE Users
  ASSIGNED_USERS: "Assigned Users",
  //Lead
  LEAD_DETAILS: "Lead Details",
  LEAD_NOTES: "Notes",
  LEAD_ATTACHMENTS: "Documents",
  LEAD_LOG: "Logs",
  LEAD_PAYMENTS: "Payments",
  LEAD_DUPLICATE: "Possible Duplicates",
  LEAD_PROSPECTS: "Prospects",
  LEAD_CREDIT_REPORT: "Credit Report",
  //Business
  BUSINESS_LEAD_DETAILS: "Lead Details",
  BUSINESS_LEAD_NOTES: "Notes",
  BUSINESS_LEAD_ATTACHMENTS: "Documents",
  BUSINESS_LEAD_LOG: "Logs",
  //Contact
  CONTACT_LEAD_DETAILS: "Lead Details",
  CONTACT_LEAD_NOTES: "Notes",
  CONTACT_LEAD_ATTACHMENTS: "Documents",
  CONTACT_LEAD_LOG: "Logs",

  //Service
  SERVICE_SUMMARY: "Summary",
  SERVICE_INFO: "Service Information",
  SERVICE_COMMISION: "Commision Management",
  SERVICE_SALES: "Sales Management",
  SERVICE_LEADS: "Service Leads",
  SERVICE_LOGS: "Logs",
  //Service Category
  SERVICE_CATEGORY_SUMMARY: "Summary",
  SERVICE_CATEGORY_SERVICES: "Services",
  SERVICE_CATEGORY_LOGS: "Logs",
  //Ticket
  TICKET_DETAILS: "Ticket Details",
  TICKET_LOGS: "Status History",
  // Partnership Requests
  PARTNERSHIP_DETAILS: "Partnership Request Details",
  PARTNERSHIP_REQUEST_LOGS: "Status History",
  // Customer Support
  CUSTOMER_SUPPORT_DETAILS: "Customer Support Details",
  CUSTOMER_SUPPORT_LOGS: "Status History",
  //Presales
  PRESALE_DETAILS: "Presale Lead Details",
  PRESALE_NOTES: "Notes",
  PRESALE_FOLLWOUP_NOTES: "Followup Notes",
}

export const MENU_LABELS = {
  RESET_PASSWORD: "Reset Password",
  CHANGE_RANK: "Change Rank",
  ADD_ATTACHMENT: "Add Documents",
  EDIT: "Edit",
  CHANGE_STATUS: "Change Status",
  ADD_NOTE: "Add Note",
  ASSIGN_USERS: "Assign Users",
}

export const STATUS_LABELS = {
  ACTIVE: "Active",
}
