import { TAB_LABELS } from "constants/label"

export const LEAD_TAB_LABELS = [
  {
    id: "lead-details",
    label: TAB_LABELS.LEAD_DETAILS,
  },
  {
    id: "lead-notes",
    label: TAB_LABELS.LEAD_NOTES,
  },
  {
    id: "lead-attachments",
    label: TAB_LABELS.LEAD_ATTACHMENTS,
  },
  {
    id: "lead-logs",
    label: TAB_LABELS.LEAD_LOG,
  },
  {
    id: "lead-payments",
    label: TAB_LABELS.LEAD_PAYMENTS,
  },
]

export const USER_DETAILS_BREADCRUMB = {}
