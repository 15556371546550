import React from "react"

import { Col, Container, Row, Button } from "reactstrap"

import Breadcrumb from "components/Common/Breadcrumb"

import UserManageSection from "../component/common/manageSection"
import { useRef } from "react"
import { postServiceCategoryCreate } from "helpers/api_actions"

import { useToasts } from "react-toast-notifications"
import { isValid, validate } from "helpers/utilities"
import { AUTH_ROUTES } from "constants/routes"
import { useState } from "react"

// Pages Components

const CreateUser = props => {
  const { addToast } = useToasts()
  const [submiting,setsubmitting]=useState(false)
  const userRef = useRef(null)

  const handleSubmit = data => {
    setsubmitting(true)
  
      let payload = new FormData()
      if (isValid(data?.name)) {
        payload.append("name", data?.name)
      }
      if (isValid(data?.description)) {
        payload.append("description", data?.description ?? null)
      }

      if (isValid(data?.is_featured)) {
        payload.append("is_featured", data?.is_featured ?? false)
      }

      if (isValid(data?.sort_order)) {
        payload.append("sort_order", data?.sort_order ?? null)
      }

      if (isValid(data?.icon_file)) {
        payload.append("icon_file", data?.icon_file ?? null)
      }
      if (isValid(data?.image_file)) {
        payload.append("image_file", data?.image_file ?? null)
      }
      if (isValid(data?.parent)) {
        payload.append("parent_category_id", data?.parent?.value ?? null)
      }

      postServiceCategoryCreate(payload)
        .then(res => {
          setsubmitting(false)
          addToast(res?.message, { appearance: "success" })
          props.history.push(AUTH_ROUTES.SERVICE_CATEGORY)
        })
        .catch(err => {
          setsubmitting(false)
          // toastr.error(err.response.data.message)
          addToast(err?.response?.data?.message, { appearance: "error" })
        })
  
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <div className="page-title-left">
                  <Breadcrumb title="Users" breadcrumbItem="Create User" />
                  <h4 className="mb-0 font-size-18">Create Service Category</h4>
                </div>
                <div className="page-title-right">
                  <Button
                    className="btn btn-secondary"
                    onClick={() => {
                      props.history.push(AUTH_ROUTES.SERVICE_CATEGORY)
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn btn-primary"
                    disabled={submiting}
                    onClick={() => {
                      userRef.current && userRef.current.onSubmitAction()
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <form
            onSubmit={e => userRef.current && userRef.current.onSubmitAction(e)}
          >
            <UserManageSection
              ref={userRef}
              data={{}}
              isReadOnly={false}
              handleSubmit={handleSubmit}
            />
          </form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CreateUser
