import CustomSpinner from "components/customSpinner"
import HeaderTitle from "components/Common/headerTitleComponent"
import { HEADER_LABELS, BUTTON_LABELS } from "constants/label"
import { AUTH_ROUTES } from "constants/routes"
import { DEFAULT_CLASSES } from "constants/styles"
import React, { Suspense, lazy, useState } from "react"
import { withRouter } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import PropTypes from "prop-types"
import { getCreUsersListExport } from "helpers/api_actions"

//Import Breadcrumb
const UserListing = lazy(() => import("./listing"))
const CreUsers = props => {
  const [pageNumber, setPageNumber] = useState(1)
  const [pgSize, setPgSize] = useState(15)
  const [searchData, setSearchData] = useState("")
  const [sortBy, setSortBy] = useState("&sort_by[users.full_name]=ASC")
  const [filterData, setFilterData] = useState("")
  const exportUsers = () => {
    let basicData = {
      select: `users.full_name as Name,users.primary_phone as Phone,user_addresses.city as City,LeadCount,TeamCount,my_earnings,team_earnings,Requestcount,users.status as Status,platform_users.updated_at as Updated On,platform_users.created_at as Created On`,
      include: `users,ranks,user_addresses,users as cre_user&page=${pageNumber}&per_page=${pgSize}&export=true`,
    }
    getCreUsersListExport(basicData, searchData, filterData, sortBy)
      .then(res => {
        const outputFilename = `${Date.now()}-CRE-Users.xlsx`

        // If you want to download file automatically using link attribute.
        var blob = new Blob([res])

        var link = document.createElement("a")
        link.href = window.URL.createObjectURL(blob)
        link.download = outputFilename

        document.body.appendChild(link)

        link.click()
        document.body.removeChild(link)
      })
      .catch(err => {
        setIsFetching(false)
      })
  }

  const headerActions = [
    {
      type: "button",
      label: BUTTON_LABELS.CREATE_NEW_USER,
      className: DEFAULT_CLASSES.HEADER_BUTTON_PRIMARY,
      action: () => {
        props.history.push(AUTH_ROUTES.CREATE_NEW_CRE_USER)
      },
    },
    // {
    //   type: "button",
    //   label: BUTTON_LABELS.EXPORT,
    //   className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
    //   action: () => {
    //     exportUsers()
    //   },
    // },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <HeaderTitle title={HEADER_LABELS.CRE_USERS} actions={headerActions} />
          </Row>
          <Row>
            <Col lg="12">
              <Suspense fallback={<CustomSpinner />}>
                <UserListing
                  {...props}
                  setPageNumber={setPageNumber}
                  setPgSize={setPgSize}
                  setSearchData={setSearchData}
                  setFilterData={setFilterData}
                  setSortBy={setSortBy}
                />
              </Suspense>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

CreUsers.propTypes = {
  history: PropTypes.object,
}

export default withRouter(CreUsers)
