import React from "react"
import { useState } from "react"
import { forwardRef, useImperativeHandle } from "react"
import { Button, Col, Row } from "reactstrap"

import SelectfieldComponent from "components/Common/SelectfieldComponent"
import CustomCalander from "components/CustomCalendar"
import SearchSelect from "components/CustomSearchSelect"
import CustomSelect from "components/CustomSelect"
import {
  getServiceCategoryList,
  getServiceRankList,
  getServiceTypeList,
} from "helpers/api_actions"

const UserFilterComponent = forwardRef((props, ref) => {
  UserFilterComponent.displayName = "Service Filter"
  const [formData, setFormData] = useState(props.filters)

  const defaultSelection = null

  const handleChange = (v, k, e) => {
    setFormData({ ...formData, [k]: v })
  }
  const onApplyFilter = () => {
    props.onFilterSubmission(formData)
  }
  const onCancelFilter = () => {
    setFormData({ created_at: "", updated_at: "", parent: "" })
    setTimeout(() => {
      props.onFilterSubmission({})
    }, 150)
  }

  const statusList = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ]
  const handleDataChange = (value, name) => {
    setFormData({ ...formData, [name]: value })
  }
  const listServiceCategories = async v => {
    let filter = `search[name]=${v}`

    let basicData = {
      select: `id as value,name as label`,
      include: ``,
    }
    const res = await getServiceCategoryList(basicData, filter)
    return res?.data?.data ?? []
  }
  const listServiceTypes = async v => {
    let filter = `filter[name]=${v}`

    let basicData = {
      select: `id as value,name as label`,
      include: ``,
    }
    const res = await getServiceTypeList(basicData, filter)
    return res?.data?.data ?? []
  }
  const listServiceRank = async v => {
    let filter = `filter[name]=${v}`

    let basicData = {
      select: `id as value,name as label`,
      include: ``,
    }
    const res = await getServiceRankList(basicData, filter)
    return res?.data?.data ?? []
  }

  return (
    <>
      <Row>
        <Col lg={3} md={4} sm={6} xs={12}>
          <SearchSelect
            getData={listServiceTypes}
            isMulti={false}
            clearable
            label="Type"
            setData={v => {
              handleChange(v, "serviceTypes")
            }}
            value={formData?.serviceTypes ?? null}
          />
        </Col>
        <Col lg={3} md={4} sm={6} xs={12}>
          <SearchSelect
            getData={listServiceRank}
            isMulti={false}
            clearable
            label="Rank"
            setData={v => {
              handleChange(v, "serviceRanks")
            }}
            value={formData?.serviceRanks ?? null}
          />
        </Col>

        <Col lg={3} md={4} sm={6} xs={12}>
          <CustomSelect
            options={statusList}
            isMulti={false}
            clearable
            label="Status"
            setData={v => {
              handleChange(v, "status")
            }}
            value={formData?.status ?? null}
          />
        </Col>
        <Col lg={3} md={4} sm={6} xs={12}>
          <CustomCalander
            dateRange
            value={formData?.updatedOn}
            onChange={date => handleDataChange(date, "updatedOn")}
            label="Updated Between"
            placeholder={"Start Date - End Date"}
            maxDate={"today"}
          />
        </Col>
        <Col lg={3} md={4} sm={6} xs={12}>
          <CustomCalander
            dateRange
            maxDate={"today"}
            placeholder={"Start Date - End Date"}
            value={formData?.createdOn}
            onChange={date => handleDataChange(date, "createdOn")}
            label="Created Between"
          />
        </Col>
        <Col
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className="d-flex align-items-end justify-content-start pt-3"
        >
          <div className="me-2">
            <Button
              className="btn btn-sm btn-primary btn-success text-white"
              onClick={() => onApplyFilter()}
            >
              Apply
            </Button>
          </div>
          <div>
            <Button
              className="btn btn-sm btn-danger"
              onClick={() => onCancelFilter()}
            >
              Reset
            </Button>
          </div>
        </Col>
      </Row>
    </>
  )
})

export default UserFilterComponent
