//LOGIN
export const AUTH_LOGIN = "/login"
export const FORGOT_PWD = "/generate-code"
export const FORGOT_PWD_VERIFY = "/verify-code"
export const FORGOT_PWD_RESET = "/set-passcode"
export const AUTH_FCMClear = "/clear-fcm"
export const AUTH_FCMSave = "/save-fcm"
export const AUTH_FCMCheck = "/check-fcm"
//LOGOUT
export const AUTH_LOGOUT = "/logout"
//DASHBOARD
export const ADMIN_DASHBOARD = "/admin-dashboard"
export const SALES_DASHBOARD_DATA = "/sale-generated"
export const COMMISSION_DASHBOARD_DATA = "/commission-report-dashboard"
export const GET_DASHBOARD_LEADS_CHART = "/leads-generated-chart"
export const GET_DASHBOARD_COMMISSIN_CHART = "/commissions-given-chart"
export const GET_DASHBOARD_SALES_CHART = "/sale-generated-chart"
//USERS
export const ADMIN_PROFILE = "/admin-profile"
export const ADMIN_PROFILE_UPDATE = "/admin-profile-update"
export const PLATFORM_USER_PROFILE = "/profile"
export const PLATFORM_USER_LIST = "/list/platform_users"
export const CRE_USER_LIST = "/cre"
export const USER_DETAILS = "/users"
export const USER_CREATE = "/users"
export const CRE_USER_CREATE = "/cre"
export const USER_UPDATE = "/update-user"
export const CRE_USER_UPDATE = "/update-cre"
export const UNASSIGNED_USER_LIST = "/creusers"
export const ASSIGN_CRE_USERS = "/creusers?"
export const UNASSIGN_CRE_USERS = "/remove-creuser"
export const USER_DELETE = "/users"
export const USER_RESETPASSCODE = "/reset-passcode"
export const CHANGE_USER_STATUS = "/user-status"
export const USER_ACCOUNT_DETAILS = "/list/bank_accounts"
export const GET_TEAM_MEMBERS = "/list/invited_team_members"
export const GET_QUALIFICATIONS = "/list/qualifications"
export const GET_JOB = "/list/professions"
export const GET_USER_DASHBOARD = "/user-dashboard"
export const ACCOUNT_CREATE = "/accounts"
export const CHANGE_REFERRER_COMMISION = "/referrer-commision"
//PRESALE
export const PRESALE_LIST = "/list/pre_sales"
export const PRESALE_LIST_DETAILS = "/presales-users"
export const PRESALE_IMPORT = "/presales-import"
export const PRESALE_NOTES = "/notes"
export const PRESALE_FOLLOWUP_NOTES = "/presales-followup"
export const PRESALE_FOLLOWUP_NOTES_LIST = "/get-followup"
export const PRESALE_ASSIGN_CRE = "/assign-presales-cre"
export const PRESALE_IMPORT_LOG_LIST = "/list/pre_sales_import_log"
//LEADS
export const CUSTOMERS_LIST = "/list/customers"
export const LEADS_LIST = "/list/leads"
export const LEADS_CREATE = "/leads"
export const LEADS_DETAILS = "/leads"
export const NOTE = "/notes"
export const ASSIGNPROSPECTS = "/assign-customer-services"
export const DELETEPROSPECTS = "/remove-customer-services"
export const REQUEST_PAYMENT = "/request-payment"
export const CHANGE_PAYMENT_STATUS = "/payment-status-change"
export const CHANGE_SERVICE_STATUS = "/service-status-change"
export const GENERATE_CREDIT_REPORT = "/generate-credit-report"
export const ATTACHMENT = "/attachments"
export const CHANGE_LEAD_STATUS = "/lead-change-status"
export const GET_COMMISION = "/get-commision"
export const LEAD_PAYMENT_LIST = "/list/lead_payment_history"
export const CALCULATE_LEAD_OWNER_COMMISSION = "/calc-commission"
export const PAYMENT_URL = "/get-payment-url"
export const CREDIT_REPORT = "/get-credit-detailed-report"
// SERVICE CATEGORY
export const SERVICE_CATEGORY_LIST = "/list/service_categories"
export const SERVICE_CATEGORY_CREATE = "/service_category"
export const SERVICE_CATEGORY_DETAILS = "/service_category"
export const SERVICE_CATEGORY_DELETE = "/service_category"
export const SERVICE_CATEGORY_STATUS = "/service-category-status"
// SERVICE
export const SERVICE_LIST = "/list/services"
export const SERVICE_CREATE = "/service"
export const SERVICE_DETAIL = "/service"
export const SERVICE_DELETE = "/service"
export const GET_SERVICE_DASHBOARD = "/service-dashboard"
export const GET_SERVICE_DASHBOARD_LEADS_CHART = "/leads-generated-chart"
export const GET_SERVICE_DASHBOARD_Commission_CHART = "/commissions-given-chart"
export const GET_SERVICE_LOG = "/list/change_logs"
export const SERVICE_STATUS_LIST = "/list/service_status"
export const CUSTOMER_SERVICE_LIST = "/list/customer_services"
//COMMISSION
export const COMMISSION_LIST = "/list/commisions"
export const FETCH_COMMISSION_DATA = "/get-commision"
export const CREATE_COMMISSION = "/commisions"
export const GET_COMMISSION_DETAILS = "/commisions"
// SALES
export const SALES_LIST = "/list/sale_amount"
export const CREATE_SALE = "/sale-amount"
// PAYOUT
export const PAYOUT_LIST = "/list/payout_requests"
export const PAYOUT_LIST_EXPORT = "/payouts/export"
export const PAYOUT_MODES = "/list/mode_of_transfers"
export const PAYOUT_REQUEST_UPDATE = "/payout-commision"
export const PAYOUT_IMPORT = "/payouts/import"
export const PAYOUT_IMPORT_LIST = "/list/payout_import_log"
//Earnings
export const EARNINGS_LIST = "/list/earnings"
export const EARNINGS_DETAIL = "/earning"

export const EARNINGS_STATUS = "/earning-status-change"
export const EARNING_HISTORY = "/list/earnings_log"

//CUSTOMER SUPPORT
export const CUSTOMER_SUPPORT_LIST = "/list/customer_support"
export const CUSTOMER_SUPPORT_DETAILS = "/form-details/customer_support"
export const CHANGE_CUSTOMER_SUPPORT_STATUS = "/change/status/customer_support"
export const GET_CUSTOMER_SUPPORT_LOG = "/list/customer_support_log"
export const CHANGE_CUSTOMER_SUPPORT_CRE_USER =
  "/change/cre-user/customer_support"
//PARTNERSHIP REQUEST
export const PARTNERSHIP_REQUEST_LIST = "/list/partnership_form"
export const PARTNERSHIP_REQUEST_DETAILS = "/form-details/partnership_form"
export const CHANGE_PARTNERSHIP_REQUEST_STATUS =
  "/change/status/partnership_form"
export const CHANGE_PARTNERSHIP_REQUEST_CRE_USER =
  "/change/cre-user/partnership_form"
export const GET_PARTNERSHIP_REQUEST_LOG = "/list/partnership_log"
//TICKETS
export const TICKETS_LIST = "/list/raise_ticket_form"
export const TICKET_DETAILS = "/form-details/raise_ticket_form"
export const CHANGE_TICKET_STATUS = "/change/status/raise_ticket_form"
export const CHANGE_TICKET_CRE_USER = "/change/cre-user/raise_ticket_form"
export const GET_TICKET_LOG = "/list/ticket_log"
// REGISTERS
export const SERVICE_TYPE_LIST = "/list/service_types"
export const SERVICE_RANK_LIST = "/list/service_ranks"

export const GET_LEAD_LOG = "/list/change_logs"
export const GET_USER_ACTIVITY_LOG = "/list/user_activity_logs"
export const NOTES_BY_ENTITY = "/get-notes"
export const ATTACHMENT_BY_ENTITY = "/get-attachments"
export const CITIES_LIST = "/list/cities"
export const STATES_LIST = "/list/states"
export const COUNTRIES_LIST = "/list/countries"
export const USER_RANK_LIST = "/list/ranks"
export const USER_LIST = "/list/users"
export const USER_TYPE_LIST = "/list/user_type"
export const LEAD_TYPE_LIST = "/list/lead_types"
export const LEAD_STATUS_LIST = "/list/lead_statuses"
export const PRESALE_STATUS_LIST = "/list/pre_sales_statuses"
export const PRESALE_CHANGE_STATUS = "/presales-change-status"
export const BANKS_LIST = "/list/banks"
//REPORTS
export const SERVICE_WISE_REPORT_LIST = "/service-sale-report"
export const USER_WISE_SALES_REPORT_LIST = "/user-sale-report"
export const SERVICE_COMMISSION_REPORT_LIST = "/service-commission-report"
export const USER_COMMISSION_REPORT_LIST = "/user-commission-report"
export const SERVICE_WISE_REPORT_LIST_TOTAL = "/sale-report-total"
export const USER_WISE_SALES_REPORT_LIST_TOTAL = "/user-sale-report-total"
export const SERVICE_COMMISSION_REPORT_LIST_TOTAL = "/commission-report-total"
export const USER_COMMISSION_REPORT_LIST_TOTAL = "/user-commission-report-total"
//PAYMENT COLLECTION
export const PAYMENT_COLLECTION_LIST = "/list/lead_payment_history"
