import React, { useEffect, useState  } from "react"
import { Modal } from "reactstrap"
import { Col, Row } from "reactstrap"

import CustomCalander from "components/CustomCalendar/index"
import CustomSelect from "components/CustomSelect/index"
import CustomTextField from "components/CustomTextField/index"
import moment from 'moment';

const AddSale = props => {


  const [data, setData] = useState({ ...props.data })
  const [errors, setErrors] = useState({})
  const statuses = [
    { label: "Active", value: "Active" },
    { label: "InActive", value: "InActive" },
  ]
  const serviceData = props.serviceData
  const [minDate, setMinDate] = useState("today");
  const [initialEndDate, setInitialEndDate] = useState(data?.end_date ? data?.end_date[0] : null);

  useEffect(() => {
    if (props.edit && initialEndDate && moment(initialEndDate).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
      setMinDate("today");
    } else {
      setMinDate(moment().add(1, 'days').format('YYYY-MM-DD'));
    }
  }, [props.edit, initialEndDate]);

  const handleChange = (v, k, e) => {
    if (k === 'end_date' && !initialEndDate) {
      setInitialEndDate(v);
    }
    if (k === "value" && (v < 1 || v > 100)) {
      setErrors({
        ...errors,
        [k]: "Sales revenue percentage should be between 1 and 100",
      })
    } else if (k === "value") {
      setErrors({})
    }
    setData({ ...data, [k]: v })
  }
  const handleChangeDate = (date, field) => {
    if (field === 'end_date' && !initialEndDate) {
      setInitialEndDate(date);
    }
    // Handle the date change
  };

  return (
    <Modal
      isOpen={true}
      toggle={props.onCancelAction}
      centered={true}
      size={props.edit ? "lg" : "md"}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {props.edit ? "Edit Sale Rate" : "Create Sale Rate"}
        </h5>
        <button
          type="button"
          disabled={props.submiting}
          onClick={() => {
            props.onCancelAction()
          }}
          className="btn-close"
        ></button>
      </div>
      <div className="modal-body">
        <form>
          <Row>
            {props.edit && (
              <>
                <Col xs={"6"}>
                  <div className="mb-3">
                    <CustomTextField
                      label="Sale ID"
                      disabled
                      value={data?.display_id}
                    />
                  </div>
                </Col>
                <Col xs={"6"}>
                  <div className="mb-3">
                    <CustomTextField
                      label="Created By"
                      disabled
                      value={data?.created_by}
                    />
                  </div>
                </Col>
                <Col xs={"6"}>
                  <div className="mb-3">
                    <CustomTextField
                      label="Last Updated By"
                      disabled
                      value={data?.modified_by}
                    />
                  </div>
                </Col>
              </>
            )}
            <Col xs={props.edit ? "6" : "12"}>
              <div className="mb-3">
                <CustomTextField
                  label="Service Name"
                  disabled
                  value={props.name}
                />
              </div>
            </Col>
            <Col xs={props.edit ? "6" : "12"}>
              <div className="mb-3">
                <CustomCalander
                  label="Start Date"
                  required
                  placeholder="Select Date"
                  name="start_date"
                  value={data?.start_date ?? null}
                  onChange={date => handleChange(date, "start_date")}
                  minDate={"today"}
                  disabled={props.edit && data?.start_date && moment(data?.start_date[0]).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")} 
                />
              </div>
            </Col>{" "}
             <Col xs={props.edit ? "6" : "12"}>
              <div className="mb-3">
                <CustomCalander
                  label="End Date"
                  required
                  // maxDate={data?.start_date}
                  placeholder="Select Date"
                  value={data?.end_date ?? null}
                  onChange={date => handleChange(date, "end_date")}
                  minDate={minDate}
                />
              </div>
            </Col>{" "}
            <Col xs={props.edit ? "6" : "12"}>
              <div className=" mb-3">
                <CustomTextField
                  label="Sales Revenue (%)"
                  placeholder="Enter Sales Revenue"
                  name="value"
                  required
                  disabled={props.edit ? true : false} 
                  type="decimal"
                  onChange={(v, k, e) => handleChange(v, k, e)}
                  value={data?.value ?? ""}
                />
              </div>
            </Col>{" "}
            {/* <Col xs={props.edit ? "6" : "12"}>
              <div className=" mb-3">
                <CustomSelect
                  label="Status"
                  options={statuses}
                  required
                  setData={v => {
                    handleChange(v, "status")
                  }}
                  value={data?.status ?? null}
                />
              </div>
            </Col> */}
          </Row>
        </form>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          data-bs-dismiss="modal"
          disabled={props.submiting}
          onClick={() => {
            props.onCancelAction()
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          disabled={props.submiting}
          className="btn btn-primary"
          onClick={e => props.onSubmitAction(data)}
        >
          {props.edit ? "Update" : "Save"}
        </button>
      </div>
    </Modal>
  )
}
export default AddSale
