import moment from "moment"
import React, { Component } from "react"
import { Col, Input, Modal, Row } from "reactstrap"

import uploadplaceholder from "assets/admin-images/upload-placeholder.png"
import DatepickerComponent from "components/Common/DatepickerComponent"
import SelectfieldComponent from "components/Common/SelectfieldComponent"
import TextfieldComponent from "components/Common/TextfieldComponent"
import CustomCalander from "components/CustomCalendar"
import CustomTextField from "components/CustomTextField/index"
import { validate } from "helpers/utilities"

import { addUserKyc } from "../../../../../helpers/api_actions"

const required = ["pancard", "aadharCard", "dateofBirth"]
class PanInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal_center: false,
      submitted: false,
      formData: {
        pancard: "",
        aadharCard: "",
        dateofBirth: "",
      },
      errors: {},
    }
  }
  handleValidate = flag => {
    var regex = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/

    let errors = {}
    required?.forEach(req => {
      if (!this.state.formData[req]) {
        errors[req] = "Please Enter Details"
      } else if (
        req === "pancard" &&
        (this.state.formData[req]?.length !== 10 ||
          !regex.test(this.state.formData[req].toUpperCase()))
      ) {
        errors[req] = "PLease Enter Valid PAN Card Number"
      } else if (
        req === "aadharCard" &&
        this.state.formData[req]?.length !== 12
      ) {
        errors[req] = "PLease Enter Valid Aadhar Card Number"
      }
    })
    return errors
  }
  handleChange = (v, k) => {
    if (k === "pancard") {
      const str = v.trim()
      this.setState({
        formData: {
          ...this.state.formData,
          [k]: str.toUpperCase(),
        },
      })
    } else {
      this.setState({
        formData: {
          ...this.state.formData,
          [k]: v,
        },
      })
    }
    setTimeout(() => {
      this.setState({
        errors: { ...this.state.errors, [k]: v ? "" : this.state.errors[k] },
      })
    }, 100)
  }

  handleSubmit = () => {
    const { formData } = this.state
    const error = this.handleValidate(false)
    this.setState({ errors: error })

    if (Object.keys(error)?.length === 0) {
      this.setState({ submitted: true })

      const form = new FormData()
      form.append("pan_number", formData.pancard)
      form.append("aadhar_number", formData.aadharCard)
      form.append(
        "date_of_birth",
        moment(formData.dateofBirth[0]).format("YYYY-MM-DD")
      )
      form.append("user_id", this.props.params.id)

      addUserKyc(form)
        .then(res => {
          this.setState({ submitted: false, formData: {} })
          this.props.handleClose(res, "success")
        })
        .catch(error => {
          this.setState({ submitted: false })

          this.props.handleClose(error, "error")
        })
    }
  }
  handleCancel = () => {
    this.setState({ formData: {}, errors: {} })
    this.props.handleClose({}, "")
  }
  handleChangeDate = date => {
    this.setState(
      {
        formData: {
          ...this.state.formData,
          dateofBirth: date,
        },
      },
      () => {
        setTimeout(() => {
          this.setState({
            errors: {
              ...this.state.errors,
              ["dateofBirth"]: date ? "" : this.state.errors.dateofBirth,
            },
          })
        }, 100)
      }
    )
  }
  render() {
    const { formData, errors } = this.state
    return (
      <React.Fragment>
        <div>
          <Modal
            isOpen={this.props.open}
            toggle={this.tog_center}
            centered={true}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                PAN & Aadhar Card Information
              </h5>
              <button
                type="button"
                onClick={() => {
                  this.handleCancel()
                }}
                className="btn-close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className=" mb-3">
                  <CustomTextField
                    required={true}
                    label="Aadhar Card Number"
                    placeholder="Enter Aadhar Card Number"
                    type="number"
                    maxLength={12}
                    minLength={12}
                    name="aadharCard"
                    onChange={(v, k, e) => this.handleChange(v, k, e)}
                    value={formData?.aadharCard ?? ""}
                  />
                  {errors?.aadharCard && (
                    <p className="text-danger">{errors?.aadharCard}</p>
                  )}
                </div>
                <div className=" mb-3">
                  <CustomTextField
                    required={true}
                    label="PAN Card Number"
                    placeholder="Enter PAN Card Number"
                    fieldtype="text"
                    maxLength={10}
                    minLength={10}
                    name="pancard"
                    inputCatagory="trim"
                    onChange={(v, k, e) => this.handleChange(v, k, e)}
                    value={formData.pancard ?? ""}
                  />
                  {errors?.pancard && (
                    <p className="text-danger">{errors?.pancard}</p>
                  )}
                </div>

                <div className=" mb-3">
                  <CustomCalander
                    maxDate={"today"}
                    required
                    label="Date of Birth in PAN Card"
                    placeholder="Enter Date of Birth"
                    value={formData.dateofBirth}
                    onChange={date => this.handleChangeDate(date)}
                  />
                  {errors?.dateofBirth && (
                    <p className="text-danger">{errors?.dateofBirth}</p>
                  )}
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  this.handleCancel()
                }}
              >
                Cancel
              </button>
              <button
                disabled={this.state.submitted}
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  this.handleSubmit()
                }}
              >
                Save
              </button>
            </div>
          </Modal>
        </div>
      </React.Fragment>
    )
  }
}

export default PanInfo
