import create from "zustand"

export const useNotificationStore = create(set => ({
  notificationsCount: 0,
  notificationsData: [],
  addNotification: data =>
    set(state => ({
      notificationsCount: data,
    })),
  addNotificationData: data =>
    set(state => ({
      notificationsData: data,
    })),
}))
