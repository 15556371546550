import { React, useImperativeHandle, useRef, useState } from "react"
import { forwardRef } from "react"
import { useParams } from "react-router-dom"
import { useToasts } from "react-toast-notifications"

import { AUTH_ROUTES } from "constants/routes"
import { preSaleUpdate } from "helpers/api_actions"
import { emailValidator, generateErrorMessage } from "helpers/utilities"
import PreSaleManagement from "pages/PreSales/presale_form"

const PresaleDetailsView = forwardRef((props, ref) => {
  PresaleDetailsView.displayName="PreSale Lead Details"
  const { addToast } = useToasts()
  const editRef = useRef(null)
  const param = useParams()
  const [editFlag,setEditFlag]=useState(false)
  const [tempFlag,setTempFlag]=useState(false)
  useImperativeHandle(ref, () => ({
    update(e) {
      editRef.current.onSubmitAction()
    },
  }))
  const handleSubmit = data => {
    props.setSubmiting(true)
    if (
      (data.primary_phone && data.primary_phone.length) ||
      (data.primary_email && data.primary_email.length) ||(data.secondary_phone && data.secondary_phone.length) ||
      (data.secondary_email && data.secondary_email.length)
    ) {
      if (
        data.primary_email &&
        data.primary_email.length &&
        !emailValidator(data?.primary_email)&&data.secondary_email &&
        data.secondary_email.length &&
        !emailValidator(data?.secondary_email)
      ) {
        props.setSubmiting(false)
        addToast("Please enter a Valid Email", { appearance: "error" })
      } else if (data.primary_phone && data.primary_phone.length !== 10 && data.secondary_phone && data.secondary_phone.length !== 10) {
        props.setSubmiting(false)
        addToast("Phone number should have 10 digits", {
          appearance: "error",
        })
      }else if(data.primary_phone==data.secondary_phone){
        addToast(" Phone numbers for primary and secondary fields cannot be the same", {
          appearance: "error",
        })
      }else if(data.primary_email==data.secondary_email){
        addToast(" Email for primary and secondary fields cannot be the same", {
          appearance: "error",
        })
      }      
      else {
        let payload = {
          primary_email: data?.primary_email ?? null,
          secondary_email: data?.secondary_email ?? null,
          name: data?.name ?? null,
          // owner: data?.owner?.value ?? null,
          primary_phone: data?.primary_phone
            ? `+91${data?.primary_phone}`
            : null,
          secondary_phone: data?.secondary_phone
            ? `+91${data?.secondary_phone}`
            : null,
          address_type: data?.address_type?.value ?? null,
          address_line_1: data?.address_line_1 ?? null,
          address_line_2: data?.address_line_2 ?? null,
          city: data?.city?.label ?? null,
          state: data?.state?.label ?? null,
          country: data?.country?.label ?? null,
          pincode: data?.pincode ?? null,
          business_name: data?.business_name ?? null,
          processed_by: data?.processed_by ?? null,
        }
        preSaleUpdate(param.id, payload)
          .then(res => {
            addToast(res?.message, { appearance: "success" })
            props.refresh()
            props.setSubmiting(false)
            props.navigateToDetails()
          })
          .catch(err => {
            setTempFlag(true)
            let errorMsg = generateErrorMessage(err?.response?.data)
            props.setSubmiting(true)
            addToast(errorMsg ? errorMsg: err?.response?.data?.message, {
        appearance: "error",
      })
          })
        }
      } else {
        props.setSubmiting(false)
        addToast("Please enter primary Email/Phone ", { appearance: "error" })
      }
    }

  const data = {
    id:props?.data?.id,
    name:props.data?.name,
    business_name:props.data?.business_name,
    primary_email:props.data?.primary_email,
    secondary_email:props.data?.secondary_email,
    primary_phone: props?.data?.primary_phone
      ? props.data?.primary_phone?.replace("+91", "")
      : null,
    secondary_phone: props?.data?.secondary_phone
      ? props.data?.secondary_phone?.replace("+91", "")
      : null,

    created_by: props?.data?.created_by?.full_name,
    created_at: props?.data?.created_at,
    updated_at: props?.data?.modified_at,

    handled_by: props?.data?.cre_user?.full_name,
    processed_by: props?.data?.processed_by,
    status: props?.data?.pre_sale_status?.name,
    address_type: {
      label: props?.data?.address_type,
      value: props?.data?.address_type,
    },
    address_line_1: props?.data?.address_line_1,
    address_line_2: props?.data?.address_line_2,
    pincode: props?.data?.pincode,
    city: props?.data?.city
      ? {
          label: props?.data?.city,
          value: props?.data?.city,
        }
      : null,
    state: props?.data?.state
      ? {
          label: props?.data?.state,
          value: props?.data?.state,
        }
      : null,
    country: props?.data?.country
      ? {
          label: props?.data?.country,
          value: props?.data?.country,
        }
      : null,

  }
  useState(()=>{
  var currentUrl = window.location.href;

  if (currentUrl.includes("edit")) {
      setEditFlag(true)
  } 
  }, [])
 return (
    <div>
      {editFlag ?  <PreSaleManagement
        data={data}
        ref={editRef}
        isReadOnly={false}
        edit={true}
        handleSubmit={handleSubmit}
        activeTab={props.activeTab}
      /> : <PreSaleManagement
        data={data}
        ref={editRef}
        isReadOnly={!props.edit }
        edit={props.edit}
        handleSubmit={handleSubmit}
        activeTab={props.activeTab}
      />}
    </div>
  )
})

export default PresaleDetailsView
