import React, { Suspense, useState } from "react"
import { withRouter } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"

import CustomSpinner from "components/customSpinner"
import ImportLogList from "./component/list"

const ImportLog = props => {
  const [filters, setAppliedFilters] = useState("")

  const setFilters = filters => {
    setAppliedFilters(filters)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Suspense fallback={<CustomSpinner />}>
                <ImportLogList
                  setFilters={setFilters}
                  {...props}

                />
              </Suspense>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ImportLog)
