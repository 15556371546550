import moment from "moment"
import React, { forwardRef, Suspense, useImperativeHandle, useState } from "react"
import { useEffect } from "react"
import { Link } from "react-router-dom"
import { Card, Col, Row } from "reactstrap"

import CustomSpinner from "components/customSpinner"
import CustomTable from "components/customTable/index"
import { NAV_LABELS } from "constants/label"
import { AUTH_ROUTES } from "constants/routes"
import { getPaymentCollectionsExport, getPaymentCollectionsList } from "helpers/api_actions"
import { generateClassNameByValue, generateClassNameByValueForStatus } from "helpers/utilities"

import PaymentCollectionFilterComponent from "./filterComponent"

// import PaymentCollectionFilterComponent from "../filterComponent"

const PaymentCollections = forwardRef((props, ref) => {
  PaymentCollections.displayName = "PaymentCollections"
  const [totalCount, setTotalCount] = useState(0)
  const [pageSize, setPageSize] = useState(15)
  const [pageNo, setPageNo] = useState(1)
  const [filters, setFilters] = useState({})
  const [appliedFilters, setAppliedFilters] = useState("")
  const [search, setSearch] = useState("")
  const [appliedSeach, setAppliedSearch] = useState("")
  const [data, setData] = useState([])
  const [sortKey, setSortKey] = useState("&sort_by[leads.name]=ASC")
  const [isFetching, setIsFetching] = useState(false)

  useImperativeHandle(
    ref,
    () => {
      return {
        exportPatmentCollection() {
          let basicData = {
            // select: `&page=${pageNo}&per_page=${pageSize}&export=true`,
            include: `leads,services,users`,
            select: `leads.name as Lead,services.name as Service,users.full_name as Lead Owner,lead_payment_history.payment_amount as Amount,lead_payment_history.status as Status,lead_payment_history.amount_paid as Amount Paid,transaction_number as Transaction number,mode_payment as Mode of Payment,lead_payment_history.created_at as Created At,lead_payment_history.updated_at as Updated At&page=${pageNo}&per_page=${pageSize}&export=true`,
          }
          getPaymentCollectionsExport(
            basicData,
            appliedSeach,
            appliedFilters,
            sortKey
          ).then(res => {
            const outputFilename = `Payment_collection_${moment().format(
              "DDMMYYYY"
            )}.xlsx`

            var blob = new Blob([res])

            var link = document.createElement("a")
            link.href = window.URL.createObjectURL(blob)
            link.download = outputFilename

            document.body.appendChild(link)

            link.click()
            document.body.removeChild(link)
          })
        },
      }
    },
    [pageNo, pageSize, appliedFilters, appliedSeach, sortKey]
  )
  useEffect(() => {
    PaymentCollection()
  }, [pageNo, pageSize, appliedSeach, appliedFilters, sortKey])

  const PaymentCollection = () => {
    let basicData = {
      include: `leads,services,users`,
      select: `leads.id,lead_payment_history.created_at,leads.name as lead_name,services.name as service_name,users.full_name as lead _owner,lead_payment_history.payment_amount,lead_payment_history.status,lead_payment_history.remark,lead_payment_history.amount_paid,transaction_number,mode_payment,lead_payment_history.updated_at&page=${pageNo}&per_page=${pageSize}`,
    }
    setIsFetching(true)
    getPaymentCollectionsList(basicData, appliedSeach, appliedFilters, sortKey)
      .then(res => {
        setData(res.data.data)
        setTotalCount(res?.data?.total || 0)
        setIsFetching(false)
      })
      .catch(err => {
        setIsFetching(false)
      })
  }

  const handleSearch = val => {
    setSearch(val)
    let searchString =
      val && val !== ""
        ? `&search[leads.name]=${val}
          &search[services.name]=${val}
       `
        : undefined
    setPageNo(1)
    setAppliedSearch(searchString)
  }
  const onFilterSubmission = fil => {
    setFilters(fil)
    let filterString = undefined
    filterString = `
    ${
      fil?.service?.value ? "&filter[services.name]=" + fil?.service?.value : ""
    }
    ${
      fil?.status?.value
        ? "&filter[lead_payment_history.status]=" + fil?.status?.value
        : ""
    }${
      fil?.updatedOn?.length === 2
        ? "&filter[lead_payment_history.updated_at]=" +
          moment(fil.updatedOn[0]).format("YYYY-MM-DD") +
          " - " +
          moment(fil.updatedOn[1]).format("YYYY-MM-DD")
        : ""
    }${
      fil?.createdOn?.length === 2
        ? "&filter[lead_payment_history.created_at]=" +
          moment(fil.createdOn[0]).format("YYYY-MM-DD") +
          " - " +
          moment(fil.createdOn[1]).format("YYYY-MM-DD")
        : ""
    }${
      fil?.leadOwner?.value ? "&filter[users.id]=" + fil?.leadOwner?.value : ""
    }`
    setPageNo(1)
    setAppliedFilters(filterString ?? "")
  }
  const [columns, setColumns] = useState([
    {
      title: "Name",
      field: "lead_name",
      minWidth: 180,
      width: 250,
      maxWdith: 250,
      wrap: true,
      sort_direction: "ASC",
      sort_key: "leads.name",
      render: (r, v) => (
        <Link
          className="text-link"
          to={{
            pathname: `${AUTH_ROUTES.PAYMENT_COLLECTION_LEAD_DETAILS}${r.id}`,
            state: {
              edit: false,
              frompath: AUTH_ROUTES.PAYMENT_COLLECTION,
              fromlabel: NAV_LABELS.PAYMENT_COLLECTION,
            },
          }}
        >
          {r.lead_name}
        </Link>
      ),
    },

    {
      title: "Lead Owner",
      field: "lead _owner",
      minWidth: 150,
      width: 200,
      maxWdith: 200,

      wrap: true,
      sort_key: "users.full_name",
      //   tooltip_text: "User who gave the lead",
    },
    {
      title: "Service Name",
      field: "service_name",
      sort_key: "services.name",
      minWidth: 200,
      width: 250,
      maxWdith: 250,
      wrap: true,
    },
    {
      title: "Status",
      field: "status",
      sort_key: "lead_payment_history.status",
      minWidth: 130,
      width: 130,
      wrap: true,
      align: "start",
      render: (r, v) => (
        <span
          className={`ms-2 chips ${v && generateClassNameByValueForStatus(v)}`}
        >
          {v}
        </span>
      ),
    },
    {
      title: "Payment Amount",
      field: "payment_amount",
      // sort_key: "services.name",
      minWidth: 80,
      width: 80,
      maxWdith: 80,
      wrap: true,
      align: "center",
      render: (row, val) => (
        <div>{val ? parseFloat(val).toLocaleString("en-IN") : "--"}</div>
      ),
    },
    {
      title: "Created On",
      sort_key: "lead_payment_history.created_at",
      field: "created_at",
      minWidth: 140,
      width: 140,
      wrap: true,
      render: (row, v) => (
        <div>{v ? moment(v).format("DD-MMM-YYYY") : "--"}</div>
      ),
    },
    {
      title: "Updated On",
      field: "updated_at",
      sort_key: "lead_payment_history.updated_at",
      minWidth: 140,
      width: 140,
      wrap: true,
      render: (row, v) => (
        <div>{v ? moment(v).format("DD-MMM-YYYY") : "--"}</div>
      ),
    },
  ])
  const handleOnPageChange = p => {
    setPageNo(p.page)
    setPageSize(p.pageSize)
  }
  const handleSort = row => {
    let nextDirection = row.sort_direction
      ? row?.sort_direction === "ASC"
        ? "DESC"
        : "ASC"
      : "ASC"
    columns.forEach(col => {
      if (col.field === row.field) {
        col["sort_direction"] = nextDirection
      } else {
        col["sort_direction"] = undefined
      }
    })
    setColumns(() => columns)

    let sortkey = `&sort_by[${row.sort_key}]=${nextDirection}`
    setSortKey(sortkey)
  }

  return (
    <>
      <Suspense fallback={<CustomSpinner />}>
        <CustomTable
          columns={columns}
          data={data}
          currentPage={pageNo}
          totalCount={totalCount}
          pageSize={pageSize}
          search={search}
          filters={filters}
          onSearch={handleSearch}
          onFilterSubmission={onFilterSubmission}
          filterComponent={PaymentCollectionFilterComponent}
          pagination={{
            onPageChange: handleOnPageChange,
          }}
          isFetching={isFetching}
          onSort={handleSort}
        />
      </Suspense>
    </>
  )
})
export default PaymentCollections
