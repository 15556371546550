import React, { useState, useEffect } from "react"
import { Col, Input, Modal, Row } from "reactstrap"
import { isValid, validate } from "helpers/utilities"
import CustomTextField from "components/CustomTextField/index"

const UnassignUser = props => {
  const [data, setData] = useState(props.data || {})

  const handleChange = (v, k) => {
    setData({ ...data, [k]: v })
  }

  return (
    <Modal isOpen={true} toggle={props.onCancelAction} centered={true}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {props.title || "Unassign User"}
        </h5>
        <button
          type="button"
          disabled={props.submiting}
          onClick={() => {
            props.onCancelAction()
          }}
          className="btn-close"
        ></button>
      </div>
      <div className="modal-body">
        <p>Are you sure you want to unassign the user?</p>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          data-bs-dismiss="modal"
          disabled={props.submiting}
          onClick={() => {
            props.onCancelAction()
          }}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={props.submiting}
          className="btn btn-danger"
          onClick={e => props.onSubmitAction(data)}
        >
          Unassign
        </button>
      </div>
    </Modal>
  )
}
export default UnassignUser
