import React, { useState, useEffect } from "react"
import { Modal } from "reactstrap"

import CustomTextField from "components/CustomTextField/index"
import CustomSelect from "components/CustomSelect/index"
import SearchSelect from "components/CustomSearchSelect/index"
import {
  calculateLeadOwnerCommission,
  getLeadStatusesList,
} from "helpers/api_actions"
import { validate } from "helpers/utilities"
import { useToasts } from "react-toast-notifications"
import { TICKET_STATUS_OPTIONS } from "pages/Tickets/dataManager"
let required = ["status"]
const ManageStatus = props => {
  const [formData, setFormData] = useState(props.data || {})
  const [errs, setErrs] = useState({})
  const { addToast } = useToasts()
  const statuses = TICKET_STATUS_OPTIONS
  const handleChange = (v, k) => {
    const error = validate(required, { ...formData, [k]: v }, "")
    setFormData(f => ({ ...f, [k]: v }))
    if (Object.keys(errs).length) {
      setErrs(error)
    }
  }
  const handleSubmit = () => {
    const errors = validate(required, formData, "")
    setErrs(errors)
    if (Object.keys(errors).length === 0) {
      props.onSubmitAction(formData)
    }
  }

  return (
    <Modal isOpen={true} toggle={props.onCancelAction} centered={true}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          Change Ticket Status
        </h5>
        <button
          type="button"
          disabled={props.submiting}
          onClick={() => {
            props.onCancelAction()
          }}
          className="btn-close"
        ></button>
      </div>
      <div className="modal-body">
        <form>
          <div className=" mb-3">
            <CustomSelect
              label="Ticket Status"
              required
              options={statuses}
              setData={v => {
                handleChange(v, "status")
              }}
              value={formData?.status ?? null}
            />
            {errs?.status && <p className="text-danger"> {errs?.status}</p>}
          </div>
          <div className=" mb-3">
            <CustomTextField
              label="Remarks"
              name="note"
              type="textarea"
              // required
              value={formData?.note ?? ""}
              placeholder="Enter Remarks"
              onChange={(v, k, e) => handleChange(v, k, e)}
            />
          </div>
        </form>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          data-bs-dismiss="modal"
          disabled={props.submiting}
          onClick={() => {
            props.onCancelAction()
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          disabled={
            props.submiting ||
            (props?.data?.status?.value &&
              props?.data?.status?.value === formData?.status?.value)
          }
          className="btn btn-primary"
          onClick={e => handleSubmit(e)}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}
export default ManageStatus
