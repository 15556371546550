import React from "react"
import { useState } from "react"
import { Button, Col, Row } from "reactstrap"

import CustomCalander from "components/CustomCalendar"
import CustomSelect from "components/CustomSelect/index"
import { TICKET_STATUS_OPTIONS } from "./dataManager"
import SearchSelect from "components/CustomSearchSelect/index"
import { getCreUsers } from "helpers/dropdown_helper"
import { isAdmin } from "helpers/utilities"

const UserFilterComponent = props => {
  const [selectedCreatedOn, setSelectedCreatedOn] = useState(
    props.filters?.createdOn
  )
  const [selectedStatus, setSelectedStatus] = useState(props.filters?.status)
  const [handledBy, setHandledBy] = useState(props.filters?.handledBy)
  const statuses = TICKET_STATUS_OPTIONS
  const defaultSelection = null
  const onApplyFilter = () => {
    let filters = {
      createdOn: selectedCreatedOn,
      status: selectedStatus,
      handledBy: handledBy,
    }
    props.onFilterSubmission(filters)
  }
  const onCancelFilter = () => {
    setSelectedCreatedOn(undefined)
    props.onFilterSubmission({
      createdOn: undefined,
      status: undefined,
      handledBy: undefined,
    })
  }
  return (
    <>
      <Row>
        <Col lg={3} md={4} sm={6} xs={12}>
          <CustomSelect
            options={statuses}
            label="Status"
            setData={v => {
              setSelectedStatus(v)
            }}
            value={selectedStatus ?? defaultSelection}
          />
        </Col>
        {isAdmin() && (
          <Col lg={3} md={4} sm={6} xs={12}>
            <SearchSelect
              getData={getCreUsers}
              isMulti={false}
              label="Handled By"
              autoload={false}
              setData={v => {
                setHandledBy(v)
              }}
              value={handledBy ?? null}
            />
          </Col>
        )}
        <Col lg={3} md={4} sm={6} xs={12}>
          <CustomCalander
            dateRange
            maxDate={"today"}
            placeholder={"Start Date - End Date"}
            value={selectedCreatedOn}
            onChange={date => setSelectedCreatedOn(date)}
            label="Created Between"
          />
        </Col>
        <Col
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className="d-flex align-items-end justify-content-start pt-3"
        >
          <div className="me-2">
            <Button
              className="btn btn-sm btn-primary btn-success text-white"
              onClick={() => onApplyFilter()}
            >
              Apply
            </Button>
          </div>
          <div>
            <Button
              className="btn btn-sm btn-danger"
              onClick={() => onCancelFilter()}
            >
              Reset
            </Button>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default UserFilterComponent
