import classnames from "classnames"
import moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap"

import HeaderTitle from "components/Common/headerTitleComponent"
import CustomSpinner from "components/customSpinner"
import ManageNote from "components/Leads/manage/components/modal/manageNote"
import { BUTTON_LABELS, NAV_LABELS } from "constants/label"
import { AUTH_ROUTES } from "constants/routes"
import { DEFAULT_CLASSES } from "constants/styles"
import { changePresaleStatus, getPresaleDetails, postPresaleCreUserAssign, preSaleCreateFollowupNote, preSaleCreateNote } from "helpers/api_actions"
import { generateClassNameByValueForStatus, isAdmin, isCreUser } from "helpers/utilities"

import { PRESALE_TAB_LABELS } from "../dataManager"
import ManageCreUser from "../modals/manageCreUser"
import ManageStatus from "../modals/manageStatus"
import FollowUpNotes from "./tabs/followUpNotes/index"
import Notes from "./tabs/notes/index"
import PresaleDetailsView from "./tabs/presaleDetails/index"

const PresaleDetails = props => {
  const [activeTab, setActiveTab] = useState(PRESALE_TAB_LABELS[0].id)
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([])
  const param = useParams()
  const noteRef = useRef(null)
  const noteRef1 = useRef(null)
  const [edit, setEdit] = useState(
    window.location.href.includes("edit") ? true : false
  )
  const detailRef = useRef(null)
  const [submiting, setSubmiting] = useState(false)
  const [addNote, setAddNote] = useState(false)
  const [addFollowUpNote, setAddFollowUpNote] = useState(false)
  const { addToast } = useToasts()
  const [assignCreModalOpen, setAssignCreModalOpen] = useState(false)
  const [selectedList, setSelectedList] = useState([])
  const [creId, setCreId] = useState("")
  const [changeStatusData, setChangeStatusData] = useState({})
  const [openChangeStatus, setOpenChangeStatus] = useState(false)
  const [creData, setCreData] = useState({})
  const toggleCustom = tab => {
    if (activeTab !== tab) {
      fetchTabData(tab)
    }
  }
  const fetchTabData = tab => {
    setActiveTab(tab)
    setIsLoading(true)
    switch (tab) {
      case PRESALE_TAB_LABELS[0].id:
        setIsLoading(false)
        break
      case PRESALE_TAB_LABELS[1].id:
        setIsLoading(false)
        break
      case PRESALE_TAB_LABELS[2].id:
        setIsLoading(false)
        break
    }
  }

  const fetchPresaleDetails = () => {
    setIsLoading(true)
    getPresaleDetails(param.id)
      .then(res => {
        setData(res.data)
        setIsLoading(false)
        // setEdit(false)
      })
      .catch(e => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    fetchPresaleDetails()
  }, [])
  useEffect(() => {
    if (data?.cre_user?.id) {
      let details = {
        id: data?.cre_user?.id,
        cre_user_id: data?.cre_user?.id,
        value: data?.cre_user?.id,
        label: data?.cre_user?.full_name,
      }
      setCreData(details)
    }
  }, [data])
  const tags = [
    {
      label: data?.pre_sale_status?.name || "Unknown",
      className: generateClassNameByValueForStatus(data?.pre_sale_status?.name),
    },
    {
      label: data?.display_id || "n/a",
      className: "display_id",
    },
  ]
  const breadcrumb = [
    { label: NAV_LABELS.PRESALES, path: AUTH_ROUTES.PRESALES },
    {
      label: data?.name,
      path: `${AUTH_ROUTES.PRESALEDETAILS}${param.id}`,
    },
  ]
  const headerActions =
    data?.pre_sale_status?.name !== "Converted"
      ? !edit
        ? isAdmin()
          ? [
              {
                type: "button",
                label: BUTTON_LABELS.ADD_NOTE,
                className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
                action: () => {
                  setAddNote(true)
                },
                show: [PRESALE_TAB_LABELS[1].id],
              },
              {
                type: "button",
                label: BUTTON_LABELS.PRESALE_FOLLWOUP_NOTES,
                className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
                action: () => {
                  setAddFollowUpNote(true)
                },
                show: [PRESALE_TAB_LABELS[2].id],
              },
              {
                type: "button",
                label: BUTTON_LABELS.CHANGE_STATUS,
                className: DEFAULT_CLASSES.HEADER_BUTTON_PRIMARY,
                action: () => {
                  handleChangeStatus(true)
                },
                show: [
                  PRESALE_TAB_LABELS[0].id,
                  PRESALE_TAB_LABELS[1].id,
                  PRESALE_TAB_LABELS[2].id,
                ],
              },
              {
                type: "button",
                label: creData.id
                  ? BUTTON_LABELS.CHANGE_CRE_USER
                  : BUTTON_LABELS.ASSIGN_CRE_USER,
                className: DEFAULT_CLASSES.HEADER_BUTTON_PRIMARY,
                action: () => {
                  handleAssignCre()
                },
                show: [
                  PRESALE_TAB_LABELS[0].id,
                  PRESALE_TAB_LABELS[1].id,
                  PRESALE_TAB_LABELS[2].id,
                ],
              },
              {
                type: "button",
                label: BUTTON_LABELS.EDIT,
                className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
                action: () => {
                  setEdit(true)
                },
                show: [PRESALE_TAB_LABELS[0].id],
              },
            ]
          : isCreUser()
          ? [
              {
                type: "button",
                label: BUTTON_LABELS.ADD_NOTE,
                className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
                action: () => {
                  setAddNote(true)
                },
                show: [PRESALE_TAB_LABELS[1].id],
              },
              {
                type: "button",
                label: BUTTON_LABELS.PRESALE_FOLLWOUP_NOTES,
                className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
                action: () => {
                  setAddFollowUpNote(true)
                },
                show: [PRESALE_TAB_LABELS[2].id],
              },
              {
                type: "button",
                label: BUTTON_LABELS.CHANGE_STATUS,
                className: DEFAULT_CLASSES.HEADER_BUTTON_PRIMARY,
                action: () => {
                  handleChangeStatus(true)
                },
                show: [
                  PRESALE_TAB_LABELS[0].id,
                  PRESALE_TAB_LABELS[1].id,
                  PRESALE_TAB_LABELS[2].id,
                ],
              },
              {
                type: "button",
                label: BUTTON_LABELS.EDIT,
                className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
                action: () => {
                  setEdit(true)
                },
                show: [PRESALE_TAB_LABELS[0].id],
              },
            ]
          : []
        : isAdmin
        ? [
            {
              type: "button",
              label: BUTTON_LABELS.ADD_NOTE,
              className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
              action: () => {
                setAddNote(true)
              },
              show: [PRESALE_TAB_LABELS[1].id],
            },
            {
              type: "button",
              label: BUTTON_LABELS.PRESALE_FOLLWOUP_NOTES,
              className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
              action: () => {
                setAddFollowUpNote(true)
              },
              show: [PRESALE_TAB_LABELS[2].id],
            },
            {
              type: "button",
              label: BUTTON_LABELS.CHANGE_STATUS,
              className: DEFAULT_CLASSES.HEADER_BUTTON_PRIMARY,
              action: () => {
                handleChangeStatus(true)
              },
              show: [
                PRESALE_TAB_LABELS[0].id,
                PRESALE_TAB_LABELS[1].id,
                PRESALE_TAB_LABELS[2].id,
              ],
            },
            {
              type: "button",
              label: creData.id
                ? BUTTON_LABELS.CHANGE_CRE_USER
                : BUTTON_LABELS.ASSIGN_CRE_USER,
              className: DEFAULT_CLASSES.HEADER_BUTTON_PRIMARY,
              action: () => {
                handleAssignCre()
              },
              show: [
                PRESALE_TAB_LABELS[0].id,
                PRESALE_TAB_LABELS[1].id,
                PRESALE_TAB_LABELS[2].id,
              ],
            },
            {
              type: "button",
              label: BUTTON_LABELS.CANCEL,
              className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
              action: () => {
                setEdit(false)
                fetchPresaleDetails()
                navigateToDetails()
              },
              show: [PRESALE_TAB_LABELS[0].id],
            },
            {
              type: "button",
              label: BUTTON_LABELS.SAVE,
              className: DEFAULT_CLASSES.HEADER_BUTTON_PRIMARY,
              action: () => {
                detailRef.current.update()
              },
              show: [PRESALE_TAB_LABELS[0].id],
            },
          ]
        : isCreUser
        ? [
            {
              type: "button",
              label: BUTTON_LABELS.ADD_NOTE,
              className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
              action: () => {
                setAddNote(true)
              },
              show: [PRESALE_TAB_LABELS[1].id],
            },
            {
              type: "button",
              label: BUTTON_LABELS.PRESALE_FOLLWOUP_NOTES,
              className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
              action: () => {
                setAddFollowUpNote(true)
              },
              show: [PRESALE_TAB_LABELS[2].id],
            },
            {
              type: "button",
              label: BUTTON_LABELS.CHANGE_STATUS,
              className: DEFAULT_CLASSES.HEADER_BUTTON_PRIMARY,
              action: () => {
                handleChangeStatus(true)
              },
              show: [
                PRESALE_TAB_LABELS[0].id,
                PRESALE_TAB_LABELS[1].id,
                PRESALE_TAB_LABELS[2].id,
              ],
            },

            {
              type: "button",
              label: BUTTON_LABELS.CANCEL,
              className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
              action: () => {
                setEdit(false)
                fetchPresaleDetails()
              },
              show: [PRESALE_TAB_LABELS[0].id],
            },
            {
              type: "button",
              label: BUTTON_LABELS.SAVE,
              className: DEFAULT_CLASSES.HEADER_BUTTON_PRIMARY,
              action: () => {
                detailRef.current.update()
              },
              show: [PRESALE_TAB_LABELS[0].id],
            },
          ]
        : []
      : []

  const handleAssignCre = () => {
    setAssignCreModalOpen(true)
    let data = [param.id]
    setSelectedList(data)
  }
  const handleChangeStatus = () => {
    setOpenChangeStatus(true)
    setChangeStatusData(data)
  }
  const closeCreateNote = () => {
    setSubmiting(false)
    setAddNote(false)
    setAddFollowUpNote(false)
  }
  const handleSaveNote = data => {
    setSubmiting(true)
    preSaleCreateNote({ ...data, model: "PreSales", model_id: param.id })
      .then(res => {
        addToast(res?.message, { appearance: "success" })
        noteRef1.current.refetch()
        setSubmiting(false)
        setAddNote(false)
      })
      .catch(err => {
        setSubmiting(false)
        addToast(err.response?.data?.message, { appearance: "error" })
      })
  }
  const handleSaveFollowupNote = data => {
    const details = {
      title: data.title,
      note: data.note,
      pre_sales_id: param.id,
      followup_date: data.followup_date,
    }
    setSubmiting(true)
    preSaleCreateFollowupNote({
      ...details,
      title: data.title,
      note: data.note,
      pre_sales_id: param.id,
      followup_date: moment(data.followup_date[0]).format("YYYY-MM-DD"),
    })
      .then(res => {
        addToast(res?.message, { appearance: "success" })
        noteRef.current.refetch()
        setSubmiting(false)
        setAddFollowUpNote(false)
      })
      .catch(err => {
        setSubmiting(false)
        addToast(err.response?.data?.message, { appearance: "error" })
      })
  }
  const onSubmitAction = data => {
    let selectedItems = selectedList.length > 0 ? selectedList.join(",") : null

    let submitData = {
      select_all: false,
      include: selectedItems,
      exclude: [],
      cre_user_id: data?.cre_user_id?.id,
      action: "assignment",
    }
    onAssignSubmit(submitData)
  }
  function onAssignSubmit(data) {
    if (!data?.select_all && (!data?.include || data?.include.length < 1)) {
      addToast("Select atleast one user to assign", {
        appearance: "error",
      })
    } else {
      postPresaleCreUserAssign(data)
        .then(res => {
          setAssignCreModalOpen(false)
          setTimeout(() => {
            addToast(res?.message, { appearance: "success" })

            setTimeout(() => {
              window.location.reload()
            }, 2000)
          }, 500)
        })
        .catch(err => {
          addToast(generateErrorMessage(err?.response?.data), {
            appearance: "error",
          })
        })
    }
  }
  const onStatusChangeAction = data => {
    const presaleData = {
      pre_sales_statuses_id: data?.status?.id,
    }
    changePresaleStatus(param.id, presaleData)
      .then(res => {
        addToast(res?.message, { appearance: "success" })
        setOpenChangeStatus(false)
        setSubmiting(false)
        fetchPresaleDetails()
      })
      .catch(err => {
        setSubmiting(false)
        addToast(err.response?.data?.message, { appearance: "error" })
      })
  }
  const onCancelAction = () => {
    setOpenChangeStatus(false)
    setSubmiting(false)
  }
  const navigateToDetails = () => {
    setEdit(false)
    props.history.push({
      pathname: `${AUTH_ROUTES.PRESALEDETAILS}${param.id}`,
    })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {assignCreModalOpen && (
            <ManageCreUser
              assignCreModalOpen={assignCreModalOpen}
              setAssignCreModalOpen={setAssignCreModalOpen}
              onSubmitAction={onSubmitAction}
              selectedList={selectedList}
              setCreId={setCreId}
              data={creData}
            />
          )}
          {openChangeStatus && (
            <ManageStatus
              onSubmitAction={onStatusChangeAction}
              onCancelAction={onCancelAction}
              submiting={submiting}
              data={changeStatusData}
              isDetailsFlag={true}
            />
          )}
          {addNote && (
            <ManageNote
              submiting={submiting}
              onCancelAction={closeCreateNote}
              onSubmitAction={handleSaveNote}
            />
          )}
          {addFollowUpNote && (
            <ManageNote
              submiting={submiting}
              onCancelAction={closeCreateNote}
              onSubmitAction={handleSaveFollowupNote}
              followupFlag={true}
              title={"Create Followup Note"}
            />
          )}
          <Row>
            <HeaderTitle
              title={data?.name}
              tags={tags}
              loading={isLoading}
              activeTab={activeTab}
              breadcrumb={breadcrumb}
              actions={headerActions}
            />
          </Row>
          <Row>
            <React.Fragment>
              <Col lg="12">
                <Card className="m-0">
                  <CardBody className="p-0">
                    <Row>
                      <Col xs={12}>
                        <Nav tabs className="nav-tabs-custom ">
                          {PRESALE_TAB_LABELS.map((tabitem, tabkey) => (
                            <NavItem key={tabkey}>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: activeTab === tabitem.id,
                                })}
                                onClick={() => {
                                  toggleCustom(tabitem.id)
                                }}
                              >
                                <span className="">{tabitem.label}</span>
                              </NavLink>
                            </NavItem>
                          ))}
                        </Nav>
                      </Col>
                    </Row>

                    <TabContent activeTab={activeTab} className="text-muted">
                      {/* {isLoading ? (
                        <CustomSpinner />
                      ) : ( */}
                      <>
                        {/*PRESALE DETAILS*/}
                        <TabPane tabId={PRESALE_TAB_LABELS[0].id}>
                          <PresaleDetailsView
                            parentID={param.id}
                            data={data}
                            edit={edit}
                            ref={detailRef}
                            setSubmiting={setSubmiting}
                            refresh={fetchPresaleDetails}
                            navigateToDetails={navigateToDetails}
                            activeTab={activeTab}
                          />
                        </TabPane>
                        {/* NOTES */}
                        <TabPane tabId={PRESALE_TAB_LABELS[1].id}>
                          <Notes parentID={param.id} ref={noteRef1} />
                        </TabPane>
                        {/* FOLLOWUP NOTES */}
                        <TabPane tabId={PRESALE_TAB_LABELS[2].id}>
                          <FollowUpNotes parentID={param.id} ref={noteRef} />
                        </TabPane>
                      </>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </React.Fragment>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PresaleDetails
