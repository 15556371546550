import React from "react"

function TableCell({ children, colSpan, className, cellProps }) {
  return (
    <td
      className={className ?? ""}
      colSpan={colSpan ?? 1}
      style={{
        width: cellProps?.width ?? "auto",
        minWidth: cellProps?.minWidth ?? "auto",
        maxWidth: cellProps?.maxWidth ?? "auto",
      }}
    >
      {children}
    </td>
  )
}

export default TableCell
