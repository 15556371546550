import React, { useState, useEffect } from "react"
import { Col, Input, Modal, Row } from "reactstrap"

import CustomTextField from "components/CustomTextField/index"
import { validate } from "helpers/utilities"
import CustomSelect from "components/CustomSelect/index"
import { getPaymentUrl } from "helpers/api_actions"
let required = ["status"]

const ViewPaymentUrl = props => {
  const { leadData } = props
  const [apiData, setApiData] = useState(null);
  const [loading, setLoading] = useState(true);
  
  const fetchPaymentUrl = () => {
    getPaymentUrl(leadData.id)
    .then((response) => {
      setApiData(response.data); // Assuming your API response is an object with a 'data' property
    })
    .catch((error) => {
      console.error("Error fetching API data:", error);
    })
    .finally(() => {
      setLoading(false);
    });
  }
  useEffect(() => {
    fetchPaymentUrl()
  }, [])

  return (
    <Modal isOpen={true} toggle={props.onCancelAction} centered={true}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {props.title || "Payment Url"}
        </h5>
        <button
          type="button"
          disabled={props.submiting}
          onClick={() => {
            props.onCancelAction()
          }}
          className="btn-close"
        ></button>
      </div>
      <div className="modal-body">
      {loading ? (
          <p>Loading...</p>
        ) : (
          <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{apiData ? apiData : "Default Text"}</p>
        )}
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          data-bs-dismiss="modal"
          disabled={props.submiting}
          onClick={() => {
            props.onCancelAction()
          }}
        >
          Cancel
        </button>
       
      </div>
    </Modal>
  )
}
export default ViewPaymentUrl
