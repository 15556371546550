import React, { useState, useEffect } from "react"
import { Col, Input, Modal, Row } from "reactstrap"

import CustomTextField from "components/CustomTextField/index"
import CustomSelect from "components/CustomSelect/index"
import SearchSelect from "components/CustomSearchSelect/index"
import {
  calculateLeadOwnerCommission,
  getLeadStatusesList,
} from "helpers/api_actions"
import { validate } from "helpers/utilities"
import { useToasts } from "react-toast-notifications"
const required = ["lead_status"]
const ManageStatus = props => {
  const [formData, setFormData] = useState(props.data || {})
  const [statuses, setStatuses] = useState([])
  const [errs, setErrs] = useState({})
  const [canChangeLeadStatus, setCanChangeLeadStatus] = useState(false)
  const [commissionSubmitting, setCommissionSubmitting] = useState(false)
  const { addToast } = useToasts()
  const handleChange = (v, k) => {
    let requiredFields = required.slice()
    // if (
    //   formData?.lead_status?.label === "Converted" &&
    //   props.leadData?.service?.earning_process === "Normal Flow"
    // ) {
    //   requiredFields.push("amount_earned")
    //   if (props.leadData?.is_calculate_commission) {
    //     requiredFields.push("loan_amount")
    //   }
    // }
    const error = validate(requiredFields, { ...formData, [k]: v }, "")

    if (k === "lead_status" && v?.label !== "Converted") {
      setFormData(f => ({
        ...f,
        [k]: v,
        amount_earned: props?.leadData?.is_calculate_commission
          ? null
          : props.leadData?.Own_commision,
        loan_amount: null,
      }))
      setCanChangeLeadStatus(false)
    } else {
      setFormData(f => ({ ...f, [k]: v }))
    }
    if (Object.keys(errs).length) {
      setErrs(error)
    }
  }
  const handleSubmit = () => {
    let requiredFields = required.slice()
    // if (
    //   formData?.lead_status?.label === "Converted" &&
    //   props.leadData?.service?.earning_process === "Normal Flow"
    // ) {
    //   requiredFields.push("amount_earned")
    //   if (props.leadData?.is_calculate_commission) {
    //     requiredFields.push("loan_amount")
    //   }
    // }
    const errors = validate(requiredFields, formData, "")
    setErrs(errors)
    if (Object.keys(errors).length === 0) {
      props.onSubmitAction(formData)
    }
  }
  const getLeadStatus = () => {
    let selectStatement = "id,name"
    selectStatement += "&find[is_loan]=false"

    let payload = { select: selectStatement, paginate: false }

    getLeadStatusesList(payload).then(res => {
      let data = res?.data || []
      data.forEach(item => {
        item["label"] = item.name
        item["value"] = item.id
      })

      setStatuses(data)
    })
  }
  useEffect(() => {
    getLeadStatus()
  }, [])

  useEffect(() => {
    if (props?.leadData?.is_calculate_commission) {
      setFormData({
        ...formData,
        amount_earned: null,
      })
    } else {
      setFormData({
        ...formData,
        amount_earned: props.leadData?.Own_commision,
      })
    }
  }, [props?.leadData])

  function handleCalculateCommission() {
    if (
      props.leadData?.id &&
      formData?.loan_amount &&
      formData?.loan_amount > 0
    ) {
      let payload = {
        loan_amount: formData?.loan_amount,
      }
      setCommissionSubmitting(true)
      calculateLeadOwnerCommission(props.leadData?.id, payload)
        .then(res => {
          addToast("Commission fetched successfully", { appearance: "success" })
          setFormData({
            ...formData,
            amount_earned: res?.data?.lead_owner_commission,
          })
          setCanChangeLeadStatus(true)
          setCommissionSubmitting(false)
        })
        .catch(err => {
          addToast(err.response?.data?.message, { appearance: "error" })
          setCommissionSubmitting(false)
        })
    }
  }

  return (
    <Modal isOpen={true} toggle={props.onCancelAction} centered={true}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          Change Lead Status
        </h5>
        <button
          type="button"
          disabled={props.submiting}
          onClick={() => {
            props.onCancelAction()
          }}
          className="btn-close"
        ></button>
      </div>
      <div className="modal-body">
        <form>
          <div className=" mb-3">
            <CustomSelect
              label="Lead Status"
              required
              options={statuses}
              setData={v => {
                handleChange(v, "lead_status")
              }}
              value={formData?.lead_status ?? null}
            />
            {errs?.lead_status && (
              <p className="text-danger"> {errs?.lead_status}</p>
            )}
          </div>
          {/* {formData?.lead_status?.label === "Converted" &&
            props.leadData?.service?.earning_process === "Normal Flow" && (
              <>
                {props.leadData?.is_calculate_commission && (
                  <div
                    className={`mb-3 d-flex ${
                      errs?.loan_amount
                        ? "align-items-center"
                        : "align-items-end"
                    }`}
                  >
                    <div className="flex-grow-1">
                      <CustomTextField
                        label="Service Amount"
                        type="decimal"
                        required
                        placeholder="Enter Service Amount"
                        name="loan_amount"
                        value={formData?.loan_amount ?? ""}
                        onChange={(v, k, e) => handleChange(v, k, e)}
                      />
                      {errs?.loan_amount && (
                        <p className="text-danger"> {errs?.loan_amount}</p>
                      )}
                    </div>
                    <button
                      type="button"
                      className="btn btn-primary d-block mx-2"
                      disabled={
                        !formData?.loan_amount ||
                        formData?.loan_amount < 1 ||
                        commissionSubmitting
                      }
                      onClick={() => {
                        handleCalculateCommission()
                      }}
                    >
                      Calculate Commission
                    </button>
                  </div>
                )}
                <div className=" mb-3">
                  <CustomTextField
                    label="Lead Owner Commission"
                    name="amount_earned"
                    type="decimal"
                    required
                    value={formData?.amount_earned ?? null}
                    placeholder="Enter Lead Owner Commission"
                    onChange={(v, k, e) => handleChange(v, k, e)}
                  />
                  {errs?.amount_earned && (
                    <p className="text-danger"> {errs?.amount_earned}</p>
                  )}
                </div>
              </>
            )} */}
          <div className=" mb-3">
            <CustomTextField
              label="Remarks"
              name="lead_note"
              type="textarea"
              // required
              value={formData?.lead_note ?? ""}
              placeholder="Enter Remarks"
              onChange={(v, k, e) => handleChange(v, k, e)}
            />
          </div>
        </form>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          data-bs-dismiss="modal"
          disabled={props.submiting}
          onClick={() => {
            props.onCancelAction()
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          disabled={props.submiting}
          className="btn btn-primary"
          onClick={e => handleSubmit(e)}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}
export default ManageStatus
