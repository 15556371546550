import firebase from "firebase/app"

import "firebase/messaging"

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBjmdDkxy9-dREuPUv-kDwArz51hRtrM3c",
  authDomain: "upfin-admin-panel.firebaseapp.com",
  projectId: "upfin-admin-panel",
  storageBucket: "upfin-admin-panel.appspot.com",
  messagingSenderId: "382081021442",
  appId: "1:382081021442:web:1f933132e77add8248d91b",
}

firebase.initializeApp(firebaseConfig)

export const messaging = firebase.messaging()

export const getToken = async setTokenFound => {
  let currentToken = ""

  try {
    currentToken = await messaging.getToken({
      vapidKey:
        "BADKNiwlnga50IRh04nLuFvIgvmX3-WN028DjN-IIt-zgJWaecg1D40lJRVK4UtleUbsUpWo-YzhN17SYDJUKZA",
    })
    if (currentToken) {
      setTokenFound(true)
    } else {
      setTokenFound(false)
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error)
  }

  return currentToken
}

export const onMessageListener = () =>
  new Promise(resolve => {
    messaging.onMessage(payload => {
      resolve(payload)
    })
  })
