import { data } from "autoprefixer"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { BreadcrumbItem, Col, Row } from "reactstrap"
import React from "react"
const Breadcrumb = props => {
  return (
    <div className="d-flex">
     {props.loading?
     <>
      {[1,2].map((brd,i)=>(

   <div className={` skeleton-box text-transparent ${i===1?"ms-2":"mr-2"}`}  key={i} >loading...</div>

      ))}
      
     </>:
    <ol className="breadcrumb m-0">
     
   
      {props.data &&
        props.data.length > 0 &&
        props.data.map((britem, i) => (
          <BreadcrumbItem active={props.data.length - 1 === i} className={props.data.length - 1 === i?"link-default":""} key={i}>
            <Link to={props.data.length - 1 !== i ? britem.path : "#"}>
              {britem.label}
            </Link>
          </BreadcrumbItem>
        ))}
     
    </ol>}
    </div>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
}

export default Breadcrumb
