import CustomSpinner from "components/customSpinner"
import HeaderTitle from "components/Common/headerTitleComponent"
import { BUTTON_LABELS, HEADER_LABELS } from "constants/label"
import { AUTH_ROUTES } from "constants/routes"
import { DEFAULT_CLASSES } from "constants/styles"
import React, { Suspense, lazy, useRef } from "react"
import { withRouter } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import PropTypes from "prop-types"
import { isAdmin } from "helpers/utilities"
import LeadListing from "components/Leads/listing"

//Import Breadcrumb
const Users = props => {
  const listRef = useRef(null)
  const headerActions = isAdmin()
    ? [
        {
          type: "button",
          label: BUTTON_LABELS.CREATE_BUSINESS_LEAD,
          className: DEFAULT_CLASSES.HEADER_BUTTON_PRIMARY,
          action: () => {
            props.history.push(AUTH_ROUTES.CREATE_NEW_BUSINESS_LEAD)
          },
        },
        {
          type: "button",
          label: BUTTON_LABELS.EXPORT,
          className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
          action: () => {
            listRef.current.exportLeads()
          },
        },
      ]
    : []

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <HeaderTitle
              title={HEADER_LABELS.BUSINESS}
              actions={headerActions}
            />
          </Row>
          <Row>
            <Col lg="12">
              <Suspense fallback={<CustomSpinner />}>
                <LeadListing
                  leadType="Business Lead"
                  ref={listRef}
                  {...props}
                />
              </Suspense>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Users.propTypes = {
  history: PropTypes.object,
}

export default withRouter(Users)
