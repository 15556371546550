import { useFormik } from "formik"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
//redux
import { useDispatch, useSelector } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Alert, Card, CardBody, Col, Container, Form, FormFeedback, Input, InputGroup, Label, Row } from "reactstrap"
import OTPInput from "otp-input-react";
// Formik Validation
import * as Yup from "yup"

import logo from "../../assets/images/logo.svg"
// import images
import profile from "../../assets/images/profile-img.png"
// action
import { userForgetPassword } from "../../store/actions"
import { postForgotPwdReset, postForgotPwdVerify } from "helpers/api_actions"
import { useToasts } from "react-toast-notifications"
import CustomTextField from "components/CustomTextField/index"
import { generateErrorMessage } from "helpers/utilities"

const ResetPassworddPage = props => {
  //meta title
  document.title = "Reset Password | Upfin"

  const [ formData, setFormData ] = useState()
  const { addToast } = useToasts()

  const { forgetError, forgetSuccess } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccess: state.ForgetPassword.forgetSuccessMsg,
  }))
  const [forgetErrorMsg, setForgetErrorMsg] = useState(forgetError)
  const [forgetSuccessMsg, setForgetSuccessMsg] = useState(forgetSuccess)

  useEffect(() => {
    if (!props.location?.state?.phone || !props.location?.state?.token) {
        props.history.replace("/forgot-password")
    }
  }, [])

  const handleChange = (v, k) => {
    setFormData({ ...formData, [k]: v })
  }

  function handleFormSubmit(event) {
    event.preventDefault()

    const data = {
        phone: props.location.state.phone,
        token: props.location.state.token,
        password: formData?.password,
        confirm_password: formData?.confirm_password
    }

    postForgotPwdReset(data)
    .then(res => {
        addToast(res?.message, { appearance: "success" })
        props.history.replace("/login")
      })
      .catch(err => {
        addToast(generateErrorMessage(err?.response?.data), {
          appearance: "error",
        })
      })
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-softbg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Skote.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="80"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {forgetErrorMsg && forgetErrorMsg ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetErrorMsg}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}

<Form
                      className="form-horizontal"
                      onSubmit={handleFormSubmit}
                    >

                      <div className="mb-3">
                      <CustomTextField
                        label="New Password"
                        type="pin"
                        placeholder="Enter New Password"
                        name="password"
                        maxLength={4}
                        required
                        onChange={(v, k, e) => handleChange(v, k, e)}
                        />
                      </div>

                      <div className=" mb-3">
                            <CustomTextField
                            label="Confirm New Password"
                            name="confirm_password"
                            type="pin"
                            maxLength={4}
                            required
                            placeholder="Enter New Password"
                            onChange={(v, k, e) => handleChange(v, k, e)}
                            />
                        </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Reset
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              {/*<div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Skote. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Themesbrand
                </p>
                          </div>*/}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ResetPassworddPage.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ResetPassworddPage)
