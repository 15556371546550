import classnames from "classnames"
import React, { useEffect, useRef, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import {
  Button,
  Card,
  CardBody,
  CardText,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  CardTitle,
} from "reactstrap"

import HeaderTitle from "components/Common/headerTitleComponent"
import CustomSpinner from "components/customSpinner"
import {
  BUTTON_LABELS,
  MENU_LABELS,
  NAV_LABELS,
  STATUS_LABELS,
} from "constants/label"
import { AUTH_ROUTES } from "constants/routes"
import { DEFAULT_CLASSES } from "constants/styles"
import {
  changeCustomerSupportCreUser,
  changeCustomerSupportStatus,
  changeLeadStatus,
  createAttachment,
  createNote,
  getCustomerSupportDetails,
} from "helpers/api_actions"
import { generateClassNameByValue, isAdmin } from "helpers/utilities"
import CustomTextField from "components/CustomTextField"
import { CUSTOMER_SUPPORT_TAB_LABELS } from "./dataManager"
import ManageStatus from "./components/modals/manageStatus"
import Logs from "./components/tabs/logs/index"
import ManageCreUser from "./components/modals/manageCreUser"

const CustomerSupportDetails = props => {
  const state = props.history.state
  const [customerSupportData, setCustomerSupportData] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const param = useParams()
  const { addToast } = useToasts()
  const noteRef = useRef(null)
  const attachmentRef = useRef(null)
  const attachmentFieldRef = useRef(null)
  const detailRef = useRef(null)
  const [manageStatus, setManageStatus] = useState(false)
  const [manageCreUser, setManageCreUser] = useState(false)
  const [activeTab, setActiveTab] = useState(CUSTOMER_SUPPORT_TAB_LABELS[0].id)
  const [submiting, setSubmiting] = useState(false)
  const breadcrumb = [
    { label: NAV_LABELS.CUSTOMER_SUPPORT, path: AUTH_ROUTES.CUSTOMER_SUPPORT },
    {
      label: customerSupportData?.subject,
      path: `${AUTH_ROUTES.CUSTOMER_SUPPORT_DETAILS}${param.id}`,
    },
  ]
  const headerActions = [
    {
      type: "button",
      label: BUTTON_LABELS.CHANGE_STATUS,
      className: DEFAULT_CLASSES.HEADER_BUTTON_PRIMARY,
      action: () => {
        setManageStatus(true)
      },
      show: [CUSTOMER_SUPPORT_TAB_LABELS[0].id],
    },
    ...(isAdmin()
      ? [
          {
            type: "button",
            label: customerSupportData?.cre_user_id
              ? BUTTON_LABELS.CHANGE_CRE_USER
              : BUTTON_LABELS.ASSIGN_CRE_USER,
            className: DEFAULT_CLASSES.HEADER_BUTTON_PRIMARY,
            action: () => {
              setManageCreUser(true)
            },
            show: [CUSTOMER_SUPPORT_TAB_LABELS[0].id],
          },
        ]
      : []),
  ]

  const tags = [
    {
      label: customerSupportData?.status,
      className: generateClassNameByValue(customerSupportData?.status),
    },
  ]

  const toggleCustom = tab => {
    if (activeTab !== tab) {
      fetchTabData(tab)
    }
  }

  const fetchTabData = tab => {
    setActiveTab(tab)
    setIsLoading(true)
    switch (tab) {
      // CUSTOMER_SUPPORT_DETAILS
      case CUSTOMER_SUPPORT_TAB_LABELS[0].id:
        fetchCustomerSupportDetails()
        setIsLoading(false)
        break
      case CUSTOMER_SUPPORT_TAB_LABELS[1].id:
        setIsLoading(false)
        break
      default:
        break
    }
  }

  const fetchCustomerSupportDetails = () => {
    setIsLoading(true)
    getCustomerSupportDetails(param.id)
      .then(res => {
        setCustomerSupportData(
          res.data && res.data.length > 0 ? res.data[0] : {}
        )
        setIsLoading(false)
        setEdit(false)
      })
      .catch(e => {
        setIsLoading(false)
      })
  }
  useEffect(() => {
    fetchCustomerSupportDetails()
  }, [])

  const closeChangeStatus = () => {
    setSubmiting(false)
    setManageStatus(false)
  }
  const handleChangeStatus = data => {
    setSubmiting(true)
    let payload = {
      status: data?.status?.value,
      note: data?.note,
    }
    changeCustomerSupportStatus(payload, param.id)
      .then(res => {
        addToast(res?.message, { appearance: "success" })
        fetchCustomerSupportDetails()
        setSubmiting(false)
        setManageStatus(false)
      })
      .catch(err => {
        setSubmiting(false)
        addToast(err.response?.data?.message, { appearance: "error" })
      })
  }

  const closeChangeCreUser = () => {
    setSubmiting(false)
    setManageCreUser(false)
  }
  const handleChangeCreUser = data => {
    setSubmiting(true)
    let payload = {
      cre_user_id: data?.cre_user_id?.value,
    }
    changeCustomerSupportCreUser(payload, param.id)
      .then(res => {
        addToast(res?.message, { appearance: "success" })
        fetchCustomerSupportDetails()
        setSubmiting(false)
        setManageCreUser(false)
      })
      .catch(err => {
        setSubmiting(false)
        addToast(err.response?.data?.message, { appearance: "error" })
      })
  }

  return (
    <React.Fragment>
      {manageStatus && (
        <ManageStatus
          submiting={submiting}
          data={{
            status: {
              value: customerSupportData?.status,
              label: customerSupportData?.status,
            },
          }}
          activeStatus={customerSupportData?.status}
          onCancelAction={closeChangeStatus}
          onSubmitAction={handleChangeStatus}
        />
      )}
      {manageCreUser && isAdmin() && (
        <ManageCreUser
          submiting={submiting}
          data={{
            cre_user_id: customerSupportData?.cre_user_id
              ? {
                  value: customerSupportData?.cre_user_id,
                  label: customerSupportData?.cre_user,
                }
              : null,
          }}
          onCancelAction={closeChangeCreUser}
          onSubmitAction={handleChangeCreUser}
        />
      )}
      <div className="page-content">
        <Container fluid>
          <Row>
            <HeaderTitle
              title={customerSupportData?.subject}
              actions={headerActions}
              tags={tags}
              disabled={false}
              loading={isLoading}
              activeTab={activeTab}
              breadcrumb={breadcrumb}
            />
          </Row>
          <Row>
            <React.Fragment>
              <Col lg="12">
                <Card className="m-0">
                  <CardBody className="p-0">
                    <Row>
                      <Col xs={12}>
                        <Nav tabs className="nav-tabs-custom ">
                          {CUSTOMER_SUPPORT_TAB_LABELS.map(
                            (tabitem, tabkey) => (
                              <NavItem key={tabkey}>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: activeTab === tabitem.id,
                                  })}
                                  onClick={() => {
                                    toggleCustom(tabitem.id)
                                  }}
                                >
                                  <span className="">{tabitem.label}</span>
                                </NavLink>
                              </NavItem>
                            )
                          )}
                        </Nav>
                      </Col>
                    </Row>
                    <TabContent activeTab={activeTab} className="text-muted">
                      {isLoading ? (
                        <CustomSpinner />
                      ) : (
                        <>
                          <TabPane tabId={CUSTOMER_SUPPORT_TAB_LABELS[0].id}>
                            <div className="p-4">
                              <Row>
                                <Col lg="12">
                                  <CardTitle className="mt-0 mb-3">
                                    Basic Information
                                  </CardTitle>
                                  <Row>
                                    <Col md="4">
                                      <div className=" mb-3">
                                        <CustomTextField
                                          disabled={true}
                                          label="Subject"
                                          type="text"
                                          value={
                                            customerSupportData?.subject ?? ""
                                          }
                                        />
                                      </div>
                                    </Col>
                                    <Col md="2">
                                      <div className=" mb-3">
                                        <CustomTextField
                                          disabled={true}
                                          label="Type of Support"
                                          type="text"
                                          value={
                                            customerSupportData?.type_of_support ??
                                            ""
                                          }
                                        />
                                      </div>
                                    </Col>
                                    <Col md="6">
                                      <div className=" mb-3">
                                        <CustomTextField
                                          disabled={true}
                                          label="Message"
                                          type="textarea"
                                          value={
                                            customerSupportData?.message ?? ""
                                          }
                                        />
                                      </div>
                                    </Col>
                                    <Col md="6">
                                      <div className=" mb-3">
                                        <CustomTextField
                                          disabled={true}
                                          label="Remarks"
                                          type="textarea"
                                          value={
                                            customerSupportData?.note ?? ""
                                          }
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <CardTitle className="mt-0 mb-3">
                                    User Information
                                  </CardTitle>
                                  <Row>
                                    <Col md="4">
                                      <div className=" mb-3">
                                        <CustomTextField
                                          disabled={true}
                                          label="User name"
                                          type="text"
                                          value={
                                            customerSupportData?.full_name ?? ""
                                          }
                                        />
                                      </div>
                                    </Col>
                                    <Col md="2">
                                      <div className=" mb-3">
                                        <CustomTextField
                                          disabled={true}
                                          label="Phone"
                                          type="text"
                                          value={
                                            customerSupportData?.primary_phone ??
                                            ""
                                          }
                                        />
                                      </div>
                                    </Col>
                                    <Col md="6">
                                      <div className=" mb-3">
                                        <CustomTextField
                                          disabled={true}
                                          label="Email"
                                          type="text"
                                          value={
                                            customerSupportData?.email ?? ""
                                          }
                                        />
                                      </div>
                                    </Col>
                                    <Col md="4">
                                      <div className=" mb-3">
                                        <CustomTextField
                                          disabled={true}
                                          label="Handled By"
                                          type="text"
                                          value={
                                            customerSupportData?.cre_user ?? ""
                                          }
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </div>
                          </TabPane>
                          <TabPane tabId={CUSTOMER_SUPPORT_TAB_LABELS[1].id}>
                            <Logs parentID={param.id} />
                          </TabPane>
                        </>
                      )}
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </React.Fragment>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CustomerSupportDetails
