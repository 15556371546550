import React, { useEffect } from "react"
import Button from "react-bootstrap/Button"
import Toast from "react-bootstrap/Toast"
import ToastContainer from "react-bootstrap/ToastContainer"

function DismissibleExample(props) {
  useEffect(() => {
    const interval = setInterval(() => {
      props.onClose()
    }, 10000)
    return () => clearInterval(interval)
  }, [])
  return (
    <ToastContainer position={"top-end"} className="m-2">
      <Toast onClose={() => props.onClose()} show={true} animation={false}>
        <Toast.Header className="toast-header">
          <strong className="me-auto text-white">{props.data.title}</strong>
        </Toast.Header>
        <Toast.Body className="bg-white">{props.data.body}</Toast.Body>
      </Toast>
    </ToastContainer>
  )
}

export default DismissibleExample
