import { CHANGE_PRELOADER, CHANGE_SIDEBAR_THEME } from "./actionTypes"

export const changePreloader = layout => ({
  type: CHANGE_PRELOADER,
  payload: layout,
})

export const changeSidebarTheme = theme => ({
  type: CHANGE_SIDEBAR_THEME,
  payload: theme,
})
