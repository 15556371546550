// import * as EmailValidator from "email-validator";
import moment from "moment"

// export const FormValidate = (data, valid) => {
//   const error = {};
//   if (valid) {
//     valid.forEach((item) => {
//       if (typeof data[item] === "string" && data[item]) {
//         data[item] = data[item]?.trim();
//       }
//       if (
//         !data[item] ||
//         data[item] === null ||
//         data[item] === undefined ||
//         data[item] === ""
//       ) {
//         error[item] = true;
//       } else if (
//         data[item] &&
//         data[item] !== null &&
//         data[item] !== undefined &&
//         data[item] !== ""
//       ) {
//         delete error[item];
//       }
//     });
//   }
//   return error;
// };

// export const DynFormValidate = (data) => {
//   let dataerror = data;
//   if (dataerror) {
//     dataerror.forEach((item) => {
//       if (
//         (item.attribute_type_slug === "select" ||
//           item.attribute_type_slug === "multi_select") &&
//         item.is_mandatory
//       ) {
//         if (
//           !item.api_id ||
//           item.api_id === null ||
//           item.api_id === undefined ||
//           item.api_id === ""
//         ) {
//           item["error"] = true;
//         } else if (
//           item.api_id &&
//           item.api_id !== null &&
//           item.api_id !== undefined &&
//           item.api_id !== ""
//         ) {
//           item["error"] = false;
//         }
//       } else if (item.is_mandatory) {
//         if (
//           typeof item.api_value === "string" &&
//           item.api_value &&
//           item.attribute_type_slug !== "select" &&
//           item.attribute_type_slug !== "multi_select"
//         ) {
//           item.api_value = item.api_value.trim();
//         }
//         if (
//           !item.api_value ||
//           item.api_value === null ||
//           item.api_value === undefined ||
//           item.api_value === ""
//         ) {
//           item["error"] = true;
//         } else if (
//           item.api_value &&
//           item.api_value !== null &&
//           item.api_value !== undefined &&
//           item.api_value !== ""
//         ) {
//           item["error"] = false;
//         } else if (item.attribute_type_slug === "phone") {
//           // let regx = "^[0-9]{10,12}$";
//           if (
//             item.api_value !== null &&
//             item.api_value !== undefined &&
//             item.api_value !== ""
//           ) {
//             let regx =
//               /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
//             if (item.api_value.match(regx)) {
//               item["error"] = false;
//             } else {
//               item["error"] = true;
//               item["error_message"] =
//                 "Please enter a valid " + item.display_name;
//             }
//           } else {
//             item["error"] = true;
//             item["error_message"] = "Please enter a valid " + item.display_name;
//           }
//         }
//       }
//       if (
//         item.attribute_type_slug === "phone" &&
//         item.api_value &&
//         item.api_value !== null &&
//         item.api_value !== undefined &&
//         item.api_value !== ""
//       ) {
//         // let regx = "^[0-9]{10,12}$";
//         let regx =
//           /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
//         if (item.api_value.match(regx)) {
//           item["error"] = false;
//           item["error_message"] = "";
//         } else {
//           item["error"] = true;
//           item["error_message"] = "Please enter a valid " + item.display_name;
//         }
//       }
//       if (
//         item.attribute_type_slug === "currency" &&
//         item.api_id &&
//         item.api_id !== null &&
//         item.api_id !== undefined
//       ) {
//         item["error"] = false;
//         console.log("api value", item.api_value)
//         console.log("api api_id", item.api_id)
//         if (item.api_id.hasOwnProperty('original_value') && item.api_id['original_value'] > 0) {
//           if (item.api_id['original_currency'] === null ||
//             item.api_id['original_currency'] === undefined ||
//             item.api_id['original_currency'].trim() === '') {
//             console.log("item.api_id['original_currency']", item.api_id['original_currency']);

//             item["error"] = true;
//             item["error_message"] = "Please select a valid currency";
//           }
//           else {
//             item["error"] = false;
//           }
//         }
//         else if (item.api_id.hasOwnProperty('value') && item.api_id['value'] > 0) {
//           if (item.api_id['currency'] === null ||
//             item.api_id['currency'] === undefined ||
//             item.api_id['currency'].trim() === '') {
//             console.log("item.api_id['currency']", item.api_id['currency']);
//             item["error"] = true;
//             item["error_message"] = "Please select a valid currency";
//           }
//           else {
//             item["error"] = false;
//           }
//         }

//       }

//       if (
//         item.attribute_type_slug === "email" &&
//         item.api_value &&
//         item.api_value !== "" &&
//         item.api_value !== null &&
//         item.api_value !== undefined
//       ) {
//         //
//         if (typeof item.api_value === "string" && item.api_value) {
//           item.api_value = item.api_value.trim();
//         }
//         let erroremail = !EmailValidate(item.api_value);
//         item["error"] = erroremail;
//         item["error_message"] = "Please enter a valid " + item.display_name;
//       }
//       if (
//         (item.attribute_type_slug === "date" ||
//           item.attribute_type_slug === "date_time") &&
//         item.api_value
//       ) {
//         item["error"] = !isDate(item.api_value);
//         item["error_message"] = "Please enter a valid date"; // + item.display_name
//       }
//     });
//   }
//   return dataerror;
// };

export const isDate = function (date) {
  try {
    if (date instanceof Date) {
      return date.isValid()
    }
    return moment(date, "DD-MM-YYYY").isValid()
  } catch (error) {
    return false
  }

  // return new Date(date) !== "Invalid Date" && !isNaN(new Date(date));
}

// export const isDate = function (date) {
//   return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
// };

// export const EmailValidate = (value) => {
//   return EmailValidator.validate(value);
// };

export const parseQueryParams = (params = {}) => {
  if (!Object.entries(params).length) return ""
  return Object.entries(params).reduce(
    (acc, [key, value]) => acc + `${key}=${value}&`,
    "?"
  )
}
export const parseExpQueryParams = (params = {}) => {
  if (!Object.entries(params).length) return ""
  return Object.entries(params).reduce(
    (acc, [key, value]) => acc + `${key}=${value}&`,
    ""
  )
}

export const formatFormErrors = (params = {}) => {
  if (!Object.entries(params).length) return ""
  return Object.entries(params).reduce(
    (acc, [key, value]) => acc + `${key}=${value},`,
    ""
  )
}
export const FormatError = error => {
  var errorString = error.response.data.message

  if (
    typeof error.response.data.error === "object" &&
    !Array.isArray(error.response.data.error) &&
    error.response.data.error !== null
  ) {
    errorString = Object.keys(error.response.data.error)
      .map(key => {
        // return key + ": " + error.response.data.error[key];
        return error.response.data.error[key]
      })
      .join("\n")
  } else {
    errorString = error.response.data.message
  }
  return errorString
}
