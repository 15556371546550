import React, { useState, useEffect } from "react"
import { Modal } from "reactstrap"
import SearchSelect from "components/CustomSearchSelect/index"
import { validate } from "helpers/utilities"
import { useToasts } from "react-toast-notifications"
import { getCreUsers } from "helpers/dropdown_helper"
let required = ["cre_user_id"]
const ManageCreUser = props => {
  const [formData, setFormData] = useState(props.data || {})
  const [errs, setErrs] = useState({})
  const { addToast } = useToasts()
  const handleChange = (v, k) => {
    const error = validate(required, { ...formData, [k]: v }, "")
    setFormData(f => ({ ...f, [k]: v }))
    if (Object.keys(errs).length) {
      setErrs(error)
    }
  }
  const handleSubmit = () => {
    let errors = validate(required, formData, "")
    if (!formData?.cre_user_id?.value) {
      errors = {
        ...errors,
        cre_user_id: "Please enter details",
      }
    }
    setErrs(errors)
    if (Object.keys(errors).length === 0) {
      props.onSubmitAction(formData)
    }
  }

  return (
    <Modal isOpen={true} toggle={props.onCancelAction} centered={true}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {props.data?.cre_user_id ? "Change CRE User" : "Assign CRE User"}
        </h5>
        <button
          type="button"
          disabled={props.submiting}
          onClick={() => {
            props.onCancelAction()
          }}
          className="btn-close"
        ></button>
      </div>
      <div className="modal-body">
        <form>
          <div className=" mb-3">
            <SearchSelect
              getData={getCreUsers}
              isMulti={false}
              required
              label="CRE User"
              autoload={false}
              setData={v => {
                handleChange(v, "cre_user_id")
              }}
              value={formData.cre_user_id ?? null}
            />
            {errs?.cre_user_id && (
              <p className="text-danger"> {errs?.cre_user_id}</p>
            )}
          </div>
        </form>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          data-bs-dismiss="modal"
          disabled={props.submiting}
          onClick={() => {
            props.onCancelAction()
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          disabled={props.submiting}
          className="btn btn-primary"
          onClick={e => handleSubmit(e)}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}
export default ManageCreUser
