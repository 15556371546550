import { TAB_LABELS } from "constants/label"

export const CUSTOMER_SUPPORT_TAB_LABELS = [
  {
    id: "customer-support-details",
    label: TAB_LABELS.CUSTOMER_SUPPORT_DETAILS,
  },
  {
    id: "customer-support-logs",
    label: TAB_LABELS.CUSTOMER_SUPPORT_LOGS,
  },
]

export const CUSTOMER_SUPPORT_STATUS_OPTIONS = [
  { label: "New", value: "New" },
  { label: "Processing", value: "Processing" },
  { label: "Closed", value: "Closed" },
  { label: "Resolved", value: "Resolved" },
]
