import moment from "moment"
import React, { useEffect, useImperativeHandle, useRef } from "react"
import { useState } from "react"
import { forwardRef } from "react"
import { AiFillCloseCircle } from "react-icons/ai"
import { useParams } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import { Button, Card, CardBody, CardTitle, Col, Input, Row } from "reactstrap"

import uploadplaceholder from "assets/admin-images/upload-placeholder.png"
import CustomCalander from "components/CustomCalendar"
import SearchSelect from "components/CustomSearchSelect"
import CustomSelect from "components/CustomSelect"
import CustomTextField from "components/CustomTextField"
import {
  getCitiesList,
  getCountriesList,
  getCreUsersList,
  getKycDetails,
  getQualificationList,
  getJobList,
  getStatesList,
  getUserList,
  getUserRankList
} from "helpers/api_actions"
import {
  emailValidator,
  generateClassNameByValue,
  validate,
} from "helpers/utilities"
import UpiInfo from "pages/Users/manage/components/modal/upiinfo"

import AadharInfo from "../../manage/components/modal/aadharinfo"
import BankingInfo from "../../manage/components/modal/bankinginfo"
import PanInfo from "../../manage/components/modal/paninfo"

let required = [
  "full_name",
  "primary_phone",
  "state",
  "city",
  "country",
  "address_type",
]
let intervalCall = null
const UserManageSection = forwardRef((props, ref) => {
  const params = useParams()
  UserManageSection.displayName = "User Manage Section"
  var dobMax = new Date()
  dobMax.setFullYear(dobMax.getFullYear() - 18)
  const [formData, setFormData] = useState(props.data)
  const [kycDetails, setKycDetails] = useState()
  const [count, setCount] = useState(1)
  const [addPanOpen, setAddPanOpen] = useState(false)
  const [errors, setErrors] = useState({})
  const [qualifications, setQualifications] = useState([])
  const [job, setJob] = useState([])
  const [rank, setRank] = useState([])
  const [hasValidEmail, setHasValidEmail] = useState(true)
  const { addToast } = useToasts()

  const bankInfoRef = useRef()
  const upiInfoRef = useRef()
  useEffect(() => {
    // setFormData(props.data)
    getQualification()
    getJob()
    getRank()
  }, [])
  useEffect(() => {
    if (params.id) handleKycDetails(params.id)
  }, [params.id])
  const handleKycDetails = id => {
    getKycDetails(id).then(res => {
      setKycDetails(res.data)
      if (res.data?.is_kyc_verified !== "Processing") {
        // if (count > 1) {
        //   addToast("KYC verification failed please try again!", {
        //     appearance: "error",
        //   })
        // }
        clearInterval(intervalCall)
      } else {
        setCount(count + 1)
      }
    })
  }
  useEffect(() => {
    intervalCall = setInterval(() => {
      if (params.id) {
        handleKycDetails(params.id)
      }
    }, 10000)
    return () => {
      clearInterval(intervalCall)
    }
  }, [count])
  useImperativeHandle(ref, () => ({
    onSubmitAction(e) {
      const error = validate(required, formData, "")
      setErrors(error)
      setTimeout(() => {
        if (Object.keys(error).length === 0) {
          props.handleSubmit(formData)
        }
      }, 150)
    },
    onCancelAction(e) {
      setFormData(null)
      document.getElementById("profilePickViewer").src = uploadplaceholder
    },
  }))
  const handleChange = (v, k) => {
    const error = validate(required, { ...formData, [k]: v }, "")
    setFormData(f => ({
      ...f,
      [k]: v,
      ...(k === "country" && { state: null, city: null }),
      ...(k === "state" && { city: null }),
      ...(k === "state" && { city: null }),
    }))
    if (k === "email") {
      setHasValidEmail(emailValidator(v))
    }
    if (Object.keys(errors).length) {
      setErrors(error)
    }
  }
  const getCities = async v => {
    let payload = { select: "cities.name as name", include: "states" }
    let filter = `&search[cities.name]=${v}${
      formData?.state?.value
        ? "&filter[states.name]=" + formData?.state?.value
        : ""
    }`
    const res = await getCitiesList(payload, filter)
    const data = res?.data?.data || []
    data.forEach(item => {
      item["label"] = item.name
      item["value"] = item.name
    })
    return data
  }
  const getQualification = () => {
    let payload = { select: "id,name" }
    // let filter = `?filter[name]=${v}`
    getQualificationList(payload).then(res => {
      let data = res?.data?.data || []
      data.forEach(item => {
        item["label"] = item.name
        item["value"] = item.name
      })
      setQualifications(data)
    })
  }
  const getJob = () => {
    let payload = { select: "id,name" }
    // let filter = `?filter[name]=${v}`
    getJobList(payload).then(res => {
      let data = res?.data?.data || []
      data.forEach(item => {
        item["label"] = item.name
        item["value"] = item.name
      })
      setJob(data)
    })
  }
  const getRank = () => {
    let payload = { select: "id,name" }
    // let filter = `?filter[name]=${v}`
    getUserRankList(payload).then(res => {
      let data = res?.data?.data || []
      data.forEach(item => {
        item["label"] = item.name
        item["value"] = item.name
      })
      setRank(data)
    })
  }
  const getCountries = async v => {
    let payload = { select: "name" }
    let filter = `&filter[name]=${v}`
    const res = await getCountriesList(payload, filter)
    const data = res?.data?.data || []
    data.forEach(item => {
      item["label"] = item.name
      item["value"] = item.name
    })
    return data
  }
  const getStates = async v => {
    let payload = { select: "states.name as name" }
    let filter = `&filter[states.name]=${v}`
    const res = await getStatesList(payload, filter)
    const data = res?.data?.data || []
    data.forEach(item => {
      item["label"] = item.name
      item["value"] = item.name
    })
    return data
  }
  const getUsers = async v => {
    let payload = {
      select: `full_name,users.id as id,display_id&find[user_type.name]=PlatformUser&search[full_name]=${v}`,
      include: "user_type",
    }
    const res = await getUserList(payload)
    const data = res?.data?.data || []
    data.forEach(item => {
      item["label"] = `${item.full_name} (${item.display_id})`
      item["value"] = item.id
    })
    return data
  }
  const getCreUsers = async v => {
    let payload = {
      select: `users.id,users.full_name&search[full_name]=${v}`,
      include: "users",
    }
    const res = await getCreUsersList(payload)
    const data = res?.data?.data || []
    data.forEach(item => {
      item["label"] = item.full_name
      item["value"] = item.id
    })
    return data
  }
  const previewImage = img => {
    var oFReader = new FileReader()
    oFReader.readAsDataURL(img)

    oFReader.onload = function (oFREvent) {
      document.getElementById("profilePickViewer").src = oFREvent.target.result
    }
  }

  const handleImageChange = e => {
    let value = e.target.files[0]
    if (value.type.includes("image")) {
      setFormData({ ...formData, image: value })
    }
    previewImage(value)
  }
  function handleBankInfoToggle(event) {
    event.preventDefault()
    bankInfoRef.current.edit()
  }
  function handleUpiInfoToggle(event) {
    event.preventDefault()
    upiInfoRef.current.edit()
  }
  const handleCloseModel = (data, value) => {
    if (value === "success") {
      handleKycDetails(params.id)

      addToast(data?.message, { appearance: "success" })
      setAddPanOpen(false)
    } else if (value == "error") {
      addToast(data.response?.data?.message, { appearance: "error" })
    } else {
      setAddPanOpen(false)
    }
  }
  return (
    <Row style={{ overflow: "auto" }}>
      <Col lg="12">
        <Card className="m-0">
          <CardBody>
            <Row>
              <Col lg="9" className="">
                <CardTitle className="mt-0 mb-3">Basic Information</CardTitle>
                <Row>
                  <Col md="4">
                    <div className="mb-3">
                      <CustomTextField
                        label="Full Name"
                        required
                        disabled={props.isReadOnly}
                        placeholder="Enter Full Name"
                        type="text"
                        name="full_name"
                        onChange={(v, k, e) => handleChange(v, k, e)}
                        value={formData?.full_name ?? ""}
                      />
                      {errors?.full_name && (
                        <p className="text-danger"> {errors?.full_name}</p>
                      )}
                    </div>
                  </Col>
                  <Col md="4">
                    <div className=" mb-3">
                      <CustomTextField
                        label="Phone"
                        required
                        disabled={props.isReadOnly}
                        startAdorement="+91"
                        maxLength={10}
                        minLength={10}
                        placeholder="Enter Phone"
                        type="phone"
                        name="primary_phone"
                        onChange={(v, k, e) => handleChange(v, k, e)}
                        value={formData?.primary_phone ?? ""}
                      />
                      {errors?.primary_phone && (
                        <p className="text-danger"> {errors?.primary_phone}</p>
                      )}
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="mb-3">
                      <CustomTextField
                        label="Email"
                        disabled={props.isReadOnly}
                        placeholder="Enter Email ID"
                        type="email"
                        name="email"
                        onChange={(v, k, e) => handleChange(v, k, e)}
                        value={formData?.email ?? ""}
                      />
                      {(errors?.email || !hasValidEmail) && (
                        <p className="text-danger">
                          {!hasValidEmail
                            ? "Provide a valid email id"
                            : errors?.email}
                        </p>
                      )}
                    </div>
                  </Col>
                  <Col md="4">
                    <div className=" mb-3">
                      <CustomCalander
                        label="Date of Birth"
                        maxDate={dobMax}
                        disabled={props.isReadOnly}
                        placeholder="Select Date"
                        value={formData?.date_of_birth ?? ""}
                        onChange={d => handleChange(d, "date_of_birth")}
                      />
                      {errors?.date_of_birth && (
                        <p className="text-danger"> {errors?.date_of_birth}</p>
                      )}
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="mb-3">
                    <CustomSelect
                        label="Job or Profession"
                        // required
                        placeholder="Enter Job or Profession"
                        disabled={props.isReadOnly}
                        options={job}
                        value={formData?.job ?? null}
                        setData={data => handleChange(data, "job")}
                      />
                      {/* <CustomTextField
                        label="Job or Profession"
                        disabled={props.isReadOnly}
                        placeholder="Enter Job or Profession"
                        type="text"
                        name="job"
                        onChange={(v, k, e) => handleChange(v, k, e)}
                        value={formData?.job ?? ""}
                      /> */}
                      {errors?.job && (
                        <p className="text-danger"> {errors?.job}</p>
                      )}
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="mb-3">
                      <CustomTextField
                        label="Business Name"
                        disabled={props.isReadOnly}
                        placeholder="Enter Business Name"
                        type="text"
                        name="business_name"
                        onChange={(v, k, e) => handleChange(v, k, e)}
                        value={formData?.business_name ?? ""}
                      />
                      {errors?.business_name && (
                        <p className="text-danger"> {errors?.business_name}</p>
                      )}
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="mb-3">
                      <CustomSelect
                        label="Working as"
                        placeholder="Working as"
                        // required
                        disabled={props.isReadOnly}
                        options={[
                          { label: "Individual", value: "Individual" },
                          { label: "Non-Individual", value: "Non-Individual" },
                        ]}
                        value={formData?.working_as ?? null}
                        setData={data => handleChange(data, "working_as")}
                      />
                      {errors?.working_as && (
                        <p className="text-danger"> {errors?.working_as}</p>
                      )}
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="mb-3">
                      <CustomSelect
                        label="Qualification"
                        // required
                        placeholder="Qualification"
                        disabled={props.isReadOnly}
                        options={qualifications}
                        value={formData?.qualification ?? null}
                        setData={data => handleChange(data, "qualification")}
                      />
                      {errors?.working_as && (
                        <p className="text-danger"> {errors?.qualification}</p>
                      )}
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="mb-3">
                      <CustomSelect
                        label="Gender"
                        // required
                        placeholder="Gender"
                        disabled={props.isReadOnly}
                        options={[
                          { label: "Male", value: "Male" },
                          { label: "Female", value: "Female" },
                          { label: "Others", value: "Others" },
                        ]}
                        value={formData?.gender ?? null}
                        setData={data => handleChange(data, "gender")}
                      />
                      {errors?.working_as && (
                        <p className="text-danger"> {errors?.working_as}</p>
                      )}
                    </div>
                  </Col>
                </Row>
                <CardTitle className="mt-0 mb-3">Primary Address</CardTitle>
                <Row>
                  <Col md="4">
                    <div className="mb-3">
                      <CustomSelect
                        label="Address Type"
                        required
                        placeholder="Address Type"
                        disabled={props.isReadOnly}
                        options={[
                          { label: "Home", value: "Home" },
                          { label: "Office", value: "Office" },
                          { label: "Other", value: "Other" },
                        ]}
                        value={formData?.address_type ?? null}
                        setData={data => handleChange(data, "address_type")}
                      />
                      {errors?.address_type && (
                        <p className="text-danger"> {errors?.address_type}</p>
                      )}
                    </div>
                  </Col>
                  <Col md="8">
                    <div className=" mb-3">
                      <CustomTextField
                        disabled={props.isReadOnly}
                        label="Address Line 1"
                        placeholder="Enter Address Line 1"
                        type="text"
                        name="address_line_1"
                        onChange={(v, k, e) => handleChange(v, k, e)}
                        value={formData?.address_line_1 ?? ""}
                      />
                      {errors?.address_line_1 && (
                        <p className="text-danger"> {errors?.address_line_1}</p>
                      )}
                    </div>
                  </Col>
                  <Col md="8">
                    <div className="mb-3">
                      <CustomTextField
                        disabled={props.isReadOnly}
                        label="Address Line 2"
                        placeholder="Enter Address Line 2"
                        type="text"
                        name="address_line_2"
                        onChange={(v, k, e) => handleChange(v, k, e)}
                        value={formData?.address_line_2 ?? ""}
                      />
                      {errors?.address_line_2 && (
                        <p className="text-danger"> {errors?.address_line_2}</p>
                      )}
                    </div>
                  </Col>
                  <Col md="4">
                    <div className=" mb-3">
                      <SearchSelect
                        getData={getCountries}
                        isMulti={false}
                        disabled={props.isReadOnly}
                        label="Country"
                        required
                        autoload={true}
                        setData={v => {
                          handleChange(v, "country")
                        }}
                        value={formData?.country ?? null}
                        default
                      />
                      {errors?.country && (
                        <p className="text-danger"> {errors?.country}</p>
                      )}
                    </div>
                  </Col>

                  <Col md="4">
                    <div className=" mb-3">
                      <SearchSelect
                        getData={formData?.country ? getStates : null}
                        isMulti={false}
                        required
                        label="State"
                        disabled={
                          props.isReadOnly
                            ? true
                            : formData?.country?.value
                            ? false
                            : true
                        }
                        autoload={false}
                        setData={v => {
                          handleChange(v, "state")
                        }}
                        value={formData?.state ?? null}
                      />
                      {errors?.state && (
                        <p className="text-danger"> {errors?.state}</p>
                      )}
                    </div>
                  </Col>
                  <Col md="4">
                    <div className=" mb-3">
                      <SearchSelect
                        getData={formData?.state ? getCities : null}
                        isMulti={false}
                        required
                        label="City"
                        disabled={
                          props.isReadOnly
                            ? true
                            : formData?.state
                            ? false
                            : true
                        }
                        autoload={false}
                        setData={v => {
                          handleChange(v, "city")
                        }}
                        value={formData?.city ?? null}
                      />
                      {errors?.city && (
                        <p className="text-danger"> {errors?.city}</p>
                      )}
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="mb-3">
                      <CustomTextField
                        label="Pincode"
                        disabled={props.isReadOnly}
                        placeholder="Enter Pincode"
                        type="number"
                        name="pincode"
                        onChange={(v, k, e) => handleChange(v, k, e)}
                        value={formData?.pincode ?? ""}
                      />
                      {errors?.pincode && (
                        <p className="text-danger"> {errors?.pincode}</p>
                      )}
                    </div>
                  </Col>
                </Row>
                <CardTitle className="mt-0 mb-3">Other Data</CardTitle>
                <Row>
                  <Col md="4">
                    <div className="mb-3">
                      <CustomTextField
                        label="Nominee Name"
                        disabled={props.isReadOnly}
                        placeholder="Enter Nominee Name"
                        type="text"
                        name="nominee_name"
                        onChange={(v, k, e) => handleChange(v, k, e)}
                        value={formData?.nominee_name ?? ""}
                      />
                      {errors?.nominee_name && (
                        <p className="text-danger"> {errors?.nominee_name}</p>
                      )}
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="mb-3">
                      <CustomTextField
                        label="Nominee Relation"
                        disabled={props.isReadOnly}
                        placeholder="Enter Nominee Relation"
                        type="text"
                        name="nominee_relation"
                        onChange={(v, k, e) => handleChange(v, k, e)}
                        value={formData?.nominee_relation ?? ""}
                      />
                      {errors?.nominee_relation && (
                        <p className="text-danger">
                          {" "}
                          {errors?.nominee_relation}
                        </p>
                      )}
                    </div>
                  </Col>
                  <Col md="4">
                    <div className=" mb-3">
                      <CustomCalander
                        label="Nominee Date of Birth"
                        maxDate={dobMax}
                        disabled={props.isReadOnly}
                        placeholder="Select Date"
                        value={formData?.nominee_date_of_birth ?? ""}
                        onChange={d => handleChange(d, "nominee_date_of_birth")}
                      />
                      {errors?.nominee_date_of_birth && (
                        <p className="text-danger">
                          {" "}
                          {errors?.nominee_date_of_birth}
                        </p>
                      )}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className=" mb-3">
                      <SearchSelect
                        getData={getCreUsers}
                        menuPlacement={"top"}
                        isMulti={false}
                        disabled={props.isReadOnly}
                        placeholder="Search by Name or ID & Select"
                        label="Handled By"
                        setData={v => {
                          handleChange(v, "cre_user_id")
                        }}
                        value={formData?.cre_user_id ?? null}
                      />
                      {errors?.cre_user && (
                        <p className="text-danger"> {errors?.cre_user_id}</p>
                      )}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className=" mb-3">
                      <SearchSelect
                        getData={getUsers}
                        menuPlacement={"top"}
                        isMulti={false}
                        disabled={props.isReadOnly}
                        placeholder="Search by Name or ID & Select"
                        label="Invited By"
                        setData={v => {
                          handleChange(v, "refferer")
                        }}
                        value={formData?.refferer ?? null}
                      />
                      {errors?.refferer && (
                        <p className="text-danger"> {errors?.refferer}</p>
                      )}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className=" mb-3">
                    <CustomSelect
                        label="Rank"
                        placeholder="Select Rank"
                        disabled={props.isReadOnly}
                        options={rank}
                        value={formData?.rank ?? null}
                        setData={data => handleChange(data, "rank")}
                      />
                      {errors?.rank && (
                        <p className="text-danger"> {errors?.rank}</p>
                      )}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className=" mb-3">
                      <CustomTextField
                        label="Set Passcode"
                        placeholder="Enter Passcode"
                        type="pin"
                        disabled={props.isReadOnly}
                        maxLength={4}
                        mode={"number"}
                        name="password"
                        onChange={(v, k, e) => handleChange(v, k, e)}
                        value={formData?.password ?? ""}
                      />
                      {errors?.password && (
                        <p className="text-danger"> {errors?.password}</p>
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg="3">
                <div className="mb-3">
                  <CardTitle className="mt-0 mb-3">Profile Image</CardTitle>
                  <div className="imageUpload" id="imageupload">
                    <Input
                      className="form-control"
                      type="file"
                      accept="image/*"
                      // disabled={props.isReadOnly}
                      id="formFile"
                      onChange={handleImageChange}
                    />
                    <div className="defaultBlock">
                      <img
                        className="profile-image-preview"
                        src={
                          formData?.imageUrl
                            ? `${formData?.imageUrl}`
                            : uploadplaceholder
                        }
                        id="profilePickViewer"
                        // width={"100%"}
                        // height={"100%"}
                      />
                    </div>
                  </div>
                </div>
                {params.id && (
                  <CardTitle className="mt-0 mb-3">
                    KYC & Account Details
                  </CardTitle>
                )}
                {kycDetails?.is_kyc_verified === "false" && params.id && (
                  <Card className="custCard mb-2">
                    <CardBody>
                      <div className="headBox">
                        <label className="control-label">
                          PAN & Aadhar Card
                        </label>
                        <Button
                          className="btn btn-primary btnSml"
                          onClick={() => {
                            setAddPanOpen(true)
                          }}
                          // disabled={props.isReadOnly}
                        >
                          Add
                        </Button>
                      </div>
                      <div className="footBox">
                        <PanInfo
                          open={addPanOpen}
                          params={params}
                          handleClose={handleCloseModel}
                        />
                      </div>
                    </CardBody>
                  </Card>
                )}
                {kycDetails?.is_kyc_verified === "true" && params.id && (
                  <Card className="custCard mb-2">
                    <CardBody>
                      <div className="headBox">
                        <label className="control-label">Aadhar</label>
                        {kycDetails.aadhar?.length > 0 ? (
                          <span className="success">
                            <i className="bx bx-check-circle"></i>
                            {kycDetails.aadhar[0]?.verification_status}
                          </span>
                        ) : (
                          <span className="error-class">
                            <AiFillCloseCircle /> Failed
                          </span>
                        )}
                      </div>
                      <div className="headBox">
                        <label className="control-label">PAN Card</label>
                        {kycDetails.pan?.length > 0 ? (
                          <span className="success">
                            <i className="bx bx-check-circle"></i>
                            {kycDetails.pan[0]?.verification_status}
                          </span>
                        ) : (
                          <span className="error-class">
                            <AiFillCloseCircle /> Failed
                          </span>
                        )}
                      </div>
                      {kycDetails.pan?.length > 0 && (
                        <div className="footBox">
                          <AadharInfo id={kycDetails.pan[0]?.id} />
                          <p>
                            {moment(
                              kycDetails.pan[0]?.verification_date
                            ).format("DD-MM-yyyy")}
                          </p>
                        </div>
                      )}
                    </CardBody>
                  </Card>
                )}
                {kycDetails?.is_kyc_verified === "Processing" && (
                  <Card className="custCard mb-2">
                    <CardBody>
                      <div className="headBox">
                        <label className="control-label">
                          KYC Verification Under Process
                        </label>
                      </div>
                    </CardBody>
                  </Card>
                )}

                {params.id && (
                  <Card className="custCard mb-2">
                    <CardBody>
                      <div className="headBox">
                        <label className="control-label">Bank Account</label>
                        <Button
                          className="btn btn-primary btnSml"
                          onClick={handleBankInfoToggle}
                        >
                          Add
                        </Button>
                      </div>
                      <div className="footBox">
                        <BankingInfo
                          userId={params?.id}
                          bankStatusInfo={props?.data?.bank_account}
                          ref={bankInfoRef}
                          refetchUser={props?.refresh}
                          upiAccountsCount={bankInfoRef?.current?.getAccounts()}
                        />
                      </div>
                    </CardBody>
                  </Card>
                )}
                {params.id && (
                  <Card className="custCard mb-2">
                    <CardBody>
                      <div className="headBox">
                        <label className="control-label">UPI</label>
                        <Button
                          className="btn btn-primary btnSml"
                          onClick={handleUpiInfoToggle}
                        >
                          Add
                        </Button>
                      </div>
                      <div className="footBox">
                        <UpiInfo
                          userId={params?.id}
                          ref={upiInfoRef}
                          upiStatusInfo={props?.data?.upi}
                          refetchUser={props?.refresh}
                          bankAccountsCount={bankInfoRef?.current?.getAccounts()}
                        />
                      </div>
                    </CardBody>
                  </Card>
                )}
              </Col>
              <></>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
})

export default UserManageSection
