import { NAV_ICONS } from "constants/icons"
import { NAV_LABELS } from "constants/label"
import { AUTH_ROUTES } from "constants/routes"
import { isAdmin } from "helpers/utilities"

export const menuData = [
  {
    label: NAV_LABELS.DASHBOARD,
    icon: NAV_ICONS.DASHBOARD,
    path: AUTH_ROUTES.DASHBOARD,
    expand: false,
    border: true,
  },
  {
    label: NAV_LABELS.USERS_ASSOCIATES,
    icon: NAV_ICONS.USERS,
    path: AUTH_ROUTES.USERS,
    expand: false,
  },
  {
    label: NAV_LABELS.PAYOUTMANAGEMENT,
    icon: NAV_ICONS.PAYOUT,
    path: AUTH_ROUTES.PAYOUT,
    expand: false,
    border: true,
  },
  {
    label: NAV_LABELS.LEADS,
    icon: NAV_ICONS.LEADS,
    path: AUTH_ROUTES.LEADS,
    expand: false,
  },
  {
    label: NAV_LABELS.BUSINESS,
    icon: NAV_ICONS.BUSINESS,
    path: AUTH_ROUTES.BUSINESS,
    expand: false,
  },
  {
    label: NAV_LABELS.CONTACTS_MANAGEMENT,
    icon: NAV_ICONS.CONTACTS_MANAGEMENT,
    path: AUTH_ROUTES.CONTACTS_MANAGEMENT,
    expand: false,
  },
  {
    label: NAV_LABELS.PAYMENT_COLLECTION,
    icon: NAV_ICONS.PAYMENT_COLLECTION,
    path: AUTH_ROUTES.PAYMENT_COLLECTION,
    expand: false,
    border: true,
  },
  {
    label: NAV_LABELS.CUSTOMER,
    icon: NAV_ICONS.CUSTOMER,
    path: AUTH_ROUTES.CUSTOMER,
    border: true,
  },
  {
    label: NAV_LABELS.PRESALES,
    icon: NAV_ICONS.PRESALES,
    path: AUTH_ROUTES.PRESALES,
    border: false,
  },
  {
    label: NAV_LABELS.IMPORT_LOG,
    icon: NAV_ICONS.IMPORT_LOG,
    path: AUTH_ROUTES.IMPORT_LOG,
    border: true,
  },
  {
    label: NAV_LABELS.REPORTS,
    icon: NAV_ICONS.REPORTS,
    isAdminOnly: true,
    expand: false,
    path: "test",
    border: true,
    submenus: [
      {
        label: NAV_LABELS.REPORTS_SALES,
        icon: NAV_ICONS.SALES_REPORTS,
        path: AUTH_ROUTES.REPORTS_SALES,
      },
      {
        label: NAV_LABELS.REPORTS_SALES_USER,
        icon: NAV_ICONS.USER_SALES_REPORTS,
        path: AUTH_ROUTES.REPORTS_SALES_USER,
      },
      {
        label: NAV_LABELS.REPORTS_COMISSION,
        icon: NAV_ICONS.COMISSION_REPORTS,
        path: AUTH_ROUTES.REPORTS_COMISSION,
      },
      {
        label: NAV_LABELS.REPORTS_COMISSION_USER,
        icon: NAV_ICONS.USER_COMISSION_REPORTS,
        path: AUTH_ROUTES.REPORTS_COMISSION_USER,
      },
    ],
  },
  {
    label: NAV_LABELS.SETTINGS,
    icon: NAV_ICONS.SETTINGS,
    path: "test",
    isAdminOnly: true,
    expand: false,
    border: true,
    submenus: [
      {
        label: NAV_LABELS.CRE_USERS,
        icon: NAV_ICONS.CRE_USERS,
        path: AUTH_ROUTES.CRE_USERS,
      },
      {
        label: NAV_LABELS.SERVICE_CATEGORY,
        icon: NAV_ICONS.SERVICE_CATEGORY,
        path: AUTH_ROUTES.SERVICE_CATEGORY,
      },
      {
        label: NAV_LABELS.SERVICE_MANAGEMENT,
        icon: NAV_ICONS.SERVICE_MANAGEMENT,
        path: AUTH_ROUTES.SERVICE_MANAGEMENT,
      },
      {
        label: NAV_LABELS.COMMISSION_MANAGEMENT,
        icon: NAV_ICONS.COMMISSION_MANAGEMENT,
        path: AUTH_ROUTES.COMMISSION_MANAGEMENT,
      },
    ],
  },

  {
    label: NAV_LABELS.SUPPORT_REQUESTS,
    icon: NAV_ICONS.SUPPORT_REQUESTS,
    isAdminOnly: true,
    expand: false,
    path: "test",
    submenus: [
      {
        label: NAV_LABELS.CUSTOMER_SUPPORT,
        icon: NAV_ICONS.CUSTOMER_SUPPORT,
        path: AUTH_ROUTES.CUSTOMER_SUPPORT,
        expand: false,
      },
      {
        label: NAV_LABELS.PARTNERSHIP_REQUESTS,
        icon: NAV_ICONS.PARTNERSHIP_REQUESTS,
        path: AUTH_ROUTES.PARTNERSHIP_REQUESTS,
        expand: false,
      },
      {
        label: NAV_LABELS.TICKETS,
        icon: NAV_ICONS.TICKETS,
        path: AUTH_ROUTES.TICKETS,
        expand: false,
      },
    ],
  },
]

// export const menuData = [
//   {
//     label: NAV_LABELS.DASHBOARD,
//     icon: NAV_ICONS.DASHBOARD,
//     path: AUTH_ROUTES.DASHBOARD,
//     expand: false,
//   },
//   {
//     label: NAV_LABELS.CRE_USERS,
//     icon: NAV_ICONS.CRE_USERS,
//     path: AUTH_ROUTES.CRE_USERS,
//     isAdminOnly: true,
//     expand: false,
//   },
//   {
//     label: NAV_LABELS.USERS,
//     icon: NAV_ICONS.USERS,
//     path: AUTH_ROUTES.USERS,
//     expand: false,
//   },
//   {
//     label: NAV_LABELS.CUSTOMER,
//     icon: NAV_ICONS.CUSTOMER,
//     path: AUTH_ROUTES.CUSTOMER,
//   },
//   {
//     label: NAV_LABELS.LEADS,
//     icon: NAV_ICONS.LEADS,
//     path: AUTH_ROUTES.LEADS,
//     expand: false,
//   },
//   {
//     label: NAV_LABELS.BUSINESS,
//     icon: NAV_ICONS.BUSINESS,
//     path: AUTH_ROUTES.BUSINESS,
//     expand: false,
//   },
//   {
//     label: NAV_LABELS.CONTACTS_MANAGEMENT,
//     icon: NAV_ICONS.CONTACTS_MANAGEMENT,
//     path: AUTH_ROUTES.CONTACTS_MANAGEMENT,
//     expand: false,
//   },
//   {
//     label: NAV_LABELS.SERVICE_CATEGORY,
//     icon: NAV_ICONS.SERVICE_CATEGORY,
//     path: AUTH_ROUTES.SERVICE_CATEGORY,
//     isAdminOnly: true,
//     expand: false,
//   },
//   {
//     label: NAV_LABELS.SERVICE_MANAGEMENT,
//     icon: NAV_ICONS.SERVICE_MANAGEMENT,
//     path: AUTH_ROUTES.SERVICE_MANAGEMENT,
//     isAdminOnly: true,
//     expand: false,
//   },
//   {
//     label: NAV_LABELS.COMMISSION_MANAGEMENT,
//     icon: NAV_ICONS.COMMISSION_MANAGEMENT,
//     path: AUTH_ROUTES.COMMISSION_MANAGEMENT,
//     isAdminOnly: true,
//     expand: false,
//   },
//   {
//     label: NAV_LABELS.PAYOUT,
//     icon: NAV_ICONS.PAYOUT,
//     path: AUTH_ROUTES.PAYOUT,
//     expand: false,
//   },
//   {
//     label: NAV_LABELS.CUSTOMER_SUPPORT,
//     icon: NAV_ICONS.CUSTOMER_SUPPORT,
//     path: AUTH_ROUTES.CUSTOMER_SUPPORT,
//     expand: false,
//   },
//   {
//     label: NAV_LABELS.PARTNERSHIP_REQUESTS,
//     icon: NAV_ICONS.PARTNERSHIP_REQUESTS,
//     path: AUTH_ROUTES.PARTNERSHIP_REQUESTS,
//     expand: false,
//   },
//   {
//     label: NAV_LABELS.TICKETS,
//     icon: NAV_ICONS.TICKETS,
//     path: AUTH_ROUTES.TICKETS,
//     expand: false,
//   },
//   {
//     label: NAV_LABELS.REPORTS,
//     icon: NAV_ICONS.REPORTS,
//     isAdminOnly: true,
//     expand: false,
//     path: "test",
//     submenus: [
//       {
//         label: NAV_LABELS.REPORTS_SALES,
//         icon: NAV_ICONS.SALES_REPORTS,
//         path: AUTH_ROUTES.REPORTS_SALES,
//       },
//       {
//         label: NAV_LABELS.REPORTS_COMISSION,
//         icon: NAV_ICONS.COMISSION_REPORTS,
//         path: AUTH_ROUTES.REPORTS_COMISSION,
//       },
//     ],
//   },
// ]
