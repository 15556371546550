import CustomSpinner from "components/customSpinner"
import HeaderTitle from "components/Common/headerTitleComponent"
import { HEADER_LABELS, BUTTON_LABELS } from "constants/label"
import { AUTH_ROUTES } from "constants/routes"
import { DEFAULT_CLASSES } from "constants/styles"
import React, { Suspense, lazy, useRef, useState } from "react"
import { withRouter, useParams } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import PropTypes from "prop-types"
import { useToasts } from "react-toast-notifications"
import { getPlatformUsersListExport, postCreUserAssign } from "helpers/api_actions"

//Import Breadcrumb
const UserListing = lazy(() => import("./manage/components/assignUserList"))
const AssignUsers = props => {
    const params = useParams()
    const { addToast } = useToasts()
  const [pageNumber, setPageNumber] = useState(1)
  const [pgSize, setPgSize] = useState(15)
  const [searchData, setSearchData] = useState("")
  const [sortBy, setSortBy] = useState("&sort_by[users.full_name]=ASC")
  const [filterData, setFilterData] = useState("")
  const listRef = useRef()
  const exportUsers = () => {
    let basicData = {
      select: `users.full_name as Name,users.primary_phone as Phone,user_addresses.city as City,LeadCount,TeamCount,my_earnings,team_earnings,Requestcount,users.status as Status,platform_users.updated_at as Updated On,platform_users.created_at as Created On`,
      include: `users,ranks,user_addresses,users as cre_user&page=${pageNumber}&per_page=${pgSize}&export=true`,
    }
    getPlatformUsersListExport(basicData, searchData, filterData, sortBy)
      .then(res => {
        const outputFilename = `${Date.now()}-Users.xlsx`

        // If you want to download file automatically using link attribute.
        var blob = new Blob([res])

        var link = document.createElement("a")
        link.href = window.URL.createObjectURL(blob)
        link.download = outputFilename

        document.body.appendChild(link)

        link.click()
        document.body.removeChild(link)
      })
      .catch(err => {
        setIsFetching(false)
      })
  }

  const headerActions = [
    {
      type: "button",
      label: BUTTON_LABELS.ASSIGN_USERS,
      className: DEFAULT_CLASSES.HEADER_BUTTON_PRIMARY,
      action: () => {
        listRef?.current?.onAssign()
      },
    },
  ]

  function handleAssignSubmit(data, appliedFilters, appliedSeach)
  {
    if (!data?.select_all && (!data?.include || data?.include.length < 1))
    {
      addToast("Select atleast one user to assign", {
        appearance: "error",
      })
    } else {
      let payload = {
        ...data,
        cre_user_id: params.id,
        action: 'assignment'
    }

    postCreUserAssign(payload, appliedSeach, appliedFilters)
        .then(res => {
          addToast(res?.message, { appearance: "success" })
          props.history.push({
            pathname: `${AUTH_ROUTES.CRE_USER_DETAILS}${params.id}`,
            state: { edit: false },
          })
        })
        .catch(err => {
          // toastr.error(err.response.data.message)
          addToast(generateErrorMessage(err?.response?.data), {
            appearance: "error",
          })
        })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <HeaderTitle title={HEADER_LABELS.USERS} actions={headerActions} />
          </Row>
          <Row>
            <Col lg="12">
              <Suspense fallback={<CustomSpinner />}>
                <UserListing
                  {...props}
                  setPageNumber={setPageNumber}
                  setPgSize={setPgSize}
                  setSearchData={setSearchData}
                  setFilterData={setFilterData}
                  setSortBy={setSortBy}
                  ref={listRef}
                  onAssignSubmit={handleAssignSubmit}
                />
              </Suspense>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

AssignUsers.propTypes = {
  history: PropTypes.object,
}

export default withRouter(AssignUsers)
