import React from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import moment from "moment"
import ReactHtmlParser from "react-html-parser"
function LogCard({ data = {} }) {
  const getAddOns = key => {
    let value = "--"
    if (data?.additional_data) {
      let addon = JSON.parse(data.additional_data)
      value = addon[key] || "--"
    }
    return value
  }
  return (
    <div className="w-100 my-2 log-card">
      <Row className="bg-white white-card">
        <Col xs={2} className="left">
          <div className="d-flex flex-column justify-content-center h-100">
            <div className="label">Created on </div>
            <div className="label">
              {data?.created_at
                ? moment(data.created_at).format("DD-MMM-YYYY hh:mm a")
                : "--"}{" "}
            </div>
            <div className="value">{data?.full_name ?? "--"}</div>
          </div>
        </Col>
        <Col xs={10} className="right">
          <div className="d-flex flex-column align-items-between ">
            <div className="py-1 event d-flex align-items-center text-primary">
              {data?.log_title ?? "--"}
            </div>
            <div className="py-1 d-flex align-items-center desc">
              <p className="m-0 ">
                {data?.log_description ? (
                  <div> {ReactHtmlParser(data.log_description)}</div>
                ) : (
                  "--"
                )}
              </p>
            </div>
            {data?.model == "Lead" && (
              <div className="py-1  d-flex align-items-center foot">
                <div>Lead Owner: </div>
                <div className="px-1 val">{getAddOns("Lead Owner")}</div>{" "}
                <span>&#x2022;</span> &nbsp;<div>Lead Status: </div>
                <div className="px-1 val">{getAddOns("Lead Status")}</div>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default LogCard
