import React, { useEffect, useImperativeHandle } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Row,
} from "reactstrap"
import DatepickerComponent from "components/Common/DatepickerComponent"
import TextfieldComponent from "components/Common/TextfieldComponent"
import SelectfieldComponent from "components/Common/SelectfieldComponent"
import PanInfo from "../../manage/components/modal/paninfo"
import BankingInfo from "../../manage/components/modal/bankinginfo"
import uploadplaceholder from "assets/admin-images/upload-placeholder.png"
import AadharInfo from "../../manage/components/modal/aadharinfo"
import CustomCalander from "components/CustomCalendar"
import CustomTextField from "components/CustomTextField"
import { useState } from "react"
import CustomSelect from "components/CustomSelect"
import { getCitiesList, getServiceCategoryList } from "helpers/api_actions"
import SearchSelect from "components/CustomSearchSelect"
import { validate } from "helpers/utilities"
import { forwardRef } from "react"
import CustomCheckbox from "components/CustomCheckbox/index"
let required = ["name", "description", "sort_order"]
const UserManageSection = forwardRef((props, ref) => {
  UserManageSection.displayName = "User Manage Section"
  const [formData, setFormData] = useState(props.data)
  const [errs, setErrs] = useState({})
  useEffect(() => {
    setFormData(props.data)
  }, [props.data])
  useImperativeHandle(ref, () => ({
    onSubmitAction(e) {
      const errors = validate(required, formData, "")
      setErrs(errors)
      if (Object.keys(errors).length === 0) {
        props.handleSubmit(formData)
      }
    },
    onCancelAction(e) {
      setFormData(null)
      document.getElementById("image_file").src = uploadplaceholder
      document.getElementById("icon_file").src = uploadplaceholder
    },
  }))
  const handleChange = (v, k) => {
    const error = validate(required, { ...formData, [k]: v }, "")
    setFormData(f => ({
      ...formData,
      [k]: v,
    }))
    if (Object.keys(errs).length) {
      setErrs(error)
    }
  }

  const getParentCategories = async v => {
    let payload = {
      select:
        "service_categories.id,service_categories.name as Category Name,parent.name as Parent Category,Services Mapped,service_categories.description as Description,service_categories.status as Status,service_categories.display_id as  Category ID,service_categories.created_at as Created On,service_categories.updated_at as Updated On",
      include: `service_categories as parent `,
    }
    let filter = `&search[service_categories.name]=${v}`
    const res = await getServiceCategoryList(payload, filter)
    const data = props.data?.id
      ? res?.data?.data?.filter(d => d.id !== props.data.id)
      : res?.data?.data || []
    data.forEach(item => {
      item["label"] = `${item["Category Name"]} (${item["Category ID"]})`
      item["value"] = item.id
    })
    return data
  }

  const previewImage = (name, img) => {
    var oFReader = new FileReader()
    oFReader.readAsDataURL(img)

    oFReader.onload = function (oFREvent) {
      document.getElementById(name).src = oFREvent.target.result
    }
  }

  const handleImageChange = e => {
    let name = e.target.name
    let value = e.target.files[0]
    if (value.type.includes("image")) {
      setFormData({ ...formData, [name]: value })
    }
    previewImage(name, value)
  }
  return (
    <Row>
      <Col lg="12">
        <Card className="m-0 detailBox">
          <CardBody>
            <Row>
              <Col lg="9" className="">
                <CardTitle className="mt-0 mb-3">Basic Information</CardTitle>
                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <CustomTextField
                        label="Category Name"
                        required
                        disabled={props.isReadOnly}
                        placeholder="Enter Category Name"
                        type="text"
                        name="name"
                        onChange={(v, k, e) => handleChange(v, k, e)}
                        value={formData?.name ?? ""}
                      />
                      {errs?.name && (
                        <p className="text-danger"> {errs?.name}</p>
                      )}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <SearchSelect
                        getData={getParentCategories}
                        isMulti={false}
                        disabled={props.isReadOnly}
                        clearable
                        // required
                        label="Parent Category"
                        setData={v => {
                          handleChange(v, "parent")
                        }}
                        value={formData?.parent ?? null}
                      />
                    </div>
                  </Col>

                  <Col md="12">
                    <div className="mb-3">
                      <CustomTextField
                        label="Description"
                        required
                        disabled={props.isReadOnly}
                        placeholder="Enter Description"
                        type="textarea"
                        name="description"
                        onChange={(v, k, e) => handleChange(v, k, e)}
                        value={formData?.description ?? ""}
                      />
                      {errs?.description && (
                        <p className="text-danger"> {errs?.description}</p>
                      )}
                    </div>
                  </Col>

                  <Col md="3">
                    <div className="mt-4">
                      <CustomCheckbox
                        label="Featured"
                        disabled={props.isReadOnly}
                        name="is_featured"
                        onChange={(v, k, e) => handleChange(v, k, e)}
                        value={formData?.is_featured ?? false}
                      />
                      {errs?.is_featured && (
                        <p className="text-danger"> {errs?.is_featured}</p>
                      )}
                    </div>
                  </Col>

                  <Col md="6">
                    <div className="mb-3">
                      <CustomTextField
                        required
                        label="Sort Order"
                        disabled={props.isReadOnly}
                        placeholder="Enter Sort Order"
                        type="number"
                        name="sort_order"
                        onChange={(v, k, e) => handleChange(v, k, e)}
                        value={formData?.sort_order ?? null}
                      />
                      {errs?.sort_order && (
                        <p className="text-danger"> {errs?.sort_order}</p>
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg="3">
                <div className="mb-3">
                  <CardTitle className="mt-0 mb-3 small">
                    Category Icon
                  </CardTitle>
                  <div className="image-icon" id="imageupload">
                    <Input
                      className={`form-control ${
                        props.isReadOnly ? "d-none" : ""
                      }`}
                      type="file"
                      accept="image/*"
                      name="icon_file"
                      // disabled={props.isReadOnly}
                      id="formFile"
                      onChange={handleImageChange}
                    />
                    <div className="defaultBlock">
                      <img
                        className="profile-image-preview"
                        src={
                          formData?.icon_file
                            ? `${formData?.icon_file}`
                            : uploadplaceholder
                        }
                        id="icon_file"
                        // width={"100%"}
                        // height={"100%"}
                      />
                    </div>
                  </div>
                </div>

                <div className="mb-3">
                  <CardTitle className="mt-0 mb-3 small">
                    Category Background Image
                  </CardTitle>
                  <div className="image-icon" id="imageupload">
                    <Input
                      className={`form-control ${
                        props.isReadOnly ? "d-none" : ""
                      }`}
                      type="file"
                      name="image_file"
                      accept="image/*"
                      // disabled={props.isReadOnly}
                      id="formFile"
                      onChange={handleImageChange}
                    />
                    <div className="defaultBlock">
                      <img
                        className="profile-image-preview"
                        src={
                          formData?.image_file
                            ? `${formData?.image_file}`
                            : uploadplaceholder
                        }
                        id="image_file"
                        // width={"100%"}
                        // height={"100%"}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
})

export default UserManageSection
