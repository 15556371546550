import PropTypes from "prop-types"
import React from "react"
const SelectfieldComponent = props => {
  return (
    <div className="formSection">
      <label className="control-label">{props.label}</label>
      <select defaultValue="0" className="form-select">
        <option value="0">Select</option>
        <option value="1">One</option>
        <option value="2">Two</option>
        <option value="3">Three</option>
      </select>
    </div>
  )
}

SelectfieldComponent.propTypes = {
  label: PropTypes.string,
}

export default SelectfieldComponent
