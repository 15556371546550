import React from "react"
import { Link } from "react-router-dom"
import { Col, Container, Row, Button } from "reactstrap"

import Breadcrumb from "components/Common/Breadcrumb"

import UserManageSection from "../component/common/manageSection"
import { useRef, useEffect } from "react"
import { getLeadTypeList, postLeadCreate } from "helpers/api_actions"
import { useState } from "react"
import moment from "moment"
import { useToasts } from "react-toast-notifications"
import {
  emailValidator,
  generateErrorMessage,
  isValid,
  validate,
} from "helpers/utilities"
import { AUTH_ROUTES } from "constants/routes"
import LeadManagement from "components/Leads/lead_form"

// Pages Components

const CreateGeneralLead = props => {
  const { addToast } = useToasts()
  const userRef = useRef(null)
  const [submitting, setsubmitting] = useState(false)
  const [leadTypes, setLeadTypes] = useState([])
  const [formData, setFormData] = useState({
    address_type: { label: "Home", value: "Home" },
    country: { label: "India", value: "India" },
  })
  const getLeadType = async v => {
    let payload = {
      select: "id,name",
    }

    const res = await getLeadTypeList(payload)
    const data = res?.data?.data || []
    let activeSelection = {}
    data.forEach(item => {
      if (item.name === "General Lead") {
        activeSelection["label"] = item.name
        activeSelection["value"] = item.id
      }
      item["label"] = item.name
      item["value"] = item.id
    })
    setLeadTypes(data)
    setFormData({ ...formData, lead_type: activeSelection })
  }
  useEffect(() => {
    getLeadType()
  }, [])
  const handleSubmit = data => {
    setsubmitting(true)

    if (
      (data.primary_phone && data.primary_phone.length) ||
      (data.primary_email && data.primary_email.length)
    ) {
      if (
        data.primary_email &&
        data.primary_email.length &&
        !emailValidator(data?.primary_email)
      ) {
        setsubmiting(false)
        addToast("Please enter a Valid Email", { appearance: "error" })
      } else if (data.primary_phone && data.primary_phone.length !== 10) {
        setsubmiting(false)
        addToast("Phone number should have 10 digits", {
          appearance: "error",
        })
      } else {
        let payload = new FormData()
        if (isValid(data?.lead_type)) {
          payload.append("lead_type_id", data?.lead_type?.value)
        }
        if (isValid(data?.primary_email)) {
          payload.append("primary_email", data?.primary_email)
        }
        if (isValid(data?.name)) {
          payload.append("name", data?.name)
        }
        if (isValid(data?.owner)) {
          payload.append("owner", data?.owner?.value)
        }
        if (isValid(data?.primary_phone)) {
          payload.append("primary_phone", `+91${data?.primary_phone}` ?? "")
        }
        if (isValid(data?.service)) {
          payload.append("service_id", data?.service?.value)
        }

        if (isValid(data?.address_type)) {
          payload.append("address_type", data?.address_type?.value ?? "")
        }
        if (isValid(data?.address_line_1)) {
          payload.append("address_line_1", data?.address_line_1 ?? "")
        }
        if (isValid(data?.address_line_2)) {
          payload.append("address_line_2", data?.address_line_2 ?? "")
        }
        if (isValid(data?.city?.label)) {
          payload.append("city", data?.city?.label ?? "")
        }
        if (isValid(data?.state?.label)) {
          payload.append("state", data?.state?.label ?? "")
        }
        if (isValid(data?.country?.label)) {
          payload.append("country", data?.country?.label ?? "")
        }
        if (isValid(data?.pincode)) {
          payload.append("pincode", data?.pincode ?? "")
        }
        if (isValid(data?.refferer?.value)) {
          payload.append("refferer_id", data?.refferer?.value ?? "")
        }
        if (isValid(data?.job)) {
          payload.append("job", data?.job ?? "")
        }
        if (isValid(data?.business_name)) {
          payload.append("business_name", data?.business_name ?? "")
        }

        postLeadCreate(payload)
          .then(res => {
            setsubmitting(false)
            addToast(res?.message, { appearance: "success" })
            props.history.push(AUTH_ROUTES.LEADS)
          })
          .catch(err => {
            setsubmitting(false)
            // toastr.error(err.response.data.message)
            addToast(generateErrorMessage(err?.response?.data), {
              appearance: "error",
            })
          })
      }
    } else {
      setsubmiting(false)
      addToast("Please enter primary Email/Phone", { appearance: "error" })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <div className="page-title-left">
                  <Breadcrumb title="Users" breadcrumbItem="Create User" />
                  <h4 className="mb-0 font-size-18">Create Lead</h4>
                </div>
                <div className="page-title-right">
                  <Button
                    className="btn btn-secondary"
                    disabled={submitting}
                    onClick={() => {
                      props.history.push(AUTH_ROUTES.LEADS)
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn btn-primary"
                    onClick={() => {
                      userRef.current && userRef.current.onSubmitAction()
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <form
            onSubmit={e => userRef.current && userRef.current.onSubmitAction(e)}
          >
            <LeadManagement
              ref={userRef}
              leadTypes={leadTypes}
              data={formData}
              isReadOnly={false}
              handleSubmit={handleSubmit}
            />
          </form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CreateGeneralLead
