import moment from "moment"
import React from "react"
import { useEffect, useRef } from "react"
import { useState } from "react"
import { Link } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import { Button, Col, Container, Row } from "reactstrap"

import Breadcrumb from "components/Common/Breadcrumb"
import { AUTH_ROUTES } from "constants/routes"
import { getUserTypeList, postServiceCreate } from "helpers/api_actions"
import {
  emailValidator,
  generateErrorMessage,
  isValid,
  validate,
} from "helpers/utilities"

import UserManageSection from "../component/common/manageSection"

// Pages Components

const CreateUser = props => {
  const { addToast } = useToasts()
  const userRef = useRef(null)
  const [submiting, setSubmiting] = useState(false)
  const [userTypeId, setUserTypeId] = useState("")
  const [data, setData] = useState({})
  const fetchCurrentUserType = () => {
    let payload = { select: "id" }
    let filter = "&find[name]=PlatformUser&paginate=false"
    getUserTypeList(payload, filter).then(res => {
      let id = res.data[0]?.id
      setUserTypeId(id)
    })
  }
  useEffect(() => {
    fetchCurrentUserType()
  }, [])
  const handleSubmit = data => {
    // setData(data)
    setSubmiting(true)
    let payload = new FormData()
    if (isValid(data?.name)) {
      payload.append("name", data?.name)
    }
    if (isValid(data?.service_category?.value)) {
      payload.append("service_category_id", data?.service_category?.value ?? "")
    }

    if (isValid(data?.commision_note)) {
      payload.append("commision_note", data?.commision_note ?? "")
    }

    if (data?.is_paid === true)
    {
      payload.append("is_paid", true)
      payload.append("start_amount", data?.start_amount)
    } else {
      payload.append("is_paid", false)
      payload.append("start_amount", null)
    }

    if (isValid(data?.is_popular)) {
      payload.append("is_popular", data?.is_popular)
    }

    if (isValid(data?.sort_order)) {
      payload.append("sort_order", data?.sort_order)
    }

    if (isValid(data?.process)) {
      payload.append("process", data?.process ?? "")
    }

    if (isValid(data?.long_description)) {
      payload.append("long_description", data?.long_description ?? "")
    }
    if (isValid(data?.short_description)) {
      payload.append("short_description", data?.short_description ?? "")
    }
    if (isValid(data?.video_url)) {
      payload.append("video_url", data?.video_url)
    }
    if (isValid(data?.image)) {
      payload.append("image", data?.image ?? "")
    }
    if (isValid(data?.terms_and_conditions)) {
      payload.append("terms_and_conditions", data?.terms_and_conditions ?? "")
    }
    if (isValid(data?.documents_required)) {
      payload.append("documents_required", data?.documents_required ?? "")
    }
    if (isValid(data?.pricing_information)) {
      payload.append("pricing_information", data?.pricing_information ?? "")
    }
    if (isValid(data?.attachement)) {
      Object.keys(data?.attachement).map((key, i) => {
        payload.append(`attachments[${i}]`, data?.attachement[key] ?? "")
      })
    }

    postServiceCreate(payload)
      .then(res => {
        addToast(res?.message, { appearance: "success" })
        props.history.push(AUTH_ROUTES.SERVICE_MANAGEMENT)
        setSubmiting(false)
      })
      .catch(err => {
        // toastr.error(err.response.data.message)
        setSubmiting(false)
        addToast(generateErrorMessage(err?.response?.data), {
          appearance: "error",
        })
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <div className="page-title-left">
                  <Breadcrumb title="Users" breadcrumbItem="Create User" />
                  <h4 className="mb-0 font-size-18">Create Service</h4>
                </div>
                <div className="page-title-right">
                  <Button
                    className="btn btn-secondary"
                    onClick={() => {
                      props.history.push(AUTH_ROUTES.SERVICE_MANAGEMENT)
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn btn-primary"
                    disabled={submiting}
                    onClick={() => {
                      userRef.current && userRef.current.onSubmitAction()
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <form
            onSubmit={e => userRef.current && userRef.current.onSubmitAction(e)}
          >
            <UserManageSection
              ref={userRef}
              data={data}
              isReadOnly={false}
              handleSubmit={handleSubmit}
            />
          </form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CreateUser
