import React, { useState, useEffect } from "react"
import { Col, Input, Modal, Row } from "reactstrap"

import CustomTextField from "components/CustomTextField/index"
import { validate } from "helpers/utilities"
import CustomSelect from "components/CustomSelect/index"
import { getPayoutModesList } from "helpers/api_actions"
let required = ["status"]
const ChangePaymentStatus = props => {
  const [data, setData] = useState(props.data || {})
  const [errors, setErrors] = useState({})
  const [paymentModes, setPaymentModes] = useState([])

  const handleChange = (v, k) => {
    let requiredFields = required.slice()
    if (data?.status?.value === "Paid") {
      requiredFields = [...requiredFields, "transaction_number", "mode_payment"]
    }
    let error = validate(requiredFields, { ...data, [k]: v }, "")
    setData(d => ({ ...d, [k]: v }))
    if (Object.keys(error).length) {
      setErrors(error)
    } else {
      setErrors({})
    }
  }

  const handleSubmit = () => {
    let requiredFields = required.slice()
    if (data?.status?.value === "Paid") {
      requiredFields = [...requiredFields, "transaction_number", "mode_payment"]
    }
    let error = validate(requiredFields, data, "")
    if (Object.keys(error).length === 0) {
      let submitData = {
        status: data?.status?.value,
        remark: data?.remark,
        transaction_number: data?.transaction_number,
        mode_payment: data?.mode_payment?.value,
        amount_paid: data?.amount_paid,
      }
      props.onSubmitAction(submitData)
    } else {
      setErrors(error)
    }
  }

  function getPaymentModes() {
    let payload = { select: "id,name" }
    getPayoutModesList(payload).then(res => {
      let transferModesData = res?.data?.data || []
      if (transferModesData && transferModesData.length) {
        transferModesData.forEach(item => {
          item["label"] = item.name
          item["value"] = item.name
        })
        setPaymentModes(transferModesData)
      }
    })
  }

  useEffect(() => {
    setData(data => {
      return {
        ...data,
        amount_paid: props.leadData?.payment_amount,
      }
    })
    getPaymentModes()
  }, [])

  return (
    <Modal isOpen={true} toggle={props.onCancelAction} centered={true}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {props.title || "Change Payment Status"}
        </h5>
        <button
          type="button"
          disabled={props.submiting}
          onClick={() => {
            props.onCancelAction()
          }}
          className="btn-close"
        ></button>
      </div>
      <div className="modal-body">
        <form>
          <div className="mb-3">
            <CustomSelect
              label="Status"
              placeholder="Enter Status"
              name="status"
              value={data?.status ?? null}
              options={[
                { value: "Paid", label: "Paid" },
                { value: "Cancel", label: "Cancel" },
              ]}
              required
              onChange={value => handleChange(value, "status")}
            />
            {errors?.status && <p className="text-danger"> {errors?.status}</p>}
          </div>
          <div className="mb-3">
            <CustomTextField
              label="Remarks"
              type="textarea"
              placeholder="Enter Remarks"
              name="remark"
              value={data?.remark ?? ""}
              onChange={(v, k, e) => handleChange(v, k, e)}
            />
            {errors?.remark && <p className="text-danger"> {errors?.remark}</p>}
          </div>
          {data?.status?.value === "Paid" && (
            <>
              <div className="mb-3">
                <CustomTextField
                  label="Transaction ID"
                  placeholder="Enter Transaction ID"
                  name="transaction_number"
                  required
                  value={data?.transaction_number ?? ""}
                  onChange={(v, k, e) => handleChange(v, k, e)}
                />
                {errors?.transaction_number && (
                  <p className="text-danger"> {errors?.transaction_number}</p>
                )}
              </div>
              <div className="mb-3">
                <CustomSelect
                  label="Mode of Payment"
                  placeholder="Enter Mode of Payment"
                  name="mode_payment"
                  value={data?.mode_payment ?? null}
                  options={paymentModes}
                  required
                  onChange={value => handleChange(value, "mode_payment")}
                />
                {errors?.mode_payment && (
                  <p className="text-danger"> {errors?.mode_payment}</p>
                )}
              </div>
              <div className="mb-3">
                <CustomTextField
                  label="Amount Paid"
                  placeholder="Enter Amount Paid"
                  name="amount_paid"
                  disabled={true}
                  value={data?.amount_paid ?? ""}
                  onChange={(v, k, e) => handleChange(v, k, e)}
                />
                {errors?.amount_paid && (
                  <p className="text-danger"> {errors?.amount_paid}</p>
                )}
              </div>
            </>
          )}
        </form>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          data-bs-dismiss="modal"
          disabled={props.submiting}
          onClick={() => {
            props.onCancelAction()
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          disabled={props.submiting}
          className="btn btn-primary"
          onClick={e => handleSubmit()}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}
export default ChangePaymentStatus
