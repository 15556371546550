import moment from "moment"
import React, { useRef, useImperativeHandle, forwardRef } from "react"
import UserDetailsComponent from "../../../../component/common/manageSection"
import { updateServiceCreate } from "../../../../../../helpers/api_actions"
import { useToasts } from "react-toast-notifications"
import {
  generateErrorMessage,
  validate,
} from "../../../../../../helpers/utilities"

const ServiceDetails = forwardRef((props, ref) => {
  const { addToast } = useToasts()
  const editRef = useRef(null)

  useImperativeHandle(ref, () => ({
    update(e) {
      editRef.current.onSubmitAction()
    },
  }))

let final_service_status = null;
if (props?.data?.is_service_status === true)
{
if (props?.data?.service_status && props?.data?.service_status?.length > 0)
{
  let final_status = props?.data?.service_status.find((statusData) => statusData.is_final === true);
  if (final_status)
  {
    final_service_status = {
      ...final_status,
      label: final_status?.service_lead_status,
      value: final_status?.id,
    }
  } else {
    final_service_status = {
      ...props?.data?.service_status[0],
      label: props?.data?.service_status[0]?.service_lead_status,
      value: props?.data?.service_status[0]?.id,
    }
  }
}
}

  let data = {
    id: props.data.id,
    name: props.data?.name,
    long_description: props.data?.long_description,
    short_description: props.data?.short_description,
    process: props.data?.process,
    imageUrl: props.data?.image,
    is_paid: props.data?.is_paid,
    start_amount: props.data?.start_amount,
    is_popular: props.data?.is_popular,
    sort_order: props.data?.sort_order,
    commision_note: props.data?.commision_note,
    video_url: props.data?.video_url,
    terms_and_conditions: props.data?.terms_and_conditions,
    pricing_information: props.data?.pricing_information,
    documents_required: props.data?.documents_required,
    attachment: props.data?.attachments,

    service_category: {
      label: props?.data?.service_category?.name,
      value: props?.data?.service_category?.id,
    },
    is_service_status: props?.data?.is_service_status,
  }

  if (props?.data?.is_service_status === true)
  {
    data = {
      ...data,
      final_service_status: final_service_status,
      earning_process: {
        label: props?.data?.earning_process,
        value: props?.data?.earning_process,
      },
      service_status: props?.data?.service_status,
    }
  }

  const handleSubmit = data => {
    props.setSubmiting(true)
    let datas = {
      service_category_id: data?.service_category?.value,
      _method: "PUT",
      name: data?.name,
      long_description: data?.long_description ?? null,
      short_description: data?.short_description ?? null,
      process: data?.process ?? null,
      commision_note: data?.commision_note ?? null,
      video_url: data?.video_url ?? null,
      terms_and_conditions: data.terms_and_conditions ?? null,
      pricing_information: data?.pricing_information ?? null,
      attachment: data?.attachment ?? null,
      documents_required: data?.documents_required ?? null,
      is_paid: data?.is_paid ?? false,
      start_amount: data?.start_amount ?? null,
      is_popular: data?.is_popular ?? false,
      sort_order: data?.sort_order ?? null,
    }
    if (data?.image)
    {
      datas['image'] = data?.image
    }
    if (props?.data?.is_service_status === true)
    {
      datas = {
        ...datas,
        earning_process: data?.earning_process?.value,
      }

          datas = {
            ...datas,
            final_service_status: data?.final_service_status?.value,
          }
       
    }
    const form = new FormData()
    Object.keys(datas)?.forEach((key, value) => {
      if (key !== "attachement") {
        form.append([key], datas[key])
      }
    })
    // Object.keys(datas?.attachment ?? {}).map((key, i) => {
    //   form.append(`attachments[${i}]`, data?.attachment[key] ?? "")
    // })
    updateServiceCreate(data?.id, form)
      .then(res => {
        addToast(res?.message, { appearance: "success" })
        props.fetchServiceDetails()
        props.setEdit(false)
        props.setSubmiting(false)
      })
      .catch(err => {
        // toastr.error(err.response.data.message)
        props.setSubmiting(false)
        addToast(generateErrorMessage(err?.response?.data), {
          appearance: "error",
        })
      })
  }
  return (
    <div>
      <UserDetailsComponent
        isReadOnly={!props.edit}
        handleSubmit={handleSubmit}
        data={data}
        ref={editRef}
      />
    </div>
  )
})
ServiceDetails.displayName = "ServiceDetails"

export default ServiceDetails
