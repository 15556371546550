import React from "react"
import { forwardRef, useImperativeHandle, useRef } from "react"
import { useToasts } from "react-toast-notifications"

import { updateServiceCategory } from "../../../../../../helpers/api_actions"
import { generateErrorMessage, validate } from "../../../../../../helpers/utilities"
import ServiceCategoryComponent from "../../../../component/common/manageSection"

const ServiceCategory = forwardRef((props, ref) => {
  const { addToast } = useToasts()

  const editRef = useRef(null)
  useImperativeHandle(ref, () => ({
    update(e) {
      editRef.current.onSubmitAction()
    },
  }))
  ServiceCategory.displayName = "Service Category Details"
  const data = {
    id: props?.data?.id,
    name: props.data?.name,
    icon_file: props?.data?.icon,
    image_file: props?.data?.image,
    description: props?.data?.description,
    is_featured: props?.data?.is_featured,
    sort_order: props?.data?.sort_order,
    parent: {
      value: props?.data?.parent_category_id,
      label: props?.data?.parent_category_name,
    },
  }
  const handleSubmit = data => {
props.setSubmiting(true)
      const datas = {
        name: data?.name,
        id: data?.id,
        _method: "PUT",
        description: data?.description ?? null,
        is_featured: data?.is_featured ?? false,
        sort_order: data?.sort_order ?? null,
        parent_category_id: data.parent?.value ?? null,
      }
      if (data?.image_file)
      {
        datas['image_file'] = data?.image_file
      }
      if (data?.icon_file)
      {
        datas['icon_file'] = data?.icon_file
      }
      const form = new FormData()
      Object.keys(datas)?.forEach((key, value) => {
          form.append([key], datas[key])
      })
      updateServiceCategory(data?.id, form)
        .then(res => {
          addToast(res?.message, { appearance: "success" })
          props.setEdit(false)
          props.setSubmiting(false)
          props.fetchUserDetails()
        })
        .catch(err => {
          props.setSubmiting(false)
          addToast(generateErrorMessage(err?.response?.data), { appearance: "error" })
        })
   
  }
  return (
    <div>
      <ServiceCategoryComponent
        isReadOnly={!props.edit}
        data={data}
        handleSubmit={handleSubmit}
        ref={editRef}
      />
    </div>
  )
})

export default ServiceCategory
