import moment from "moment"
import React from "react"
import { useEffect, useRef } from "react"
import { useState } from "react"
import { Link } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import { Button, Col, Container, Row } from "reactstrap"

import Breadcrumb from "components/Common/Breadcrumb"
import { AUTH_ROUTES } from "constants/routes"
import { getUserTypeList, postCreUserCreate } from "helpers/api_actions"
import { emailValidator, generateErrorMessage, isValid, validate } from "helpers/utilities"

import UserManageSection from "../component/common/manageSection"

// Pages Components

const CreateCreUser = props => {
  const { addToast } = useToasts()
  const [formData, setFormData] = useState({
    address_type: { label: "Home", value: "Home" },
    country: { label: "India", name: "India", value: "India" },
  })
  const userRef = useRef(null)
  const [submitting, setsubmitting] = useState(false)
  const [inputError, setInputError] = useState({})
  const [userTypeId, setUserTypeId] = useState("")
  const fetchCurrentUserType = () => {
    let payload = { select: "id" }
    let filter = "&find[name]=PlatformUser&paginate=false"
    getUserTypeList(payload, filter).then(res => {
      let id = res.data[0]?.id
      setUserTypeId(id)
    })
  }
  useEffect(() => {
    fetchCurrentUserType()
  }, [])
  const handleSubmit = data => {
    setsubmitting(true)
    setFormData({ ...formData, ...data })

    if (emailValidator(data?.email)) {
      let payload = new FormData()

      if (isValid(data?.full_name)) {
        payload.append("full_name", data?.full_name)
      }
      if (isValid(data?.primary_phone)) {
        payload.append("primary_phone", `+91${data?.primary_phone}` ?? "")
      }
      if (isValid(data?.email)) {
        payload.append("email", data?.email)
      }
      if (isValid(data?.password)) {
        payload.append("password", data?.password ?? "")

        payload.append("confirm_password", data?.password ?? "")
      }

      postCreUserCreate(payload)
        .then(res => {
          setsubmitting(false)
          addToast(res?.message, { appearance: "success" })
          props.history.push(AUTH_ROUTES.CRE_USERS)
        })
        .catch(err => {
          setsubmitting(false)
          addToast(generateErrorMessage(err?.response?.data), {
            appearance: "error",
          })
        })
    } else {
      setsubmitting(false)
      addToast("Please enter a Valid Email", { appearance: "error" })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <div className="page-title-left">
                  <Breadcrumb title="Users" breadcrumbItem="Create User" />
                  <h4 className="mb-0 font-size-18">Create CRE User</h4>
                </div>
                <div className="page-title-right">
                  <Button
                    className="btn btn-secondary"
                    onClick={() => {
                      props.history.push(AUTH_ROUTES.CRE_USERS)
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn btn-primary"
                    disabled={submitting}
                    onClick={() => {
                      userRef.current && userRef.current.onSubmitAction()
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <form
            onSubmit={e => userRef.current && userRef.current.onSubmitAction(e)}
          >
            <UserManageSection
              ref={userRef}
              data={formData}
              // errors={inputError}
              isReadOnly={false}
              handleSubmit={handleSubmit}
            />
          </form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CreateCreUser
