import React from "react"
import { BiError, BiDotsVerticalRounded } from "react-icons/bi"
import { FiSettings } from "react-icons/fi"
import { HiArrowNarrowUp, HiArrowNarrowDown } from "react-icons/hi"
import { IoMdArrowDropright, IoMdArrowDropdown } from "react-icons/io"

function TableIcons(props) {
  const getIcons = () => {
    switch (props.icon) {
      case "sort-asc":
        return <HiArrowNarrowDown className={props.iconClass ?? ""} />

      case "sort-des":
        return <HiArrowNarrowUp className={props.iconClass ?? ""} />

      case "expand-open":
        return <IoMdArrowDropdown className={props.iconClass ?? ""} />

      case "expand-close":
        return <IoMdArrowDropright className={props.iconClass ?? ""} />

      case "settings":
        return <FiSettings className={props.iconClass ?? ""} />

      case "menu":
        return <BiDotsVerticalRounded className={props.iconClass ?? ""} />

      default:
        return <BiError className={props.iconClass ?? ""} />
    }
  }
  return <span>{getIcons()}</span>
}

export default TableIcons
