import CustomSelect from "components/CustomSelect/index"
import CustomTextField from "components/CustomTextField/index"
import SearchSelect from "components/CustomSearchSelect"
import {
  getBanksList,
  getUserAccountDetails,
  postAccountCreate,
} from "helpers/api_actions"
import React, {
  forwardRef,
  Fragment,
  useEffect,
  useImperativeHandle,
  useState,
} from "react"
import { Modal, Row, Col } from "reactstrap"
import {
  generateClassNameByValue,
  generateErrorMessage,
  isValid,
  validate,
} from "helpers/utilities"
import { useToasts } from "react-toast-notifications"

let required = [
  "account_number",
  "account_name",
  "bank_name",
  "branch_name",
  "ifsc_code",
  "account_type",
]

const BankingInfo = forwardRef((props, ref) => {
  BankingInfo.displayName = "Banking Info"
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [accounts, setAccounts] = useState([])
  const [accountsList, setAccountsList] = useState([])
  const [activeAccount, setActiveAccount] = useState({})
  const [activeAccountData, setActiveAccountData] = useState({})
  const [formData, setFormData] = useState({})
  const [errors, setErrors] = useState({})
  const { addToast } = useToasts()
  const [accountVerifyClass, setAccountVerifyClass] = useState("bg-info")

  const accountTypeOptions = [
    { label: "Current", value: "Current" },
    { label: "Savings", value: "Savings" },
  ]
  let isPrimaryOptions = [
    { label: "No", value: false },
    { label: "Yes", value: true },
  ]
  if (
    (!props.upiAccountsCount || props.upiAccountsCount < 1) &&
    accounts.length < 1
  ) {
    isPrimaryOptions = isPrimaryOptions.filter(option => option.value === true)
  }

  const handleChange = (v, k) => {
    const error = validate(required, { ...formData, [k]: v }, "")
    setFormData(f => ({
      ...f,
      [k]: v,
    }))
    if (Object.keys(errors).length) {
      setErrors(error)
    }
  }

  function handleSubmit(event) {
    event.preventDefault()
    const error = validate(required, formData, "")
    setErrors(error)
    if (Object.keys(error).length === 0 && props.userId) {
      let payload = {}
      if (isValid(formData?.account_number)) {
        payload["account_number"] = formData?.account_number
      }
      if (isValid(formData?.account_name)) {
        payload["account_name"] = formData?.account_name
      }
      if (isValid(formData?.account_type)) {
        payload["account_type"] = formData?.account_type?.value ?? ""
      }
      if (isValid(formData?.bank_name)) {
        payload["bank_name"] = formData?.bank_name?.label
      }
      if (isValid(formData?.branch_name)) {
        payload["branch_name"] = formData?.branch_name
      }
      if (isValid(formData?.ifsc_code)) {
        payload["ifsc_code"] = formData?.ifsc_code
      }
      if (
        formData?.primary_account?.value == true ||
        formData?.primary_account?.value === false
      ) {
        payload["primary_account"] = formData?.primary_account?.value
      }
      payload["user_id"] = props.userId
      payload["payment_type"] = "Bank Account"
      postAccountCreate(payload)
        .then(res => {
          setIsEditMode(false)
          setIsModalOpen(false)
          setFormData({})
          fetchBankAccountDetails()
          if (props?.refetchUser) {
            props?.refetchUser()
          }
          addToast(res?.message, { appearance: "success" })
        })
        .catch(err => {
          // toastr.error(err.response.data.message)
          if (err?.response?.data) {
            addToast(generateErrorMessage(err?.response?.data), {
              appearance: "error",
            })
          }
        })
    }
  }

  function toggleModal(event) {
    event.preventDefault()
    setIsEditMode(false)
    setActiveAccount(accountsList[0] ?? {})
    setActiveAccountData(accounts[0] ?? {})
    setIsModalOpen(!isModalOpen)
  }
  function closeModal(event) {
    event.preventDefault()
    setIsModalOpen(false)
  }
  function handleAccountChange(selectedAccount) {
    let selectedAccountId = selectedAccount?.value
    let activeUserAccountData = accounts.find(account => {
      return account.id === selectedAccountId
    })
    setActiveAccount(selectedAccount)
    setActiveAccountData(activeUserAccountData)
  }
  useImperativeHandle(ref, () => ({
    edit(e) {
      setIsEditMode(true)
      setIsModalOpen(true)
      setActiveAccount({})
      setActiveAccountData({})
    },
    getAccounts() {
      return accounts.length
    },
  }))
  useEffect(() => {
    if (activeAccountData) {
      if (activeAccountData.verification_status == "Verified") {
        setAccountVerifyClass("bg-primary")
      } else if (
        activeAccountData.verification_status == "Verification failed"
      ) {
        setAccountVerifyClass("bg-danger")
      } else {
        setAccountVerifyClass("bg-info")
      }
    }
  }, [activeAccountData])
  function fetchBankAccountDetails() {
    if (props.userId) {
      let basicData = {
        select: `id,payment_type,bank_name,account_name,bank_icon,account_number,branch_name,ifsc_code,account_type,primary_account,upi_id,verification_status,verification_date&find[user_id]=${props.userId}&find[payment_type]=Bank+Account`,
      }
      getUserAccountDetails(basicData)
        .then(res => {
          if (res && res.data && res.data.data) {
            let userAccounts = res.data.data
            setAccounts(userAccounts)

            let userAccountsList = userAccounts.map(userAccount => ({
              label: userAccount.account_number,
              value: userAccount.id,
            }))
            setAccountsList(userAccountsList)
            let activeAccount = {}
            let activeAccountData = {}
            if (userAccountsList.length) {
              setActiveAccount(userAccountsList[0])
            }
            if (userAccounts.length) {
              setActiveAccountData(userAccounts[0])
            }
          }
        })
        .catch(err => {
          // setIsFetching(false)
        })
    }
  }

  const getBanks = async v => {
    let payload = {
      select: `id,name&filter[name]=${v}`,
    }

    const res = await getBanksList(payload)
    const data = res?.data?.data || []
    data.forEach(item => {
      item["label"] = item.name
      item["value"] = item.id
    })

    return data
  }

  useEffect(() => {
    fetchBankAccountDetails()
  }, [])
  return (
    <div>
      <div className="my-2">
        {props?.bankStatusInfo &&
          Object.keys(props?.bankStatusInfo)
            .filter(
              dataKey =>
                props?.bankStatusInfo[dataKey] &&
                props?.bankStatusInfo[dataKey] > 0
            )
            .map(dataKey => (
              <span
                key={dataKey}
                className={`chips me-2 ${generateClassNameByValue(dataKey)}`}
              >
                {dataKey}: {props?.bankStatusInfo[dataKey]}
              </span>
            ))}
      </div>
      <div>
        {accounts.length > 0 ? (
          <a
            href="#"
            onClick={toggleModal}
            data-toggle="modal"
            data-target=".bs-example-modal-center"
            className="linkText"
          >
            View Details
          </a>
        ) : (
          <p>No account added</p>
        )}
        <Modal isOpen={isModalOpen} toggle={toggleModal} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Bank Account Information
            </h5>
            <button
              type="button"
              onClick={closeModal}
              className="btn-close"
            ></button>
          </div>
          <div className="modal-body">
            {(activeAccountData?.primary_account ||
              activeAccountData?.verification_status) && (
              <div className="mb-3">
                {activeAccountData?.primary_account && (
                  <span className="ms-2 chips bg-info">Primary</span>
                )}
                {activeAccountData?.verification_status && (
                  <span className={`ms-2 chips ${accountVerifyClass}`}>
                    Verification status:{" "}
                    {activeAccountData?.verification_status}
                  </span>
                )}
              </div>
            )}
            {isEditMode && (
              <form>
                <div className="mb-3">
                  {isEditMode ? (
                    <CustomTextField
                      label="Account Number"
                      type="number"
                      required
                      name="account_number"
                      disabled={!isEditMode}
                      onChange={(v, k, e) => handleChange(v, k, e)}
                      placeholder="Enter Account Number"
                      value={formData?.account_number ?? ""}
                    />
                  ) : (
                    <CustomSelect
                      label="Choose Account"
                      options={accountsList}
                      value={activeAccount}
                      setData={data => handleAccountChange(data)}
                    />
                  )}
                  {isEditMode && errors?.account_number && (
                    <p className="text-danger"> {errors?.account_number}</p>
                  )}
                </div>
                <div className=" mb-3">
                  <CustomSelect
                    label="Account Type"
                    required
                    placeholder="Account Type"
                    disabled={!isEditMode}
                    options={accountTypeOptions}
                    value={
                      isEditMode
                        ? formData?.account_type
                        : activeAccountData?.account_type
                    }
                    setData={data => handleChange(data, "account_type")}
                  />
                  {isEditMode && errors?.account_type && (
                    <p className="text-danger"> {errors?.account_type}</p>
                  )}
                </div>
                <div className=" mb-3">
                  <CustomTextField
                    label="Account Name"
                    required
                    name="account_name"
                    disabled={!isEditMode}
                    onChange={(v, k, e) => handleChange(v, k, e)}
                    placeholder="Enter Account Name"
                    value={
                      isEditMode
                        ? formData?.account_name
                        : activeAccountData?.account_name
                    }
                  />
                  {isEditMode && errors?.account_name && (
                    <p className="text-danger"> {errors?.account_name}</p>
                  )}
                </div>
                <div className="mb-3">
                  {isEditMode ? (
                    <SearchSelect
                      getData={getBanks}
                      menuPlacement={"top"}
                      isMulti={false}
                      placeholder="Search by Name"
                      label="Bank Name"
                      required
                      setData={v => {
                        handleChange(v, "bank_name")
                      }}
                      value={formData?.bank_name ?? null}
                    />
                  ) : (
                    <CustomTextField
                      label="Bank Name"
                      disabled={!isEditMode}
                      placeholder="Enter Bank Name"
                      value={activeAccountData?.bank_name}
                    />
                  )}
                  {isEditMode && errors?.bank_name && (
                    <p className="text-danger"> {errors?.bank_name}</p>
                  )}
                </div>
                <div className=" mb-3">
                  <CustomTextField
                    label="Branch Name"
                    disabled={!isEditMode}
                    name="branch_name"
                    required
                    placeholder="Enter Branch Name"
                    onChange={(v, k, e) => handleChange(v, k, e)}
                    value={
                      isEditMode
                        ? formData?.branch_name
                        : activeAccountData?.branch_name
                    }
                  />
                  {isEditMode && errors?.branch_name && (
                    <p className="text-danger"> {errors?.branch_name}</p>
                  )}
                </div>
                <div className=" mb-3">
                  <CustomTextField
                    label="IFSC Code"
                    disabled={!isEditMode}
                    name="ifsc_code"
                    required
                    placeholder="Enter IFSC Code"
                    onChange={(v, k, e) => handleChange(v, k, e)}
                    value={
                      isEditMode
                        ? formData?.ifsc_code
                        : activeAccountData?.ifsc_code
                    }
                  />
                  {isEditMode && errors?.ifsc_code && (
                    <p className="text-danger"> {errors?.ifsc_code}</p>
                  )}
                </div>
                <div className="mb-3">
                  <CustomSelect
                    label="Is Account Primary?"
                    options={isPrimaryOptions}
                    value={
                      isEditMode
                        ? formData?.primary_account ?? isPrimaryOptions[0]
                        : isPrimaryOptions.find(
                            option =>
                              option.value ===
                              activeAccountData?.primary_account
                          )
                    }
                    setData={data => handleChange(data, "primary_account")}
                  />
                  {isEditMode && errors?.primary_account && (
                    <p className="text-danger"> {errors?.primary_account}</p>
                  )}
                </div>
              </form>
            )}
            {!isEditMode && (
              <>
                <form>
                  <div className="mb-3">
                    <CustomSelect
                      label="Choose Account"
                      options={accountsList}
                      value={activeAccount}
                      setData={data => handleAccountChange(data)}
                    />
                  </div>
                </form>
                <hr />
                <Row>
                  <Col md="4">
                    <p className="detail-title">Account Number</p>
                    <p className="details-content">
                      {activeAccountData?.account_number ?? "--"}
                    </p>
                  </Col>
                  <Col md="4">
                    <p className="detail-title">Account Type</p>
                    <p className="details-content">
                      {activeAccountData?.account_type ?? "--"}
                    </p>
                  </Col>
                  <Col md="4">
                    <p className="detail-title">Account Name</p>
                    <p className="details-content">
                      {activeAccountData?.account_name ?? "--"}
                    </p>
                  </Col>
                  <Col md="4">
                    <p className="detail-title">Bank Name</p>
                    <p className="details-content">
                      {activeAccountData?.bank_name ?? "--"}
                    </p>
                  </Col>
                  <Col md="4">
                    <p className="detail-title">Branch Name</p>
                    <p className="details-content">
                      {activeAccountData?.branch_name ?? "--"}
                    </p>
                  </Col>
                  <Col md="4">
                    <p className="detail-title">IFSC Code</p>
                    <p className="details-content">
                      {activeAccountData?.ifsc_code ?? "--"}
                    </p>
                  </Col>
                </Row>
              </>
            )}
          </div>
          <div className="modal-footer">
            {isEditMode && (
              <button
                type="submit"
                disabled={props.submiting}
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                Save
              </button>
            )}
          </div>
        </Modal>
      </div>
    </div>
  )
})

export default BankingInfo
