import axios from "axios"

//pass new generated access token here

// let token = null
// if (localStorage.getItem("access_token")) {
//   token = localStorage.getItem("access_token")
// }

//apply base url for axios
// const API_URL = process.env.REACT_APP_BASE_URL

const axiosApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
})

let refreshTokenPromise = null

axiosApi.interceptors.response.use(
  res => {
    return res
  },
  async err => {
    const originalConfig = err.config
    if (err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true

        try {
          if (refreshTokenPromise === null) {
            refreshTokenPromise = axios
              .get(
                `${
                  process.env.REACT_APP_BASE_URL
                }refresh-token?refresh_token=${localStorage.getItem(
                  "refresh_token"
                )}&user_id=${
                  JSON.parse(localStorage.getItem("userData")).user_id
                }`
              )
              .then(rs => {
                refreshTokenPromise = null // clear state

                localStorage.setItem("access_token", rs.data.data.token)
                localStorage.setItem(
                  "refresh_token",
                  rs.data.data.refresh_token
                )
                err.config.headers[
                  "Authorization"
                ] = `Bearer ${rs.data.data.token}`
                axiosApi.defaults.headers.common.Authorization = `Bear ${rs.data.data.token}`
                return axiosApi
              })
              .catch(err => {
                if (err.response.status == 401) {
                  localStorage.clear()
                  window.open("/", "_self")
                }
                if (err.response.status == 403) {
                  window.open("/", "_self")
                }
              })
          }

          return refreshTokenPromise.then(rs => {
            err.config.headers[
              "Authorization"
            ] = `Bearer ${localStorage.getItem("access_token")}`
            return axiosApi(err.config)
          })
        } catch (_error) {
          return Promise.reject(_error)
        }
      } else if (err.response.status === 401 && originalConfig._retry) {
        localStorage.clear()
        window.open("/", "_self")
        return Promise.reject(err)
      } else if (err.response.status === 403) {
        window.open("/", "_self")
        return Promise.reject(err)
      }
      return Promise.reject(err)
    }
  }
)
axiosApi.interceptors.request.use(
  config => {
    config.headers = config.headers ?? {}
    config.headers["Content-Type"] = "application/json"
    config.headers.common["Authorization"] = localStorage.getItem(
      "access_token"
    )
      ? `Bearer ${localStorage.getItem("access_token")}`
      : null
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi.post(url, data, { ...config }).then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi.put(url, data, { ...config }).then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
