import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { FORGET_PASSWORD, FORGET_PASSWORD_ERROR, FORGET_PASSWORD_SUCCESS } from "./actionTypes"
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions"

import { postFakeForgetPwd, postForgotPwd } from "../../../helpers/api_actions"
import { useToasts } from "react-toast-notifications"

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
    const response = yield call(postForgotPwd, {
      phone: user.phone,
      code_type: "reset_password"
    })
    if (response) {
      yield put(
        userForgetPasswordSuccess(
          "Reset OTP is sent to your phone"
        )
      )
      history.push({
        pathname: '/forgot-password/verify',
        state: { phone: user.phone }
      })
    }
  } catch (error) {
    yield put(userForgetPasswordError(error.message))
  }
}

// function* forgetUserSuccess({ payload }) {
//   const { addToast } = useToasts()
//   addToast(payload, { appearance: "success" })
// }

// function* forgetUserError({ payload }) {
//   const { addToast } = useToasts()
//   addToast(payload, { appearance: "error" })
// }

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser)
}

// export function* watchUserPasswordForgetSuccess() {
//   yield takeEvery(FORGET_PASSWORD_SUCCESS, forgetUserSuccess)
// }

// export function* watchUserPasswordForgetError() {
//   yield takeEvery(FORGET_PASSWORD_ERROR, forgetUserError)
// }

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
}

export default forgetPasswordSaga
