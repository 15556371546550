import SelectfieldComponent from "components/Common/SelectfieldComponent"
import SearchSelect from "components/CustomSearchSelect"
import CustomSelect from "components/CustomSelect/index"
import CustomCalander from "components/CustomCalendar"
import {
  getCitiesList,
  getPlatformUsersList,
  getLeadStatusesList,
} from "helpers/api_actions"
import React from "react"
import { useEffect } from "react"
import { useState } from "react"

import { Row, Col, Button } from "reactstrap"

const UserFilterComponent = props => {
  const [selectedCity, setSelectedCity] = useState(props.filters?.city)
  const [selectedStatus, setSelectedStatus] = useState([])
  const [statuses, setStatuses] = useState(props.filters?.status)
  const [selectedCreatedOn, setSelectedCreatedOn] = useState(
    props.filters?.createdOn
  )
  const [selectedUpdatedOn, setSelectedUpdatedOn] = useState(
    props.filters?.updatedOn
  )
  const defaultSelection = null
  const onApplyFilter = () => {
    let filters = {
      city: selectedCity,
      status: selectedStatus,
      updatedOn: selectedUpdatedOn,
      createdOn: selectedCreatedOn,
    }
    props.onFilterSubmission(filters)
  }
  const onCancelFilter = () => {
    setSelectedCity(undefined)
    setSelectedCreatedOn(undefined)

    setSelectedUpdatedOn(undefined)
    setSelectedStatus(undefined)
    props.onFilterSubmission({
      city: undefined,
      rank: undefined,
      status: undefined,
      updatedOn: undefined,
      createdOn: undefined,
    })
  }
  const getCities = async v => {
    let payload = { select: "cities.name as name" }
    let filter = `&search[cities.name]=${v}`
    const res = await getCitiesList(payload, filter)
    const data = res?.data?.data || []
    data.forEach(item => {
      item["label"] = item.name
      item["value"] = item.name
    })
    return data
  }
  const getUsers = async v => {
    let payload = {
      select:
        "users.id,users.full_name as Full Name,users.primary_phone as Phone,users.display_id as User Id,ranks.name as Rank Name",
      include: "users,ranks",
    }

    const res = await getPlatformUsersList(payload)
    const data = res?.data?.data || []
    data.forEach(item => {
      item["label"] = `${item["Full Name"]} (${item["User Id"]})`
      item["value"] = item.id
    })
    return data
  }
  const getLeadStatus = () => {
    let payload = { select: "id,name", paginate: false }

    getLeadStatusesList(payload).then(res => {
      let data = res?.data || []
      data.forEach(item => {
        item["label"] = item.name
        item["value"] = item.id
      })

      setStatuses(data)
    })
  }
  useEffect(() => {
    getLeadStatus()
  }, [])
  return (
    <>
      <Row>
        <Col lg={3} md={4} sm={6} xs={12}>
          <SearchSelect
            getData={getCities}
            isMulti={false}
            label="City"
            setData={v => {
              setSelectedCity(v)
            }}
            value={selectedCity ?? defaultSelection}
          />
        </Col>
        <Col lg={3} md={4} sm={6} xs={12}>
          <CustomSelect
            options={statuses}
            label="Status"
            setData={v => {
              setSelectedStatus(v)
            }}
            value={selectedStatus ?? defaultSelection}
          />
        </Col>
        <Col lg={3} md={4} sm={6} xs={12}>
          <CustomCalander
            dateRange
            value={selectedUpdatedOn}
            onChange={date => setSelectedUpdatedOn(date)}
            label="Updated Between"
            placeholder={"Start Date - End Date"}
            maxDate={"today"}
          />
        </Col>
        <Col lg={3} md={4} sm={6} xs={12}>
          <CustomCalander
            dateRange
            maxDate={"today"}
            placeholder={"Start Date - End Date"}
            value={selectedCreatedOn}
            onChange={date => setSelectedCreatedOn(date)}
            label="Created Between"
          />
        </Col>
        <Col
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className="d-flex align-items-end justify-content-end pt-3"
        >
          <div className="me-2">
            <Button
              className="btn btn-sm btn-primary btn-success text-white"
              onClick={() => onApplyFilter()}
            >
              Apply
            </Button>
          </div>
          <div>
            <Button
              className="btn btn-sm btn-danger"
              onClick={() => onCancelFilter()}
            >
              Reset
            </Button>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default UserFilterComponent
