import { TAB_LABELS } from "constants/label"

export const LEAD_TAB_LABELS = [
  {
    id: "lead-details",
    label: TAB_LABELS.LEAD_DETAILS,
  },
  {
    id: "lead-notes",
    label: TAB_LABELS.LEAD_NOTES,
  },
  {
    id: "lead-attachments",
    label: TAB_LABELS.LEAD_ATTACHMENTS,
  },
  {
    id: "lead-logs",
    label: TAB_LABELS.LEAD_LOG,
  },
  {
    id: "lead-payments",
    label: TAB_LABELS.LEAD_PAYMENTS,
  },
  {
    id: "lead-credit-report",
    label: TAB_LABELS.LEAD_CREDIT_REPORT,
  },
  {
    id: "lead-duplicates",
    label: TAB_LABELS.LEAD_DUPLICATE,
  },
  {
    id: "lead-prospects",
    label: TAB_LABELS.LEAD_PROSPECTS,
  },

]

export const USER_DETAILS_BREADCRUMB = {}
