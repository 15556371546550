export const AUTH_ROUTES = {
  // ===============================================
  DASHBOARD: "/dashboard",
  PROFILE: "/profile",
  // ===============================================
  USERS: "/users",
  //////////////////////////////////////////////////
  /* */ CREATE_NEW_USER: "/users/create",
  /* */ USER_DETAILS: "/users/view/",

  // ===============================================
  CRE_USERS: "/cre-users",
  //////////////////////////////////////////////////
  /* */ CREATE_NEW_CRE_USER: "/cre-users/create",
  /* */ CRE_USER_DETAILS: "/cre-users/view/",
  /* */ ASSIGN_CRE_USERS: "/cre-users/assign/",
  /////////////////////////////////////////////////
  // ===============================================
  CUSTOMER: "/customer",
  CUSTOMER_LEADS: "/customer/view/",
  //////////////////////////////////////////////////
  /////////////////////////////////////////////////
  // ===============================================
  LEADS: "/leads",
  //////////////////////////////////////////////////
  /* */ CREATE_NEW_LEAD: "/leads/create",
  /* */ LEAD_DETAILS: "/leads/view/",
  /////////////////////////////////////////////////
  // ===============================================
  BUSINESS: "/business",
  //////////////////////////////////////////////////
  /* */ CREATE_NEW_BUSINESS_LEAD: "/business/create",
  BUSINESS_DETAILS: "/business/view/",
  /////////////////////////////////////////////////
  // ===============================================
  CONTACTS_MANAGEMENT: "/contacts",
  CONTACTS_DETAILS: "/contacts/view/",
  // ===============================================
  //////////////////////////////////////////////////
  /* */ CREATE_NEW_CONTACT_LEAD: "/contacts/create",
  /////////////////////////////////////////////////
  SERVICE_CATEGORY: "/service-category",
  SERVICE_CATEGORY_VIEW: "/service-category/view/",
  CREATE_SERVICE_CATEGORY: "/service-category/create",
  // ===============================================
  SERVICE_MANAGEMENT: "/services",
  SERVICE_MANAGEMENT_DETAILS: "/services/view/",

  CREATE_SERVICE: "/services/create",
  // ===============================================
  COMMISSION_MANAGEMENT: "/commission-management",
  COMMISSION_MANAGEMENT_SERVICES: "/commission-management/services/view/",
  // ===============================================
  PAYOUT: "/payout",
  PAYOUT_IMPORT_HISTORY: "/payout/import-history",
  // ===============================================
  CUSTOMER_SUPPORT: "/customer-support",
  CUSTOMER_SUPPORT_DETAILS: "/customer-support/view/",
  // ===============================================
  PARTNERSHIP_REQUESTS: "/partnership-request",
  PARTNERSHIP_REQUEST_DETAILS: "/partnership-request/view/",
  // ===============================================
  TICKETS: "/tickets",
  TICKET_DETAILS: "/tickets/view/",
  // ===============================================
  REPORTS_SALES: "/sales",
  REPORTS_SALES_USER: "/user-sales",
  REPORTS_COMISSION: "/commission",
  REPORTS_COMISSION_USER: "/user-commission",
  SALES_REPORT_SERVICE_MANAGEMENT_DETAILS: "/sales/services/view/",
  COMMISSION_REPORT_SERVICE_MANAGEMENT_DETAILS: "/commission/services/view/",
  SALES_REPORT_USER_MANAGEMENT_DETAILS: "/sales/users/view/",
  COMMISSION_REPORT_USER_MANAGEMENT_DETAILS: "/commission/users/view/",
  // ===============================================
  PAYMENT_COLLECTION: "/payment-collection",
  PAYMENT_COLLECTION_LEAD_DETAILS: "/payment-collection/leads/view/",
  // ===============================================
  PRESALES: "/presales",
  PRESALEDETAILS: "/presales/presale-details/",
  // PRESALEDETAILS_EDIT: "/presales/presale-details/edit",
  // ===================================================
  IMPORT_LOG: "/import-log",
}
