import axios from "axios"

import { del, get, post, put } from "./api_helper"
import { parseQueryParams } from "./formValidate"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("access_token")
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}
const postAuthFCMClear = data => post(url.AUTH_FCMClear, data)
const postAuthFCMSave = data => post(url.AUTH_FCMSave, data)
const postAuthFCMCheck = data => post(url.AUTH_FCMCheck, data)
// Login Methodaccess_token
const postAuthLogin = data => post(url.AUTH_LOGIN, data)
const postAuthLogout = data => post(url.AUTH_LOGOUT, data)
const postForgotPwd = data => post(url.FORGOT_PWD, data)
const postForgotPwdVerify = data => post(url.FORGOT_PWD_VERIFY, data)
const postForgotPwdReset = data => post(url.FORGOT_PWD_RESET, data)
const postFakeForgetPwd = data => post(url.AUTH_LOGIN, data)
const postFakeRegister = data => post(url.AUTH_LOGIN, data)

//User section

const getPlatformUsersList = (data, search = "", filter = "", sort = "") =>
  get(
    `${url.PLATFORM_USER_LIST}${parseQueryParams(
      data
    )}${search}${filter}${sort}`
  )
const getPlatformUsersListExport = (
  data,
  search = "",
  filter = "",
  sort = ""
) =>
  get(
    `${url.PLATFORM_USER_LIST}${parseQueryParams(
      data
    )}${search}${filter}${sort}`,
    {
      responseType: "blob",
    }
  )
const getCreUsersList = (data, search = "", filter = "", sort = "") =>
  get(`${url.CRE_USER_LIST}${parseQueryParams(data)}${search}${filter}${sort}`)
const getUnassignedUsersList = (data, search = "", filter = "", sort = "") =>
  get(
    `${url.UNASSIGNED_USER_LIST}${parseQueryParams(
      data
    )}${search}${filter}${sort}`
  )
const getCreUsersListExport = (data, search = "", filter = "", sort = "") =>
  get(
    `${url.PLATFORM_USER_LIST}${parseQueryParams(
      data
    )}${search}${filter}${sort}`,
    {
      responseType: "blob",
    }
  )
const addUserKyc = data => post(`/kyc`, data)
const getKycPAN = data => get(`/kyc/${data}`)
const getKycDetails = data => get(`/kyc?user_id=${data}`)
const getNotification = data =>
  get("/list/notifications?select=id,title,text,created_at,is_read")
const getNotificationStatus = data => get("/notification-status/${data}")

const getUserDetails = id => get(`${url.USER_DETAILS}/${id}`)
const getAdminProfile = () => get(`${url.ADMIN_PROFILE}`)
const getUserProfile = () => get(`${url.PLATFORM_USER_PROFILE}`)
const getServiceDetails = id => get(`${url.SERVICE_DETAIL}/${id}`)
const getServiceCategoryDetails = id =>
  get(`${url.SERVICE_CATEGORY_DETAILS}/${id}`)
const getServiceLog = (data, search = "", filter = "", sort = "") =>
  get(
    `${url.GET_SERVICE_LOG}${parseQueryParams(data)}${search}${filter}${sort}`
  )
const postUserCreate = data =>
  post(`${url.USER_CREATE}`, data, {
    headers: { "content-type": "multipart/form-data" },
  })
const postCreUserCreate = data => post(`${url.CRE_USER_CREATE}`, data)
const postUserUpdate = (id, data) =>
  post(`${url.USER_UPDATE}/${id}`, data, {
    headers: { "content-type": "multipart/form-data" },
  })
const postCreUserUpdate = (id, data) =>
  post(`${url.CRE_USER_UPDATE}/${id}`, data)
const postCreUserAssign = (data, search = "", filter = "") =>
  post(`${url.ASSIGN_CRE_USERS}${search}${filter}`, data)
const postCreUserUnassign = (id, data) =>
  post(`${url.UNASSIGN_CRE_USERS}/${id}`, data)
const postAdminProfileUpdate = data =>
  post(`${url.ADMIN_PROFILE_UPDATE}`, data, {
    headers: { "content-type": "multipart/form-data" },
  })
const postCommisionCreate = data => post(`${url.CREATE_COMMISSION}`, data)
const putCommisionUpdate = (id, data) =>
  put(`${url.CREATE_COMMISSION}/${id}`, data)
const postResetPasscode = (id, data) =>
  post(`${url.USER_RESETPASSCODE}/${id}`, data)
const changeUserStatus = (data, id) =>
  post(`${url.CHANGE_USER_STATUS}/${id}`, data)
const updateReferrerCommision = (userId, data) =>
  post(`${url.CHANGE_REFERRER_COMMISION}/${userId}`, data)
const deleteCustomerService = id => post(`${url.DELETEPROSPECTS}/${id}`)
const getCommisionDetails = ID => get(`${url.GET_COMMISSION_DETAILS}/${ID}`)
const deleteUser = id => del(`${url.USER_DELETE}/${id}`)
const deleteServiceCategory = id => del(`${url.SERVICE_CATEGORY_DELETE}/${id}`)
const deleteService = id => del(`${url.SERVICE_DELETE}/${id}`)
const postLeadCreate = data => post(`${url.LEADS_CREATE}`, data)
const putLeadUpdate = (id, data) => put(`${url.LEADS_CREATE}/${id}`, data)
const postServiceCategoryCreate = data =>
  post(`${url.SERVICE_CATEGORY_CREATE}`, data)
const updateServiceCategory = (id, data) =>
  post(`${url.SERVICE_CATEGORY_CREATE}/${id}`, data)
const postServiceCreate = data =>
  post(`${url.SERVICE_CREATE}`, data, {
    headers: { "content-type": "multipart/form-data" },
  })
const updateServiceCreate = (id, data) =>
  post(`${url.SERVICE_CREATE}/${id}`, data, {
    headers: { "content-type": "multipart/form-data" },
  })
const getUserAccountDetails = (data, search = "", filter = "", sort = "") =>
  get(
    `${url.USER_ACCOUNT_DETAILS}${parseQueryParams(
      data
    )}${search}${filter}${sort}`
  )
const getTeamMembers = (data, search = "", filter = "", sort = "") =>
  get(
    `${url.GET_TEAM_MEMBERS}${parseQueryParams(data)}${search}${filter}${sort}`
  )

const getUserDashboardDetails = data =>
  get(`${url.GET_USER_DASHBOARD}${parseQueryParams(data)}`)
//MAIN DASHBOARD
const getAdminDashboardDetails = data =>
  get(`${url.ADMIN_DASHBOARD}${parseQueryParams(data)}`)
const getSalesDashboardDetails = data =>
  get(`${url.SALES_DASHBOARD_DATA}${parseQueryParams(data)}`)
const getCommissionDashboardDetails = data =>
  get(`${url.COMMISSION_DASHBOARD_DATA}${parseQueryParams(data)}`)
const getAdminDashboardLeadschart = data =>
  get(`${url.GET_DASHBOARD_LEADS_CHART}${parseQueryParams(data)}`)
const getAdminDashboardCommissionchart = data =>
  get(`${url.GET_DASHBOARD_COMMISSIN_CHART}${parseQueryParams(data)}`)
const getAdminDashboardSaleschart = data =>
  get(`${url.GET_DASHBOARD_SALES_CHART}${parseQueryParams(data)}`)
const postAccountCreate = data => post(`${url.ACCOUNT_CREATE}`, data)
const updateNotificationToken = data => post("update-fcm", data)
//Lead Section
const getCusomterList = (
  data,
  search = "",
  filter = "",
  sort = "",
  isExport = false
) =>
  isExport
    ? get(
        `${url.CUSTOMERS_LIST}${parseQueryParams(
          data
        )}${search}${filter}${sort}`,
        {
          responseType: "blob",
        }
      )
    : get(
        `${url.CUSTOMERS_LIST}${parseQueryParams(
          data
        )}${search}${filter}${sort}`
      )
const getLeadsList = (
  data,
  search = "",
  filter = "",
  sort = "",
  isExport = false
) =>
  isExport
    ? get(
        `${url.LEADS_LIST}${parseQueryParams(data)}${search}${filter}${sort}`,
        {
          responseType: "blob",
        }
      )
    : get(`${url.LEADS_LIST}${parseQueryParams(data)}${search}${filter}${sort}`)
const getPresaleImportList = (
  data,
  search = "",
  filter = "",
  sort = "",
  isExport = false
) =>
  get(
    `${url.PRESALE_IMPORT_LOG_LIST}${parseQueryParams(
      data
    )}${search}${filter}${sort}`
  )
const getPresaleList = (
  data,
  search = "",
  filter = "",
  sort = "",
  isExport = false
) =>
  get(`${url.PRESALE_LIST}${parseQueryParams(data)}${search}${filter}${sort}`)
const postPreSaleImport = data =>
  post(`${url.PRESALE_IMPORT}`, data, {
    headers: { "content-type": "multipart/form-data" },
  })
const preSaleCreateNote = data => post(`${url.PRESALE_NOTES}`, data)
const preSaleCreateFollowupNote = data =>
  post(`${url.PRESALE_FOLLOWUP_NOTES}`, data)

const getPresaleDetails = id => get(`${url.PRESALE_LIST_DETAILS}/${id}`)
const getFollowUpNotesByEntity = data =>
  get(`${url.PRESALE_FOLLOWUP_NOTES_LIST}/${data}`)
const updateFollowUpNote = (id, data) =>
  put(`${url.PRESALE_FOLLOWUP_NOTES}/${id}`, data)
const deleteFollowUpNote = id => del(`${url.PRESALE_FOLLOWUP_NOTES}/${id}`)
const postPresaleCreUserAssign = (data, search = "", filter = "") =>
  post(`${url.PRESALE_ASSIGN_CRE}${search}${filter}`, data)
const changePresaleStatus = (id, data) =>
  post(`${url.PRESALE_CHANGE_STATUS}/${id}`, data)
const preSaleUpdate = (id, data) =>
  put(`${url.PRESALE_LIST_DETAILS}/${id}`, data)

const getLeadDetails = id => get(`${url.LEADS_DETAILS}/${id}`)
const getCreditReport = id => get(`${url.CREDIT_REPORT}/${id}`)
const getPaymentUrl = id => get(`${url.PAYMENT_URL}/${id}`)
const createNote = data => post(`${url.NOTE}`, data)
const AssignProspects = data => post(`${url.ASSIGNPROSPECTS}`, data)
const submitPaymentRequest = (id, data) =>
  post(`${url.REQUEST_PAYMENT}/${id}`, data)
const submitChangePaymentStatus = (id, data) =>
  post(`${url.CHANGE_PAYMENT_STATUS}/${id}`, data)
const submitChangeServiceStatus = (id, data) =>
  post(`${url.CHANGE_SERVICE_STATUS}/${id}`, data)
const submitGenerateCreditReport = (id, data) =>
  post(`${url.GENERATE_CREDIT_REPORT}/${id}`, data)
const createAttachment = data => post(`${url.ATTACHMENT}`, data)
const updateNote = (id, data) => put(`${url.NOTE}/${id}`, data)
const deleteNote = id => del(`${url.NOTE}/${id}`)
const deleteCommision = id => del(`${url.CREATE_COMMISSION}/${id}`)
const deleteAttachment = id => del(`${url.ATTACHMENT}/${id}`)
const getServiceStatusesList = (data, search = "", filter = "", sort = "") =>
  get(
    `${url.SERVICE_STATUS_LIST}${parseQueryParams(
      data
    )}${search}${filter}${sort}`
  )
const calculateLeadOwnerCommission = (leadId, data) =>
  get(
    `${url.CALCULATE_LEAD_OWNER_COMMISSION}/${leadId}${parseQueryParams(data)}`
  )
// Payout
const getLeadPaymentList = (data, search = "", filter = "", sort = "") =>
  get(
    `${url.LEAD_PAYMENT_LIST}${parseQueryParams(data)}${search}${filter}${sort}`
  )
// Service Category Section
const getServiceCategoryList = (
  data,
  search = "",
  filter = "",
  sort = "",
  isExport = false
) =>
  isExport
    ? get(
        `${url.SERVICE_CATEGORY_LIST}${parseQueryParams(
          data
        )}${search}${filter}${sort}`,
        {
          responseType: "blob",
        }
      )
    : get(
        `${url.SERVICE_CATEGORY_LIST}${parseQueryParams(
          data
        )}${search}${filter}${sort}`
      )
const updateServieCategoryStatus = (id, data) =>
  post(`${url.SERVICE_CATEGORY_STATUS}/${id}`, data)

// Services
const getServicesList = (
  data,
  search = "",
  filter = "",
  sort = "",
  isExport = false
) =>
  isExport
    ? get(
        `${url.SERVICE_LIST}${parseQueryParams(data)}${search}${filter}${sort}`,
        {
          responseType: "blob",
        }
      )
    : get(
        `${url.SERVICE_LIST}${parseQueryParams(data)}${search}${filter}${sort}`
      )
const getServiceDashboardDetails = data =>
  get(`${url.GET_SERVICE_DASHBOARD}${parseQueryParams(data)}`)
const getServiceDashboarLeadsChartdDetails = data =>
  get(`${url.GET_SERVICE_DASHBOARD_LEADS_CHART}${parseQueryParams(data)}`)
const getServiceDashboarCommissionChartdDetails = data =>
  get(`${url.GET_SERVICE_DASHBOARD_Commission_CHART}${parseQueryParams(data)}`)
// Commission
const getCommissionList = (data, search = "", filter = "", sort = "") =>
  get(
    `${url.COMMISSION_LIST}${parseQueryParams(data)}${search}${filter}${sort}`
  )
const getCustomerService = (
  data,
  search = "",
  filter = "",
  sort = "",
  isExport = false
) =>
  isExport
    ? get(
        `${url.CUSTOMER_SERVICE_LIST}${parseQueryParams(
          data
        )}${search}${filter}${sort}`,
        {
          responseType: "blob",
        }
      )
    : get(
        `${url.CUSTOMER_SERVICE_LIST}${parseQueryParams(
          data
        )}${search}${filter}${sort}`
      )
// Sales
const getSalesList = (data, search = "", filter = "", sort = "") =>
  get(`${url.SALES_LIST}${parseQueryParams(data)}${search}${filter}${sort}`)
const postSaleCreate = data => post(`${url.CREATE_SALE}`, data)
const putSaleUpdate = (id, data) => put(`${url.CREATE_SALE}/${id}`, data)
const getSaleDetails = ID => get(`${url.CREATE_SALE}/${ID}`)
const deleteSale = id => del(`${url.CREATE_SALE}/${id}`)

// Payout
const getPayoutList = (data, search = "", filter = "", sort = "") =>
  get(`${url.PAYOUT_LIST}${parseQueryParams(data)}${search}${filter}${sort}`)
const getPayoutListExport = (search = "", filter = "") =>
  get(`${url.PAYOUT_LIST_EXPORT}?${filter}`, {
    responseType: "blob",
  })
const getPayoutModesList = (data, search = "", filter = "", sort = "") =>
  get(`${url.PAYOUT_MODES}${parseQueryParams(data)}${search}${filter}${sort}`)
const postPayoutRequestUpdate = data =>
  post(`${url.PAYOUT_REQUEST_UPDATE}`, data)
const postPayoutImport = data =>
  post(`${url.PAYOUT_IMPORT}`, data, {
    headers: { "content-type": "multipart/form-data" },
  })
const getPayoutImportList = (data, search = "", filter = "", sort = "") =>
  get(
    `${url.PAYOUT_IMPORT_LIST}${parseQueryParams(
      data
    )}${search}${filter}${sort}`
  )
//Earnings
const getEarnings = (data, search = "", filter = "", sort = "") =>
  get(`${url.EARNINGS_LIST}${parseQueryParams(data)}${search}${filter}${sort}`)
const changeEarningsStatus = (data, id) =>
  post(`${url.EARNINGS_STATUS}/${id}`, data)
const getEarningsDetails = data => get(`${url.EARNINGS_DETAIL}/${data}`)

const getEarningHistory = (data, search = "", filter = "", sort = "") =>
  get(
    `${url.EARNING_HISTORY}${parseQueryParams(data)}${search}${filter}${sort}`
  )
// Customer Support
const getCustomerSupportList = (
  data,
  search = "",
  filter = "",
  sort = "",
  isExport = false
) =>
  get(
    `${url.CUSTOMER_SUPPORT_LIST}${parseQueryParams(
      data
    )}${search}${filter}${sort}`
  )
const getCustomerSupportDetails = id =>
  get(`${url.CUSTOMER_SUPPORT_DETAILS}/${id}`)
const changeCustomerSupportStatus = (data, id) =>
  post(`${url.CHANGE_CUSTOMER_SUPPORT_STATUS}/${id}`, data)
const getCustomerSupportLog = (data, search = "", filter = "", sort = "") =>
  get(
    `${url.GET_CUSTOMER_SUPPORT_LOG}${parseQueryParams(
      data
    )}${search}${filter}${sort}`
  )
const changeCustomerSupportCreUser = (data, id) =>
  post(`${url.CHANGE_CUSTOMER_SUPPORT_CRE_USER}/${id}`, data)
// Partnership Request
const getPartnerShipRequestList = (
  data,
  search = "",
  filter = "",
  sort = "",
  isExport = false
) =>
  get(
    `${url.PARTNERSHIP_REQUEST_LIST}${parseQueryParams(
      data
    )}${search}${filter}${sort}`
  )
const getPartnershipRequestDetails = id =>
  get(`${url.PARTNERSHIP_REQUEST_DETAILS}/${id}`)
const changePartnershipRequestStatus = (data, id) =>
  post(`${url.CHANGE_PARTNERSHIP_REQUEST_STATUS}/${id}`, data)
const changePartnershipRequestCreUser = (data, id) =>
  post(`${url.CHANGE_PARTNERSHIP_REQUEST_CRE_USER}/${id}`, data)
const getPartnershipRequestLog = (data, search = "", filter = "", sort = "") =>
  get(
    `${url.GET_PARTNERSHIP_REQUEST_LOG}${parseQueryParams(
      data
    )}${search}${filter}${sort}`
  )
// Tickets
const getTicketsList = (
  data,
  search = "",
  filter = "",
  sort = "",
  isExport = false
) =>
  get(`${url.TICKETS_LIST}${parseQueryParams(data)}${search}${filter}${sort}`)
const getTicketDetails = id => get(`${url.TICKET_DETAILS}/${id}`)
const changeTicketStatus = (data, id) =>
  post(`${url.CHANGE_TICKET_STATUS}/${id}`, data)
const changeTicketCreUser = (data, id) =>
  post(`${url.CHANGE_TICKET_CRE_USER}/${id}`, data)
const getTicketLog = (data, search = "", filter = "", sort = "") =>
  get(`${url.GET_TICKET_LOG}${parseQueryParams(data)}${search}${filter}${sort}`)
// Registers
const getServiceTypeList = (data, search = "", filter = "", sort = "") =>
  get(
    `${url.SERVICE_TYPE_LIST}${parseQueryParams(data)}${search}${filter}${sort}`
  )
const getServiceRankList = (data, search = "", filter = "", sort = "") =>
  get(
    `${url.SERVICE_RANK_LIST}${parseQueryParams(data)}${search}${filter}${sort}`
  )

const getLeadLog = (data, search = "", filter = "", sort = "") =>
  get(`${url.GET_LEAD_LOG}${parseQueryParams(data)}${search}${filter}${sort}`)
const getUserActivityLogs = (data, search = "", filter = "", sort = "") =>
  get(
    `${url.GET_USER_ACTIVITY_LOG}${parseQueryParams(
      data
    )}${search}${filter}${sort}`
  )
const getNotesByEntity = data => get(`${url.NOTES_BY_ENTITY}/${data}`)
const getAttachmentByEntity = data => get(`${url.ATTACHMENT_BY_ENTITY}/${data}`)
const getCitiesList = (data, filter = "", sort = "") =>
  get(`${url.CITIES_LIST}${parseQueryParams(data)}${filter}${sort}`)
const getQualificationList = (data, filter = "", sort = "") =>
  get(`${url.GET_QUALIFICATIONS}${parseQueryParams(data)}${filter}${sort}`)

const getJobList = (data, filter = "", sort = "") =>
  get(`${url.GET_JOB}${parseQueryParams(data)}${filter}${sort}`)

const getStatesList = (data, search = "", filter = "", sort = "") =>
  get(`${url.STATES_LIST}${parseQueryParams(data)}${search}${filter}${sort}`)

const getCountriesList = (data, search = "", filter = "", sort = "") =>
  get(`${url.COUNTRIES_LIST}${parseQueryParams(data)}${search}${filter}${sort}`)
const getLeadStatusesList = (data, search = "", filter = "", sort = "") =>
  get(
    `${url.LEAD_STATUS_LIST}${parseQueryParams(data)}${search}${filter}${sort}`
  )
const getPreSaletatusesList = data =>
  get(`${url.PRESALE_STATUS_LIST}${parseQueryParams(data)}`)

const getUserRankList = (data, search = "", filter = "", sort = "") =>
  get(`${url.USER_RANK_LIST}${parseQueryParams(data)}${search}${filter}${sort}`)
const changeLeadStatus = (data, id) =>
  post(`${url.CHANGE_LEAD_STATUS}/${id}`, data)
const getUserList = (data, search = "", filter = "", sort = "") =>
  get(`${url.USER_LIST}${parseQueryParams(data)}${search}${filter}${sort}`)

const getLeadList = (data, search = "", filter = "", sort = "") =>
  get(`${url.LEADS_LIST}${parseQueryParams(data)}${search}${filter}${sort}`)

const getUserTypeList = (data, search = "", filter = "", sort = "") =>
  get(`${url.USER_TYPE_LIST}${parseQueryParams(data)}${search}${filter}${sort}`)

const getLeadTypeList = (data, search = "", filter = "", sort = "") =>
  get(`${url.LEAD_TYPE_LIST}${parseQueryParams(data)}${search}${filter}${sort}`)
const getCommisionByService = (data, search = "", filter = "", sort = "") =>
  get(`${url.GET_COMMISION}${parseQueryParams(data)}${search}${filter}${sort}`)

const fetchCommissionData = (data, search = "", filter = "", sort = "") =>
  get(
    `${url.FETCH_COMMISSION_DATA}${parseQueryParams(
      data
    )}${search}${filter}${sort}`
  )

const getBanksList = (data, search = "", filter = "", sort = "") =>
  get(`${url.BANKS_LIST}${parseQueryParams(data)}${search}${filter}${sort}`)

//REPORTS
const getServiceWiseReports = (data, search = "", filter = "") =>
  get(
    `${url.SERVICE_WISE_REPORT_LIST}${parseQueryParams(data)}${search}${filter}`
  )
const getServiceWiseReportsTotal = (data, search = "", filter = "") =>
  get(
    `${url.SERVICE_WISE_REPORT_LIST_TOTAL}${parseQueryParams(
      data
    )}${search}${filter}`
  )

const getServiceWiseReportsExport = (data, search = "", filter = "") =>
  get(
    `${url.SERVICE_WISE_REPORT_LIST}${parseQueryParams(
      data
    )}${search}${filter}`,
    {
      responseType: "blob",
    }
  )
const getUserWiseSalesReports = (data, search = "", filter = "") =>
  get(
    `${url.USER_WISE_SALES_REPORT_LIST}${parseQueryParams(
      data
    )}${search}${filter}`
  )
const getUserWiseSalesReportsTotal = (data, search = "", filter = "") =>
  get(
    `${url.USER_WISE_SALES_REPORT_LIST_TOTAL}${parseQueryParams(
      data
    )}${search}${filter}`
  )

const getUserWiseSalesReportsExport = (data, search = "", filter = "") =>
  get(
    `${url.USER_WISE_SALES_REPORT_LIST}${parseQueryParams(
      data
    )}${search}${filter}`,
    {
      responseType: "blob",
    }
  )
const getServiceCommissionReports = (data, search = "", filter = "") =>
  get(
    `${url.SERVICE_COMMISSION_REPORT_LIST}${parseQueryParams(
      data
    )}${search}${filter}`
  )
const getServiceCommissionReportsTotal = (data, search = "", filter = "") =>
  get(
    `${url.SERVICE_COMMISSION_REPORT_LIST_TOTAL}${parseQueryParams(
      data
    )}${search}${filter}`
  )
const getServiceCommissionReportsExport = (data, search = "", filter = "") =>
  get(
    `${url.USER_COMMISSION_REPORT_LIST}${parseQueryParams(
      data
    )}${search}${filter}`,
    {
      responseType: "blob",
    }
  )
const getUserCommissionReports = (data, search = "", filter = "") =>
  get(
    `${url.USER_COMMISSION_REPORT_LIST}${parseQueryParams(
      data
    )}${search}${filter}`
  )
const getUserCommissionReportsTotal = (data, search = "", filter = "") =>
  get(
    `${url.USER_COMMISSION_REPORT_LIST_TOTAL}${parseQueryParams(
      data
    )}${search}${filter}`
  )
const getUserCommissionReportsExport = (data, search = "", filter = "") =>
  get(
    `${url.USER_COMMISSION_REPORT_LIST}${parseQueryParams(
      data
    )}${search}${filter}`,
    {
      responseType: "blob",
    }
  )
const getPaymentCollectionsExport = (
  data,
  search = "",
  filter = "",
  sort = ""
) =>
  get(
    `${url.PAYMENT_COLLECTION_LIST}${parseQueryParams(
      data
    )}${search}${filter}${sort}`,
    {
      responseType: "blob",
    }
  )
const getPaymentCollectionsList = (data, search = "", filter = "", sort = "") =>
  get(
    `${url.PAYMENT_COLLECTION_LIST}${parseQueryParams(
      data
    )}${search}${filter}${sort}`
  )
export {
  //auth
  getLoggedInUser,
  postForgotPwd,
  postForgotPwdVerify,
  postForgotPwdReset,
  postFakeForgetPwd,
  postFakeRegister,
  isUserAuthenticated,
  postAuthLogin,
  postAuthLogout,
  postAuthFCMClear,
  postAuthFCMSave,
  postAuthFCMCheck,
  getAdminDashboardDetails,
  getAdminDashboardLeadschart,
  getAdminDashboardCommissionchart,
  getAdminDashboardSaleschart,
  getSalesDashboardDetails,
  getCommissionDashboardDetails,
  //User section
  getPlatformUsersList,
  getPlatformUsersListExport,
  getAdminProfile,
  getUserProfile,
  getUserDetails,
  addUserKyc,
  getKycDetails,
  getKycPAN,
  getNotification,
  getNotificationStatus,
  postUserCreate,
  postUserUpdate,
  postAdminProfileUpdate,
  postAccountCreate,
  deleteUser,
  postResetPasscode,
  changeUserStatus,
  getEarnings,
  getQualificationList,
  getJobList,
  getEarningHistory,
  getEarningsDetails,
  getUserAccountDetails,
  getTeamMembers,
  getUserDashboardDetails,
  updateReferrerCommision,
  // CRE users section
  getCreUsersList,
  getCreUsersListExport,
  postCreUserCreate,
  postCreUserUpdate,
  getUnassignedUsersList,
  postCreUserAssign,
  postCreUserUnassign,
  // Leads Section
  getLeadsList,
  getCusomterList,
  getLeadDetails,
  getPaymentUrl,
  postLeadCreate,
  updateNote,
  changeLeadStatus,
  createNote,
  AssignProspects,
  deleteNote,
  getCommisionByService,
  submitPaymentRequest,
  submitChangePaymentStatus,
  getLeadPaymentList,
  submitChangeServiceStatus,
  getServiceStatusesList,
  calculateLeadOwnerCommission,
  submitGenerateCreditReport,
  getCreditReport,

  // Service Category
  getServiceCategoryList,
  postServiceCategoryCreate,
  getServiceCategoryDetails,
  updateServiceCategory,
  updateServieCategoryStatus,
  deleteServiceCategory,
  // Service
  updateServiceCreate,
  getServicesList,
  getCustomerService,
  getServiceLog,
  postServiceCreate,
  getServiceDetails,
  deleteService,
  deleteCustomerService,
  deleteAttachment,
  getServiceDashboardDetails,
  getServiceDashboarLeadsChartdDetails,
  getServiceDashboarCommissionChartdDetails,
  // Commission
  getCommissionList,
  postCommisionCreate,
  putCommisionUpdate,
  getCommisionDetails,
  deleteCommision,
  putLeadUpdate,
  // Sales
  getSalesList,
  postSaleCreate,
  putSaleUpdate,
  getSaleDetails,
  deleteSale,
  //Payout
  getPayoutList,
  getPayoutListExport,
  getPayoutModesList,
  postPayoutRequestUpdate,
  postPayoutImport,
  getPayoutImportList,
  changeEarningsStatus,
  // Customer Support
  getCustomerSupportList,
  getCustomerSupportDetails,
  changeCustomerSupportStatus,
  getCustomerSupportLog,
  changeCustomerSupportCreUser,
  // Partnership Request
  getPartnerShipRequestList,
  getPartnershipRequestDetails,
  changePartnershipRequestStatus,
  changePartnershipRequestCreUser,
  getPartnershipRequestLog,
  // Tickets
  getTicketsList,
  getTicketDetails,
  changeTicketStatus,
  changeTicketCreUser,
  getTicketLog,
  // Registers list
  getServiceRankList,
  updateNotificationToken,
  getCitiesList,
  getLeadLog,
  getUserActivityLogs,
  getServiceTypeList,
  getUserTypeList,
  getStatesList,
  getCountriesList,
  getUserRankList,
  getLeadStatusesList,
  getUserList,
  getLeadList,
  getLeadTypeList,
  createAttachment,
  fetchCommissionData,
  getNotesByEntity,
  getAttachmentByEntity,
  getBanksList,
  //REPORTS
  getServiceWiseReports,
  getUserWiseSalesReports,
  getServiceCommissionReports,
  getUserCommissionReports,
  getServiceWiseReportsExport,
  getUserWiseSalesReportsExport,
  getServiceCommissionReportsExport,
  getUserCommissionReportsExport,
  getServiceWiseReportsTotal,
  getUserWiseSalesReportsTotal,
  getServiceCommissionReportsTotal,
  getUserCommissionReportsTotal,
  // PAYMENT COLLECTION
  getPaymentCollectionsList,
  getPaymentCollectionsExport,
  //PRESALE
  getPresaleList,
  postPreSaleImport,
  getPresaleDetails,
  preSaleCreateNote,
  preSaleCreateFollowupNote,
  getFollowUpNotesByEntity,
  updateFollowUpNote,
  deleteFollowUpNote,
  postPresaleCreUserAssign,
  getPreSaletatusesList,
  changePresaleStatus,
  preSaleUpdate,
  getPresaleImportList,
}
