import SelectfieldComponent from "components/Common/SelectfieldComponent"
import CustomCalander from "components/CustomCalendar/index"
import SearchSelect from "components/CustomSearchSelect"
import CustomSelect from "components/CustomSelect/index"
import {
  getCitiesList,
  getUserList,
  getUserRankList,
} from "helpers/api_actions"
import React from "react"
import { useState } from "react"
import { forwardRef, useImperativeHandle } from "react"
import { Row, Col, Button } from "reactstrap"

const UserFilterComponent = forwardRef((props, ref) => {
  UserFilterComponent.displayName = "User Filter"
  const [selectedRank, setSelectedRank] = useState(props.filters?.userModified)
  const [selectedStatus, setSelectedStatus] = useState(props.filters?.status)
  const [selectedCreatedOn, setSelectedCreatedOn] = useState(
    props.filters?.createdOn
  )
  const [selectedUpdatedOn, setSelectedUpdatedOn] = useState(
    props.filters?.updatedOn
  )
  const defaultSelection = null
  const onApplyFilter = () => {
    let filters = {
      userModified: selectedRank,
      status: selectedStatus,
      updatedOn: selectedUpdatedOn,
      createdOn: selectedCreatedOn,
    }
    props.onFilterSubmission(filters)
  }
  const onCancelFilter = () => {
    setSelectedCreatedOn(undefined)
    setSelectedRank(undefined)
    setSelectedUpdatedOn(undefined)
    setSelectedStatus(undefined)
    props.onFilterSubmission({
      userModified: undefined,
      status: undefined,
      updatedOn: undefined,
      createdOn: undefined,
    })
  }
  const statusList = [{ label: "Active", value: "Active" }]
  const getUsers = async v => {
    let payload = {
      select: "users.id,full_name",
      include: `user_type&find[user_type.name]=AdminUser&search[full_name]=${v}`,
    }

    const res = await getUserList(payload)
    const data = res?.data?.data || []
    data.forEach(item => {
      item["label"] = item.full_name
      item["value"] = item.id
    })
    return data
  }
  return (
    <>
      <Row>
        <Col lg={3} md={4} sm={6} xs={12}>
          <SearchSelect
            getData={getUsers}
            isMulti={false}
            clearable
            label="Updated By"
            setData={v => {
              setSelectedRank(v)
            }}
            value={selectedRank ?? null}
          />
        </Col>

        <Col lg={3} md={4} sm={6} xs={12}>
          <CustomSelect
            options={statusList}
            isMulti={false}
            clearable
            label="Status"
            setData={v => {
              setSelectedStatus(v)
            }}
            value={selectedStatus ?? defaultSelection}
          />
        </Col>
        <Col lg={3} md={4} sm={6} xs={12}>
          <CustomCalander
            dateRange
            value={selectedUpdatedOn}
            onChange={date => setSelectedUpdatedOn(date)}
            label="Updated Between"
            placeholder={"Start Date - End Date"}
            maxDate={"today"}
          />
        </Col>
        <Col lg={3} md={4} sm={6} xs={12}>
          <CustomCalander
            dateRange
            maxDate={"today"}
            placeholder={"Start Date - End Date"}
            value={selectedCreatedOn}
            onChange={date => setSelectedCreatedOn(date)}
            label="Created Between"
          />
        </Col>
        <Col
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className="d-flex align-items-end justify-content-end pt-3"
        >
          <div className="me-2">
            <Button
              className="btn btn-sm btn-primary btn-success text-white"
              onClick={() => onApplyFilter()}
            >
              Apply
            </Button>
          </div>
          <div>
            <Button
              className="btn btn-sm btn-danger"
              onClick={() => onCancelFilter()}
            >
              Reset
            </Button>
          </div>
        </Col>
      </Row>
    </>
  )
})

export default UserFilterComponent
