import classnames from "classnames"
import React, { useEffect, useRef, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import { Card, CardBody, CardText, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap"

import HeaderTitle from "components/Common/headerTitleComponent"
import CustomSpinner from "components/customSpinner"
import { BUTTON_LABELS, MENU_LABELS, NAV_LABELS, STATUS_LABELS } from "constants/label"
import { AUTH_ROUTES } from "constants/routes"
import { DEFAULT_CLASSES } from "constants/styles"
import { getServiceCategoryDetails, updateServieCategoryStatus } from "helpers/api_actions"
import { generateClassNameByValue, generateClassNameByValueForStatus } from "helpers/utilities"

import { USER_TAB_LABELS } from "./dataManager"
import UserDetailsComponent from "./manage/components/tabs/categoryDetails"
import Logs from "./manage/components/tabs/logs/index"
import ServiceListing from "./manage/components/tabs/services"

const UserDetails = props => {
  const state = props.location?.state?.edit
  const [edit, setEdit] = useState(false)
  const [userData, setUserData] = useState({})
  const [submiting, setSubmiting] = useState(false)
  const [activeTab, setActiveTab] = useState(USER_TAB_LABELS[0].id)
  const [isLoading, setIsLoading] = useState(true)
  const param = useParams()
  const detailRef = useRef(null)
  const headerActions = [
    // {
    //   type: "menubutton",
    //   show: [USER_TAB_LABELS[0].id, USER_TAB_LABELS[1].id],
    //   className: DEFAULT_CLASSES.HEADER_MENU_DROPDOWN,
    //   menus: [
    //     {
    //       label: MENU_LABELS.CHANGE_STATUS,
    //       action: () => {},
    //     },
    //   ],
    // },
    {
      type: "button",
      label: userData?.status === "Active" ? "Inactive" : "Active",
      className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
      action: () => {
        handleChangeStatus()
      },
      show: [
        USER_TAB_LABELS[0].id,
        // USER_TAB_LABELS[1].id
      ],
    },
    ...(edit
      ? [
          {
            type: "button",
            label: BUTTON_LABELS.CANCEL,
            className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
            action: () => {
              setEdit(false)
              fetchUserDetails()
            },
            show: [USER_TAB_LABELS[0].id],
          },
          {
            type: "button",
            label: BUTTON_LABELS.SAVE,
            className: DEFAULT_CLASSES.HEADER_BUTTON_PRIMARY,
            action: () => {
              detailRef.current.update()
            },
            show: [USER_TAB_LABELS[0].id],
          },
        ]
      : [
          {
            type: "button",
            label: BUTTON_LABELS.EDIT,
            className: DEFAULT_CLASSES.HEADER_BUTTON_PRIMARY,
            action: () => {
              setEdit(true)
            },
            show: [USER_TAB_LABELS[0].id],
          },
        ]),
  ]
  const breadcrumb = [
    { label: NAV_LABELS.SERVICE_CATEGORY, path: AUTH_ROUTES.SERVICE_CATEGORY },
    {
      label: userData?.name,
      path: `${AUTH_ROUTES.SERVICE_CATEGORY_VIEW}${param.id}`,
    },
  ]
  const tags = [
    {
      label: userData?.status || "Unknown",
      className: generateClassNameByValueForStatus(userData?.status),
    },
    // {
    //   label: userData?.platform_user_details?.rank?.name,
    //   className: generateClassNameByValue(
    //     userData?.platform_user_details?.rank?.name
    //   ),
    // },
    {
      label: userData?.display_id || "n/a",
      className: "display_id",
    },
  ]
  const update = () => {}
  const { addToast } = useToasts()

  const handleChangeStatus = () => {
    const status = userData?.status == "Active" ? "Inactive" : "Active"
    const form = new FormData()
    form.append("status", status)
    updateServieCategoryStatus(param.id, form)
      .then(res => {
        fetchUserDetails()
        addToast(res.message, { appearance: "success" })
      })
      .catch(err => {
        addToast(err.response?.data?.message, { appearance: "error" })
      })
  }
  const fetchUserDetails = () => {
    setIsLoading(true)
    getServiceCategoryDetails(param.id)
      .then(res => {
        setUserData(res.data)
        setIsLoading(false)
      })
      .catch(e => {
        setIsLoading(false)
      })
  }
  const toggleCustom = tab => {
    if (activeTab !== tab) {
      fetchTabData(tab)
    }
  }
  const fetchTabData = tab => {
    setActiveTab(tab)
    setIsLoading(true)
    switch (tab) {
      // USER_OVERVIEW
      case USER_TAB_LABELS[0].id:
        fetchUserDetails()
        setIsLoading(false)
        break
      case USER_TAB_LABELS[1].id:
        setIsLoading(false)
        break
      // USER_DETAILS
      // case USER_TAB_LABELS[1].id:
      //   setIsLoading(false)
      //   break

      default:
        break
    }
  }
  useEffect(() => {
    if (state === true) {
      setActiveTab(USER_TAB_LABELS[0].id)
      setEdit(true)
    }
    fetchUserDetails()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <HeaderTitle
              title={userData?.name}
              actions={headerActions}
              tags={tags}
              loading={isLoading}
              disabled={submiting}
              activeTab={activeTab}
              breadcrumb={breadcrumb}
            />
          </Row>
          <Row>
            <React.Fragment>
              <Col lg="12">
                <Card className="m-0">
                  <CardBody className="p-0">
                    <Nav tabs className="nav-tabs-custom ">
                      {USER_TAB_LABELS.map((tabitem, tabkey) => (
                        <NavItem key={tabkey}>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab === tabitem.id,
                            })}
                            onClick={() => {
                              toggleCustom(tabitem.id)
                            }}
                          >
                            <span className="">{tabitem.label}</span>
                          </NavLink>
                        </NavItem>
                      ))}
                    </Nav>

                    <TabContent activeTab={activeTab} className="text-muted">
                      {isLoading ? (
                        <CustomSpinner />
                      ) : (
                        <>
                          <TabPane tabId={USER_TAB_LABELS[0].id}>
                            <UserDetailsComponent
                              parentID={param.id}
                              edit={edit}
                              fetchUserDetails={fetchUserDetails}
                              data={userData}
                              setSubmiting={setSubmiting}
                              setEdit={setEdit}
                              ref={detailRef}
                            />
                          </TabPane>
                          <TabPane tabId={USER_TAB_LABELS[1].id}>
                            <ServiceListing parentID={param.id} />
                          </TabPane>
                        </>
                      )}
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </React.Fragment>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UserDetails
