import { getCreUsersList } from "./api_actions"

export const getCreUsers = async v => {
  let payload = {
    select: `users.id,users.full_name&search[full_name]=${v}`,
    include: "users",
  }
  const res = await getCreUsersList(payload)
  const data = res?.data?.data || []
  data.forEach(item => {
    item["label"] = item.full_name
    item["value"] = item.id
  })
  return data
}
