import moment from "moment"
import React, { lazy, Suspense, useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useToasts } from "react-toast-notifications"

import CustomSpinner from "components/customSpinner"
import { AUTH_ROUTES } from "constants/routes"
import { getLeadPaymentList, postCreUserUnassign } from "helpers/api_actions"

const CustomTable = lazy(() => import("components/customTable"))
function Payments(props) {
  const [pageSize, setPageSize] = useState(15)
  const [data, setData] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [pageNo, setPageNo] = useState(1)
  const [search, setSearch] = useState("")
  const [filters, setFilters] = useState({})
  const [sortKey, setSortKey] = useState("&sort_by[created_at]=DESC")
  const [appliedFilters, setAppliedFilters] = useState("")
  const [appliedSeach, setAppliedSearch] = useState("")
  const [isFetching, setIsFetching] = useState(false)
  const [unassignUser, setUnassignUser] = useState(false)
  const [selectedRow, setSelectedRow] = useState();
  const [submiting, setSubmiting] = useState(false)
  const { addToast } = useToasts()
  const navigateToDetails = (id, isEdit = false) => {
    props.history.push({
      pathname: `${AUTH_ROUTES.USER_DETAILS}${id}`,
      state: { edit: isEdit },
    })
  }
  const listPayments = params => {
    let basicData = {
      select: `id,payment_amount,status,remark,transaction_number,mode_payment,created_at,updated_at&find[lead_id]=${props.parentID}&page=${pageNo}&per_page=${pageSize}`,
    }
    setIsFetching(true)
    getLeadPaymentList(basicData, appliedSeach, appliedFilters, sortKey)
      .then(res => {
        setData(res?.data?.data)
        setTotalCount(res?.data?.total || 0)
        setIsFetching(false)
      })
      .catch(err => {
        setIsFetching(false)
      })
  }
  useEffect(() => {
    listPayments()
  }, [pageSize, pageNo, appliedSeach, sortKey, appliedFilters])

  const [columns, setColumns] = useState([
    {
      title: "Transaction ID",
      field: "transaction_number",
      minWidth: 180,
      width:180,
      wrap:true,
    },
    {
      title: "Payment Amount",
      field: "payment_amount",
      minWidth: 150,
      width: 150,
      wrap:true,
      align: "center",
      render: (row, val) => (
        <div>
          {val ? parseFloat(val).toLocaleString('en-IN') : "--"}
        </div>
      ),

    },
    {
      title: "Remarks",
      field: "remark",
      minWidth: 150,
      width: 150,
      wrap:true,
    },
    {
      title: "Mode of Payment",
      field: "mode_payment",
      minWidth: 150,
      width: 150,
      wrap:true,
    },
    {
      title: "Status",
      field: "status",
      minWidth: 180,
      width: 180,
      wrap:true,
    },
    {
      title: "Updated On",
      field: "updated_at",
      minWidth: 135,
      width: 135,
      wrap:true,
      align: "start",
      render: (row, val) => (
        <div>{val ? moment(val).format("DD-MMM-YYYY") : "--"}</div>
      ),
    },
    {
      title: "Created On",
      field: "created_at",
      minWidth: 130,
      width: 130,
      wrap:true,
      align: "start",
      //sort_direction: "ASC",
      render: (row, val) => (
        <div>{val ? moment(val).format("DD-MMM-YYYY") : "--"}</div>
      ),
    },
  ])
  
  const handleOnPageChange = p => {
    setPageNo(p.page)
    setPageSize(p.pageSize)
  }

  if (isFetching) {
    return <CustomSpinner />
  }
  return (
    <Suspense fallback={<CustomSpinner />}>
      <CustomTable
        columns={columns}
        data={data}
        currentPage={pageNo}
        totalCount={totalCount}
        pageSize={pageSize}
        pagination={{
          onPageChange: handleOnPageChange,
        }}
        isFetching={isFetching}
        detailsTable={true}
      />
    </Suspense>
  )
}

export default Payments
