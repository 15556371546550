import { TAB_LABELS } from "constants/label"

export const USER_TAB_LABELS = [
  {
    id: "service-category-summary",
    label: TAB_LABELS.SERVICE_CATEGORY_SUMMARY,
  },
  {
    id: "service-list",
    label: TAB_LABELS.SERVICE_CATEGORY_SERVICES,
  },
  // {
  //   id: "service-category-logs",
  //   label: TAB_LABELS.SERVICE_CATEGORY_LOGS,
  // },
]

export const USER_DETAILS_BREADCRUMB = {}
