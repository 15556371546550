import React, { useState, useEffect } from "react"
import { Col, Input, Modal, Row } from "reactstrap"

import CustomTextField from "components/CustomTextField/index"
import { validate } from "helpers/utilities"
import { useToasts } from "react-toast-notifications"
import CustomSelect from "components/CustomSelect/index"
import moment from 'moment';
import {
  calculateLeadOwnerCommission,
  getPayoutModesList,
  getServiceStatusesList,
} from "helpers/api_actions"
let required = ["service_status_id"]
const ChangeServiceStatus = props => {
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [serviceStatusList, setServiceStatusList] = useState([])
  const [canChangeServiceStatus, setCanChangeServiceStatus] = useState(false)
  const [commissionSubmitting, setCommissionSubmitting] = useState(false)
  const { addToast } = useToasts()
  const { leadData } = props

  const handleChange = (v, k) => {
    let requiredFields = required
    if (data?.service_status_id?.is_final === true) {
      requiredFields = [...required, "lead_owner_commission"]
      if (leadData?.is_calculate_commission) {
        requiredFields = [...required, "loan_amount"]
      }
    }
    let error = validate(requiredFields, { ...data, [k]: v }, "")
    setData(d => ({ ...d, [k]: v }))
    if (k === "loan_amount") {
      setCanChangeServiceStatus(false)
    }
    if (
      k === "lead_owner_commission" &&
      leadData?.is_calculate_commission &&
      parseInt(data?.loan_amount) < parseInt(v)
    ) {
      error = {
        ...error,
        lead_owner_commission:
          "Lead owner commission should be less than loan amount",
      }
    }
    if (Object.keys(error).length) {
      setErrors(error)
    } else {
      setErrors({})
    }
  }

  const handleSubmit = () => {
    let requiredFields = required
    if (data?.service_status_id?.is_final === true) {
      requiredFields = [...requiredFields, "lead_owner_commission"]
      if(leadData?.service?.earning_process === "Loan Flow" )
        requiredFields = [...requiredFields, "sale_amount"]
      if (leadData?.is_calculate_commission) {
        requiredFields = [...requiredFields, "loan_amount"]
      }
     
    }
    let error = validate(requiredFields, data, "")
    if (parseInt(data?.loan_amount) < parseInt(data?.lead_owner_commission)) {
      error = {
        ...error,
        lead_owner_commission:
          "Lead owner commission should be less than loan amount",
      }
    }
    if(leadData?.service?.earning_process === "Loan Flow"  && parseInt(data?.loan_amount) < parseInt(data?.sale_amount)) {
      error = {
        ...error,
        sale_amount:
          "Sale amount should be less than loan amount",
      }
    }
    if (parseInt(data?.lead_owner_commission) <= 0) {
      error = {
        ...error,
        lead_owner_commission:
          "The lead owner commission is expected to have a value greater than zero.",
      }
    }
    if (leadData?.service?.earning_process === "Loan Flow"  && parseInt(data?.sale_amount) <= 0) {
      error = {
        ...error,
        sale_amount:
          "The sale amount is expected to have a value greater than zero.",
      }
    }
    if (Object.keys(error).length === 0) {
      let submitData = {
        service_status_id: data?.service_status_id?.value,
        lead_note: data?.lead_note,
      }
      if (data?.service_status_id?.is_final === true) {
        if(leadData?.service?.earning_process === "Loan Flow"){
          submitData = {
            ...submitData,
            lead_owner_commission: data?.lead_owner_commission,
            sale_amount: data?.sale_amount,
          }
        }
        else {
          submitData = {
            ...submitData,
            lead_owner_commission: data?.lead_owner_commission,
          }
        }
       

        if (leadData?.is_calculate_commission) {
          submitData = {
            ...submitData,
            loan_amount: data?.loan_amount,
          }
        }
      }
      props.onSubmitAction(submitData)
    } else {
      setErrors(error)
    }
  }

  function handleCalculateCommission() {
    if (leadData?.id && data?.loan_amount && data?.loan_amount > 0) {
      let payload = {
        loan_amount: data?.loan_amount,
      }
      setCommissionSubmitting(true)
      calculateLeadOwnerCommission(leadData?.id, payload)
        .then(res => {
          addToast("Commission fetched successfully", { appearance: "success" })
          setData({
            ...data,
            lead_owner_commission: res?.data?.lead_owner_commission,
            sale_amount: res?.data?.sale_amount,
          })
          setCanChangeServiceStatus(true)
          setCommissionSubmitting(false)
        })
        .catch(err => {
          addToast(err.response?.data?.message, { appearance: "error" })
          setCommissionSubmitting(false)
        })
    }
  }

  useEffect(() => {
    if (!leadData?.is_calculate_commission) {
      setData({
        ...data,
        lead_owner_commission: leadData?.Own_commision,
      })
    }
  }, [leadData])

  function getServiceStatuses() {
    if (leadData?.service_id) {
      let payload = {
        select: `id,service_lead_status,status_category,is_final&find[service_id]=${leadData.service_id}&sort_by[sort_order]=asc`,
      }
      getServiceStatusesList(payload).then(res => {
        let servicesData = res?.data?.data || []
        if (servicesData && servicesData.length) {
          servicesData.forEach(item => {
            item["label"] = item.service_lead_status
            item["value"] = item.id
          })
          let filteredServiceData = servicesData.filter(serviceStatusData => {
            if (
              leadData?.service_status_id &&
              leadData?.service_status_id === serviceStatusData.id
            ) {
              return false
            }
            return true
          })
          setServiceStatusList(filteredServiceData)
        }
      })
    }
  }

  useEffect(() => {
    getServiceStatuses()
  }, [])

  return (
    <Modal isOpen={true} toggle={props.onCancelAction} centered={true}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {props.title || "Change Service Status"}
        </h5>
        <button
          type="button"
          disabled={props.submiting}
          onClick={() => {
            props.onCancelAction()
          }}
          className="btn-close"
        ></button>
      </div>
      <div className="modal-body">
        <form>
          <div className="mb-3">
            <CustomSelect
              label="Status"
              placeholder="Enter Status"
              name="service_status_id"
              value={data?.service_status_id ?? null}
              options={serviceStatusList}
              required
              onChange={value => handleChange(value, "service_status_id")}
            />
            {errors?.service_status_id && (
              <p className="text-danger"> {errors?.service_status_id}</p>
            )}
          </div>
          {data?.service_status_id?.is_final === true && (
            <>
              {leadData?.is_calculate_commission && (
                <div
                  className={`mb-3 d-flex ${
                    errors?.loan_amount
                      ? "align-items-center"
                      : "align-items-end"
                  }`}
                >
                  <div className="flex-grow-1">
                    <CustomTextField
                      label="Loan Amount"
                      type="decimal"
                      required
                      placeholder="Enter Loan Amount"
                      name="loan_amount"
                      value={data?.loan_amount ?? ""}
                      onChange={(v, k, e) => handleChange(v, k, e)}
                    />
                    {errors?.loan_amount && (
                      <p className="text-danger"> {errors?.loan_amount}</p>
                    )}
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary d-block mx-2"
                    disabled={
                      !data?.loan_amount ||
                      data?.loan_amount < 1 ||
                      commissionSubmitting
                    }
                    onClick={() => {
                      handleCalculateCommission()
                    }}
                  >
                    Calculate Commission
                  </button>
                </div>
              )}
              <div className="mb-3">
                <CustomTextField
                  label="Lead Owner Commission"
                  type="decimal"
                  required
                  placeholder="Enter Lead Owner Commission"
                  name="lead_owner_commission"
                  value={data?.lead_owner_commission ?? ""}
                  onChange={(v, k, e) => handleChange(v, k, e)}
                />
                {errors?.lead_owner_commission && (
                  <p className="text-danger">
                    {" "}
                    {errors?.lead_owner_commission}
                  </p>
                )}
              </div>
              { props.leadData?.service?.earning_process === "Loan Flow" &&
              (
                <div className="mb-3">
                <CustomTextField
                  label={`Sale Amount (As of: ${moment().format('DD-MM-YYYY')})`}
                  type="decimal"
                  required
                  placeholder="Enter Sale Amount"
                  name="sale_amount"
                  value={data?.sale_amount ?? ""}
                  onChange={(v, k, e) => handleChange(v, k, e)}
                />
                {errors?.sale_amount && (
                  <p className="text-danger">
                    {" "}
                    {errors?.sale_amount}
                  </p>
                )}
              </div>
              )}
             
            </>
          )}
          <div className="mb-3">
            <CustomTextField
              label="Remarks"
              type="textarea"
              placeholder="Enter Remarks"
              name="lead_note"
              value={data?.lead_note ?? ""}
              onChange={(v, k, e) => handleChange(v, k, e)}
            />
            {errors?.lead_note && (
              <p className="text-danger"> {errors?.lead_note}</p>
            )}
          </div>
        </form>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          data-bs-dismiss="modal"
          disabled={props.submiting}
          onClick={() => {
            props.onCancelAction()
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          disabled={
            props.submiting ||
            (data?.service_status_id?.is_final === true &&
              leadData?.is_calculate_commission &&
              !canChangeServiceStatus)
          }
          className="btn btn-primary"
          onClick={e => handleSubmit()}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}
export default ChangeServiceStatus
