import LogCard from "components/LogCard"
import CustomSpinner from "components/customSpinner/index"
import Pagination from "components/customTable/pagination/index"
import { getLeadLog } from "helpers/api_actions"
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"

function Logs(props) {
  const [data, setData] = useState([])
  const [pageSize, setPageSize] = useState(15)
  const [totalCount, setTotalCount] = useState(0)
  const [pageNo, setPageNo] = useState(1)
  const [isFetching, setIsFetching] = useState(false)
  const fetchLogs = () => {
    let payload = {
      select:
        "log_title,log_description,additional_data,change_logs.created_at,users.full_name,model",
      include: `users&find[model]=Lead&find[model_id]=${props.parentID}&page=${pageNo}&per_page=${pageSize}`,
    }
    setIsFetching(true)
    getLeadLog(payload)
      .then(res => {
        setData(res?.data?.data)
        setTotalCount(res?.data?.total || 0)
        setIsFetching(false)
      })
      .catch(err => {
        setIsFetching(false)
      })
  }
  useEffect(() => {
    fetchLogs()
  }, [pageSize, pageNo])

  const handleOnPageChange = p => {
    setPageNo(p.page)
    setPageSize(p.pageSize)
  }
  if (isFetching) {
    return <CustomSpinner />
  }

  return (
    <div>
      {" "}
      <Row style={{ overflow: "auto" }}>
        <Col lg="12">
          <Card className="m-0 detailBox">
            <CardBody className="p-3">
              <Row className="m-0">
                {data.map((item, k) => (
                  <Col xs={12} key={k}>
                    <LogCard data={item} />
                  </Col>
                ))}
              </Row>
              {data.length > 0 && (
                <Pagination
                  className="pagination-bar"
                  currentPage={pageNo}
                  totalCount={totalCount}
                  isFetching={isFetching}
                  pageSize={pageSize}
                  onPageChange={data => handleOnPageChange(data)}
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Logs
