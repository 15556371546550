import { useFormik } from "formik"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
//redux
import { useDispatch, useSelector } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Alert, Card, CardBody, Col, Container, Form, FormFeedback, Input, InputGroup, Label, Row } from "reactstrap"
import OTPInput from "otp-input-react";
// Formik Validation
import * as Yup from "yup"

import logo from "../../assets/images/logo.svg"
// import images
import profile from "../../assets/images/profile-img.png"
// action
import { userForgetPassword } from "../../store/actions"
import { postForgotPwd, postForgotPwdVerify } from "helpers/api_actions"
import { useToasts } from "react-toast-notifications"
import { generateErrorMessage } from "helpers/utilities"

const ForgetPassworVerifydPage = props => {
  //meta title
  document.title = "Forget Password | Upfin"

  const [otp, setOtp] = useState()
  const { addToast } = useToasts()

  const { forgetError, forgetSuccess } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccess: state.ForgetPassword.forgetSuccessMsg,
  }))
  const [forgetErrorMsg, setForgetErrorMsg] = useState(forgetError)
  const [forgetSuccessMsg, setForgetSuccessMsg] = useState(forgetSuccess)
  const [needResend, setNeedResend] = useState(false)
  const [seconds, setSeconds] = useState(30);

  useEffect(() => {
    if (!props.location?.state?.phone) {
        props.history.replace("/forgot-password")
    }
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
  
      if (seconds === 0) {
        setNeedResend(true)
          clearInterval(interval);
      }
    }, 1000);
  
    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  function handleResendCode(event) {
    event.preventDefault()
    const data = {
      phone: props.location.state.phone,
      code_type: "reset_password"
    }
    postForgotPwd(data).then(res => {
      addToast(res?.message, { appearance: "success" })
      setSeconds(30)
    })
    .catch(err => {
      addToast(generateErrorMessage(err?.response?.data), {
        appearance: "error",
      })
      setSeconds(30)
    })
  }

  function handleFormSubmit(event) {
    event.preventDefault()
    const otpCode = parseInt(otp)
    if (typeof otpCode !== 'number' || otpCode.toString().length !== 4) {
        return
    }

    const data = {
        code: otpCode,
        phone: props.location.state.phone
    }

    postForgotPwdVerify(data)
    .then(res => {
        addToast(res?.message, { appearance: "success" })
        props.history.replace({
            pathname: "/forgot-password/reset",
            state: { phone: props.location.state.phone, token: res?.data?.token }
        })
      })
      .catch(err => {
        addToast(generateErrorMessage(err?.response?.data), {
          appearance: "error",
        })
      })
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-softbg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Skote.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="80"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {forgetErrorMsg && forgetErrorMsg ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetErrorMsg}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}

<Form
                      className="form-horizontal"
                      onSubmit={handleFormSubmit}
                    >

                      <div className="mb-3">
                        <Label className="form-label">Reset Code</Label>
                        {/* <Input
                          name="phone"
                          startAdorement="+91"
                          className="form-control"
                          placeholder="Enter Phone No"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.phone || ""}
                          invalid={
                            validation.touched.phone && validation.errors.phone
                              ? true
                              : false
                          }
                        />
                        {validation.touched.phone && validation.errors.phone ? (
                          <FormFeedback type="invalid">
                            {validation.errors.phone}
                          </FormFeedback>
                        ) : null} */}
                        <InputGroup>
                        <OTPInput value={otp} onChange={setOtp} autoFocus OTPLength={4} otpType="number" disabled={false} />
                        
                          {/* {validation.touched.phone &&
                          validation.errors.phone ? (
                            <FormFeedback type="invalid">
                              {validation.errors.phone}
                            </FormFeedback>
                          ) : null} */}
                        </InputGroup>
                        <p className="mt-2 d-flex align-items-center">
                          <p className="m-0">Didn't receive the code?</p>
                          {
                            seconds > 0 ? (<p className="ps-1 m-0">Resend code in {seconds} seconds</p>) : (<button className="btn btn-link p-0 ms-1" disabled={!needResend} onClick={handleResendCode}>Resend Code</button>)
                          }
                          </p>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Verify
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              {/*<div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Skote. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Themesbrand
                </p>
                          </div>*/}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPassworVerifydPage.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ForgetPassworVerifydPage)
