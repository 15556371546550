import React from "react"
import { useState } from "react"
import { forwardRef, useImperativeHandle } from "react"
import { Button, Col, Row } from "reactstrap"

import CustomCalander from "components/CustomCalendar"

import CustomSelect from "../../../../../../components/CustomSelect/index"
import CustomTextField from "../../../../../../components/CustomTextField/index"

const UserFilterComponent = forwardRef((props, ref) => {
  UserFilterComponent.displayName = "User Filter"
  const [selectedCity, setSelectedCity] = useState(props.filters?.city)
  const [selectedRank, setSelectedRank] = useState(props.filters?.rank)
  const [formData, setFormData] = useState(props.filters)
  useImperativeHandle(ref, () => ({
    onApplyFilter() {
      let filters = {
        city: selectedCity,
        rank: selectedRank,
      }
      props.onFilterSubmission(filters)
    },
    onCancelFilter() {
      props.onFilterSubmission({})
    },
  }))
  const statusData = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
    { value: "On-Hold", label: "On-Hold" },
  ]
  const handleChange = (v, k, e) => {
    setFormData({ ...formData, [k]: v })
  }
  const onApplyFilter = () => {
    props.onFilterSubmission(formData)
  }
  const onCancelFilter = () => {
    setFormData({})
    props.onFilterSubmission(formData)
  }
  const handleDataChange = (value, name) => {
    setFormData({ ...formData, [name]: value })
  }
  return (
    <>
      <Row>
        <Col lg={3} md={4} sm={6} xs={12}>
          <CustomTextField
            label="name"
            disabled={props.isReadOnly}
            placeholder="Name"
            type="text"
            name="name"
            onChange={(v, k, e) => handleChange(v, k, e)}
            value={formData?.name}
          />
        </Col>
        <Col lg={3} md={4} sm={6} xs={12}>
          <CustomSelect
            label="Status"
            options={statusData}
            value={formData?.status ?? null}
            setData={data => handleChange(data, "status")}
          />
        </Col>
        <Col lg={3} md={4} sm={6} xs={12}>
          <CustomCalander
            label="Updated Between"
            disabled={props.isReadOnly}
            maxDate={"today"}
            dateRange
            placeholder={"Start Date - End Date"}
            value={formData?.modified_at ?? ""}
            onChange={d => handleDataChange(d, "modified_at")}
          />
        </Col>
        <Col lg={3} md={4} sm={6} xs={12}>
          <CustomCalander
            label="Created Between"
            disabled={props.isReadOnly}
            dateRange
            maxDate={"today"}
            placeholder={"Start Date - End Date"}
            value={formData?.created_at ?? ""}
            onChange={d => handleDataChange(d, "created_at")}
          />
        </Col>
        <Col
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className="d-flex align-items-end justify-content-end pt-3"
        >
          <div className="me-2">
            <Button
              className="btn btn-sm btn-primary btn-success text-white"
              onClick={() => onApplyFilter()}
            >
              Apply
            </Button>
          </div>
          <div>
            <Button
              className="btn btn-sm btn-danger"
              onClick={() => onCancelFilter()}
            >
              Reset
            </Button>
          </div>
        </Col>
      </Row>
    </>
  )
})

export default UserFilterComponent
