import moment from "moment"
import React, { useEffect, useState } from "react"
import { useRef } from "react"
import Flatpickr from "react-flatpickr"
import { InputGroup } from "reactstrap"

import CustomTextField from "components/CustomTextField"

function CustomCalander(props) {
  const [selectedDate, setSelectedDate] = useState(props.value ?? undefined)
  const [error, setError] = useState(true)
  const fp = useRef()
  useEffect(() => {
    setSelectedDate(props.value)
  }, [props.value])

  const getStringifiedValue = () => {
    let value = ""
    // let preData = props.value
    if (props.value) {
      if (props.value.length === 2) {
        value =
          moment(props.value[0]).format("DD-MM-YYYY") +
          " to " +
          moment(props.value[1]).format("DD-MM-YYYY")
      }
      if (props.value.length === 1) {
        value = moment(props.value[0]).format("DD-MM-YYYY")
      }
    }

    return value
  }

   const clearData = () => {
    setSelectedDate(undefined);
    props.onChange([]); 
  };
  console.log("selectedDate", selectedDate)
  return (
    <>
      {props.disabled ? (
        <CustomTextField
          label={props.label}
          disabled
          value={getStringifiedValue()}
          placeholder={props.placeholder ?? "DD-MM-YYYY"}
        />
      ) : (
        <>
          {props.label && (
            <p className="mb-1 mt-3 font-weight-bold">{props.label}</p>
          )}
          <InputGroup>
            <Flatpickr
              ref={fp}
              value={selectedDate}
              readOnly={props.disabled}
              className={`form-control d-block ${
                props.required ? "required" : ""
              }`}
              placeholder={props.placeholder ?? "DD-MM-YYYY"}
              options={{
                altInput: props.disabled ? false : true,
                // altFormat: "F j, Y",
                ...(props.dateRange && { mode: "range" }),
                minDate: props.minDate,
                maxDate: props.maxDate,
                defaultDate: props.maxDate ?? "today",
                dateFormat: "Y-m-d",
                altInputClass: `form-control d-block ${
                  props.required ? "required" : ""
                }`,
              }}
              onChange={date => {
                setSelectedDate(date);
                props.onChange(date)
              }}
            />
            {(selectedDate && selectedDate.length > 0) &&(<div className="input-group-append">
              <button
                type="button"
                onClick={() => clearData()}
                className="btn btn-outline-primary"
              >
                x
              </button>
            </div>)}
            
            <div className="input-group-append">
              <button
                type="button"
                onClick={() => fp.current.flatpickr.open()}
                className="btn btn-outline-primary docs-datepicker-trigger"
                // disabled
              >
                <i className="fa fa-calendar" aria-hidden="true" />
              </button>
            </div>
          </InputGroup>
        </>
      )}
    </>
  )
}

export default CustomCalander
