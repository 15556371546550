import React from "react"
import { Redirect } from "react-router-dom"

import { AUTH_ROUTES } from "constants/routes"
// Authentication related pages
import ForgetPwd from "pages/Authentication/ForgetPassword"
import ForgetPwdVerify from "pages/Authentication/ForgotPasswordVerify"
import Login from "pages/Authentication/Login"
import Logout from "pages/Authentication/Logout"
import Register from "pages/Authentication/Register"
import ResetPwd from "pages/Authentication/ResetPassword"
// Business
import Business from "pages/Business"
import CreateBusinessLead from "pages/Business/manage/create"
import BusinessDetails from "pages/Business/view"
// Commisssion Management
import CommissionManagement from "pages/CommissionManagement"
// Contacts Management
import ContactsManagement from "pages/ContactsManagement"
import CreateContactLead from "pages/ContactsManagement/manage/create"
import ContactDetails from "pages/ContactsManagement/view"
import CreUsers from "pages/CreUsers"
import AssignUsers from "pages/CreUsers/assignUsers"
import CreateCreUser from "pages/CreUsers/manage/create"
import CreUserDetails from "pages/CreUsers/view"
import CustomerSupport from "pages/CustomerSupport/index"
import CustomerSupportDetails from "pages/CustomerSupport/view"
//  // Inner Authentication

// Dashboard
import Dashboard from "pages/Dashboard/index"
// Lead
import Leads from "pages/Leads"
import Customer from "pages/Customer"
import CustomerDetails from "pages/Customer/view"
import CreateLeads from "pages/Leads/manage/create"
import LeadDetails from "pages/Leads/view"
import PartnershipRequest from "pages/PartnershipRequests/index"
import PartnershipRequestDetails from "pages/PartnershipRequests/view"
// Payout
import Payout from "pages/Payout"
import PayoutImportHistory from "pages/Payout/importHistory"
import AdminProfile from "pages/Profile/index"
import ComissionReports from "pages/Reports/ComissionReports/index"
import UserComissionReports from "pages/Reports/UserComissionReports/index"
import UserSaleReports from "pages/Reports/UserSaleReports/index"
import Report from "pages/Reports/index"
// Service Category
import ServiceCategory from "pages/ServiceCategory"
import CreateServiceCategory from "pages/ServiceCategory/manage/create"
import ServiceCategoryDetails from "pages/ServiceCategory/view"
// Service Management
import ServiceManagement from "pages/ServiceManagement"
import CreateService from "pages/ServiceManagement/manage/create"
import ServiceDetails from "pages/ServiceManagement/view"
import Tickets from "pages/Tickets/index"
import TicketDetails from "pages/Tickets/view"
import Users from "pages/Users"
// User
import CreateUser from "pages/Users/manage/create"
import UserDetails from "pages/Users/view"
// PaymentCollection
import PaymentCollection from "pages/PaymentCollection/index"
import PreSales from "pages/PreSales/index"
import PresaleDetails from "pages/PreSales/component/view"
import PresaleImportLogs from "pages/PresaleImportLogs/index"

const authProtectedRoutes = [
  // Dashboard
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  { path: AUTH_ROUTES.DASHBOARD, component: Dashboard },
  { path: AUTH_ROUTES.PROFILE, component: AdminProfile },
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Users
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  { path: AUTH_ROUTES.USERS, component: Users },
  ////// detail
  { path: `${AUTH_ROUTES.USER_DETAILS}:id`, component: UserDetails },
  ////// create
  {
    path: AUTH_ROUTES.CREATE_NEW_USER,
    component: CreateUser,
    isAdminOnly: true,
  },
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // CRE Users
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  { path: AUTH_ROUTES.CRE_USERS, component: CreUsers, isAdminOnly: true },
  ////// detail
  {
    path: `${AUTH_ROUTES.CRE_USER_DETAILS}:id`,
    component: CreUserDetails,
    isAdminOnly: true,
  },
  ////// create
  {
    path: AUTH_ROUTES.CREATE_NEW_CRE_USER,
    component: CreateCreUser,
    isAdminOnly: true,
  },
  ////// assign
  {
    path: `${AUTH_ROUTES.ASSIGN_CRE_USERS}:id`,
    component: AssignUsers,
    isAdminOnly: true,
  },
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Customer
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  { path: AUTH_ROUTES.CUSTOMER, component: Customer },
  {
    path: `${AUTH_ROUTES.CUSTOMER_LEADS}:id`,
    component: CustomerDetails,
  },

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Leads
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  { path: AUTH_ROUTES.LEADS, component: Leads },
  // create
  {
    path: AUTH_ROUTES.CREATE_NEW_LEAD,
    component: CreateLeads,
    isAdminOnly: true,
  },
  { path: `${AUTH_ROUTES.LEAD_DETAILS}:id`, component: LeadDetails },
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Business
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  { path: AUTH_ROUTES.BUSINESS, component: Business },
  {
    path: AUTH_ROUTES.CREATE_NEW_BUSINESS_LEAD,
    component: CreateBusinessLead,
    isAdminOnly: true,
  },
  { path: `${AUTH_ROUTES.BUSINESS_DETAILS}:id`, component: BusinessDetails },
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Commission Management
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  {
    path: AUTH_ROUTES.COMMISSION_MANAGEMENT,
    component: CommissionManagement,
    isAdminOnly: true,
  },
  {
    path: `${AUTH_ROUTES.COMMISSION_MANAGEMENT_SERVICES}:id`,
    component: ServiceDetails,
    isAdminOnly: true,
  },
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Contacts Management
  { path: AUTH_ROUTES.CONTACTS_MANAGEMENT, component: ContactsManagement },
  {
    path: AUTH_ROUTES.CREATE_NEW_CONTACT_LEAD,
    component: CreateContactLead,
    isAdminOnly: true,
  },
  { path: `${AUTH_ROUTES.CONTACTS_DETAILS}:id`, component: ContactDetails },
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Service Category
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  {
    path: AUTH_ROUTES.SERVICE_CATEGORY,
    component: ServiceCategory,
    isAdminOnly: true,
  },
  {
    path: `${AUTH_ROUTES.SERVICE_CATEGORY_VIEW}:id`,
    component: ServiceCategoryDetails,
    isAdminOnly: true,
  },
  {
    path: AUTH_ROUTES.CREATE_SERVICE_CATEGORY,
    component: CreateServiceCategory,
    isAdminOnly: true,
  },
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Service Management
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  {
    path: AUTH_ROUTES.SERVICE_MANAGEMENT,
    component: ServiceManagement,
    isAdminOnly: true,
  },
  {
    path: AUTH_ROUTES.CREATE_SERVICE,
    component: CreateService,
    isAdminOnly: true,
  },
  {
    path: `${AUTH_ROUTES.SERVICE_MANAGEMENT_DETAILS}:id`,
    component: ServiceDetails,
    isAdminOnly: true,
  },
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //Payout
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  { path: AUTH_ROUTES.PAYOUT, component: Payout },
  { path: AUTH_ROUTES.PAYOUT_IMPORT_HISTORY, component: PayoutImportHistory },
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //Customer Support
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  { path: AUTH_ROUTES.CUSTOMER_SUPPORT, component: CustomerSupport },
  {
    path: `${AUTH_ROUTES.CUSTOMER_SUPPORT_DETAILS}:id`,
    component: CustomerSupportDetails,
  },
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //Partnership Requests
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  { path: AUTH_ROUTES.PARTNERSHIP_REQUESTS, component: PartnershipRequest },
  {
    path: `${AUTH_ROUTES.PARTNERSHIP_REQUEST_DETAILS}:id`,
    component: PartnershipRequestDetails,
  },
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //Tickets
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  { path: AUTH_ROUTES.TICKETS, component: Tickets },
  {
    path: `${AUTH_ROUTES.TICKET_DETAILS}:id`,
    component: TicketDetails,
  },
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Reports
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  { path: AUTH_ROUTES.REPORTS_SALES, component: Report },
  { path: AUTH_ROUTES.REPORTS_COMISSION, component: ComissionReports },
  { path: AUTH_ROUTES.REPORTS_SALES_USER, component: UserSaleReports },
  { path: AUTH_ROUTES.REPORTS_COMISSION_USER, component: UserComissionReports },
  {
    path: `${AUTH_ROUTES.SALES_REPORT_SERVICE_MANAGEMENT_DETAILS}:id`,
    component: ServiceDetails,
    isAdminOnly: true,
  },
  {
    path: `${AUTH_ROUTES.COMMISSION_REPORT_SERVICE_MANAGEMENT_DETAILS}:id`,
    component: ServiceDetails,
    isAdminOnly: true,
  },
  {
    path: `${AUTH_ROUTES.SALES_REPORT_USER_MANAGEMENT_DETAILS}:id`,
    component: UserDetails,
    isAdminOnly: true,
  },
  {
    path: `${AUTH_ROUTES.COMMISSION_REPORT_USER_MANAGEMENT_DETAILS}:id`,
    component: UserDetails,
    isAdminOnly: true,
  },

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // PaymentCollection
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  { path: AUTH_ROUTES.PAYMENT_COLLECTION, component: PaymentCollection },
  {
    path: `${AUTH_ROUTES.PAYMENT_COLLECTION_LEAD_DETAILS}:id`,
    component: LeadDetails,
  },
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Presales
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  { path: AUTH_ROUTES.PRESALES, component: PreSales },
  { path: `${AUTH_ROUTES.PRESALEDETAILS}:id`, component: PresaleDetails },
  { path: `${AUTH_ROUTES.PRESALEDETAILS}:id/edit`, component: PresaleDetails },
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Presales Import Log
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  { path: AUTH_ROUTES.IMPORT_LOG, component: PresaleImportLogs },
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // unknown
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  { path: "*", component: () => <Redirect to={AUTH_ROUTES.DASHBOARD} /> },
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
]
const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/forgot-password/verify", component: ForgetPwdVerify },
  { path: "/forgot-password/reset", component: ResetPwd },
  { path: "/register", component: Register },
]

export { authProtectedRoutes, publicRoutes }
