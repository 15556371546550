import React from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import moment from "moment"
import ReactHtmlParser from "react-html-parser"
function TicketLogCard({ data = {} }) {
  return (
    <div className="w-100 my-2 log-card">
      <Row className="bg-white white-card">
        <Col xs={2} className="left">
          <div className="d-flex flex-column justify-content-center h-100">
            <div className="label">Created on </div>
            <div className="label">
              {data?.created_at
                ? moment(data.created_at).format("DD-MMM-YYYY hh:mm a")
                : "--"}{" "}
            </div>
            <div className="value">{data?.change_by ?? "--"}</div>
          </div>
        </Col>
        <Col xs={10} className="right">
          <div className="d-flex flex-column align-items-between ">
            <div className="py-1 event d-flex align-items-center text-primary">
              {data?.title ?? "--"}
            </div>
            <div className="py-1 d-flex align-items-center desc">
              <p className="m-0 ">
                {data?.note ? <div> {ReactHtmlParser(data.note)}</div> : "--"}
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default TicketLogCard
