import moment from "moment"
import React, { lazy, Suspense, useEffect, useImperativeHandle, useState } from "react"
import { forwardRef } from "react"
import { useToasts } from "react-toast-notifications"
import Swal from "sweetalert2"

import CustomSpinner from "components/customSpinner"
import { AUTH_ROUTES } from "constants/routes"
import { deleteCommision, getCommisionDetails, getCommissionList, putCommisionUpdate } from "helpers/api_actions"
import { generateErrorMessage, validate } from "helpers/utilities"

import AddCommision from "../../modal/addCommision"
import UserFilterComponent from "./filterComponent"

const CustomTable = lazy(() => import("components/customTable"))
const CommissionListing = forwardRef((props, ref) => {
  CommissionListing.displayName = "commission listing"
  const { addToast } = useToasts()
  const [pageSize, setPageSize] = useState(15)
  const [data, setData] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [pageNo, setPageNo] = useState(1)
  const [search, setSearch] = useState("")
  const [filters, setFilters] = useState({})
  const [sortKey, setSortKey] = useState("")
  const [appliedFilters, setAppliedFilters] = useState("")
  const [activeRow, setActiveRow] = useState({})
  const [openEdit, setOpenEdit] = useState(false)
  const [submiting, setSubmitting] = useState(false)
  const [appliedSeach, setAppliedSearch] = useState("")
  const [isFetching, setIsFetching] = useState(false)
  const serviceData = props.serviceData

  const listUsers = params => {
    let basicData = {
      select:
        "services.id as service_id,commisions.id,services.name as Service Name,commisions.start_date as Start Date,commisions.end_date as End Date,commisions.value as Direct Commission,Referrer Commission,Leads Processed,commisions.status as Status,user_modified.full_name as Updated By,commisions.modified_at as Updated On,commisions.created_at as Created On",
      include: `services,users as user_created,users as user_modified&find[service_id]=${props.parentID}&sort_by[commisions.start_date]=DESC`,
    }
    setIsFetching(true)
    getCommissionList(basicData, appliedSeach, appliedFilters, sortKey)
      .then(res => {
        setData(res?.data?.data)
        setTotalCount(res?.data?.total || 0)
        setIsFetching(false)
      })
      .catch(err => {
        setIsFetching(false)
      })
  }
  useEffect(() => {
    listUsers()
  }, [pageSize, pageNo, appliedSeach, sortKey, appliedFilters])
  useImperativeHandle(ref, () => ({
    refetch() {
      listUsers()
    },
  }))
  const handleUpdateCommision = data => {
    let payload = {
      // status: data?.status?.value ?? null,
      start_date: data?.start_date
        ? moment(data.start_date[0]).format("YYYY-MM-DD")
        : null,
      end_date: data?.end_date
        ? moment(data.end_date[0]).format("YYYY-MM-DD")
        : null,
      own_commision: data?.own_commision ?? null,
      calculation: data?.calculation?.value ?? null,
      service_id: data?.service_id ?? null,
    }
    setSubmitting(true)

    let required = ["start_date", "end_date", "own_commision", "calculation"]
    const errors = validate(required, payload, "")
    if (Object.keys(errors).length === 0) {
      if (
        data?.calculation?.value === "Percentage" &&
        (data?.own_commission < 1 || data?.own_commision > 100)
      ) {
        setSubmitting(false)
        addToast("Lead Owner Commission should be between 1 and 100", {
          appearance: "error",
        })
      } else {
        putCommisionUpdate(data?.id, payload)
          .then(res => {
            addToast(res?.message, { appearance: "success" })
            listUsers()
            setSubmitting(false)
            setOpenEdit(false)
            setActiveRow({})
          })
          .catch(err => {
            setSubmitting(false)
            addToast(generateErrorMessage(err.response?.data), {
              appearance: "error",
            })
          })
      }
    } else {
      setSubmitting(false)
      addToast("Please fill all required fields", { appearance: "error" })
    }
  }
  const fetchEditDetails = row => {
    let rowData = {}
    getCommisionDetails(row.id)
      .then(re => {
        let res = re.data
        rowData = {
          display_id: res["display_id"] ?? null,
          created_by: res["created_by"] ?? null,
          modified_by: res["modified_by"] ?? null,
          end_date: res["end_date"] ? [new Date(res["end_date"])] : null,
          start_date: res["start_date"] ? [new Date(res["start_date"])] : null,
          own_commision: res["own_commision"] ?? null,
          service: res["service"] ?? null,
          id: res["id"] ?? null,
          service_id: res["service"]["id"] ?? null,
          calculation: res["calculation"]
            ? { label: res["calculation"], value: res["calculation"] }
            : null,
          service_amount: res["service_amount"] ?? null,
          status: row.Status
            ? {
                label: row.Status,
                value: row.Status,
              }
            : null,
        }
        setActiveRow(rowData)
        setOpenEdit(true)
      })
      .catch(err => {
        addToast(err.response?.data?.message, { appearance: "error" })
      })
  }
  const closeEdit = () => {
    setOpenEdit(false)
    setActiveRow({})
  }

  const handleInactive = data => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then(result => {
      if (result.isConfirmed) {
        deleteCommision(data.id)
          .then(res => {
            addToast(res?.message, { appearance: "success" })
            listUsers()
          })
          .catch(err => {
            addToast(err.response?.data?.message, { appearance: "error" })
          })
      }
    })
  }

  const handleTableMenu = (action, rowData) => {
    switch (action.action) {
      case "edit":
        fetchEditDetails(rowData)
        break

      case "inactive":
        handleInactive(rowData)
        break
      default:
        break
    }
  }
  const [columns, setColumns] = useState([
    {
      title: "Start Date",
      field: "Start Date",
      align: "left",
      minWidth: 135,
      width: 200,
      maxWidth:200,
      wrap: true,
      sort_key: "users.full_name",
      tooltip_text: "Starting date for the corresponding Commission Rate",
      render: (row, val) => (
        <div>{val ? moment(val).format("DD-MMM-YYYY") : "--"}</div>
      ),
    },
    {
      title: "End Date",

      field: "End Date",
      align: "left",
      minWidth: 135,
      width: 200,
      maxWidth:200,
      wrap: true,
      tooltip_text: "End date for the corresponding Commission Rate",
      render: (row, val) => (
        <div>{val ? moment(val).format("DD-MMM-YYYY") : "--"}</div>
      ),
    },
    {
      title: "Direct Commission",
      field: "Direct Commission",
      minWidth: 190,
      width: 200,
      maxWidth:200,
      wrap: true,
      align: "center",
      tooltip_text:
        "Commission amount for the user when the user gives the leads for a service",
    },

    {
      title: "Referrer Commission",
      field: "referrercommission",
      minWidth: 200,
      width: 230,
      wrap: true,
      align: "center",
      tooltip_text:
        "Commission amount for a referrer user when one of his referees gives the leads for a service",
    },
    {
      title: "Leads Processed",
      field: "Leads Processed",
      sort_key: "Leads Processed",
      minWidth: 210,
      width: 230,
      wrap: true,
      align: "center",
      tooltip_text:
        "Number of 'Completed Leads' during the corresponding commission period for the service",
    },
    {
      title: "Status",
      field: "Status",
      minWidth: 110,
      width: 150,
      maxWidth:150,
      wrap: true,
      align: "start",
    },
    {
      title: "Updated By",
      field: "Updated By",
      minWidth: 150,
      width: 250,
      maxWidth:250,
      wrap: true,
      sort_key: "user_modified.full_name",
      // align: "end",
    },
    {
      title: "Updated On",
      field: "Updated On",
      minWidth: 165,
      width: 220,
      maxWidth:220,
      wrap: true,
      sort_key: "commisions.modified_at",
      render: (row, val) => (
        <div>{val ? moment(val).format("DD-MMM-YYYY HH:mm") : "--"}</div>
      ),
      // align: "end",
    },
    {
      title: "Created On",
      field: "Created On",
      sort_key: "commisions.created_at",
      minWidth: 165,
      width: 220,
      maxWidth:220,
      wrap: true,
      render: (row, val) => (
        <div>{val ? moment(val).format("DD-MMM-YYYY HH:mm") : "--"}</div>
      ),
      // align: "end",
    },
  ])

  const handleSort = row => {
    let nextDirection = row.sort_direction
      ? row?.sort_direction === "ASC"
        ? "DESC"
        : "ASC"
      : "ASC"
    columns.forEach(col => {
      if (col.field === row.field) {
        col["sort_direction"] = nextDirection
      } else {
        col["sort_direction"] = undefined
      }
    })
    setColumns(() => columns)

    let sortkey = `&sort_by[${row.sort_key}]=${nextDirection}`
    setSortKey(sortkey)
  }
  const handleSearch = val => {
    setSearch(val)
    let searchString =
      val && val !== ""
        ? `&search[users.full_name]=${val}&search[users.primary_phone]=${val}&search[user_addresses.city]=${val}&search[platform_users.secondary_phone]=${val}&search[platform_users.secondary_email]=${val}&search[platform_users.job]=${val}&search[platform_users.business_name]=${val}&search[user_addresses.address_line_1]=${val}&search[user_addresses.address_line_2]=${val}&search[user_addresses.state]=${val}&search[user_addresses.pincode]=${val}&search[user_addresses.country]=${val}&search[users.display_id]=${val}`
        : undefined
    setPageNo(1)
    setAppliedSearch(searchString)
  }

  const onFilterSubmission = fil => {
    setFilters(fil)
    let filterString = undefined
    filterString = `${
      fil?.userModified?.value
        ? "&filter[user_modified.id]=" + fil?.userModified?.value
        : ""
    }${
      fil?.status?.value
        ? "&filter[commisions.status]=" + fil?.status?.value
        : ""
    }${
      fil?.updatedOn?.length === 2
        ? "&filter[commisions.modified_at]=" +
          moment(fil.updatedOn[0]).format("YYYY-MM-DD") +
          " - " +
          moment(fil.updatedOn[1]).format("YYYY-MM-DD")
        : ""
    }${
      fil?.createdOn?.length === 2
        ? "&filter[commisions.created_at]=" +
          moment(fil.createdOn[0]).format("YYYY-MM-DD") +
          " - " +
          moment(fil.createdOn[1]).format("YYYY-MM-DD")
        : ""
    }`
    setPageNo(1)
    setAppliedFilters(filterString ?? "")
  }
  const getTableActions = rowData => {
    const actions = [];
    if (rowData.Status === 'Active') {
      actions.push({
        icon: <i className="mr-1" />,
        action: "edit",
        name: "Edit",
        onClick: handleTableMenu,
      });
    }
    if (rowData.Status !== 'InActive') {
      actions.push({
        icon: <i className="mr-1 text-danger" />,
        action: "inactive",
        name: "Deactivate",
        class: "text-danger",
        onClick: handleTableMenu,
      });
    }
  
    return actions;
  }
  
  const handleOnPageChange = p => {
    setPageNo(p.page)
    setPageSize(p.pageSize)
  }
  if (isFetching) {
    return <CustomSpinner />
  }
  return (
    <>
      {openEdit && (
        <AddCommision
          name={activeRow?.service?.name}
          submiting={submiting}
          edit
          data={activeRow}
          onCancelAction={closeEdit}
          onSubmitAction={handleUpdateCommision}
          serviceData={serviceData}
        />
      )}
      <Suspense fallback={<CustomSpinner />}>
        <CustomTable
          columns={columns}
          data={data}
          actions={rowData => {
            return getTableActions(rowData)
          }}
          currentPage={pageNo}
          totalCount={totalCount}
          pageSize={pageSize}
          search={search}
          filters={filters}
          onSearch={handleSearch}
          onFilterSubmission={onFilterSubmission}
          filterComponent={UserFilterComponent}
          pagination={{
            onPageChange: handleOnPageChange,
          }}
          isFetching={isFetching}
          onSort={handleSort}
          detailsTable={true}
        />
      </Suspense>
    </>
  )
})

export default CommissionListing
