import React, { useEffect, useState } from "react"
import { useToasts } from "react-toast-notifications"
import { Col, Input, Modal, Row } from "reactstrap"

import SearchSelect from "components/CustomSearchSelect/index"
import CustomSelect from "components/CustomSelect/index"
import CustomTextField from "components/CustomTextField/index"
import { getUserList } from "helpers/api_actions"
import { validate } from "helpers/utilities"

import Earnings from "../index"

const HoldEarnings = props => {
  const [formData, setFormData] = useState(props.data || {})
  const [autherisedList, setautherisedList] = useState([])

  const handleChange = (v, k) => {
    setFormData({ ...formData, [k]: v })
  }

  const statusList = [
    {
      value: "On Hold",
      label: "On Hold",
    },
  ]

  const statusList2 = [
    {
      value: "Processing",
      label: "Processing",
    },
    {
      value: "Approved",
      label: "Approved",
    },
  ]
  const getUser = async () => {
    let payload = {
      select: "users.id,full_name",
      include: `user_type&find[user_type.name]=AdminUser&search[full_name]=`,
    }
    const res = await getUserList(payload)
    const data = res?.data?.data || []

    data.forEach(item => {
      item["label"] = item.full_name
      item["value"] = item.id
    })
    setautherisedList(data)
  }
  useEffect(() => {
    const data = {}
    getUser()
    setFormData({
      ...formData,
      status:
        props.data.Status === "On Hold"
          ? { ...statusList2[0] }
          : { ...statusList[0] },
    })
  }, [])
  const { addToast } = useToasts()

  const handleValidate = () => {
    let required = ["status", "changed_by"]

    const errors = validate(required, formData, "")

    if (Object.keys(errors)?.length === 0) {
      props.onSubmitAction(formData)
    } else {
      addToast("Please fill all required fields", { appearance: "error" })
    }
  }

  return (
    <Modal isOpen={true} toggle={props.onCancelAction} centered={true}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {props.data.Status === "On Hold"
            ? "Un-Hold Earning"
            : "Hold Earnings"}
        </h5>
        <button
          type="button"
          disabled={props.submiting}
          onClick={() => {
            props.onCancelAction()
          }}
          className="btn-close"
        ></button>
      </div>
      <div className="modal-body">
        <form>
          <div className=" mb-3">
            <CustomSelect
              required
              label="Lead Status"
              key={props.data.Status}
              options={
                props.data.Status === "On Hold" ? statusList2 : statusList
              }
              setData={v => {
                handleChange(v, "status")
              }}
              value={formData?.status ?? null}
            />
          </div>
          <div className=" mb-3">
            <CustomSelect
              required
              label="Change Authorised By"
              options={autherisedList}
              setData={v => {
                handleChange(v, "changed_by")
              }}
              value={formData?.changed_by ?? null}
            />
          </div>

          <div className=" mb-3">
            <CustomTextField
              label="General Notes"
              name="note"
              type="textarea"
              value={formData?.note ?? ""}
              placeholder="Enter Notes"
              onChange={(v, k, e) => handleChange(v, k, e)}
            />
          </div>
        </form>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          data-bs-dismiss="modal"
          disabled={props.submiting}
          onClick={e => {
            props.onCancelAction(e)
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          disabled={props.submiting}
          className="btn btn-primary"
          onClick={e => handleValidate()}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}
export default HoldEarnings
