import React from "react"
import "./table.skelton.style.css"
function TableSkelton() {
  return (
    <div className="table--isloading">
      <div className="loading-image"></div>
    </div>
  )
}

export default TableSkelton
