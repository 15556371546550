import debounce from "lodash.debounce"
import React, { useEffect, useState } from "react"
import AsyncSelect from "react-select/async"

import CustomTextField from "components/CustomTextField"

const SearchSelect = props => {
  const [query, setQuery] = useState("")
  const [disableSearchWhenNotEmpty, setDisableSearchWhenNotEmpty] = useState(false)

  const [defaultOptions, setDefaultOptions] = useState(props.value)

  useEffect(() => {
    if (props.value) {
      setDefaultOptions(props.value)
      if(props.disableSearchWhenNotEmpty){
        setDisableSearchWhenNotEmpty(true)
      }
    }
  }, [props.value])

  const loadOptions = async (inputValue, callback) => {
    setQuery(inputValue)

    const res = await props.getData(inputValue)
    return res
    if (res) {
      callback(res)
    } else {
      callback([])
    }

  }

  // const loadSuggestions = debounce(loadOptions, 1000)
  return (
    <>
      {props.disabled ? (
        <>
          <CustomTextField
            label={props.label}
            disabled
            required={props.required}
            value={props.value?.label}
            placeholder={props.placeholder ?? "Select"}
          />
        </>
      ) : (
        <>
          {props.label && (
            <p className="mb-1 mt-3 font-weight-bold">{props.label}</p>
          )}
          <AsyncSelect
            cacheOptions
            menuPlacement={props.menuPlacement ?? "auto"}
            style={{ overflow: "auto" }}
            isMulti={props.isMulti ?? false}
            className={props.required ? "required" : ""}
            defaultOptions={true}
            clearValue={() => props.setData(null)}
            classNamePrefix="react-select-search"
            getOptionLabel={e => e?.label}
            isDisabled={props.disabled}
            placeholder={props.placeholder ?? "Search and Select"}
            getOptionValue={e => e?.value}
            isClearable={props.isClearable ?? true}
            loadOptions={loadOptions}
            value={defaultOptions}
            onInputChange={value => setQuery(value)}
            onChange={value => props.setData(value)}
            required={props.required}
            {...props}
          />
        </>
      )}
    </>
  )
}

export default SearchSelect
