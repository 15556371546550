import React, { useEffect, useState } from "react"
import { Col, Input, Modal, Row } from "reactstrap"

import CustomTextField from "components/CustomTextField/index"
import { validate } from "helpers/utilities"

let required = ["amount"]
const RequestPayment = props => {
  const [data, setData] = useState(props.data || {})
  const [errors, setErrors] = useState({})

  const handleChange = (v, k) => {
    let error = validate(required, { ...data, [k]: v }, "")
    const errors = {}

    setData(d => ({ ...d, [k]: v }))
    if (Object.keys(error).length) {
      setErrors(error)
    } else {
      if (k === "amount") {
        if (v <= 0) {
          errors.amount = "Amount should be greater than 0"
          setErrors(errors)
        } else {
          setErrors({})
        }
      } else {
        setErrors({})
      }
    }
  }

  const handleSubmit = () => {
    let error = validate(required, data, "")

    // URL validation
    if (data?.payment_url) {
      const urlPattern = /^(https?:\/\/)?([a-z0-9-]+\.)+[a-z]{2,6}([-a-zA-Z0-9@:%_\+.~#?&//=]*)?$/i;
      if (!urlPattern.test(data?.payment_url)) {
        error = {
          ...error,
          payment_url: "Please enter a valid URL",
        };
      }
    }
    
    if (data?.amount <= 0) {
      error = {
        ...error,
        amount: "Amount should be greater than 0",
      }
    }
    setErrors(error)
    if (Object.keys(error).length === 0) {
      props.onSubmitAction(data)
    }
  }
  
  return (
    <Modal isOpen={true} toggle={props.onCancelAction} centered={true}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {props.title || "Request Payment"}
        </h5>
        <button
          type="button"
          disabled={props.submiting}
          onClick={() => {
            props.onCancelAction()
          }}
          className="btn-close"
        ></button>
      </div>
      <div className="modal-body">
        <p>Service Amount: {props.service?.start_amount ? parseFloat(props.service.start_amount).toLocaleString('en-IN') : "N/A"}</p>
        <form>
          <div className="mb-3">
            <CustomTextField
              label="Amount"
              type="decimal"
              placeholder="Enter Amount"
              name="amount"
              value={data?.amount ?? ""}
              required
              onChange={(v, k, e) => handleChange(v, k, e)}
            />
            {errors?.amount && <p className="text-danger"> {errors?.amount}</p>}
          </div>
          <div className="mb-3">
            <CustomTextField
              label="Url"
              type="url"
              placeholder="Enter Url"
              name="payment_url"
              value={data?.payment_url ?? ""}
              onChange={(v, k, e) => handleChange(v, k, e)}
            />
                 {errors?.payment_url && (
                          <p className="text-danger"> {errors?.payment_url}</p>
                        )}
          </div>
          <div className="mb-3">
            <CustomTextField
              label="Remarks"
              type="textarea"
              placeholder="Enter Remarks"
              name="remark"
              value={data?.remark ?? ""}
              onChange={(v, k, e) => handleChange(v, k, e)}
            />
            {errors?.remark && <p className="text-danger"> {errors?.remark}</p>}
          </div>
        </form>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          data-bs-dismiss="modal"
          disabled={props.submiting}
          onClick={() => {
            props.onCancelAction()
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          disabled={props.submiting}
          className="btn btn-primary"
          onClick={e => handleSubmit()}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}
export default RequestPayment
