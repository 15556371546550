import React from "react"
import { useEffect } from "react"
import { useState } from "react"
import { Button, Col, Row } from "reactstrap"

import CustomCalander from "components/CustomCalendar"
import SearchSelect from "components/CustomSearchSelect"
import CustomSelect from "components/CustomSelect/index"
import { getCreUsersList, getPreSaletatusesList } from "helpers/api_actions"

const ImportLogFilterComponent = props => {
  const [selectedStatus, setSelectedStatus] = useState(props.filters?.status)
  const [statuses, setStatuses] = useState([])
  const [handledBy, setHandledBy] = useState(props.filters?.handledBy)
  const [selectedCreatedOn, setSelectedCreatedOn] = useState(
    props.filters?.createdOn
  )
  const [selectedUpdatedOn, setSelectedUpdatedOn] = useState(
    props.filters?.updatedOn
  )
  const defaultSelection = null
  const onApplyFilter = () => {
    let filters = {
      status: selectedStatus,
      updatedOn: selectedUpdatedOn,
      createdOn: selectedCreatedOn,
      handledBy: handledBy,

    }
    props.onFilterSubmission(filters)
  }
  const onCancelFilter = () => {

    setSelectedUpdatedOn(undefined)
    setSelectedStatus(undefined)
    setHandledBy(undefined)
    props.onFilterSubmission({
      status: undefined,
      updatedOn: undefined,
      createdOn: undefined,
      handledBy: undefined,
    })
  }

  const getUsers = async v => {
    let payload = {
      select: "users.id,full_name",
      include: `user_type&find[user_type.name]=AdminUser&search[full_name]=${v}`,
    }

    const res = await getCreUsersList(payload)
    const data = res?.data?.data || []
    data.forEach(item => {
      item["label"] = `${item["full_name"]}`
      item["value"] = item.id
    })
    return data
  }

  const getLeadStatus = () => {
    // let payload = { select: "id,name", paginate: false }
    let dropdownData=[{"id":1,"name":"Completed"},{"id":2,"name":"Failed"},{"id":3,"name":"Incomplete"}]

    // getPreSaletatusesList(payload).then(res => {
    //   let data = res?.data || []
      dropdownData.forEach(item => {
        item["label"] = item.name
        item["value"] = item.id
      })
      setStatuses(dropdownData)
    // })
  }

  useEffect(() => {
    getLeadStatus()
  }, [])
  return (
    <>
      <Row>

        <Col lg={3} md={4} sm={6} xs={12}>
          <CustomSelect
            options={statuses}
            label="Status"
            setData={v => {
              setSelectedStatus(v)
            }}
            value={selectedStatus ?? defaultSelection}
          />
        </Col>
       
        
        <Col lg={3} md={4} sm={6} xs={12}>
          <CustomCalander
            dateRange
            maxDate={"today"}
            placeholder={"Start Date - End Date"}
            value={selectedCreatedOn}
            onChange={date => setSelectedCreatedOn(date)}
            label="Created Between"
          />
        </Col>  
        <Col
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className="d-flex align-items-end justify-content-start pt-3"
        >
          <div className="me-2">
            <Button
              className="btn btn-sm btn-primary btn-success text-white"
              onClick={() => onApplyFilter()}
            >
              Apply
            </Button>
          </div>
          <div>
            <Button
              className="btn btn-sm btn-danger"
              onClick={() => onCancelFilter()}
            >
              Reset
            </Button>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default ImportLogFilterComponent
