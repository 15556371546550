import React, { useEffect, useState } from "react"
import { FormGroup, Input, Label } from "reactstrap"

export default function CustomCheckbox(props)
{
    const [values, setValues] = useState(props.value)

    const onInputChange = e => {
        setValues(e.target.checked)
        props.onChange(e.target.checked, e.target.name, e)
      }

    useEffect(() => {
        if (props.value) {
          setValues(props.value)
        } else {
          setValues(false)
        }
      }, [props.value])
    return (
        <>
            <FormGroup check inline>
                <Input type="checkbox" checked={values} name={props.name} onChange={e => onInputChange(e)} disabled={props.disabled ?? false} />
                {
                    props.label && (
                        <Label check>
                        {props.label}
                        </Label>
                    )
                }
            </FormGroup>
  </>
    )
}