import { TAB_LABELS } from "constants/label"

export const USER_TAB_LABELS = [
  {
    id: "service-summary",
    label: TAB_LABELS.SERVICE_SUMMARY,
  },
  {
    id: "service-details",
    label: TAB_LABELS.SERVICE_INFO,
  },
  {
    id: "service-commission",
    label: TAB_LABELS.SERVICE_COMMISION,
  },
  {
    id: "service-sales",
    label: TAB_LABELS.SERVICE_SALES,
  },
  {
    id: "service-leads",
    label: TAB_LABELS.SERVICE_LEADS,
  },
  {
    id: "service-logs",
    label: TAB_LABELS.SERVICE_LOGS,
  },
]

export const USER_DETAILS_BREADCRUMB = {}
