import React, { useEffect, useState } from "react"
import Flatpickr from "react-flatpickr"
import { Input, InputGroup, Button } from "reactstrap"
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai"

function CustomTextField(props) {
  const [values, setValues] = useState(props.value ?? undefined)
  const [isInValid, setIsInvalid] = useState(false)

  useEffect(() => {
    if (props.value) {
      setValues(props.value)
    } else {
      setValues(undefined)
    }
  }, [props.value])

  var numbersonly = new RegExp("^[0-9]*$")
  var amountRegex = new RegExp("^[+-]?[0-9]+([.][0-9]*)?$")
  const [showPassword, setshowPassword] = useState(false)

  const onInputChange = e => {
    let val = e.target.value

    switch (props.type) {
      case "number":
        if (numbersonly.test(val)) {
          setValues(e.target.value)
          props.onChange(e.target.value, e.target.name, e)
        }
        break
      case "decimal":
        if (!e.target.value || amountRegex.test(val)) {
          setValues(e.target.value)
          props.onChange(e.target.value, e.target.name, e)
        }
        break
      case "pin":
        if (numbersonly.test(val)) {
          setValues(e.target.value)
          props.onChange(e.target.value, e.target.name, e)
        }
        break
      case "pinshow":
        if (numbersonly.test(val)) {
          setValues(e.target.value)
          props.onChange(e.target.value, e.target.name, e)
        }
        break
      case "phone":
        if (numbersonly.test(val)) {
          setValues(e.target.value)
          props.onChange(e.target.value, e.target.name, e)
        }
        break
      case "email":
        setValues(e.target.value)
        props.onChange(e.target.value, e.target.name, e)
        break
      default:
        setValues(e.target.value)

        props.onChange(e.target.value, e.target.name, e)
        break
    }
  }

  return (
    <>
      {props.label && (
        <p className="mb-1 mt-3 font-weight-bold">{props.label}</p>
      )}
      <InputGroup>
        {props.startAdorement && (
          <Input
            style={{ maxWidth: 40, padding: 4 }}
            disabled={true}
            value={props.startAdorement}
            className={`adorement ${props.required ? "required" : ""}`}
          ></Input>
        )}
        <Input
          value={props.inputCatagory ? props.value : values ?? ""}
          required={props.required ?? false}
          disabled={props.disabled ?? false}
          name={props.name ?? "name"}
          maxLength={props.maxLength}
          minLength={props.minLength}
          type={
            props.type === "password"
              ? "password"
              : props.type === "url"
              ? "url"
              : props.type === "pin" && showPassword
              ? "pinshow"
              : props.type === "pin"
              ? "password"
              : props.type === "email"
              ? "email"
              : props.type === "textarea"
              ? "textarea"
              : "text"
          }
          className={`form-control d-block ${
            props.required && !props.startAdorement ? "required" : ""
          }`}
          placeholder={props.placeholder ?? ""}
          onChange={e => onInputChange(e)}
        />
        {props.type === "pin" && (
          <Button
            className="adorement mb-0 "
            style={{ maxHeight: 40 }}
            onClick={() => {
              setshowPassword(f => !f)
            }}
          >
            {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
          </Button>
        )}
      </InputGroup>
    </>
  )
}

export default CustomTextField
