export const NAV_ICONS = {
  DASHBOARD: "bx bxs-dashboard",
  USERS: "bx bx-user-circle",
  CRE_USERS: "bx bx-user-circle",
  CUSTOMER: "bx bx-user-check",
  BUSINESS: "bx bxs-business",
  LEADS: "bx bx-briefcase",
  BUSINESS: "bx bxs-business",
  CONTACTS_MANAGEMENT: "bx bx-user",
  SERVICE_CATEGORY: "bx bx-card",
  SERVICE_MANAGEMENT: "bx bx-cube",
  COMMISSION_MANAGEMENT: "mdi  mdi-percent-outline",
  PAYOUT: "bx bx-credit-card",
  CUSTOMER_SUPPORT: "bx bx-support",
  PARTNERSHIP_REQUESTS: "mdi mdi-handshake",
  TICKETS: "mdi mdi-file-document-multiple-outline",
  REPORTS: "mdi mdi-file-chart",
  SALES_REPORTS: "mdi mdi-trending-up",
  COMISSION_REPORTS: "mdi mdi-wallet",
  USER_SALES_REPORTS: "mdi mdi-account-multiple-plus",
  USER_COMISSION_REPORTS: "mdi mdi-file-account",
  SUPPORT_REQUESTS: "mdi mdi-thumb-up",
  SETTINGS: "mdi mdi-help-box",
  PAYMENT_COLLECTION: "mdi mdi-shopping",
  PRESALES: "mdi mdi-presentation",
  IMPORT_LOG: "mdi mdi-file-import",
}
