import LeadManagement from "components/Leads/lead_form"
import {
  getCreditReport,
  postLeadCreate,
  putLeadUpdate,
} from "helpers/api_actions"
import { emailValidator, isValid, validate } from "helpers/utilities"
import moment from "moment"
import React from "react"
import { forwardRef } from "react"
import { useRef, useImperativeHandle, useEffect, useState } from "react"
import { useToasts } from "react-toast-notifications"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Row,
  Table,
} from "reactstrap"

const CreditReport = forwardRef((props, ref) => {
  CreditReport.displayName = "Credit Report Details"
  const [isFetching, setIsFetching] = useState(false)
  const [creditData, setCreditData] = useState()
  const { addToast } = useToasts()
  const editRef = useRef(null)
  useImperativeHandle(ref, () => ({
    update(e) {
      editRef.current.onSubmitAction()
    },
  }))
  const fetchCreditReport = params => {
    setIsFetching(true)
    getCreditReport(props.parentID)
      .then(res => {
        setIsFetching(false)
        setCreditData(res?.data)
        console.log(res?.data)
      })
      .catch(err => {
        setIsFetching(false)
      })
  }
  useEffect(() => {
    fetchCreditReport()
  }, [])
  return (
    <div>
      <Row style={{ overflow: "auto" }}>
        <Col lg="12">
          <Card className="m-0">
            <CardBody>
              <Row>
                <Col lg="9" className="border-right-none">
                  <CardTitle className="mt-0 mb-3">Basic Information</CardTitle>
                  <Row>
                    <Col md="4">
                      <p>First Name: {creditData?.userInfo?.first_name}</p>
                    </Col>
                    <Col md="4">
                      <p>Middle Name: {creditData?.userInfo?.middle_name}</p>
                    </Col>
                    <Col md="4">
                      <p>Last Name: {creditData?.userInfo?.last_name}</p>
                    </Col>
                    <Col md="4">
                      <p>
                        Mobile Number: {creditData?.userInfo?.mobile_number}
                      </p>
                    </Col>
                    <Col md="4">
                      <strong>
                        Credit Score: {creditData?.userInfo?.cibil_score}
                      </strong>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col lg="9" className="border-right-none">
                  <CardTitle className="mt-4 mb-3">
                    Loan Enquiry Information
                  </CardTitle>
                  <Row>
                    <Col md="4">
                      <p>
                        Enquiry Count: {creditData?.loan_enquiry?.inquiryCount}
                      </p>
                    </Col>
                    {creditData?.loan_enquiry?.inquiriesDetails &&
                      creditData?.loan_enquiry?.inquiriesDetails?.length >
                        0 && (
                        <Col md="12">
                          <Table>
                            <thead>
                              <tr>
                                <th>Date of Enquiry</th>
                                <th>Financial Institution</th>
                                <th>Enquiry Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {creditData?.loan_enquiry?.inquiriesDetails?.map(
                                inquiry_data => (
                                  <tr key={inquiry_data.id}>
                                    <td>{inquiry_data?.date_of_enquiry}</td>
                                    <td>{inquiry_data?.member_name}</td>
                                    <td>{inquiry_data?.enquiry_amount}</td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </Table>
                        </Col>
                      )}
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col lg="9" className="border-right-none">
                  <CardTitle className="mt-4 mb-3">Loan Information</CardTitle>
                  <Row>
                    <Col md="4">
                      <p>
                        Total Loan Account:{" "}
                        {creditData?.loan_list?.creditMix?.total_loan_account}
                      </p>
                    </Col>
                    <Col md="4">
                      <p>
                        Secured Loans:{" "}
                        {creditData?.loan_list?.creditMix?.secured_loan}
                      </p>
                    </Col>
                    <Col md="4">
                      <p>
                        Unsecured Loans:{" "}
                        {creditData?.loan_list?.creditMix?.unsecured_loan}
                      </p>
                    </Col>
                    <Col md="4">
                      <p>
                        Total Percent Completed:{" "}
                        {
                          creditData?.loan_list?.creditMix
                            ?.total_percent_completed
                        }
                        %
                      </p>
                    </Col>
                    {creditData?.loan_list?.creditMixAccountDetails?.secured &&
                      creditData?.loan_list?.creditMixAccountDetails?.secured
                        ?.length > 0 && (
                        <Col md="12" className="mt-2">
                          <h6>Secured Loans</h6>
                          <Table>
                            <thead>
                              <tr>
                                <th>Financial Institution</th>
                                <th>Account Type</th>
                                <th>Account No.</th>
                                <th>Account Holder Type</th>
                                <th>Date Reported</th>
                                <th>Account Status</th>
                                <th>Date Opened</th>
                                <th>Sanctioned Amount</th>
                                <th>Current Balance</th>
                                <th>Amount Overdue</th>
                              </tr>
                            </thead>
                            <tbody>
                              {creditData?.loan_list?.creditMixAccountDetails?.secured?.map(
                                loan_data => (
                                  <tr key={loan_data.account_id}>
                                    <td>{loan_data?.member_name}</td>
                                    <td>{loan_data?.data[0]?.account_type}</td>
                                    <td>
                                      {loan_data?.data[0]?.account_number}
                                    </td>
                                    <td>{loan_data?.data[0]?.ownership}</td>
                                    <td>
                                      {
                                        loan_data?.data[0]
                                          ?.date_reported_certified
                                      }
                                    </td>
                                    <td>
                                      {loan_data?.is_active === "1"
                                        ? "Yes"
                                        : "No"}
                                    </td>
                                    <td>{loan_data?.data[0]?.date_opened}</td>
                                    <td>
                                      {loan_data?.data[0]?.sanctioned_amount}
                                    </td>
                                    <td>
                                      {loan_data?.data[0]?.current_balance}
                                    </td>
                                    <td>
                                      {loan_data?.data[0]?.amount_overdue}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </Table>
                        </Col>
                      )}
                    {creditData?.loan_list?.creditMixAccountDetails
                      ?.unsecured &&
                      creditData?.loan_list?.creditMixAccountDetails?.unsecured
                        ?.length > 0 && (
                        <Col md="12" className="mt-2">
                          <h6>Unsecured Loans</h6>
                          <Table>
                            <thead>
                              <tr>
                                <th>Financial Institution</th>
                                <th>Account Type</th>
                                <th>Account No.</th>
                                <th>Account Holder Type</th>
                                <th>Date Reported</th>
                                <th>Account Status</th>
                                <th>Date Opened</th>
                                <th>Sanctioned Amount</th>
                                <th>Current Balance</th>
                                <th>Amount Overdue</th>
                              </tr>
                            </thead>
                            <tbody>
                              {creditData?.loan_list?.creditMixAccountDetails?.unsecured?.map(
                                loan_data => (
                                  <tr key={loan_data.account_id}>
                                    <td>{loan_data?.member_name}</td>
                                    <td>{loan_data?.data[0]?.account_type}</td>
                                    <td>
                                      {loan_data?.data[0]?.account_number}
                                    </td>
                                    <td>{loan_data?.data[0]?.ownership}</td>
                                    <td>
                                      {
                                        loan_data?.data[0]
                                          ?.date_reported_certified
                                      }
                                    </td>
                                    <td>
                                      {loan_data?.is_active === "1"
                                        ? "Yes"
                                        : "No"}
                                    </td>
                                    <td>{loan_data?.data[0]?.date_opened}</td>
                                    <td>
                                      {loan_data?.data[0]?.sanctioned_amount}
                                    </td>
                                    <td>
                                      {loan_data?.data[0]?.current_balance}
                                    </td>
                                    <td>
                                      {loan_data?.data[0]?.amount_overdue}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </Table>
                        </Col>
                      )}
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
})

export default CreditReport
