import React, { useEffect, useState } from "react"
import { useToasts } from "react-toast-notifications"
import { Modal } from "reactstrap"

import CustomTextField from "components/CustomTextField/index"
import { getEarningsDetails } from "helpers/api_actions"
import moment from "moment"

const EarningsView = props => {
  const [formData, setFormData] = useState(props.data || {})
  const [autherisedList, setautherisedList] = useState([])

  const getUser = async () => {
    const res = await getEarningsDetails(props.data.id)
    const data = res?.data?.data || []

    data.forEach(item => {
      item["label"] = item.full_name
      item["value"] = item.id
    })
    setautherisedList(data)
  }
  useEffect(() => {
    const data = {}
    getUser()
  }, [props.data.id])
  const { addToast } = useToasts()

  return (
    <Modal isOpen={true} toggle={props.onCancelAction} centered={true}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          View Earning
        </h5>
        <button
          type="button"
          disabled={props.submiting}
          onClick={() => {
            props.onCancelAction()
          }}
          className="btn-close"
        ></button>
      </div>
      <div className="modal-body">
        <form>
          <div className=" mb-3">
            <CustomTextField
              label="Date"
              name="note"
              disabled={true}
              type="text"
              value={
                formData["Date"]
                  ? moment(formData["Date"]).format("DD-MMM-YYYY")
                  : ""
              }
              placeholder="Enter Notes"
              onChange={(v, k, e) => handleChange(v, k, e)}
            />
          </div>
          <div className=" mb-3">
            <CustomTextField
              label="Lead ID"
              name="note"
              disabled={true}
              type="text"
              value={formData["Lead ID"] ?? ""}
              placeholder="Enter Notes"
              onChange={(v, k, e) => handleChange(v, k, e)}
            />
          </div>
          <div className=" mb-3">
            <CustomTextField
              label="Lead Name"
              name="note"
              disabled={true}
              type="text"
              value={formData["Lead Name"] ?? ""}
              placeholder="Enter Notes"
              onChange={(v, k, e) => handleChange(v, k, e)}
            />
          </div>
          <div className=" mb-3">
            <CustomTextField
              label="Lead Owner"
              name="note"
              disabled={true}
              type="text"
              value={formData["Lead Owner"] ?? ""}
              placeholder="Enter Notes"
              onChange={(v, k, e) => handleChange(v, k, e)}
            />
          </div>
          <div className=" mb-3">
            <CustomTextField
              label="Service Name"
              name="note"
              disabled={true}
              type="text"
              value={formData["Service Name"] ?? ""}
              placeholder="Enter Notes"
              onChange={(v, k, e) => handleChange(v, k, e)}
            />
          </div>
          <div className=" mb-3">
            <CustomTextField
              label="Status"
              name="note"
              disabled={true}
              type="text"
              value={formData["Status"] ?? ""}
              placeholder="Enter Notes"
              onChange={(v, k, e) => handleChange(v, k, e)}
            />
          </div>
        </form>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          data-bs-dismiss="modal"
          disabled={props.submiting}
          onClick={e => {
            props.onCancelAction(e)
          }}
        >
          Cancel
        </button>
      </div>
    </Modal>
  )
}
export default EarningsView
