import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap"
import { getServiceDashboardDetails } from "helpers/api_actions"
import LeadsGeneratedchart from "./tabs/LeadsGeneratedchart.js"
import CommissionsGivenchart from "./tabs/CommissionsGivenchart.js"
import { useParams } from "react-router-dom"
import SalesRevenueChart from "./tabs/SalesRevenueChart.js"
const UserUserOverview = props => {
  const [activeTab, setActiveTab] = useState("1")
  const [dashData, setDashData] = useState()
  const param = useParams(props.parentID)

  const getdashboardhServiceDetails = () => {
    let payload = { service_id: props.parentID }
    getServiceDashboardDetails(payload)
      .then(res => {
        let data = res.data
        setDashData(res.data)
      })
      .catch(e => {})
  }

  useEffect(() => {
    getdashboardhServiceDetails()
  }, [])

  return (
    <>
      <Card className="servicedashboard m-0">
        <CardBody>
          <div className="subCard">
            <Row className="leadbystatus m-0 mb-4">
              <Col lg="12">
                <p className="title ">
                  {" "}
                  <b>Leads by Status</b>
                </p>
              </Col>
              <Col lg="12" className="satuslist">
                <div>
                  <p className="subtitle">New</p>
                  <p className="values">{dashData?.LeadsByStatus.New}</p>
                </div>
                <div>
                  <p className="subtitle">Working</p>
                  <p className="values">{dashData?.LeadsByStatus.Working}</p>
                </div>
                <div>
                  <p className="subtitle">Nurturing</p>
                  <p className="values">{dashData?.LeadsByStatus.Nurturing}</p>
                </div>
                <div>
                  <p className="subtitle">Qualified</p>
                  <p className="values">{dashData?.LeadsByStatus.Qualified}</p>
                </div>
                <div>
                  <p className="subtitle">Converted</p>
                  <p className="values">{dashData?.LeadsByStatus.Converted}</p>
                </div>
                <div>
                  <p className="subtitle">Completed</p>
                  <p className="values">{dashData?.LeadsByStatus.Completed}</p>
                </div>
                <div>
                  <p className="subtitle">Unqualified</p>
                  <p className="values">
                    {dashData?.LeadsByStatus.Unqualified}
                  </p>
                </div>
              </Col>

              <Col lg="12" className="leads">
                <Col lg="12">
                  <p className="title ">
                    {" "}
                    <b>Closed Leads & Commissions Given</b>
                  </p>
                </Col>

                <Col lg="12" className="leadslist">
                  <div>
                    <p className="subtitle">General Leads </p>
                    <p className="count">
                      {dashData?.ClosedLeadsCommissions?.GeneralLeads?.count}
                    </p>
                    <p className="amount">
                      {
                       dashData?.ClosedLeadsCommissions?.GeneralLeads?.commision
                       ? parseFloat(dashData.ClosedLeadsCommissions.GeneralLeads.commision).toLocaleString('en-IN')
                       : 'N/A'
                      }
                    </p>
                  </div>
                  <div>
                    <p className="subtitle">Business Leads</p>
                    <p className="count">
                      {dashData?.ClosedLeadsCommissions?.BusinessLeads?.count}
                      
                    </p>
                    <p className="amount">
                      {
                          dashData?.ClosedLeadsCommissions?.BusinessLeads?.commision
  ? parseFloat(dashData.ClosedLeadsCommissions.BusinessLeads.commision).toLocaleString('en-IN')
  : 'N/A'
                      }
                    </p>
                  </div>
                  <div>
                    <p className="subtitle">Total</p>
                    <p className="count">
                      {dashData?.ClosedLeadsCommissions?.TotalLeads?.count}
                    </p>
                    <p className="amount">
                      {
                      dashData?.ClosedLeadsCommissions?.TotalLeads?.commision
                       ? parseFloat(dashData.ClosedLeadsCommissions.TotalLeads.commision).toLocaleString('en-IN')
                       : 'N/A'
                      }
                    </p>
                  </div>
                </Col>
              </Col>
            </Row>

            <div className="leadbystatus bg-none m-0">
              <Row>
                <Col lg="12">
                  <Nav tabs className="nav-tabs-custom ">
                    <NavItem>
                      <NavLink
                        className={activeTab == "1" ? "active" : ""}
                        onClick={() => setActiveTab("1")}
                      >
                        Leads Generated
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={activeTab == "2" ? "active" : ""}
                        onClick={() => setActiveTab("2")}
                      >
                        Commissions Given
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={activeTab == "3" ? "active" : ""}
                        onClick={() => setActiveTab("3")}
                      >
                        Sales Generated
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <LeadsGeneratedchart
                        parentID={param.id}
                        dataColors='["--bs-primary", "--bs-warning", "--bs-info"]'
                      ></LeadsGeneratedchart>
                    </TabPane>
                    <TabPane tabId="2">
                      <CommissionsGivenchart
                        parentID={param.id}
                        dataColors='["--bs-primary", "--bs-info"]'
                      ></CommissionsGivenchart>
                    </TabPane>
                    <TabPane tabId="3">
                      <SalesRevenueChart
                        parentID={param.id}
                        dataColors='["--bs-primary", "--bs-info"]'
                      ></SalesRevenueChart>
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  )
}

export default UserUserOverview
