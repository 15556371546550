import { TAB_LABELS } from "constants/label"

export const PRESALE_TAB_LABELS = [
  {
    id: "presale-details",
    label: TAB_LABELS.PRESALE_DETAILS,
  },
  {
    id: "presale-notes",
    label: TAB_LABELS.PRESALE_NOTES,
  },
  {
    id: "presale-followup-notes",
    label: TAB_LABELS.PRESALE_FOLLWOUP_NOTES,
  },
]

