import React from "react"
import { useEffect } from "react"
import { useState } from "react"
import { Button, Col, Row } from "reactstrap"

import SelectfieldComponent from "components/Common/SelectfieldComponent"
import CustomCalander from "components/CustomCalendar"
import SearchSelect from "components/CustomSearchSelect"
import CustomSelect from "components/CustomSelect/index"
import {
  getCitiesList,
  getLeadStatusesList,
  getServicesList,
  getUserList,
} from "helpers/api_actions"
import { CUSTOMER_SUPPORT_STATUS_OPTIONS } from "./dataManager"
import { getCreUsers } from "helpers/dropdown_helper"
import { isAdmin } from "helpers/utilities"

const UserFilterComponent = props => {
  const [selectedSupportType, setSelectedSupportType] = useState(
    props.filters?.supportType
  )
  const [supportTypes, setSupportTypes] = useState([
    {
      id: "General Issues",
      label: "General Issues",
    },
    {
      id: "Payment Issues",
      label: "Payment Issues",
    },
  ])
  const [createdBy, setCreatedBy] = useState(props.filters?.createdBy)
  const [selectedCreatedOn, setSelectedCreatedOn] = useState(
    props.filters?.createdOn
  )
  const [selectedStatus, setSelectedStatus] = useState(props.filters?.status)
  const [handledBy, setHandledBy] = useState(props.filters?.handledBy)
  const statuses = CUSTOMER_SUPPORT_STATUS_OPTIONS
  const defaultSelection = null
  const onApplyFilter = () => {
    let filters = {
      supportType: selectedSupportType,
      createdOn: selectedCreatedOn,
      createdBy: createdBy,
      status: selectedStatus,
      handledBy: handledBy,
    }
    props.onFilterSubmission(filters)
  }
  const onCancelFilter = () => {
    setSelectedCreatedOn(undefined)
    setSelectedSupportType(undefined)
    setCreatedBy(undefined)
    props.onFilterSubmission({
      supportType: undefined,
      createdOn: undefined,
      createdBy: undefined,
      status: undefined,
      handledBy: undefined,
    })
  }
  const getUsers = async v => {
    let payload = {
      select: "users.id,full_name",
      include: `user_type&search[full_name]=${v}`,
    }

    const res = await getUserList(payload)
    const data = res?.data?.data || []
    data.forEach(item => {
      item["label"] = `${item["full_name"]}`
      item["value"] = item.id
    })
    return data
  }
  return (
    <>
      <Row>
        <Col lg={3} md={4} sm={6} xs={12}>
          <CustomSelect
            options={supportTypes}
            label="Support Type"
            setData={v => {
              setSelectedSupportType(v)
            }}
            value={selectedSupportType ?? defaultSelection}
          />
        </Col>
        <Col lg={3} md={4} sm={6} xs={12}>
          <CustomSelect
            options={statuses}
            label="Status"
            setData={v => {
              setSelectedStatus(v)
            }}
            value={selectedStatus ?? defaultSelection}
          />
        </Col>
        {isAdmin() && (
          <Col lg={3} md={4} sm={6} xs={12}>
            <SearchSelect
              getData={getCreUsers}
              isMulti={false}
              label="Handled By"
              autoload={false}
              setData={v => {
                setHandledBy(v)
              }}
              value={handledBy ?? null}
            />
          </Col>
        )}
        <Col lg={3} md={4} sm={6} xs={12}>
          <CustomCalander
            dateRange
            maxDate={"today"}
            placeholder={"Start Date - End Date"}
            value={selectedCreatedOn}
            onChange={date => setSelectedCreatedOn(date)}
            label="Created Between"
          />
        </Col>
        <Col lg={3} md={4} sm={6} xs={12}>
          <SearchSelect
            getData={getUsers}
            isMulti={false}
            label="Created By"
            autoload={false}
            setData={v => {
              setCreatedBy(v)
            }}
            value={createdBy ?? null}
          />
        </Col>
        <Col
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className="d-flex align-items-end justify-content-start pt-3"
        >
          <div className="me-2">
            <Button
              className="btn btn-sm btn-primary btn-success text-white"
              onClick={() => onApplyFilter()}
            >
              Apply
            </Button>
          </div>
          <div>
            <Button
              className="btn btn-sm btn-danger"
              onClick={() => onCancelFilter()}
            >
              Reset
            </Button>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default UserFilterComponent
