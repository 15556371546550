import React, {
  useState,
  useImperativeHandle,
  useEffect,
  lazy,
  Suspense,
} from "react"
import { deleteAttachment, getAttachmentByEntity } from "helpers/api_actions"

import UserFilterComponent from "./filterComponent"
import { AUTH_ROUTES } from "constants/routes"
import Swal from "sweetalert2"
import CustomSpinner from "components/customSpinner"
import { useToasts } from "react-toast-notifications"
import moment from "moment"
import { forwardRef } from "react"
const CustomTable = lazy(() => import("components/customTable"))
const Attachments = forwardRef((props, ref) => {
  Attachments.displayName = "Documents"
  const [pageSize, setPageSize] = useState(15)
  const [data, setData] = useState([])
  const { addToast } = useToasts()
  const [totalCount, setTotalCount] = useState(0)
  const [pageNo, setPageNo] = useState(1)
  const [search, setSearch] = useState("")
  const [filters, setFilters] = useState({})
  const [sortKey, setSortKey] = useState("")
  const [appliedFilters, setAppliedFilters] = useState("")
  const [appliedSeach, setAppliedSearch] = useState("")
  const [isFetching, setIsFetching] = useState(false)

  const navigateToDetails = (id, isEdit = false) => {
    props.history.push({
      pathname: `${AUTH_ROUTES.USER_DETAILS}${id}`,
      state: { edit: isEdit },
    })
  }
  const listAttachments = params => {
    setIsFetching(true)
    getAttachmentByEntity(props.parentID)
      .then(res => {
        setData(res?.data?.attachments)
        setTotalCount(res?.data?.attachments?.length || 0)
        setIsFetching(false)
      })
      .catch(err => {
        setIsFetching(false)
      })
  }
  useImperativeHandle(ref, () => ({
    refetch() {
      listAttachments()
    },
  }))
  useEffect(() => {
    listAttachments()
  }, [])

  const handleDelete = data => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        deleteAttachment(data.id)
          .then(res => {
            addToast(res?.message, { appearance: "success" })
            listAttachments()
          })
          .catch(err => {
            addToast(err.response?.data?.message, { appearance: "error" })
          })
      }
    })
  }

  const handleTableMenu = (action, rowData) => {
    switch (action.action) {
      case "delete":
        handleDelete(rowData)
        break
      default:
        break
    }
  }
  const [columns, setColumns] = useState([
    {
      title: "Documents Name",
      field: "name",
      minWidth: 150,

      render: (rowData, val) => (
        <div
          onClick={() => window.open(rowData?.attachment, "_blank")}
          className="text-link"
        >
          {val}
        </div>
      ),
    },
    
    {
      title: "Uploaded Date",
      field: "created_at",
      render: (rowData, val) => (
        <div>{val ? moment(val).format("DD-MMM-YYYY hh:mm a") : "--"}</div>
      ),
    },
    {
      title: "Uploaded By",
      field: "created_by_name",
      minWidth: 150,
      width: 150,
      wrap:true,
    },
    {
      title: "Uploader",
      field: "created_by_type",
      minWidth: 150,
      width: 150,
      wrap:true,
    },
  ])

  const handleSort = row => {
    let nextDirection = row.sort_direction
      ? row?.sort_direction === "ASC"
        ? "DESC"
        : "ASC"
      : "ASC"
    columns.forEach(col => {
      if (col.field === row.field) {
        col["sort_direction"] = nextDirection
      } else {
        col["sort_direction"] = undefined
      }
    })
    setColumns(() => columns)

    let sortkey = `&sort_by[${row.sort_key}]=${nextDirection}`
    setSortKey(sortkey)
  }
  const handleSearch = val => {
    setSearch(val)
    let searchString =
      val && val !== ""
        ? `&search[users.full_name]=${val}&search[users.primary_phone]=${val}&search[user_addresses.city]=${val}&search[platform_users.secondary_phone]=${val}&search[platform_users.secondary_email]=${val}&search[platform_users.job]=${val}&search[platform_users.business_name]=${val}&search[user_addresses.address_line_1]=${val}&search[user_addresses.address_line_2]=${val}&search[user_addresses.state]=${val}&search[user_addresses.pincode]=${val}&search[user_addresses.country]=${val}&search[users.display_id]=${val}`
        : undefined
    setAppliedSearch(searchString)
  }

  const onFilterSubmission = fil => {
    setFilters(fil)
    let filterString = undefined
    filterString = `${
      fil?.city?.value ? "&filter[user_addresses.city]=" + fil?.city?.value : ""
    }${
      fil?.rank?.value ? "&filter[user_addresses.city]=" + fil?.rank?.value : ""
    }`
    setAppliedFilters(filterString ?? "")
  }
  const getTableActions = rowData => {
    return [
      {
        icon: <i className="mr-1 text-danger" />,
        action: "delete",
        name: "Delete",
        class: "text-danger",
        onClick: handleTableMenu,
      },
    ]
  }
  const handleOnPageChange = p => {
    setPageNo(p.page)
    setPageSize(p.pageSize)
  }
  if (isFetching) {
    return <CustomSpinner />
  }
  return (
    <Suspense fallback={<CustomSpinner />}>
      <CustomTable
        columns={columns}
        data={data}
        actions={rowData => {
          return getTableActions(rowData)
        }}
        currentPage={pageNo}
        totalCount={totalCount}
        pageSize={pageSize}
        search={search}
        filters={filters}
        // onSearch={handleSearch}
        // onFilterSubmission={onFilterSubmission}
        // filterComponent={UserFilterComponent}
        pagination={{
          onPageChange: handleOnPageChange,
        }}
        isFetching={isFetching}
        onSort={handleSort}
      />
    </Suspense>
  )
})

export default Attachments
