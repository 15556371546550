import moment from "moment"
import React, { forwardRef, lazy, Suspense, useEffect, useImperativeHandle, useState } from "react"
import { Link } from "react-router-dom"
import { useToasts } from "react-toast-notifications"

import CustomSpinner from "components/customSpinner"
import { AUTH_ROUTES } from "constants/routes"
import { changeLeadStatus, getCustomerSupportList, getLeadDetails } from "helpers/api_actions"
import { generateClassNameByValue, generateClassNameByValueForStatus } from "helpers/utilities"

import UserFilterComponent from "./filterComponent"

const CustomTable = lazy(() => import("components/customTable"))
const CustomerSupportListing = props => {
  const [pageSize, setPageSize] = useState(15)
  const [data, setData] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [pageNo, setPageNo] = useState(1)
  const [search, setSearch] = useState("")
  const [filters, setFilters] = useState({})
  const [activeItem, setActiveItem] = useState({})
  const [statusData, setStatusData] = useState({})
  const [sortKey, setSortKey] = useState(
    "&sort_by[customer_support.created_at]=DESC"
  )
  const [appliedFilters, setAppliedFilters] = useState("")
  const [appliedSeach, setAppliedSearch] = useState("")
  const [isFetching, setIsFetching] = useState(false)
  const [submiting, setSubmiting] = useState(false)
  const [manageStatus, setManageStatus] = useState(false)
  const { addToast } = useToasts()
  const navigateToDetails = (id, isEdit = false) => {
    props.history.push({
      pathname: `${AUTH_ROUTES.LEAD_DETAILS}${id}`,
    })
  }
  const listCustomerSupport = params => {
    let basicData = {
      select: `customer_support.id,type_of_support,customer_support.subject,customer_support.message,customer_support.status,users.email,users.primary_phone,users.full_name as created_by,customer_support.created_at,cre_user.full_name as cre_user,cre_user_id`,
      include: `users as cre_user,users&page=${pageNo}&per_page=${pageSize}`,
    }
    setIsFetching(true)
    getCustomerSupportList(basicData, appliedSeach, appliedFilters, sortKey)
      .then(res => {
        setData(res?.data?.data)
        setTotalCount(res?.data?.total || 0)
        setIsFetching(false)
      })
      .catch(err => {
        setIsFetching(false)
      })
  }

  useEffect(() => {
    listCustomerSupport()
  }, [pageSize, pageNo, appliedSeach, sortKey, appliedFilters])
  const handleOpenChangeStatus = rowData => {
    setIsFetching(true)
    getLeadDetails(rowData.id)
      .then(res => {
        setActiveItem(rowData)
        setStatusData({
          lead_status: {
            value: res.data?.lead_status?.id,
            label: res.data?.lead_status?.name,
          },
        })
        setManageStatus(true)
        setIsFetching(false)
      })
      .catch(e => {
        setIsFetching(false)
      })
  }
  const closeChangeStatus = () => {
    setSubmiting(false)
    setManageStatus(false)
  }
  const handleChangeStatus = data => {
    setSubmiting(true)
    changeLeadStatus(
      { lead_status_id: data?.lead_status?.value, lead_note: data?.lead_note },
      activeItem.id
    )
      .then(res => {
        addToast(res?.message, { appearance: "success" })
        listCustomerSupport()
        setSubmiting(false)
        setManageStatus(false)
      })
      .catch(err => {
        setSubmiting(false)
        addToast(err.response?.data?.message, { appearance: "error" })
      })
  }
  const handleDelete = () => {}
  const handleTableMenu = (action, rowData) => {
    switch (action.action) {
      case "edit":
        navigateToDetails(rowData.id, true)
        break
      case "status":
        handleOpenChangeStatus(rowData)
        break

      case "delete":
        handleDelete(rowData)
        break
      default:
        break
    }
  }
  const [columns, setColumns] = useState([
    {
      title: "Subject",
      field: "subject",
      minWidth: 150,
      width: 280,
      maxWidth: 280,
      wrap: true,
      sort_key: "customer_support.subject",
      render: (r, v) => (
        <Link
          className="text-link"
          to={{
            pathname: `${AUTH_ROUTES.CUSTOMER_SUPPORT_DETAILS}${r.id}`,
            state: { edit: false },
          }}
        >
          {v}
        </Link>
      ),
      //   tooltip_text: "User who gave the lead",
    },
    {
      title: "Type of Support",
      field: "type_of_support",
      minWidth: 180,
      width: 180,
      wrap: true,
      sort_key: "type_of_support",
    },

    {
      title: "Created By",
      sort_key: "users.full_name",
      field: "created_by",
      minWidth: 150,
      width: 200,
      maxWidth: 200,
      wrap: true,
    },
    {
      title: "Handled By",
      field: "cre_user",
      minWidth: 145,
      width: 200,
      maxWidth: 200,
      wrap: true,
      sort_key: "cre_user",
      tooltip_text:
        "Upfin CRE User who is in mapped to process the support ticket",
      render: (r, v) => (
        <Link
          className="text-link"
          to={{
            pathname: `${AUTH_ROUTES.CRE_USER_DETAILS}${r.cre_user_id}`,
            state: { edit: false },
          }}
        >
          {v}
        </Link>
      ),
    },
    {
      title: "Phone",
      sort_key: "users.primary_phone",
      field: "primary_phone",
      minWidth: 150,
      width: 150,
      wrap: true,
    },
    {
      title: "Email",
      field: "email",
      minWidth: 250,
      width: 250,
      wrap: true,
      sort_key: "users.email",
    },
    {
      title: "Status",
      field: "status",
      minWidth: 200,
      width: 200,
      wrap: true,
      sort_key: "customer_support.status",
      render: (r, v) => (
        <span className={`ms-2 chips ${v && generateClassNameByValueForStatus(v)}`}>
          {v}
        </span>
      ),
    },
    {
      title: "Created On",
      sort_key: "customer_support.created_at",
      field: "created_at",
      minWidth: 135,
      width: 135,
      wrap: true,
      sort_direction: "DESC",
      render: (row, v) => (
        <div>{v ? moment(v).format("DD-MMM-YYYY") : "--"}</div>
      ),
    },
  ])

  const handleSort = row => {
    let nextDirection = row.sort_direction
      ? row?.sort_direction === "ASC"
        ? "DESC"
        : "ASC"
      : "ASC"
    columns.forEach(col => {
      if (col.field === row.field) {
        col["sort_direction"] = nextDirection
      } else {
        col["sort_direction"] = undefined
      }
    })
    setColumns(() => columns)

    let sortkey = `&sort_by[${row.sort_key}]=${nextDirection}`
    setSortKey(sortkey)
  }

  const handleSearch = val => {
    setSearch(val)
    let searchString =
      val && val !== ""
        ? `&search[customer_support.type_of_support]=${val}
          &search[customer_support.subject]=${val}
          &search[users.full_name]=${val}
          &search[customer_support.created_by]=${val}
       `
        : undefined
    setPageNo(1)
    setAppliedSearch(searchString)
  }

  const onFilterSubmission = fil => {
    setFilters(fil)
    let filterString = undefined
    filterString = `${
      fil?.status?.value
        ? "&filter[customer_support.status]=" + fil?.status?.value
        : ""
    }${
      fil?.handledBy?.value
        ? "&filter[cre_user_id]=" + fil?.handledBy?.value
        : ""
    }${
      fil?.supportType?.label
        ? "&filter[customer_support.type_of_support]=" + fil?.supportType?.label
        : ""
    }${
      fil?.createdBy?.value
        ? "&filter[users.full_name]=" + fil?.createdBy?.label
        : ""
    }${
      fil?.createdOn?.length === 2
        ? "&filter[customer_support.created_at]=" +
          moment(fil.createdOn[0]).format("YYYY-MM-DD") +
          " - " +
          moment(fil.createdOn[1]).format("YYYY-MM-DD")
        : ""
    }`
    setPageNo(1)
    setAppliedFilters(filterString ?? "")
  }
  const handleOnPageChange = p => {
    setPageNo(p.page)
    setPageSize(p.pageSize)
  }
  // if (isFetching) {
  //   return <CustomSpinner />
  // }
  return (
    <>
      <Suspense fallback={<CustomSpinner />}>
        <CustomTable
          columns={columns}
          data={data}
          currentPage={pageNo}
          totalCount={totalCount}
          pageSize={pageSize}
          search={search}
          filters={filters}
          onSearch={handleSearch}
          onFilterSubmission={onFilterSubmission}
          filterComponent={UserFilterComponent}
          pagination={{
            onPageChange: handleOnPageChange,
          }}
          isFetching={isFetching}
          onSort={handleSort}
        />
      </Suspense>
    </>
  )
}

export default CustomerSupportListing
