import React, { useEffect, useState } from "react"
import { Col, Input, Modal, Row } from "reactstrap"

import CustomCalander from "components/CustomCalendar/index"
import CustomTextField from "components/CustomTextField/index"
import { validate, validateNote } from "helpers/utilities"

let required = []
const ManageNote = props => {
  const [data, setData] = useState(props.data || {})
  const [errors, setErrors] = useState({})
  var dayMax = new Date()

  useEffect(() => {
    {
      props.followupFlag
        ? (required = ["title", "note", "followup_date"])
        : (required = ["title", "note"])
    }
  })

  const handleChange = (v, k) => {
    const error = validateNote(required, { ...data, [k]: v }, "")

    setData(d => ({ ...d, [k]: v }))
    if (Object.keys(errors).length) {
      setErrors(error)
    }
  }

  const handleSubmit = () => {
    const error = validateNote(required, data, "")
    setErrors(error)
    if (Object.keys(error).length === 0) {
      props.onSubmitAction(data)
    }
  }

  return (
    <Modal isOpen={true} toggle={props.onCancelAction} centered={true}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {props.title || "Create Note"}
        </h5>
        <button
          type="button"
          disabled={props.submiting}
          onClick={() => {
            props.onCancelAction()
          }}
          className="btn-close"
        ></button>
      </div>
      <div className="modal-body">
        <form>
          <div className=" mb-3">
            <CustomTextField
              label="Note Title"
              placeholder="Enter Title"
              name="title"
              value={data?.title ?? ""}
              required
              onChange={(v, k, e) => handleChange(v, k, e)}
            />
            {errors?.title && <p className="text-danger"> {errors?.title}</p>}
          </div>
          <div className=" mb-3">
            <CustomTextField
              label="Description"
              name="note"
              type="textarea"
              required
              value={data?.note ?? ""}
              placeholder="Enter Description"
              onChange={(v, k, e) => handleChange(v, k, e)}
            />
            {errors?.note && <p className="text-danger"> {errors?.note}</p>}
          </div>
          {props.followupFlag && (
            // <Col md="4">
            <div className=" mb-3">
              <CustomCalander
                label="Followup Date"
                // maxDate={dayMax}
                disabled={props.isReadOnly}
                placeholder="Select Date"
                value={data?.followup_date ?? ""}
                onChange={d => handleChange(d, "followup_date")}
              />
              {errors?.followup_date && (
                <p className="text-danger"> {errors?.followup_date}</p>
              )}
            </div>
            // </Col>
          )}
        </form>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          data-bs-dismiss="modal"
          disabled={props.submiting}
          onClick={() => {
            props.onCancelAction()
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          disabled={props.submiting}
          className="btn btn-primary"
          onClick={e => handleSubmit()}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}
export default ManageNote
