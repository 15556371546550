import PropTypes from "prop-types"
import React, { lazy, Suspense, useState } from "react"
import { withRouter } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"

import HeaderTitle from "components/Common/headerTitleComponent"
import CustomSpinner from "components/customSpinner"
import { BUTTON_LABELS, HEADER_LABELS } from "constants/label"
import { AUTH_ROUTES } from "constants/routes"
import { DEFAULT_CLASSES } from "constants/styles"
import { getPlatformUsersListExport } from "helpers/api_actions"
import { isAdmin } from "helpers/utilities"

//Import Breadcrumb
const UserListing = lazy(() => import("./listing"))
const Users = props => {
  const status = props.location?.state?.status
  const [pageNumber, setPageNumber] = useState(1)
  const [pgSize, setPgSize] = useState(15)
  const [searchData, setSearchData] = useState("")
  const [sortBy, setSortBy] = useState("&sort_by[users.full_name]=ASC")
  const [filterData, setFilterData] = useState("")
  let filters = {}
  if (status) {
    filters = {
      status: { label: status, value: status },
    }
  }
  const exportUsers = () => {
    let basicData = {
      select: `users.full_name as Name,users.primary_phone as Phone,user_addresses.city as City,LeadCount,SaleCount,Prospect,TeamCount,my_earnings,team_earnings,Requestcount,users.status as Status,platform_users.updated_at as Updated On,platform_users.created_at as Created On`,
      include: `users,ranks,user_addresses,users as cre_user&page=${pageNumber}&per_page=${pgSize}&export=true`,
    }
    getPlatformUsersListExport(basicData, searchData, filterData, sortBy)
      .then(res => {
        const outputFilename = `${Date.now()}-Users.xlsx`

        // If you want to download file automatically using link attribute.
        var blob = new Blob([res])

        var link = document.createElement("a")
        link.href = window.URL.createObjectURL(blob)
        link.download = outputFilename

        document.body.appendChild(link)

        link.click()
        document.body.removeChild(link)
      })
      .catch(err => {
        setIsFetching(false)
      })
  }

  const headerActions = isAdmin()
    ? [
        {
          type: "button",
          label: BUTTON_LABELS.CREATE_NEW_USER,
          className: DEFAULT_CLASSES.HEADER_BUTTON_PRIMARY,
          action: () => {
            props.history.push(AUTH_ROUTES.CREATE_NEW_USER)
          },
        },
        {
          type: "button",
          label: BUTTON_LABELS.EXPORT,
          className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
          action: () => {
            exportUsers()
          },
        },
      ]
    : []

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <HeaderTitle title={HEADER_LABELS.USERS} actions={headerActions} />
          </Row>
          <Row>
            <Col lg="12">
              <Suspense fallback={<CustomSpinner />}>
                <UserListing
                  {...props}
                  setPageNumber={setPageNumber}
                  setPgSize={setPgSize}
                  setSearchData={setSearchData}
                  setFilterData={setFilterData}
                  setSortBy={setSortBy}
                  filters={filters}
                />
              </Suspense>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Users.propTypes = {
  history: PropTypes.object,
}

export default withRouter(Users)
