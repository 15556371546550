import React from "react"
import { BiDotsVerticalRounded } from "react-icons/bi"
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"

import Breadcrumb from "./Breadcrumb"

function HeaderTitle({
  title,
  actions = [],
  loading=false,
  tags = [],
  disabled = false,
  breadcrumb = [],
  activeTab,
}) {
  const renderElement = act => {
    switch (act.type) {
      case "button":
        return (
          <div>
            <Button
              disabled={disabled}
              className={act.className ?? "btn btn-primary text-white"}
              onClick={e => act.action(e, act)}
            >
              {act.label}
            </Button>
          </div>
        )
      case "menubutton":
        return (
          <div>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="button"
                className={act.className ?? "btn btn-icon"}
              >
                <BiDotsVerticalRounded />
              </DropdownToggle>
              <DropdownMenu end>
                {act?.menus.map((menu, i) => (
                  <DropdownItem
                    key={i}
                    className="position-relative"
                    onClick={e => menu.action(e, menu)}
                  >
                    {menu.label}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        )
      default:
        return <Button className="btn btn-light">No Action</Button>
    }
  }
  return (
    <Col lg="12">
      <div className="page-title-box d-sm-flex align-items-center justify-content-between">
        <div className="page-title-left">
          {breadcrumb.length > 0 && (
            <div className="mb-2">
              <Breadcrumb data={breadcrumb} loading={loading} />
            </div>
          )}
          <div className="d-flex flex-wrap">
            <div className="d-flex text-center align-items-center">
             {loading?
              <div className="mr-2 skeleton-box text-transparent font-size-18">loading...</div>:
              <h4 className="mb-0 font-size-18">
                <>{title}</></h4>}
            </div>
            <div className="d-flex">
              {tags.length > 0 &&
                tags.map((tag, i) => (
                  <React.Fragment    key={i}>
                    {loading?
                    <>
                 
                      <div className="ms-2 skeleton-box font-size-18 text-transparent">loading...</div>
                    
                 
                    </>:<>
                    <span
                      className={`ms-2 chips ${tag.className ?? "bg-primary"}`}
                   
                    >
                      <>
                        {tag.className === "display_id" ? (
                          <>{`ID :  ${tag.label}`}</>
                        ) : (
                          <>{tag.label}</>
                        )}
                      </>
                    </span>
                    
                    </>}
                  </React.Fragment>
                ))}
            </div>
          </div>
        </div>
        {actions.length > 0 && (
          <div className="page-title-right">
            <div className="d-flex flex-wrap">
              {actions.map((act, i) => (
                <React.Fragment key={i}>
                  {activeTab ? (
                    <>
                      {act.show.some(f => f === activeTab) &&
                        renderElement(act)}
                    </>
                  ) : (
                    <>{renderElement(act)}</>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        )}
      </div>
    </Col>
  )
}

export default HeaderTitle
