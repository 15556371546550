import { Component } from "react"
import Flatpickr from "react-flatpickr"
import { InputGroup } from "reactstrap"
import React from "react"
import "flatpickr/dist/themes/material_blue.css"

class DatepickerComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      defaultDate: new Date(),
      default: false,
      date: new Date(),
    }

    // DatePicker
    this.handleDefault = this.handleDefault.bind(this)
  }

  //DatePicker
  handleDefault(date) {
    this.setState({ default_date: date })
  }

  render() {
    const { pick } = this.state
    const { label, placeholder } = this.props
    return (
      <div className="formSection">
        <label className="control-label">{label}</label>
        <div className="docs-datepicker">
          <InputGroup>
            <Flatpickr
              value={pick}
              className="form-control d-block"
              placeholder={placeholder}
              options={{
                altInput: true,
                // altFormat: "F j, Y",
                dateFormat: "dd-mm-yyyy",
              }}
              onChange={date => {
                this.setState({ defaultDate: date })
              }}
            />
            <div className="input-group-append">
              <button
                type="button"
                className="btn btn-outline-secondary docs-datepicker-trigger"
                disabled
              >
                <i className="fa fa-calendar" aria-hidden="true" />
              </button>
            </div>
          </InputGroup>
          <div className="docs-datepicker-container" />
        </div>
      </div>
    )
  }
}
export default DatepickerComponent
