import classnames from "classnames"
import React, { useEffect, useRef, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import { Card, CardBody, CardText, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap"

import HeaderTitle from "components/Common/headerTitleComponent"
import CustomSpinner from "components/customSpinner"
import { BUTTON_LABELS, MENU_LABELS, NAV_LABELS, STATUS_LABELS } from "constants/label"
import { AUTH_ROUTES } from "constants/routes"
import { DEFAULT_CLASSES } from "constants/styles"
import { changeUserStatus, getUserDetails, postResetPasscode } from "helpers/api_actions"
import { generateClassNameByValue, generateClassNameByValueForStatus, isValid, validate } from "helpers/utilities"

import { USER_TAB_LABELS } from "./dataManager"
import Managestatus from "./manage/components/modal/manageStatus"
import ResetPassword from "./manage/components/modal/resetPassword"
import ContactListing from "./manage/components/tabs/contacts"
import Earnings from "./manage/components/tabs/earnings/index"
import LeadListing from "./manage/components/tabs/leads"
import Logs from "./manage/components/tabs/logs/index"
import Overview from "./manage/components/tabs/overview"
import PayOutRequestListing from "./manage/components/tabs/payoutRequest"
import TeamMemberListing from "./manage/components/tabs/teamMembers"
import UserDetailsComponent from "./manage/components/tabs/userDetails"

const UserDetails = props => {
  const state = props.location?.state?.edit
  const [userData, setUserData] = useState({})
  const [edit, setEdit] = useState(false)
  const [activeTab, setActiveTab] = useState(USER_TAB_LABELS[0].id)
  const [isLoading, setIsLoading] = useState(true)
  const [resetpassword, setResetpassword] = useState(false)
  const [manageStatus, setManageStatus] = useState(false)
  const [submiting, setSubmiting] = useState(false)
  const { addToast } = useToasts()
  const param = useParams()
  const detailRef = useRef(null)

  // const headerActions = [
  //   {
  //     type: "menubutton",
  //     show: [
  //       USER_TAB_LABELS[0].id,
  //       USER_TAB_LABELS[1].id,
  //       USER_TAB_LABELS[2].id,
  //       USER_TAB_LABELS[3].id,
  //       USER_TAB_LABELS[5].id,
  //       USER_TAB_LABELS[4].id,
  //       USER_TAB_LABELS[6].id,
  //     ],
  //     className: DEFAULT_CLASSES.HEADER_MENU_DROPDOWN,
  //     menus: [
  //       {
  //         label: MENU_LABELS.RESET_PASSWORD,
  //         action: () => {
  //           setResetpassword(true)
  //         },
  //       },
  //     ],
  //   },
  //   // {
  //   //   type: "button",
  //   //   label: BUTTON_LABELS.ADD_NOTE,
  //   //   className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
  //   //   action: () => {
  //   //     setAddNote(true)
  //   //   },
  //   //   show: [USER_TAB_LABELS[1].id],
  //   // },
  //   {
  //     type: "button",
  //     label: BUTTON_LABELS.EXPORT,
  //     className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
  //     action: () => {},
  //     show: [
  //       USER_TAB_LABELS[2].id,
  //       USER_TAB_LABELS[3].id,
  //       USER_TAB_LABELS[4].id,

  //       USER_TAB_LABELS[5].id,
  //       USER_TAB_LABELS[6].id,
  //     ],
  //   },
  //   {
  //     type: "button",

  //     label:
  //       userData.status === "Active"
  //         ? BUTTON_LABELS.BLOCK_USER
  //         : BUTTON_LABELS.UNBLOCK_USER,
  //     className:
  //       userData.status === "Active"
  //         ? DEFAULT_CLASSES.HEADER_BUTTON_DANGER
  //         : DEFAULT_CLASSES.HEADER_BUTTON_PRIMARY,
  //     action: () => {
  //       setManageStatus(true)
  //     },
  //     show: [
  //       USER_TAB_LABELS[0].id,
  //       USER_TAB_LABELS[1].id,
  //       USER_TAB_LABELS[2].id,
  //       USER_TAB_LABELS[3].id,
  //       USER_TAB_LABELS[4].id,
  //       USER_TAB_LABELS[5].id,
  //       USER_TAB_LABELS[6].id,
  //     ],
  //   },
  //   ...(edit
  //     ? [
  //         {
  //           type: "button",
  //           label: BUTTON_LABELS.CANCEL,
  //           className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
  //           action: () => {
  //             setEdit(false)
  //           },
  //           show: [USER_TAB_LABELS[1].id],
  //         },
  //         {
  //           type: "button",
  //           label: BUTTON_LABELS.SAVE,
  //           className: DEFAULT_CLASSES.HEADER_BUTTON_PRIMARY,
  //           action: () => {
  //             detailRef.current.update()
  //           },
  //           show: [USER_TAB_LABELS[1].id],
  //         },
  //       ]
  //     : [
  //         {
  //           type: "button",
  //           label: BUTTON_LABELS.EDIT,
  //           className: DEFAULT_CLASSES.HEADER_BUTTON_PRIMARY,
  //           action: () => {
  //             setEdit(true)
  //           },
  //           show: [USER_TAB_LABELS[1].id],
  //         },
  //       ]),
  // ]

  const headerActions = [
    {
      type: "menubutton",
      show: [
        USER_TAB_LABELS[0].id,
        USER_TAB_LABELS[1].id,
        USER_TAB_LABELS[2].id,
        USER_TAB_LABELS[3].id,
        USER_TAB_LABELS[5].id,
        USER_TAB_LABELS[4].id,
        USER_TAB_LABELS[6].id,
      ],
      className: DEFAULT_CLASSES.HEADER_MENU_DROPDOWN,
      menus: [
        {
          label: MENU_LABELS.RESET_PASSWORD,
          action: () => {
            setResetpassword(true)
          },
        },
      ],
    },
    {
      type: "button",
      label: BUTTON_LABELS.EXPORT,
      className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
      action: () => {},
      show: [
        USER_TAB_LABELS[2].id,
        USER_TAB_LABELS[3].id,
        USER_TAB_LABELS[4].id,
        USER_TAB_LABELS[5].id,
        USER_TAB_LABELS[6].id,
      ],
    },
    ...(userData.is_password_set
      ? [
          {
            type: "button",
            label:
              userData.status === "Active"
                ? BUTTON_LABELS.BLOCK_USER
                : BUTTON_LABELS.UNBLOCK_USER,
            className:
              userData.status === "Active"
                ? DEFAULT_CLASSES.HEADER_BUTTON_DANGER
                : DEFAULT_CLASSES.HEADER_BUTTON_PRIMARY,
            action: () => {
              setManageStatus(true)
            },
            show: [
              USER_TAB_LABELS[0].id,
              USER_TAB_LABELS[1].id,
              USER_TAB_LABELS[2].id,
              USER_TAB_LABELS[3].id,
              USER_TAB_LABELS[4].id,
              USER_TAB_LABELS[5].id,
              USER_TAB_LABELS[6].id,
            ],
          },
        ]
      : []),
    ...(edit
      ? [
          {
            type: "button",
            label: BUTTON_LABELS.CANCEL,
            className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
            action: () => {
              setEdit(false)
            },
            show: [USER_TAB_LABELS[1].id],
          },
          {
            type: "button",
            label: BUTTON_LABELS.SAVE,
            className: DEFAULT_CLASSES.HEADER_BUTTON_PRIMARY,
            action: () => {
              detailRef.current.update()
            },
            show: [USER_TAB_LABELS[1].id],
          },
        ]
      : [
          {
            type: "button",
            label: BUTTON_LABELS.EDIT,
            className: DEFAULT_CLASSES.HEADER_BUTTON_PRIMARY,
            action: () => {
              setEdit(true)
            },
            show: [USER_TAB_LABELS[1].id],
          },
        ]),
  ]

  const breadcrumb = [
    { label: NAV_LABELS.USERS, path: AUTH_ROUTES.USERS },
    {
      label: userData?.full_name,
      path: `${AUTH_ROUTES.USER_DETAILS}${param.id}`,
    },
  ]

  const tags = [
    {
      label: userData?.status || "Unknown",
      className: generateClassNameByValueForStatus(userData?.status),
    },
    {
      label: userData?.platform_user?.rank?.name || "Unranked",
      rank: "user",
      className: generateClassNameByValue(
        userData?.platform_user_details?.rank?.name
      ),
    },
    {
      label: userData?.display_id || "n/a",
      className: "display_id",
    },
  ]

  const fetchUserDetails = () => {
    setIsLoading(true)
    getUserDetails(param.id)
      .then(res => {
        let data = res.data
        setUserData(data)
        setIsLoading(false)
      })

      .catch(e => {
        setIsLoading(false)
      })
  }

  const toggleCustom = tab => {
    if (activeTab !== tab) {
      fetchTabData(tab)
    }
  }

  const fetchTabData = tab => {
    setActiveTab(tab)
    setIsLoading(true)
    switch (tab) {
      // USER_OVERVIEW
      case USER_TAB_LABELS[0].id:
        setIsLoading(false)
        break
      // USER_DETAILS
      case USER_TAB_LABELS[1].id:
        fetchUserDetails()
        setIsLoading(false)
        break
      // USER_LEADS
      case USER_TAB_LABELS[2].id:
        setIsLoading(false)
        break
      // USER_TEAM_MEMBERS
      case USER_TAB_LABELS[3].id:
        setIsLoading(false)
        break
      // USER_PAYOUT_REQUEST
      case USER_TAB_LABELS[4].id:
        setIsLoading(false)
        break
      case USER_TAB_LABELS[5].id:
        setIsLoading(false)
        break
      case USER_TAB_LABELS[6].id:
        setIsLoading(false)
        break
      case USER_TAB_LABELS[7].id:
        setIsLoading(false)
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (state === true) {
      setActiveTab(USER_TAB_LABELS[1].id)
      setEdit(true)
    }
    fetchUserDetails()
  }, [])

  const closeCreateNote = () => {
    setSubmiting(false)
    setResetpassword(false)
  }

  const handlesubmit = data => {
    let required = ["password", "confirm_password"]
    const errors = validate(required, data, "")
    if (Object.keys(errors).length === 0) {
      let payload = new FormData()
      if (isValid(data?.password)) {
        payload.append("password", data?.password)
      }
      if (isValid(data?.confirm_password)) {
        payload.append("confirm_password", data?.confirm_password)
      }
      if (data?.password === data?.confirm_password) {
        setSubmiting(true)
        postResetPasscode(param.id, {
          ...data,
          model: "Reset Password",
          model_id: param.id,
        })
          .then(res => {
            addToast(res?.message, { appearance: "success" })
            setSubmiting(false)
            setResetpassword(false)
          })
          .catch(err => {
            setSubmiting(false)
            addToast(err.response?.data?.message, { appearance: "error" })
            setResetpassword(false)
          })
      } else {
        addToast("New password and Confirm Password is mismatches", {
          appearance: "error",
        })
      }
    } else {
      addToast("Please fill all required fields", { appearance: "error" })
    }
  }

  const closeChangeStatus = () => {
    setSubmiting(false)
    setManageStatus(false)
  }

  const handleChangeStatus = data => {
    if (userData?.status !== data?.User_status.value) {
      setSubmiting(true)
      changeUserStatus({ status: data.User_status.value }, param.id)
        .then(res => {
          addToast(res?.message, { appearance: "success" })
          fetchUserDetails()
          setSubmiting(false)
          setManageStatus(false)
        })
        .catch(err => {
          setSubmiting(false)
          addToast(err.response?.data?.message, { appearance: "error" })
        })
    } else {
      addToast("Cannot change status to be same", { appearance: "error" })
    }
  }
  return (
    <React.Fragment>
      {resetpassword && (
        <ResetPassword
          submiting={submiting}
          onCancelAction={closeCreateNote}
          onSubmitAction={handlesubmit}
        />
      )}
      {manageStatus && (
        <Managestatus
          submiting={submiting}
          data={{
            User_status: {
              value: userData.status === "Inactive" ? "Active" : "Inactive",
              label: userData.status === "Inactive" ? "Active" : "Inactive",
            },
          }}
          activeStatus={userData?.status}
          onCancelAction={closeChangeStatus}
          onSubmitAction={handleChangeStatus}
        />
      )}
      <div className="page-content">
        <Container fluid>
          <Row>
            <HeaderTitle
              title={userData?.full_name}
              actions={headerActions}
              tags={tags}
              loading={isLoading}
              disabled={submiting}
              activeTab={activeTab}
              breadcrumb={breadcrumb}
            />
          </Row>
          <Row>
            <React.Fragment>
              <Col lg="12">
                <Card className="m-0">
                  <CardBody className="p-0">
                    <Nav tabs className="nav-tabs-custom ">
                      {USER_TAB_LABELS.map((tabitem, tabkey) => (
                        <NavItem key={tabkey}>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab === tabitem.id,
                            })}
                            onClick={() => {
                              toggleCustom(tabitem.id)
                            }}
                          >
                            <span className="">{tabitem.label}</span>
                          </NavLink>
                        </NavItem>
                      ))}
                    </Nav>
                    <TabContent activeTab={activeTab} className="text-muted">
                      {isLoading ? (
                        <CustomSpinner />
                      ) : (
                        <>
                          {/* USER_OVERVIEW */}
                          <TabPane tabId={USER_TAB_LABELS[0].id}>
                            <Overview parentID={param.id} />
                          </TabPane>
                          {/* USER_DETAILS */}
                          <TabPane tabId={USER_TAB_LABELS[1].id}>
                            <UserDetailsComponent
                              isReadOnly={!edit}
                              parentID={param.id}
                              data={userData}
                              ref={detailRef}
                              setEdit={setEdit}
                              setsubmitting={v => setSubmiting(v)}
                              edit={edit}
                              refresh={fetchUserDetails}
                            />
                          </TabPane>
                          {/* USER_LEADS */}
                          <TabPane tabId={USER_TAB_LABELS[2].id}>
                            <LeadListing parentID={param.id} {...props} />
                          </TabPane>
                          {/* USER_CONTACTS */}
                          <TabPane tabId={USER_TAB_LABELS[3].id}>
                            <ContactListing parentID={param.id} />
                          </TabPane>
                          {/* USER_TEAM_MEMBERS */}
                          <TabPane tabId={USER_TAB_LABELS[4].id}>
                            <TeamMemberListing parentID={param.id} />
                          </TabPane>

                          <TabPane tabId={USER_TAB_LABELS[5].id}>
                            <Earnings parentID={param.id} />
                          </TabPane>
                          {/* USER_PAYOUT_REQUEST */}
                          <TabPane tabId={USER_TAB_LABELS[6].id}>
                            <PayOutRequestListing parentID={param.id} />
                          </TabPane>
                          {/* LOGS */}
                          {activeTab === USER_TAB_LABELS[7].id && (
                            <TabPane tabId={USER_TAB_LABELS[7].id}>
                              <Logs parentID={param.id} />
                            </TabPane>
                          )}
                        </>
                      )}
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </React.Fragment>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UserDetails
