import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import ReactApexChart from "react-apexcharts"

import getChartColorsArray from "../../../../../../../components/Common/ChartsDynamicColor"
import DatePicker from "react-datepicker"
import { getServiceDashboarCommissionChartdDetails } from "helpers/api_actions"

const StackedColumnChart = ({ dataColors, periodData, parentID }) => {
  const stackedColumnChartColors = getChartColorsArray(dataColors)
  const [starYear, setStartYear] = useState(new Date())
  const [data, setData] = useState([])
  const result = starYear.getFullYear()

  const getdashboardhServicechartDetails = () => {
    let payload = { year: result, service_id: parentID }
    getServiceDashboarCommissionChartdDetails(payload)
      .then(res => {
        const sortData = []
        res.data.forEach(item => {
          let dataItem = {}
          if (item.data) {
            dataItem["name"] = item.name
            dataItem["data"] = []
            item.data.forEach(child => {
              dataItem.data.push(child.amount)
            })
          }

          sortData.push(dataItem)
        })
        setData(sortData)
      })
      .catch(e => {})
  }

  useEffect(() => {
    getdashboardhServicechartDetails()
  }, [result])

  const options = {
    chart: {
      stacked: !1,
      toolbar: {
        show: true,
        tools: {
          download: false,
        },
      },
      zoom: {
        enabled: !0,
      },
    },
    title: {
      text: undefined,
      align: "left",
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: undefined,
        color: "#263238",
      },
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "30%",
        // endingShape: "rounded"
      },
    },
    dataLabels: {
      enabled: !1,
    },
    xaxis: {
      show: true,
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        show: true,
      },
    },
    yaxis: [
      {
        opposite: false,
        min: min => {
          return min
        },
        max: max => {
          return max
        },
        labels: {
          formatter: function (value) {
            return value.toLocaleString('en-IN'); // Format as comma-separated
          },
        },
      },
    ],
    colors: stackedColumnChartColors,
    legend: {
      position: "bottom",
    },
    fill: {
      opacity: 1,
    },
  }
  const dateend = {}
  const series = [
    {
      name: "Business Lead",

      data: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60],
    },
    {
      name: "General Lead",

      data: [3, 6, 9, 12, 15, 18, 21, 25, 28, 31, 47, 65],
    },
  ]
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-lg-8"></div>
        <div className="col-lg-4" style={{ textAlign: "end", padding: "5px" }}>
          <DatePicker
            selected={starYear}
            onChange={date => setStartYear(date)}
            showYearPicker
            dateFormat="yyyy"
            className="form-control maxWidth"
            popperPlacement="bottom-end"
            popperClassName="customPicker"
          />
        </div>
      </div>
      {data?.length > 0 && (
        <ReactApexChart
          options={options}
          series={data ?? []}
          type="bar"
          height="359"
          className="apex-charts"
        />
      )}
    </React.Fragment>
  )
}

StackedColumnChart.propTypes = {
  periodData: PropTypes.any,
}
export default StackedColumnChart
