import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Row } from "reactstrap"

import LogCard from "components/LogCard"
import { getServiceLog } from "helpers/api_actions"

function Logs(props) {
  const [data, setData] = useState([])
  const fetchLogs = () => {
    let payload = {
      select: 'log_title,log_description,additional_data,change_logs.created_at,users.full_name,model',
      include: `users&find[model]=Service&find[model_id]=${props.parentID}`
    }
    getServiceLog(payload).then(res => {
      setData(res?.data?.data)
    })
  }
  useEffect(() => {
    fetchLogs()
  }, [])

  return (
    <div>
      {" "}
      <Row style={{ overflow: "auto" }}>
        <Col lg="12">
          <Card className="detailBox m-0">
            <CardBody className="p-3">
              <Row className="m-0">
                {data.map((item, k) => (
                  <Col xs={12} key={k}>
                    <LogCard data={item} />
                  </Col>
                ))}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Logs
