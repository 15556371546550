import React, { useState, useEffect } from "react"
import { Col, Input, Modal, Row } from "reactstrap"
import { Link, useParams } from "react-router-dom"
import CustomTextField from "components/CustomTextField/index"
import CustomSelect from "components/CustomSelect/index"
import { getUserDetails } from "helpers/api_actions"

const ManageStatus = props => {
  const param = useParams()
  const [formData, setFormData] = useState(props.data || {})
  const [statuses, setStatuses] = useState([])
  const [userData, setUserData] = useState({})
  const [destatus, setdestatus] = useState({})
  const options = [
    { value: "Inactive", label: "Inactive" },
    { value: "Active", label: "Active" },
  ]
  const datas = () => {
    setStatuses(options)
  }
  const handleChange = (v, k) => {
    setFormData({ ...formData, [k]: v })
  }

  useEffect(() => {
    datas()
  }, [])

  return (
    <Modal isOpen={true} toggle={props.onCancelAction} centered={true}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          Change User Status
        </h5>
        <button
          type="button"
          disabled={props.submiting}
          onClick={() => {
            props.onCancelAction()
          }}
          className="btn-close"
        ></button>
      </div>
      <div className="modal-body">
        <form>
          <div className=" mb-3">
            <CustomSelect
              label="User Status"
              options={statuses}
              setData={v => {
                handleChange(v, "User_status")
              }}
              value={formData?.User_status ?? null}
            />
          </div>
        </form>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          data-bs-dismiss="modal"
          disabled={props.submiting}
          onClick={() => {
            props.onCancelAction()
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          disabled={props.submiting}
          className="btn btn-primary"
          onClick={e => props.onSubmitAction(formData)}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}
export default ManageStatus
