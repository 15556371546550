import React, { Suspense, lazy } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import { HEADER_LABELS } from "constants/label"
import CustomSpinner from "components/customSpinner"
import HeaderTitle from "components/Common/headerTitleComponent"

const PayoutImportHistoryListing = lazy(() => import("./importHistoryListing"))
const PayoutImportHistory = (props) => {
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                <Row>
                    <HeaderTitle title={HEADER_LABELS.PAYOUT_IMPORT_HISTORY} />
                </Row>
                <Row>
                    <Col lg="12">
                    <Suspense fallback={<CustomSpinner />}>
                        <PayoutImportHistoryListing {...props} />
                    </Suspense>
                    </Col>
                </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

PayoutImportHistory.propTypes = {
    history: PropTypes.object,
}

export default PayoutImportHistory