import NoteCard from "components/NoteCard"
import { getNotesByEntity, updateNote, deleteNote } from "helpers/api_actions"
import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
} from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import Swal from "sweetalert2"
import ManageNote from "../../modal/manageNote"
import { useToasts } from "react-toast-notifications"
const Notes = forwardRef((props, ref) => {
  Notes.displayName = "Lead Notes"
  const [notes, setNotes] = useState([])
  const [edit, setEdit] = useState(false)
  const [submiting, setSubmiting] = useState(false)
  const [actoveItem, setActiveItem] = useState({})
  const { addToast } = useToasts()
  const fetchNotes = () => {
    getNotesByEntity(props.parentID).then(res => {
      setNotes(res.data)
    })
  }
  useImperativeHandle(ref, () => ({
    refetch() {
      fetchNotes()
    },
  }))
  useEffect(() => {
    fetchNotes()
  }, [])
  const handleEdit = data => {
    setActiveItem(data)
    setEdit(true)
  }
  const closeCreateNote = () => {
    setSubmiting(false)
    setEdit(false)
  }
  const handleSaveNote = data => {
    setSubmiting(true)
    updateNote(data?.id, {
      note: data?.note,
      title: data?.title,
      model: data?.model,
      model_id: data?.model_id,
    })
      .then(res => {
        addToast(res?.message, { appearance: "success" })
        fetchNotes()
        setSubmiting(false)
        setEdit(false)
      })
      .catch(err => {
        setSubmiting(false)
        addToast(err.response?.data?.message, { appearance: "error" })
      })
  }
  const handleDelete = data => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        deleteNote(data.id)
          .then(res => {
            addToast(res?.message, { appearance: "success" })
            fetchNotes()
          })
          .catch(err => {
            addToast(err.response?.data?.message, { appearance: "error" })
          })
      }
    })
  }
  return (
    <div>
      {edit && (
        <ManageNote
          data={actoveItem}
          title={"Update Note"}
          submiting={submiting}
          onCancelAction={closeCreateNote}
          onSubmitAction={handleSaveNote}
        />
      )}
      <Row style={{ overflow: "auto" }}>
        <Col lg="12">
          <Card className="m-0 detailBox">
            <CardBody className="p-3">
              <Row>
                {notes && notes.length > 0 ? (
                  <>
                    {notes.map((item, k) => (
                      <Col xs={12} key={k}>
                        <NoteCard
                          data={item}
                          handleEdit={handleEdit}
                          handleDelete={handleDelete}
                        />
                      </Col>
                    ))}
                  </>
                ) : (
                  <div className="d-flex align-items-center justify-content-center py-5 min-height-block">
                    No Notes found
                  </div>
                )}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
})

export default Notes
