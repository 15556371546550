import moment from "moment"
import React from "react"
import { forwardRef, useState } from "react"
import { useImperativeHandle, useRef } from "react"
import { useToasts } from "react-toast-notifications"

import { postCreUserUpdate } from "helpers/api_actions"
import { emailValidator, generateErrorMessage, isValid, validate } from "helpers/utilities"

import UserDetailsComponent from "../../../../component/common/manageSection"

const UserDetails = forwardRef((props, ref) => {
  UserDetails.displayName = "CRE User Details"

  const { addToast } = useToasts()
  const editRef = useRef(null)

  const [inputErrors, setInputErrors] = useState({})

  useImperativeHandle(ref, () => ({
    update(e) {
      editRef.current.onSubmitAction()
    },
  }))

  const handleSubmit = data => {
    props.setsubmitting(true)

    if (emailValidator(data?.email)) {
      let payload = {}

      payload["email"] = data?.email ?? null

      if (isValid(data?.full_name)) {
        payload["full_name"] = data?.full_name
      }
      if (isValid(data?.primary_phone)) {
        payload["primary_phone"] = `+91${data?.primary_phone}`
      }
      if (isValid(data?.password)) {
        payload["password"] = data?.password ?? ""
      }
      if (isValid(data?.confirm_password)) {
        payload["confirm_password"] = data?.confirm_password ?? ""
      }
      postCreUserUpdate(data.id, payload)
        .then(res => {
          props.setsubmitting(false)
          props.setEdit(false)
          addToast(res?.message, { appearance: "success" })
          props.refresh()
        })
        .catch(err => {
          props.setsubmitting(false)
          // toastr.error(err.response.data.message)
          addToast(generateErrorMessage(err?.response?.data), {
            appearance: "error",
          })
        })
    } else {
      props.setsubmitting(false)
      addToast("Please enter a Valid Email", { appearance: "error" })
    }
  }

  const data = {
    id: props.data?.id,
    full_name: props.data?.full_name,
    primary_phone: props?.data?.primary_phone
      ? props.data?.primary_phone?.replace("+91", "")
      : null,
    email: props?.data?.email ?? "",
    password: props?.data?.password,
    confirm_password: props?.data?.confirm_password,
  }
  return (
    <div>
      <UserDetailsComponent
        isReadOnly={props.isReadOnly}
        data={data}
        ref={editRef}
        //  errors={inputErrors}
        handleSubmit={handleSubmit}
      />
    </div>
  )
})

export default UserDetails
