import moment from "moment"
import React, { lazy, Suspense, useEffect, useState } from "react"

import CustomSpinner from "components/customSpinner"
import { AUTH_ROUTES } from "constants/routes"
import { changeEarningsStatus, getEarnings } from "helpers/api_actions"

import UserFilterComponent from "./filterComponent"

const CustomTable = lazy(() => import("components/customTable"))
import HoldEarnings from "./components/holdEarnings"
import { useToasts } from "react-toast-notifications"
import { validate } from "helpers/utilities"
import Earninghistory from "./components/earningshistory"
import EarningsView from "./components/earningsview"
function Earnings(props) {
  const [pageSize, setPageSize] = useState(15)
  const [data, setData] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [pageNo, setPageNo] = useState(1)
  const [search, setSearch] = useState("")
  const [filters, setFilters] = useState({})
  const [sortKey, setSortKey] = useState("")
  const [manageActions, setActions] = useState({})
  const [appliedFilters, setAppliedFilters] = useState("")
  const [appliedSeach, setAppliedSearch] = useState("")
  const [isFetching, setIsFetching] = useState(false)
  const navigateToDetails = (id, isEdit = false) => {
    props.history.push({
      pathname: `${AUTH_ROUTES.USER_DETAILS}${id}`,
      state: { edit: isEdit },
    })
  }
  const listEarnings = params => {
    let basicData = {
      select: `earnings.id,earnings.created_at as Date,leads.name as Lead Name,leads.display_id as Lead ID,lead_owner.full_name as Lead Owner,users.display_id as Lead Owner Id,services.name as Service Name,earnings.amount as Amount Earned,earnings.status as Status,earnings.modified_at as Updated On,earnings.created_at as Created On&find[earnings.user_id]=${props.parentID}`,
      include: `leads,users,services,users as lead_owner`,
    }
    setIsFetching(true)
    getEarnings(basicData, appliedSeach, appliedFilters, sortKey)
      .then(res => {
        setData(res?.data?.data)
        setTotalCount(res?.data?.total || 0)
        setIsFetching(false)
      })
      .catch(err => {
        setIsFetching(false)
      })
  }
  useEffect(() => {
    listEarnings()
  }, [pageSize, pageNo, appliedSeach, sortKey, appliedFilters])

  const handleDelete = () => {}
  const handleTableMenu = (action, rowData) => {
    switch (action.action) {
      case "view":
        setActions({ openView: true, rowdata: rowData })
        break

      case "hold":
        setActions({ open: true, rowdata: rowData })
        break
      case "unhold":
        setActions({ open: true, rowdata: rowData })
        break
      case "history":
        setActions({ openHistory: true, rowdata: rowData })
        break
      default:
        break
    }
  }
  const [columns, setColumns] = useState([
    {
      title: "Date",
      field: "Date",
      minWidth: 130,
      width: 130,
      wrap: true,
      render: (row, val) => (
        <div>{val ? moment(val).format("DD-MMM-YYYY") : "--"}</div>
      ),
      sort_direction: "ASC",
      sort_key: "payout_requests.created_at",
      // render:(row)=>
      // sort_key: "users.full_name",
    },
    {
      title: "Lead Name",
      // sort_key: "users.primary_phone",
      field: "Lead Name",
      minWidth: 180,
      width: 180,
      wrap: true,
      sort_key: "leads.name",
    },
    {
      title: "Lead Owner",
      field: "Lead Owner",
      minWidth: 150,
      width: 150,
      wrap: true,
      sort_key: "users.full_name",
      // align: "center",
    },

    {
      title: "Service Name",
      field: "Service Name",
      minWidth: 300,
      width: 300,
      wrap: true,
      sort_key: "services.name",
      // align: "end",
    },
    {
      title: "Amount Earned",
      field: "Amount Earned",
      minWidth: 180,
      width: 180,
      wrap: true,
      align: "center",
      sort_key: "earnings.amount",
      render: (row, val) => (
        <div>
          {val ? parseFloat(val).toLocaleString('en-IN') : "--"} {val && !row["Lead ID"] ? "(Signup Bonus)" : ""}
        </div>
      ),
    },
    {
      title: "Status",
      field: "Status",
      minWidth: 120,
      width: 120,
      wrap: true,
      align: "center",
      sort_key: "earnings.status",
    },

    {
      title: "Updated On",
      field: "Updated On",
      minWidth: 135,
      width: 135,
      wrap: true,
      render: (row, val) => (
        <div>{val ? moment(val).format("DD-MMM-YYYY") : "--"}</div>
      ),
      sort_key: "earnings.modified_at",
    },
    {
      title: "Created On",
      field: "Created On",
      minWidth: 135,
      width: 135,
      wrap: true,
      render: (row, val) => (
        <div>{val ? moment(val).format("DD-MMM-YYYY") : "--"}</div>
      ),
      sort_key: "earnings.created_at",
    },
  ])

  const handleSort = row => {
    let nextDirection = row.sort_direction
      ? row?.sort_direction === "ASC"
        ? "DESC"
        : "ASC"
      : "ASC"
    columns.forEach(col => {
      if (col.field === row.field) {
        col["sort_direction"] = nextDirection
      } else {
        col["sort_direction"] = undefined
      }
    })
    setColumns(() => columns)

    let sortkey = `&sort_by[${row.sort_key}]=${nextDirection}`
    setSortKey(sortkey)
  }
  const handleSearch = val => {
    setSearch(val)
    let searchString =
      val && val !== ""
        ? `&search[earnings.created_at]=${val}&search[leads.name]= ${val}&search[users.full_name]=${val}`
        : undefined
    setPageNo(1)
    setAppliedSearch(searchString)
  }

  const onFilterSubmission = fil => {
    setFilters(fil)
    let filterString = undefined
    filterString = `${
      fil?.status?.value ? "&filter[earnings.status]=" + fil?.status?.value : ""
    }${fil?.name ? "&filter[services.name]=" + fil?.handledBy?.label : ""}${
      fil?.modified_at.length === 2
        ? "&filter[earnings.updated_at]=" +
          moment(fil.modified_at[0]).format("YYYY-MM-DD") +
          " - " +
          moment(fil.modified_at[1]).format("YYYY-MM-DD")
        : ""
    }${
      fil?.created_at?.length === 2
        ? "&filter[earnings.created_at]=" +
          moment(fil.created_at[0]).format("YYYY-MM-DD") +
          " - " +
          moment(fil.created_at[1]).format("YYYY-MM-DD")
        : ""
    }`
    setPageNo(1)
    setAppliedFilters(filterString ?? "")
  }
  const getTableActions = rowData => {
    return rowData["Lead ID"]
      ? [
          {
            icon: <i className="mr-1" />,
            action: "view",
            name: "View",
            onClick: handleTableMenu,
          },
          ...(rowData?.Status !== "Cancelled"
            ? [
                {
                  icon: <i className="mr-1" />,
                  action: rowData?.Status === "On Hold" ? "unhold" : "hold",
                  name: rowData?.Status === "On Hold" ? "Un-Hold" : "Hold",
                  onClick: handleTableMenu,
                },
              ]
            : []),
          {
            icon: <i className="mr-1 text-danger" />,
            action: "history",
            name: "History",
            onClick: handleTableMenu,
          },
        ]
      : []
  }
  const handleOnPageChange = p => {
    setPageNo(p.page)
    setPageSize(p.pageSize)
  }
  if (isFetching) {
    return <CustomSpinner />
  }
  const { addToast } = useToasts()

  const handleHoldings = async data => {
    const form = new FormData()
    form.append("status", data.status?.value)
    form.append("changed_by", data.changed_by?.value)
    form.append("note", data.note)

    changeEarningsStatus(form, manageActions.rowdata.id)
      .then(res => {
        addToast(res?.message, { appearance: "success" })
        listEarnings()
        setActions({})
      })
      .catch(err => {
        setActions({})
        addToast(err.response?.data?.message, { appearance: "error" })
      })
  }
  return (
    <Suspense fallback={<CustomSpinner />}>
      <CustomTable
        columns={columns}
        data={data}
        actions={rowData => {
          return getTableActions(rowData)
        }}
        currentPage={pageNo}
        totalCount={totalCount}
        pageSize={pageSize}
        search={search}
        filters={filters}
        onSearch={handleSearch}
        onFilterSubmission={onFilterSubmission}
        filterComponent={UserFilterComponent}
        pagination={{
          onPageChange: handleOnPageChange,
        }}
        isFetching={isFetching}
        onSort={handleSort}
        detailsNoSortTable={true}
      />
      {manageActions.open && (
        <HoldEarnings
          data={manageActions.rowdata}
          onCancelAction={e => setActions({})}
          onSubmitAction={handleHoldings}
        />
      )}
      {manageActions?.openHistory && (
        <Earninghistory
          data={manageActions.rowdata}
          onCancelAction={e => setActions({})}
        />
      )}
      {manageActions.openView && (
        <EarningsView
          data={manageActions.rowdata}
          onCancelAction={e => setActions({})}
        />
      )}
    </Suspense>
  )
}

export default Earnings
