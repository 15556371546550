import React, { Component } from "react"
import { Col, Input, Modal, Row } from "reactstrap"

import uploadplaceholder from "assets/admin-images/upload-placeholder.png"
import SelectfieldComponent from "components/Common/SelectfieldComponent"
import TextfieldComponent from "components/Common/TextfieldComponent"

class PanInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal_center: false,
    }
    this.tog_center = this.tog_center.bind(this)
  }

  tog_center() {
    this.setState(prevState => ({
      modal_center: !prevState.modal_center,
    }))
    this.removeBodyCss()
  }

  show() {
    this.setState({ visible: true })
  }

  hide() {
    this.setState({ visible: false })
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <a
            href="#"
            onClick={this.tog_center}
            data-toggle="modal"
            data-target=".bs-example-modal-center"
            className="linkText"
          >
            View Details
          </a>
          <Modal
            isOpen={this.state.modal_center}
            toggle={this.tog_center}
            centered={true}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                PAN Card Information
              </h5>
              <button
                type="button"
                onClick={() => {
                  this.setState({ modal_center: false })
                }}
                className="btn-close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className=" mb-3">
                  <SelectfieldComponent label="Pan Type" />
                </div>
                <div className=" mb-3">
                  <TextfieldComponent
                    label="PAN Number"
                    placeholder="Enter PAN Number"
                    fieldtype="text"
                  />
                </div>
                <div className=" mb-3">
                  <TextfieldComponent
                    label="Full Name"
                    placeholder="Enter Full Name"
                    fieldtype="text"
                  />
                </div>
                <div className=" mb-3">
                  <TextfieldComponent
                    label="Father Name"
                    placeholder="Enter Father Name"
                    fieldtype="text"
                  />
                </div>
                <Row>
                  <Col md="6">
                    <div className="mb-2 modalUpload">
                      <label htmlFor="imageupload" className="form-label">
                        Front Image
                      </label>
                      <div className="imageUpload" id="imageupload">
                        <Input
                          className="form-control"
                          type="file"
                          id="frontImageFile"
                        />
                        <div className="defaultBlock">
                          <img src={uploadplaceholder} />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-2 modalUpload">
                      <label htmlFor="imageupload" className="form-label">
                        Back Image
                      </label>
                      <div className="imageUpload" id="imageupload">
                        <Input
                          className="form-control"
                          type="file"
                          id="backImageFile"
                        />
                        <div className="defaultBlock">
                          <img src={uploadplaceholder} />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  this.setState({ modal_center: false })
                }}
              >
                Cancel
              </button>
              <button type="button" className="btn btn-primary">
                Save
              </button>
            </div>
          </Modal>
        </div>
      </React.Fragment>
    )
  }
}

export default PanInfo
