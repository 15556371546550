import React, { useEffect, useImperativeHandle } from "react"
import { useState } from "react"
import { forwardRef } from "react"
import * as FeatherIcon from "react-feather"
import ReactHtmlParser from "react-html-parser"
import { Card, CardBody, CardTitle, Col, Input, Row } from "reactstrap"

import uploadplaceholder from "assets/admin-images/upload-placeholder.png"
import RichTextEditor from "components/customRTE/index"
import SearchSelect from "components/CustomSearchSelect"
import CustomTextField from "components/CustomTextField"
import { getServiceCategoryList } from "helpers/api_actions"
import { urlValidator, validate } from "helpers/utilities"
import CustomCheckbox from "components/CustomCheckbox/index"
import CustomSelect from "components/CustomSelect/index"

const UserManageSection = forwardRef((props, ref) => {
  UserManageSection.displayName = "User Manage Section"
  const [formData, setFormData] = useState(props.data)
  const [errs, setErrs] = useState({})
  const [urlError, setUrlError] = useState(true)
  const [serviceStatuses, setServiceStatuses] = useState([])
  let required = ["name", "service_category"]
  useImperativeHandle(ref, () => ({
    onSubmitAction(e) {
      let requiredFields = required
      if (formData?.id === true && formData?.is_service_status === true)
      {
        requiredFields.push('earning_process')
          requiredFields.push('final_service_status')
      }
      if (formData?.is_paid)
      {
        requiredFields.push('start_amount')
      }
      const errors = validate(requiredFields, formData, "")
      const urlErr = urlValidator(formData?.video_url)
      setUrlError(urlErr)
      setErrs(errors)
      if (urlErr) {
        if (Object.keys(errors).length === 0) {
          props.handleSubmit(formData)
        }
      }
    },
    onCancelAction(e) {
      // setFormData(null)
      document.getElementById("profilePickViewer").src = uploadplaceholder
    },
  }))
  useEffect(() => {
    if (Object.keys(errs).length) {
      let requiredFields = required
      if (formData?.id === true && formData?.is_service_status === true)
      {
        requiredFields.push('earning_process')

          requiredFields.push('final_service_status')
      }
      
      if (formData?.is_paid)
      {
        requiredFields.push('start_amount')
      }
      const error = validate(requiredFields, formData, "")
      setErrs(error)
    }
    // setKeyVal(keyVal+1)
  }, [formData.name, formData.service_category, formData?.final_service_status, formData?.earning_process])

  useEffect(() => {
    if (formData?.is_service_status === true && formData?.service_status && formData?.service_status.length > 0)
    {
      let serviceStatusesData = formData?.service_status.map((status) => {
        return {
          ...status,
          label: status.service_lead_status,
          value: status.id,
        }
      })
      setServiceStatuses(serviceStatusesData)
    } else {
      setServiceStatuses([])
    }
  }, [formData?.service_status, formData?.is_service_status])
  const handleChange = (v, k) => {
    setFormData(f => ({ ...f, [k]: v }))

    if (k === "video_url") {
      const urlErr = urlValidator(v)

      setUrlError(urlErr)
    }
  }

  const previewImage = img => {
    var oFReader = new FileReader()
    oFReader.readAsDataURL(img)

    oFReader.onload = function (oFREvent) {
      document.getElementById("profilePickViewer").src = oFREvent.target.result
    }
  }

  const getParentCategories = async v => {
    let payload = {
      select:
        "service_categories.id,service_categories.name as Category Name,parent.name as Parent Category,Services Mapped,service_categories.description as Description,service_categories.status as Status,service_categories.display_id as  Category ID,service_categories.created_at as Created On,service_categories.updated_at as Updated On",
      include: `service_categories as parent `,
    }
    let filter = `&search[service_categories.name]=${v}`
    const res = await getServiceCategoryList(payload, filter)
    const data = res?.data?.data || []
    data.forEach(item => {
      item["label"] = `${item["Category Name"]} (${item["Category ID"]})`
      item["value"] = item.id
    })
    return data
  }
  const onFileRemove = (files, key) => {
    let current = files
    let newObject = {}
    let i = 0
    Object.keys(current).map(k => {
      if (k !== key) {
        newObject = { ...newObject, [i]: current[k] }
        i++
      }
    })

    // delete current[key];
    return newObject
  }
  const handleImageChange = e => {
    let value = e.target.files[0]
    if (value.type.includes("image")) {
      setFormData({ ...formData, image: value })
    }
    previewImage(value)
  }

  return (
    <Row>
      <Col lg="12">
        <Card>
          <CardBody>
            <Row>
              <Col lg="12" className="">
                <CardTitle className="mt-0 mb-3">Basic Information</CardTitle>
                <Row>
                  <Col lg="3">
                    <div className="mb-3">
                      <CardTitle className="mt-0 mb-3 small">
                        Profile Image
                      </CardTitle>
                      <div className="imageUpload" id="imageupload">
                        <Input
                          type="file"
                          accept="image/*"
                          className={`form-control ${
                            props.isReadOnly ? "d-none" : ""
                          }`}
                          // disabled={props.isReadOnly}
                          id="formFile"
                          onChange={handleImageChange}
                        />
                        <div className="defaultBlock">
                          <img
                            className="profile-image-preview"
                            src={
                              formData?.imageUrl
                                ? `${formData?.imageUrl}`
                                : uploadplaceholder
                            }
                            id="profilePickViewer"
                            width={"100%"}
                            height={"100%"}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg="9">
                    <Row>
                      <Col md="6">
                        <div className="mb-3">
                          <CustomTextField
                            label="Service Name"
                            required
                            disabled={props.isReadOnly}
                            placeholder="Enter Full Name"
                            type="text"
                            name="name"
                            onChange={(v, k, e) => handleChange(v, k, e)}
                            value={formData?.name ?? ""}
                          />
                          {errs?.name && (
                            <p className="text-danger"> {errs?.name}</p>
                          )}
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3">
                          <SearchSelect
                            getData={getParentCategories}
                            isMulti={false}
                            required
                            clearable
                            disabled={props.isReadOnly}
                            label="Service Category"
                            setData={v => {
                              handleChange(v, "service_category")
                            }}
                            value={formData?.service_category ?? null}
                          />
                          {errs?.service_category && (
                            <p className="text-danger">
                              {" "}
                              {errs?.service_category}
                            </p>
                          )}
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3">
                          <CustomTextField
                            label="Video URL"
                            // required
                            disabled={props.isReadOnly}
                            placeholder="Enter Video URL"
                            type="url"
                            name="video_url"
                            onChange={(v, k, e) => handleChange(v, k, e)}
                            value={formData?.video_url ?? ""}
                          />
                          {!urlError && (
                            <p className="text-danger">
                              {" "}
                              Please enter a valid url
                            </p>
                          )}
                        </div>
                      </Col>
                      {
                    // formData?.id !== undefined && (
                      <>
                        <Col md="3">
                          <div className="mb-3">
                          <CustomSelect
                        label="Earning Process"
                        required
                        placeholder="Earning Process"
                        disabled={true}
                        options={[
                          { label: "Normal Flow", value: "Normal Flow" },
                          { label: "Loan Flow", value: "Loan Flow" },
                        ]}
                        value={formData?.is_service_status ? formData?.earning_process : { label: "Normal Flow", value: "Normal Flow" }}
                        setData={data => handleChange(data, "earning_process")}
                      />
                            {errs?.earning_process && (
                              <p className="text-danger"> {errs?.earning_process}</p>
                            )}
                          </div>
                        </Col>
                        {
                           formData?.is_service_status === true && (
                            <Col md="3">
                                <div className="mb-3">
                                <CustomSelect
                              label="Final Service Status"
                              required
                              placeholder="Final Service Status"
                              disabled={props.isReadOnly}
                              options={serviceStatuses}
                              value={formData?.final_service_status ?? null}
                              setData={data => handleChange(data, "final_service_status")}
                            />
                                  {errs?.final_service_status && (
                                    <p className="text-danger"> {errs?.final_service_status}</p>
                                  )}
                                </div>
                              </Col>
                          )
                        }
                      </>
                  //  )
                  }
                      </Row>
                  <Row>
                    
                  {
                    formData?.earning_process?.value !== "Loan Flow" && (
                      <Col md="3">
                        <div className="mt-4">
                          <CustomCheckbox
                            label="Paid Service"
                            disabled={props.isReadOnly}
                            name="is_paid"
                            onChange={(v, k, e) => handleChange(v, k, e)}
                            value={formData?.is_paid ?? false}
                          />
                          {errs?.is_paid && (
                            <p className="text-danger"> {errs?.is_paid}</p>
                          )}
                        </div>
                      </Col>
                    )
                  }
                  {
                    formData?.is_paid === true && (
                      <>
                        <Col md="3">
                          <div className="mb-3">
                            <CustomTextField
                              label="Amount"
                              disabled={props.isReadOnly}
                              placeholder="Enter Amount"
                              type="decimal"
                              name="start_amount"
                              onChange={(v, k, e) => handleChange(v, k, e)}
                              value={formData?.start_amount ?? null}
                            />
                            {errs?.start_amount && (
                              <p className="text-danger"> {errs?.start_amount}</p>
                            )}
                          </div>
                        </Col>
                      </>
                    )
                  }
                  </Row>
                  <Row>
                  <Col md="3">
                    <div className="mt-4">
                      <CustomCheckbox
                        label="Popular"
                        disabled={props.isReadOnly}
                        name="is_popular"
                        onChange={(v, k, e) => handleChange(v, k, e)}
                        value={formData?.is_popular ?? false}
                      />
                      {errs?.is_popular && (
                        <p className="text-danger"> {errs?.is_popular}</p>
                      )}
                    </div>
                  </Col>

                  <Col md="6">
                    <div className="mb-3">
                      <CustomTextField
                        label="Sort Order"
                        disabled={props.isReadOnly}
                        placeholder="Enter Sort Order"
                        type="number"
                        name="sort_order"
                        onChange={(v, k, e) => handleChange(v, k, e)}
                        value={formData?.sort_order ?? null}
                      />
                      {errs?.sort_order && (
                        <p className="text-danger"> {errs?.sort_order}</p>
                      )}
                    </div>
                  </Col>
                    </Row>
                  </Col>
                  <Col md="12">
                    <div className="mb-3">
                      <CustomTextField
                        label="Commision note"
                        disabled={props.isReadOnly}
                        placeholder="Enter Commision note"
                        type="textarea"
                        name="commision_note"
                        onChange={(v, k, e) => handleChange(v, k, e)}
                        value={formData?.commision_note ?? ""}
                      />
                    </div>
                  </Col>
                  <Col lg="12">
                    <CardTitle className="mt-0 ">Description</CardTitle>
                    <Row>
                      <Col md="12">
                        <div className="mb-3">
                          {props.isReadOnly ? (
                            <>
                              {formData?.long_description && formData?.long_description != 'null' ? (
                                <div>
                                  {" "}
                                  {ReactHtmlParser(formData?.long_description)}
                                </div>
                              ) : (
                                "--"
                              )}
                            </>
                          ) : (
                            <RichTextEditor
                              value={formData?.long_description ?? null}
                              onChange={val =>
                                handleChange(val, "long_description")
                              }
                            />
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg="12">
                    <CardTitle className="mt-0 ">Terms & Conditions</CardTitle>
                    <Row>
                      <Col md="12">
                        <div className="mb-3">
                          {props.isReadOnly ? (
                            <>
                              {formData?.terms_and_conditions && formData?.terms_and_conditions != 'null' ? (
                                <div>
                                  {" "}
                                  {ReactHtmlParser(
                                    formData?.terms_and_conditions
                                  )}
                                </div>
                              ) : (
                                "--"
                              )}
                            </>
                          ) : (
                            <RichTextEditor
                              value={formData?.terms_and_conditions ?? null}
                              onChange={val =>
                                handleChange(val, "terms_and_conditions")
                              }
                            />
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg="12">
                    <CardTitle className="mt-0 ">Attachment Files</CardTitle>
                    <Row>
                      <Col md="6">
                        <div className="mb-3">
                          <div className="form-control-file">
                            {!props.isReadOnly && (
                              <input
                                label="Attach Files"
                                name="attachement"
                                type="file"
                                multiple
                                id="attachement"
                                hidden
                                onChange={e => {
                                  handleChange(e.target.files, "attachement")
                                }}
                              />
                            )}
                            {formData?.attachement !== null &&
                              formData?.attachement !== undefined &&
                              formData?.attachement !== "" && (
                                <>
                                  {Object.keys(formData.attachement).map(
                                    (key, i) => {
                                      return (
                                        <div className="p-2 bg-light" key={i}>
                                          <div className="d-flex justify-content-between">
                                            <div>
                                              <FeatherIcon.Paperclip
                                                style={{ height: 14 }}
                                              />{" "}
                                              {formData.attachement[key].name}
                                            </div>
                                            <div
                                              className="text-danger cursor-pointer"
                                              onClick={e =>
                                                handleChange(
                                                  onFileRemove(
                                                    formData.attachement,
                                                    key
                                                  ),
                                                  "attachement"
                                                )
                                              }
                                            >
                                              <i className="fa fa-trash-alt mr-1"></i>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    }
                                  )}
                                </>
                              )}
                            {/* {!props.isReadOnly && (
                              <p className="mb-1 mt-3 font-weight-bold d-flex align-items-center">
                                <label htmlFor="attachement">
                                  <span className="btn btn-secondary">
                                    {" "}
                                    Attach Files{" "}
                                  </span>
                                </label>
                              </p>
                            )} */}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg="12">
                    <CardTitle className="mt-0 ">
                      Pricing Information{" "}
                    </CardTitle>
                    <Row>
                      <Col md="12">
                        <div className="mb-3">
                          {props.isReadOnly ? (
                            <>
                              {formData?.pricing_information && formData?.pricing_information != 'null' ? (
                                <div>
                                  {" "}
                                  {ReactHtmlParser(
                                    formData?.pricing_information
                                  )}
                                </div>
                              ) : (
                                "--"
                              )}
                            </>
                          ) : (
                            <RichTextEditor
                              disabled={props.isReadOnly}
                              value={formData?.pricing_information ?? null}
                              onChange={val =>
                                handleChange(val, "pricing_information")
                              }
                            />
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg="12">
                    <CardTitle className="mt-0 ">Documents Required</CardTitle>
                    <Row>
                      <Col md="12">
                        <div className="mb-3">
                          {props.isReadOnly ? (
                            <>
                              {formData?.documents_required && formData?.documents_required != 'null' ? (
                                <div>
                                  {" "}
                                  {ReactHtmlParser(
                                    formData?.documents_required
                                  )}
                                </div>
                              ) : (
                                "--"
                              )}
                            </>
                          ) : (
                            <RichTextEditor
                              disabled={props.isReadOnly}
                              value={formData?.documents_required ?? null}
                              onChange={val =>
                                handleChange(val, "documents_required")
                              }
                            />
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Col>

                  <Col lg="12">
                    <CardTitle className="mt-0 ">Internal notes</CardTitle>
                    <Row>
                      <Col md="12">
                        <div className="mb-3">
                          {props.isReadOnly ? (
                            <>
                              {formData?.short_description && formData?.short_description != 'null' ? (
                                <div>
                                  {" "}
                                  {ReactHtmlParser(formData?.short_description)}
                                </div>
                              ) : (
                                "--"
                              )}
                            </>
                          ) : (
                            <RichTextEditor
                              disabled={props.isReadOnly}
                              value={formData?.short_description ?? null}
                              onChange={val =>
                                handleChange(val, "short_description")
                              }
                            />
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Col>

                  <Col lg="12">
                    <CardTitle className="mt-0 ">Process</CardTitle>
                    <Row>
                      <Col md="12">
                        <div className="mb-3">
                          {props.isReadOnly ? (
                            <>
                              {formData?.process && formData?.process != 'null' ? (
                                <div> {ReactHtmlParser(formData?.process)}</div>
                              ) : (
                                "--"
                              )}
                            </>
                          ) : (
                            <RichTextEditor
                              disabled={props.isReadOnly}
                              value={formData?.process ?? null}
                              onChange={val => handleChange(val, "process")}
                            />
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
})

export default UserManageSection
