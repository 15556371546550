import moment from "moment"
// import ManageNote from "../../modal/manageNote"
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react"
import { useParams } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import { Card, CardBody, Col, Row } from "reactstrap"
import Swal from "sweetalert2"

import ManageNote from "components/Leads/manage/components/modal/manageNote"
import NoteCard from "components/NoteCard"
import {
  deleteFollowUpNote,
  deleteNote,
  getFollowUpNotesByEntity,
  updateFollowUpNote,
  updateNote,
} from "helpers/api_actions"

const FollowUpNotes = forwardRef((props, ref) => {
  FollowUpNotes.displayName = "Lead FollowUp Notes"
  const [notes, setNotes] = useState([])
  const [edit, setEdit] = useState(false)
  const [submiting, setSubmiting] = useState(false)
  const [actoveItem, setActiveItem] = useState({})
  const { addToast } = useToasts()
  const param = useParams()
  const fetchNotes = () => {
    getFollowUpNotesByEntity(props.parentID).then(res => {
      setNotes(res.data)
    })
  }
  useImperativeHandle(ref, () => ({
    refetch() {
      fetchNotes()
    },
  }))
  useEffect(() => {
    fetchNotes()
  }, [edit])
  const handleEdit = data => {
    setActiveItem(data)
    setEdit(true)
  }
  const closeCreateNote = () => {
    setSubmiting(false)
    setEdit(false)
  }
  const handleSaveNote = data => {
    setSubmiting(true)
    updateFollowUpNote(data?.id, {
      title: data.title,
      note: data.note,
      pre_sales_id: param.id,
      followup_date: moment(data.followup_date[0]).format("YYYY-MM-DD"),
    })
      .then(res => {
        addToast(res?.message, { appearance: "success" })
        fetchNotes()
        setSubmiting(false)
        setEdit(false)
      })
      .catch(err => {
        setSubmiting(false)
        addToast(err.response?.data?.message, { appearance: "error" })
      })
  }
  const handleDelete = data => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        deleteFollowUpNote(data.id)
          .then(res => {
            addToast(res?.message, { appearance: "success" })
            fetchNotes()
          })
          .catch(err => {
            addToast(err.response?.data?.message, { appearance: "error" })
          })
      }
    })
  }
  return (
    <div>
      {edit && (
        <ManageNote
          data={actoveItem}
          title={"Update Followup Note"}
          submiting={submiting}
          onCancelAction={closeCreateNote}
          onSubmitAction={handleSaveNote}
          followupFlag={true}
        />
      )}
      <Row style={{ overflow: "auto" }}>
        <Col lg="12">
          <Card className="m-0 detailBox">
            <CardBody className="p-3">
              <Row>
                {notes && notes.length > 0 ? (
                  <>
                    {notes.map((item, k) => (
                      <Col xs={12} key={k}>
                        <NoteCard
                          data={item}
                          handleEdit={handleEdit}
                          handleDelete={handleDelete}
                        />
                      </Col>
                    ))}
                  </>
                ) : (
                  <div className="d-flex align-items-center justify-content-center py-5 min-height-block">
                    No Notes found
                  </div>
                )}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
})

export default FollowUpNotes
