import CustomSelect from "components/CustomSelect/index"
import CustomTextField from "components/CustomTextField/index"
import SearchSelect from "components/CustomSearchSelect"
import {
  getBanksList,
  getUserAccountDetails,
  postAccountCreate,
} from "helpers/api_actions"
import React, {
  forwardRef,
  Fragment,
  useEffect,
  useImperativeHandle,
  useState,
} from "react"
import { Modal, Row, Col } from "reactstrap"
import {
  generateClassNameByValue,
  generateErrorMessage,
  isValid,
  validate,
} from "helpers/utilities"
import { useToasts } from "react-toast-notifications"

let required = ["upi_id"]

const UpiInfo = forwardRef((props, ref) => {
  UpiInfo.displayName = "UPI Info"
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [accounts, setAccounts] = useState([])
  const [accountsList, setAccountsList] = useState([])
  const [activeAccount, setActiveAccount] = useState({})
  const [activeAccountData, setActiveAccountData] = useState({})
  const [formData, setFormData] = useState({})
  const [errors, setErrors] = useState({})
  const { addToast } = useToasts()
  const [accountVerifyClass, setAccountVerifyClass] = useState("bg-info")

  let isPrimaryOptions = [
    { label: "No", value: false },
    { label: "Yes", value: true },
  ]
  if (
    (!props.bankAccountsCount || props.bankAccountsCount < 1) &&
    accounts.length < 1
  ) {
    isPrimaryOptions = isPrimaryOptions.filter(option => option.value === true)
  }

  const handleChange = (v, k) => {
    const error = validate(required, { ...formData, [k]: v }, "")
    setFormData(f => ({
      ...f,
      [k]: v,
    }))
    if (Object.keys(errors).length) {
      setErrors(error)
    }
  }

  function handleSubmit(event) {
    event.preventDefault()
    const error = validate(required, formData, "")
    setErrors(error)
    if (Object.keys(error).length === 0 && props.userId) {
      let payload = {}
      if (isValid(formData?.upi_id)) {
        payload["upi_id"] = formData?.upi_id
      }
      if (
        formData?.primary_account?.value == true ||
        formData?.primary_account?.value === false
      ) {
        payload["primary_account"] = formData?.primary_account?.value
      }
      payload["user_id"] = props.userId
      payload["payment_type"] = "UPI"
      postAccountCreate(payload)
        .then(res => {
          setIsEditMode(false)
          setIsModalOpen(false)
          setFormData({})
          fetchUPIAccountDetails()
          if (props?.refetchUser) {
            props?.refetchUser()
          }
          addToast(res?.message, { appearance: "success" })
        })
        .catch(err => {
          console.log(err)
          // toastr.error(err.response.data.message)
          if (err?.response?.data) {
            addToast(generateErrorMessage(err?.response?.data), {
              appearance: "error",
            })
          }
        })
    }
  }

  function toggleModal(event) {
    event.preventDefault()
    setIsEditMode(false)
    setActiveAccount(accountsList[0] ?? {})
    setActiveAccountData(accounts[0] ?? {})
    setIsModalOpen(!isModalOpen)
  }
  function closeModal(event) {
    event.preventDefault()
    setIsModalOpen(false)
  }
  function handleAccountChange(selectedAccount) {
    let selectedAccountId = selectedAccount?.value
    let activeUserAccountData = accounts.find(account => {
      return account.id === selectedAccountId
    })
    setActiveAccount(selectedAccount)
    setActiveAccountData(activeUserAccountData)
  }
  useImperativeHandle(ref, () => ({
    edit(e) {
      setIsEditMode(true)
      setIsModalOpen(true)
      setActiveAccount({})
      setActiveAccountData({})
    },
    getAccounts() {
      return accounts.length
    },
  }))
  useEffect(() => {
    if (activeAccountData) {
      if (activeAccountData.verification_status == "Verified") {
        setAccountVerifyClass("bg-primary")
      } else if (
        activeAccountData.verification_status == "Verification failed"
      ) {
        setAccountVerifyClass("bg-danger")
      } else {
        setAccountVerifyClass("bg-info")
      }
    }
  }, [activeAccountData])
  function fetchUPIAccountDetails() {
    if (props.userId) {
      let basicData = {
        select: `id,payment_type,bank_name,account_name,bank_icon,account_number,branch_name,ifsc_code,account_type,primary_account,upi_id,verification_status,verification_date&find[user_id]=${props.userId}&find[payment_type]=UPI`,
      }
      getUserAccountDetails(basicData)
        .then(res => {
          if (res && res.data && res.data.data) {
            let userAccounts = res.data.data
            setAccounts(userAccounts)

            let userAccountsList = userAccounts.map(userAccount => ({
              label: userAccount.upi_id,
              value: userAccount.id,
            }))
            setAccountsList(userAccountsList)
            let activeAccount = {}
            let activeAccountData = {}
            if (userAccountsList.length) {
              setActiveAccount(userAccountsList[0])
            }
            if (userAccounts.length) {
              setActiveAccountData(userAccounts[0])
            }
          }
        })
        .catch(err => {
          // setIsFetching(false)
        })
    }
  }

  useEffect(() => {
    fetchUPIAccountDetails()
  }, [])
  return (
    <div>
      <div className="my-2">
        {props?.upiStatusInfo &&
          Object.keys(props?.upiStatusInfo)
            .filter(
              dataKey =>
                props?.upiStatusInfo[dataKey] &&
                props?.upiStatusInfo[dataKey] > 0
            )
            .map(dataKey => (
              <span
                key={dataKey}
                className={`chips me-2 ${generateClassNameByValue(dataKey)}`}
              >
                {dataKey}: {props?.upiStatusInfo[dataKey]}
              </span>
            ))}
      </div>
      <div>
        {accounts.length > 0 ? (
          <a
            href="#"
            onClick={toggleModal}
            data-toggle="modal"
            data-target=".bs-example-modal-center"
            className="linkText"
          >
            View Details
          </a>
        ) : (
          <p>No account added</p>
        )}
        <Modal isOpen={isModalOpen} toggle={toggleModal} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              UPI Account Information
            </h5>
            <button
              type="button"
              onClick={closeModal}
              className="btn-close"
            ></button>
          </div>
          <div className="modal-body">
            {(activeAccountData?.primary_account ||
              activeAccountData?.verification_status) && (
              <div className="mb-3">
                {activeAccountData?.primary_account && (
                  <span className="ms-2 chips bg-success">Primary</span>
                )}
                {activeAccountData?.verification_status && (
                  <span className={`ms-2 chips ${accountVerifyClass}`}>
                    Verification status:{" "}
                    {activeAccountData?.verification_status}
                  </span>
                )}
              </div>
            )}
            {isEditMode && (
              <form>
                <div className="mb-3">
                  {isEditMode ? (
                    <CustomTextField
                      label="UPI ID"
                      type="text"
                      required
                      name="upi_id"
                      disabled={!isEditMode}
                      onChange={(v, k, e) => handleChange(v, k, e)}
                      placeholder="Enter UPI ID"
                      value={formData?.upi_id ?? ""}
                    />
                  ) : (
                    <CustomSelect
                      label="Choose Account"
                      options={accountsList}
                      value={activeAccount}
                      setData={data => handleAccountChange(data)}
                    />
                  )}
                  {isEditMode && errors?.upi_id && (
                    <p className="text-danger"> {errors?.upi_id}</p>
                  )}
                </div>
                <div className="mb-3">
                  <CustomSelect
                    label="Is Account Primary?"
                    options={isPrimaryOptions}
                    value={
                      isEditMode
                        ? formData?.primary_account ?? isPrimaryOptions[0]
                        : isPrimaryOptions.find(
                            option =>
                              option.value ===
                              activeAccountData?.primary_account
                          )
                    }
                    setData={data => handleChange(data, "primary_account")}
                  />
                  {isEditMode && errors?.primary_account && (
                    <p className="text-danger"> {errors?.primary_account}</p>
                  )}
                </div>
              </form>
            )}
            {!isEditMode && (
              <>
                <form>
                  <div className="mb-3">
                    <CustomSelect
                      label="Choose Account"
                      options={accountsList}
                      value={activeAccount}
                      setData={data => handleAccountChange(data)}
                    />
                  </div>
                </form>
                <hr />
                <Row>
                  <Col md="4">
                    <p className="detail-title">UPI ID</p>
                    <p className="details-content">
                      {activeAccountData?.upi_id ?? "--"}
                    </p>
                  </Col>
                </Row>
              </>
            )}
          </div>
          <div className="modal-footer">
            {isEditMode && (
              <button
                type="submit"
                disabled={props.submiting}
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                Save
              </button>
            )}
          </div>
        </Modal>
      </div>
    </div>
  )
})

export default UpiInfo
