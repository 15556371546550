import moment from "moment"
import React, { useEffect, useImperativeHandle, useRef } from "react"
import { useState } from "react"
import { forwardRef } from "react"
import { AiFillCloseCircle } from "react-icons/ai"
import { useParams } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import { Button, Card, CardBody, CardTitle, Col, Input, Row } from "reactstrap"

import uploadplaceholder from "assets/admin-images/upload-placeholder.png"
import CustomCalander from "components/CustomCalendar"
import SearchSelect from "components/CustomSearchSelect"
import CustomSelect from "components/CustomSelect"
import CustomTextField from "components/CustomTextField"
import { getCitiesList, getCountriesList, getKycDetails, getQualificationList,getJobList, getStatesList, getUserList } from "helpers/api_actions"
import { emailValidator, validate } from "helpers/utilities"
import UpiInfo from "pages/Users/manage/components/modal/upiinfo"

import AadharInfo from "../../manage/components/modal/aadharinfo"
import BankingInfo from "../../manage/components/modal/bankinginfo"
import PanInfo from "../../manage/components/modal/paninfo"

let required = [
  "full_name",
  "primary_phone",
  "email",
]
let intervalCall = null
const UserManageSection = forwardRef((props, ref) => {
  const params = useParams()
  UserManageSection.displayName = "User Manage Section"
  var dobMax = new Date()
  dobMax.setFullYear(dobMax.getFullYear() - 18)
  const [formData, setFormData] = useState(props.data)
  const [errors, setErrors] = useState({})
  const [hasValidEmail, setHasValidEmail] = useState(true)
  useImperativeHandle(ref, () => ({
    onSubmitAction(e) {
      const error = validate(required, formData, "")
      setErrors(error)
      setTimeout(() => {
        if (Object.keys(error).length === 0) {
          props.handleSubmit(formData)
        }
      }, 150)
    },
    onCancelAction(e) {
      setFormData(null)
    },
  }))
  const handleChange = (v, k) => {
    const error = validate(required, { ...formData, [k]: v }, "")
    setFormData(f => ({
      ...f,
      [k]: v,
    }))
    if (k === "email") {
      setHasValidEmail(emailValidator(v))
    }
    if (Object.keys(errors).length) {
      setErrors(error)
    }
  }
  return (
    <Row style={{ overflow: "auto" }}>
      <Col lg="12">
        <Card className="m-0">
          <CardBody>
            <Row>
              <Col lg="12" className="">
                <CardTitle className="mt-0 mb-3">Basic Information</CardTitle>
                <Row>
                  <Col md="4">
                    <div className="mb-3">
                      <CustomTextField
                        label="Full Name"
                        required
                        disabled={props.isReadOnly}
                        placeholder="Enter Full Name"
                        type="text"
                        name="full_name"
                        onChange={(v, k, e) => handleChange(v, k, e)}
                        value={formData?.full_name ?? ""}
                      />
                      {errors?.full_name && (
                        <p className="text-danger"> {errors?.full_name}</p>
                      )}
                    </div>
                  </Col>
                  <Col md="4">
                    <div className=" mb-3">
                      <CustomTextField
                        label="Phone"
                        required
                        disabled={props.isReadOnly}
                        startAdorement="+91"
                        maxLength={10}
                        minLength={10}
                        placeholder="Enter Phone"
                        type="phone"
                        name="primary_phone"
                        onChange={(v, k, e) => handleChange(v, k, e)}
                        value={formData?.primary_phone ?? ""}
                      />
                      {errors?.primary_phone && (
                        <p className="text-danger"> {errors?.primary_phone}</p>
                      )}
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="mb-3">
                      <CustomTextField
                        label="Email"
                        disabled={props.isReadOnly}
                        placeholder="Enter Email ID"
                        type="email"
                        name="email"
                        required
                        onChange={(v, k, e) => handleChange(v, k, e)}
                        value={formData?.email ?? ""}
                      />
                      {(errors?.email || !hasValidEmail) && (
                        <p className="text-danger">
                          {!hasValidEmail
                            ? "Provide a valid email id"
                            : errors?.email}
                        </p>
                      )}
                    </div>
                  </Col>
                </Row>
                {
                  !props?.data?.id && (
                    <>
                    <CardTitle className="mt-0 mb-3">Other Data</CardTitle>
                    <Row>
                      <Col md="4">
                        <div className=" mb-3">
                          <CustomTextField
                            label="Set Passcode"
                            placeholder="Enter Passcode"
                            type="pin"
                            disabled={props.isReadOnly}
                            maxLength={4}
                            mode={"number"}
                            name="password"
                            onChange={(v, k, e) => handleChange(v, k, e)}
                            value={formData?.password ?? ""}
                          />
                          {errors?.password && (
                            <p className="text-danger"> {errors?.password}</p>
                          )}
                        </div>
                      </Col>
                    </Row>
                    </>
                  )
                }
              </Col>
              <></>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
})

export default UserManageSection
