import { TAB_LABELS } from "constants/label"

export const TICKET_TAB_LABELS = [
  {
    id: "ticket-details",
    label: TAB_LABELS.TICKET_DETAILS,
  },
  {
    id: "ticket-logs",
    label: TAB_LABELS.TICKET_LOGS,
  },
]

export const TICKET_STATUS_OPTIONS = [
  { label: "New", value: "New" },
  { label: "Processing", value: "Processing" },
  { label: "Closed", value: "Closed" },
  { label: "Resolved", value: "Resolved" },
]
