import { TAB_LABELS } from "constants/label"

export const USER_TAB_LABELS = [
  {
    id: "user-overview",
    label: TAB_LABELS.USER_OVERVIEW,
  },
  {
    id: "user-details",
    label: TAB_LABELS.USER_DETAILS,
  },
  {
    id: "user-leads",
    label: TAB_LABELS.USER_LEADS,
  },
  {
    id: "user-contacts",
    label: TAB_LABELS.USER_CONTACTS,
  },
  {
    id: "user-team-members",
    label: TAB_LABELS.USER_TEAM_MEMBERS,
  },
  {
    id: "user-earnings",
    label: TAB_LABELS.USER_EARNINGS,
  },
  {
    id: "user-payout-request",
    label: TAB_LABELS.USER_PAYOUT_REQUEST,
  },
  {
    id: "user-logs",
    label: TAB_LABELS.USER_LOGS,
  },
]

export const USER_DETAILS_BREADCRUMB = {}
