import React, { useEffect, useState } from "react"
import { useToasts } from "react-toast-notifications"
import { Modal } from "reactstrap"

import SearchSelect from "components/CustomSearchSelect/index"
import CustomSelect from "components/CustomSelect/index"
import CustomTextField from "components/CustomTextField/index"
import { calculateLeadOwnerCommission, getLeadStatusesList } from "helpers/api_actions"
import { getCreUsers } from "helpers/dropdown_helper"
import { validate } from "helpers/utilities"

let required = ["cre_user_id"]
const ManageCreUser = props => {
  const [formData, setFormData] = useState(props.data || {})
  const [errs, setErrs] = useState({})
  const { addToast } = useToasts()
  const handleChange = (v, k) => {
    const error = validate(required, { ...formData, [k]: v }, "")
    setFormData({
      id:v.id,
      value:v.id,
      label:v.label,[k]: v
    })
    if (Object.keys(errs).length) {
      setErrs(error)
    }
  }
  const handleSubmit = () => {
    let errors = validate(required, formData, "")
    if (!formData?.cre_user_id?.value) {
      errors = {
        ...errors,
        cre_user_id: "Select a CRE user",
      }
    }else{
      props.setCreId(formData?.cre_user_id?.value)
    }
    setErrs(errors)
    if (Object.keys(errors).length === 0) {
      props.onSubmitAction(formData)
    }
  }

  return (
    <Modal isOpen={true} toggle={props.onCancelAction} centered={true}>
     {props.selectedList.length > 0 || props.selectAll ?(<>
     <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {formData?.value ? "Change CRE User":"Assign CRE User"}
        </h5>
        <button
          type="button"
          disabled={props.submiting}
          onClick={() => {
            props.setAssignCreModalOpen(false)
          }}
          className="btn-close"
        ></button>
      </div>
      <div className="modal-body">
        <form>
          <div className=" mb-3">
            <SearchSelect
              getData={getCreUsers}
              isMulti={false}
              required
              label="CRE User"
              autoload={false}
              setData={v => {
                handleChange(v, "cre_user_id")
              }}
              value={formData ?? null}
              isClearable={false}
            />
            {errs?.cre_user_id && (
              <p className="text-danger"> {errs?.cre_user_id}</p>
            )}
          </div>
        </form>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          data-bs-dismiss="modal"
          disabled={props.submiting}
          onClick={() => {
            props.setAssignCreModalOpen(false)
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          disabled={props.submiting}
          className="btn btn-primary"
          onClick={e => handleSubmit(e)}
        >
          Save
        </button>
      </div></>):(
       <> <div className="modal-header"><h5 className="modal-title" id="exampleModalLabel">
          {"Warning"}
        </h5>
        <button
          type="button"
          disabled={props.submiting}
          onClick={() => {
            props.setAssignCreModalOpen(false)
          }}
          className="btn-close"
        ></button></div>
        <div className="modal-body">
        <form>
          <div className=" mb-3">Please select at lease one record of data to proceed</div></form></div>
          <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          data-bs-dismiss="modal"
          disabled={props.submiting}
          onClick={() => {
            props.setAssignCreModalOpen(false)
          }}
        >
          OK
        </button></div>
        </>
      )}
    </Modal>
  )
}
export default ManageCreUser
