import moment from "moment"
import React, { forwardRef, lazy, Suspense, useEffect, useImperativeHandle, useState } from "react"
import { Link } from "react-router-dom"
import { useToasts } from "react-toast-notifications"

import CustomSpinner from "components/customSpinner"
import { AUTH_ROUTES } from "constants/routes"
import { changeLeadStatus, getCustomerSupportList, getLeadDetails, getPartnerShipRequestList, getTicketsList } from "helpers/api_actions"
import { generateClassNameByValue } from "helpers/utilities"

import UserFilterComponent from "./filterComponent"

const CustomTable = lazy(() => import("components/customTable"))
const TicketListing = props => {
  const [pageSize, setPageSize] = useState(15)
  const [data, setData] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [pageNo, setPageNo] = useState(1)
  const [search, setSearch] = useState("")
  const [filters, setFilters] = useState({})
  const [activeItem, setActiveItem] = useState({})
  const [statusData, setStatusData] = useState({})
  const [sortKey, setSortKey] = useState("&sort_by[created_at]=DESC")
  const [appliedFilters, setAppliedFilters] = useState("")
  const [appliedSeach, setAppliedSearch] = useState("")
  const [isFetching, setIsFetching] = useState(false)
  const [submiting, setSubmiting] = useState(false)
  const [manageStatus, setManageStatus] = useState(false)
  const { addToast } = useToasts()
  const navigateToDetails = (id, isEdit = false) => {
    props.history.push({
      pathname: `${AUTH_ROUTES.LEAD_DETAILS}${id}`,
    })
  }
  const listTickets = params => {
    let basicData = {
      select: `raise_ticket_form.id,raise_ticket_form.name,raise_ticket_form.phone,raise_ticket_form.email,raise_ticket_form.issue,raise_ticket_form.comments,raise_ticket_form.status,raise_ticket_form.created_at,cre_user.full_name,cre_user.id as cre_user_id`,
      include: `users as cre_user&page=${pageNo}&per_page=${pageSize}`,
    }
    setIsFetching(true)
    getTicketsList(basicData, appliedSeach, appliedFilters, sortKey)
      .then(res => {
        setData(res?.data?.data)
        setTotalCount(res?.data?.total || 0)
        setIsFetching(false)
      })
      .catch(err => {
        setIsFetching(false)
      })
  }

  useEffect(() => {
    listTickets()
  }, [pageSize, pageNo, appliedSeach, sortKey, appliedFilters])
  const [columns, setColumns] = useState([
    {
      title: "Issue",
      field: "issue",
      minWidth: 250,
      width: 250,
      maxWidth:250,
      wrap: true,
      sort_key: "raise_ticket_form.issue",
      render: (r, v) => (
        <Link
          className="text-link"
          to={{
            pathname: `${AUTH_ROUTES.TICKET_DETAILS}${r.id}`,
            state: { edit: false },
          }}
        >
          {v}
        </Link>
      ),
      //   tooltip_text: "User who gave the lead",
    },
    {
      title: "Name",
      field: "name",
      minWidth: 180,
      width: 180,
      wrap: true,
      sort_key: "raise_ticket_form.name",
    },

    {
      title: "Email",
      sort_key: "raise_ticket_form.email",
      field: "email",
      minWidth: 250,
      width: 250,
      wrap: true,
    },
    {
      title: "Phone",
      field: "phone",
      minWidth: 150,
      width: 150,
      wrap: true,
      sort_key: "raise_ticket_form.phone",
    },
    {
      title: "Handled By",
      field: "full_name",
      minWidth: 180,
      width: 180,
      wrap: true,
      sort_key: "cre_user.full_name",
      tooltip_text: "Upfin CRE User who is in mapped to process the ticket",
      render: (r, v) => (
        <Link
          className="text-link"
          to={{
            pathname: `${AUTH_ROUTES.CRE_USER_DETAILS}${r.cre_user_id}`,
            state: { edit: false },
          }}
        >
          {v}
        </Link>
      ),
    },
    {
      title: "Status",
      sort_key: "raise_ticket_form.status",
      field: "status",
      minWidth: 150,
      width: 150,
      wrap: true,
      render: (r, v) => (
        <span className={`ms-2 chips ${v && generateClassNameByValue(v)}`}>
          {v}
        </span>
      ),
    },
    {
      title: "Created On",
      sort_key: "raise_ticket_form.created_at",
      field: "created_at",
      minWidth: 135,
      width: 135,
      wrap: true,
      sort_direction: "DESC",
      render: (row, v) => (
        <div>{v ? moment(v).format("DD-MMM-YYYY") : "--"}</div>
      ),
    },
  ])

  const handleSort = row => {
    let nextDirection = row.sort_direction
      ? row?.sort_direction === "ASC"
        ? "DESC"
        : "ASC"
      : "ASC"
    columns.forEach(col => {
      if (col.field === row.field) {
        col["sort_direction"] = nextDirection
      } else {
        col["sort_direction"] = undefined
      }
    })
    setColumns(() => columns)

    let sortkey = `&sort_by[${row.sort_key}]=${nextDirection}`
    setSortKey(sortkey)
  }

  const handleSearch = val => {
    setSearch(val)
    let searchString =
      val && val !== ""
        ? `&search[raise_ticket_form.phone]=${val}
          &search[raise_ticket_form.email]=${val}
          &search[raise_ticket_form.name]=${val}
          &search[raise_ticket_form.issue]=${val}
       `
        : undefined
    setPageNo(1)
    setAppliedSearch(searchString)
  }

  const onFilterSubmission = fil => {
    setFilters(fil)
    let filterString = undefined
    filterString = `${
      fil?.status?.value
        ? "&filter[raise_ticket_form.status]=" + fil?.status?.value
        : ""
    }${
      fil?.handledBy?.value
        ? "&filter[raise_ticket_form.cre_user_id]=" + fil?.handledBy?.value
        : ""
    }${
      fil?.createdOn?.length === 2
        ? "&filter[raise_ticket_form.created_at]=" +
          moment(fil.createdOn[0]).format("YYYY-MM-DD") +
          " - " +
          moment(fil.createdOn[1]).format("YYYY-MM-DD")
        : ""
    }`
    setPageNo(1)
    setAppliedFilters(filterString ?? "")
  }
  const handleOnPageChange = p => {
    setPageNo(p.page)
    setPageSize(p.pageSize)
  }
  // if (isFetching) {
  //   return <CustomSpinner />
  // }
  return (
    <>
      <Suspense fallback={<CustomSpinner />}>
        <CustomTable
          columns={columns}
          data={data}
          currentPage={pageNo}
          totalCount={totalCount}
          pageSize={pageSize}
          search={search}
          filters={filters}
          onSearch={handleSearch}
          onFilterSubmission={onFilterSubmission}
          filterComponent={UserFilterComponent}
          pagination={{
            onPageChange: handleOnPageChange,
          }}
          isFetching={isFetching}
          onSort={handleSort}
        />
      </Suspense>
    </>
  )
}

export default TicketListing
