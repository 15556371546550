import React, { useState, useEffect, lazy, Suspense } from "react"
import { Link,useHistory  } from "react-router-dom"
import { getLeadsList, getPlatformUsersList } from "helpers/api_actions"

import UserFilterComponent from "./filterComponent"
import { AUTH_ROUTES } from "constants/routes"
import CustomSpinner from "components/customSpinner"
import moment from "moment"
import { isAdmin } from "helpers/utilities"
const CustomTable = lazy(() => import("components/customTable"))
function Attachments(props) {
  const [pageSize, setPageSize] = useState(15)
  const [data, setData] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [pageNo, setPageNo] = useState(1)
  const [search, setSearch] = useState("")
  const [filters, setFilters] = useState({})
  const [sortKey, setSortKey] = useState("&sort[leads.display_id]=ASC")
  const [appliedFilters, setAppliedFilters] = useState("")
  const [appliedSeach, setAppliedSearch] = useState("")
  const [isFetching, setIsFetching] = useState(false)
  const history = useHistory();
  // const navigateToDetails = (id, isEdit = false) => {
  //   props.history.push({
  //     pathname: `${AUTH_ROUTES.USER_DETAILS}${id}`,
  //     state: { edit: isEdit },
  //   })
  // }
  const navigateToDetails = (id, isEdit = false) => {
    const url = `${AUTH_ROUTES.LEAD_DETAILS}${id}`;
    history.push(url);
  }

  const listUsers = params => {
    let basicData = {
      select: `leads.id as id,leads.name as Name,lead_types.name as LeadType,leads.primary_phone as Phone,leads.primary_email as Email,lead_addresses.city as City,leads.business_name as Business Name,services.name as Service Name,lead_statuses.name as Status,u1.full_name as Lead Owner,u2.full_name as Handled By,cre_user,cre_user_id,leads.display_id as Lead ID,lead_types.name as Lead Type,leads.created_at as Created On,leads.updated_at as Updated On`,
      include: `services,lead_addresses,users as u1,users as u2,lead_statuses,lead_types&search[leads.primary_phone]=${props.parentID}&filter[lead_types.name]=General Lead,Business Lead&filter[leads.id]=<>${props.data?.id}`,
    }
    setIsFetching(true)
    getLeadsList(basicData, appliedSeach, appliedFilters, sortKey)
      .then(res => {
        setData(res?.data?.data)
        setTotalCount(res?.data?.total || 0)
        setIsFetching(false)
      })
      .catch(err => {
        setIsFetching(false)
      })
  }
  useEffect(() => {
    listUsers()
  }, [pageSize, pageNo, appliedSeach, sortKey, appliedFilters])

  const handleDelete = () => {}
  const handleTableMenu = (action, rowData) => {
    switch (action.action) {
      case "edit":
        navigateToDetails(rowData.id, true)
        break

      case "delete":
        handleDelete(rowData)
        break
      default:
        break
    }
  }
  const [columns, setColumns] = useState([
    {
      title: "Lead ID",
      field: "Lead ID",
      minWidth: 105,
      width: 105,
      wrap: true,
      sort_direction: "ASC",
      render: (r, v) => (
        <Link
          className="text-link"
          to={{
            pathname: `${
              r.LeadType === "Business Lead"
                ? AUTH_ROUTES.BUSINESS_DETAILS
                : AUTH_ROUTES.LEAD_DETAILS
            }${r.id} `,
            state: { edit: false },
          }}
        >
          {v}
        </Link>
      ),
      sort_key: "leads.display_id",
    },
    {
      title: "Name",
      field: "Name",
      minWidth: 180,
      width: 180,
      wrap: true,
      sort_key: "leads.name",
    },
    {
      title: "Lead Type",
      field: "LeadType",
      minWidth: 130,
      width: 130,
      wrap: true,
      sort_key: "lead_types.name",
    },
    {
      title: "Phone",
      sort_key: "users.primary_phone",
      field: "Phone",
      sort_key: "leads.primary_phone",
      minWidth: 150,
      width: 150,
      wrap: true,
    },
    {
      title: "Email",
      field: "Email",
      minWidth: 150,
      width: 150,
      wrap: true,
      sort_key: "leads.primary_email",
    },

    {
      title: "City",
      field: "City",
      sort_key: "lead_addresses.city",
      minWidth: 150,
      width: 150,
      wrap: true,
    },
    {
      title: "Service Name",
      field: "Service Name",
      sort_key: "services.name",
      minWidth: 280,
      width: 280,
      wrap: true,
    },
    {
      title: "Status",
      field: "Status",
      sort_key: "lead_statuses.name",
      minWidth: 150,
      width: 150,
      wrap: true,
    },
    ...(isAdmin()
      ? [
          {
            title: "Handled By",
            field: "cre_user",
            minWidth: 145,
            width: 145,
            wrap: true,
            sort_key: "cre_user",
            tooltip_text: "Upfin CRE User who is in mapped to process the lead",
            render: (r, v) => (
              <Link
                className="text-link"
                to={{
                  pathname: `${AUTH_ROUTES.CRE_USER_DETAILS}${r.cre_user_id}`,
                  state: { edit: false },
                }}
              >
                {v}
              </Link>
            ),
          },
        ]
      : []),
    {
      title: "Created On",
      sort_key: "leads.created_at",
      field: "Created On",
      minWidth: 135,
      width: 135,
      wrap: true,
      render: (row, v) => (
        <div>{v ? moment(v).format("DD-MMM-YYYY") : "--"}</div>
      ),
    },
    {
      title: "Updated On",
      field: "Updated On",
      sort_key: "leads.updated_at",
      minWidth: 135,
      width: 135,
      wrap: true,
      render: (row, v) => (
        <div>{v ? moment(v).format("DD-MMM-YYYY") : "--"}</div>
      ),
    },
  ])

  const handleSort = row => {
    let nextDirection = row.sort_direction
      ? row?.sort_direction === "ASC"
        ? "DESC"
        : "ASC"
      : "ASC"
    columns.forEach(col => {
      if (col.field === row.field) {
        col["sort_direction"] = nextDirection
      } else {
        col["sort_direction"] = undefined
      }
    })
    setColumns(() => columns)

    let sortkey = `&sort_by[${row.sort_key}]=${nextDirection}`
    setSortKey(sortkey)
  }
  const handleSearch = val => {
    setSearch(val)
    let searchString =
      val && val !== ""
        ? `&search[leads.name]=${val}
        &search[leads.primary_phone]=${val}
        &search[leads.primary_email]=${val}
        &search[leads.business_name]=${val}
        &search[services.name]=${val}
        &search[u1.full_name]=${val}
        &search[leads.display_id]=${val}
        &search[lead_types.name]=${val}
        &search[leads.secondary_phone]=${val}
        &search[leads.secondary_email]=${val}
        &search[leads.job]=${val}
        &search[leads.business_name]=${val}
        &search[lead_addresses.address_line_1]=${val}
        &search[lead_addresses.address_line_2]=${val}
        &search[lead_addresses.state]=${val}
        &search[lead_addresses.pincode]=${val}
        &search[lead_addresses.country]=${val}
        &search[lead_addresses.city]=${val}
     `
        : undefined
    setPageNo(1)
    setAppliedSearch(searchString)
  }

  const onFilterSubmission = fil => {
    setFilters(fil)
    let filterString = undefined
    filterString = `${
      fil?.city?.value ? "&filter[user_addresses.city]=" + fil?.city?.value : ""
    }${
      fil?.status?.value
        ? "&filter[lead_statuses.name]=" + fil?.status?.value
        : ""
    }${
      fil?.updatedOn && fil?.updatedOn.length === 2
        ? "&filter[leads.updated_at]=" +
          moment(fil.updatedOn[0]).format("YYYY-MM-DD") +
          " - " +
          moment(fil.updatedOn[1]).format("YYYY-MM-DD")
        : ""
    }${
      fil?.createdOn && fil?.createdOn?.length === 2
        ? "&filter[leads.created_at]=" +
          moment(fil.createdOn[0]).format("YYYY-MM-DD") +
          " - " +
          moment(fil.createdOn[1]).format("YYYY-MM-DD")
        : ""
    }`
    setPageNo(1)
    setAppliedFilters(filterString ?? "")
  }
  const getTableActions = rowData => {
    return [
      {
        icon: <i className="mr-1" />,
        action: "edit",
        name: "View",
        onClick: handleTableMenu,
      },
    ]
  }
  const handleOnPageChange = p => {
    setPageNo(p.page)
    setPageSize(p.pageSize)
  }
  if (isFetching) {
    return <CustomSpinner />
  }
  return (
    <Suspense fallback={<CustomSpinner />}>
      <CustomTable
        columns={columns}
        data={data}
        actions={rowData => {
          return getTableActions(rowData)
        }}
        currentPage={pageNo}
        totalCount={totalCount}
        pageSize={pageSize}
        search={search}
        filters={filters}
        onSearch={handleSearch}
        onFilterSubmission={onFilterSubmission}
        filterComponent={UserFilterComponent}
        pagination={{
          onPageChange: handleOnPageChange,
        }}
        isFetching={isFetching}
        onSort={handleSort}
        detailsTable={true}
      />
    </Suspense>
  )
}

export default Attachments
