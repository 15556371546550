import classnames from "classnames"
import React from "react"
import { Input } from "reactstrap"

import { DOTS, usePagination } from "hooks/usePagination"

const Pagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
  } = props

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  })
  // if (currentPage === 0 || paginationRange.length < 2) {
  //   return null
  // }

  const onNext = () => {
    onPageChange({ page: currentPage + 1, pageSize: pageSize })
  }

  const onPrevious = () => {
    onPageChange({ page: currentPage - 1, pageSize: pageSize })
  }

  let lastPage = paginationRange[paginationRange.length - 1]
  return (
    <>
      <div className="d-flex justify-content-between align-items-center p-2">
        <div className="d-flex align-items-center pagination justify-content-flex-start ">
          <React.Fragment>
            <Input
              type="select"
              name="select"
              defaultValue={pageSize}
              onChange={e =>
                onPageChange({
                  page: 1,
                  pageSize: Number(e.target.value),
                })
              }
            >
              <option value={15}>15</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
            </Input>
          </React.Fragment>
        </div>
        
        <div className="d-flex align-items-center justify-content-flex-start ">
          <div>
            <ul
              className={classnames("m-0 pagination-container", {
                [className]: className,
              })}
            >
              <li
                className={classnames("pagination-item", {
                  disabled: currentPage === 1,
                })}
                onClick={onPrevious}
              >
                <div className="arrow left" />
              </li>
              {paginationRange.map((pageNumber, ind) => {
                if (pageNumber === DOTS) {
                  return (
                    <li key={`a-${ind}`} className="pagination-item dots">
                      &#8230;
                    </li>
                  )
                }

                return (
                  <li
                    key={`b-${ind}`}
                    className={classnames("pagination-item", {
                      selected: pageNumber === currentPage,
                    })}
                    onClick={() =>
                      onPageChange({ page: pageNumber, pageSize: pageSize })
                    }
                  >
                    {pageNumber}
                  </li>
                )
              })}
              <li
                key={`c`}
                className={classnames("pagination-item", {
                  disabled: currentPage === lastPage,
                })}
                onClick={onNext}
              >
                <div className="arrow right" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default Pagination
