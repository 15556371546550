import React, { Component } from "react"
import { Col, Input, Modal, Row } from "reactstrap"

import uploadplaceholder from "assets/admin-images/upload-placeholder.png"
import DatepickerComponent from "components/Common/DatepickerComponent"
import SelectfieldComponent from "components/Common/SelectfieldComponent"
import TextfieldComponent from "components/Common/TextfieldComponent"
import { getKycPAN } from "helpers/api_actions"

class AadharInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal_center: false,
      kysData: {},
    }
    this.tog_center = this.tog_center.bind(this)
  }
  getPanDetails = () => {
    getKycPAN(this.props.id).then(res => {
      this.setState({ kysData: res.data?.result?.source_output })
    })
  }
  tog_center() {
    this.getPanDetails()
    this.setState(prevState => ({
      modal_center: !prevState.modal_center,
    }))
  }

  show() {
    this.setState({ visible: true })
  }

  hide() {
    this.setState({ visible: false })
  }
  render() {
    const { kysData } = this.state
    return (
      <React.Fragment>
        <div>
          <a
            href="#"
            onClick={this.tog_center}
            data-toggle="modal"
            data-target=".bs-example-modal-center"
            className="linkText"
          >
            View Details
          </a>
          <Modal
            isOpen={this.state.modal_center}
            toggle={this.tog_center}
            size="lg"
            style={{ maxWidth: "900px", width: "100%" }}
            centered={true}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                PAN Card Information
              </h5>
              <button
                type="button"
                onClick={() => {
                  this.setState({ modal_center: false })
                }}
                className="btn-close"
              ></button>
            </div>
            <div className="modal-body">
              <Row>
                <Col md="4">
                  <p className="detail-title">Masked Aadhar</p>
                  <p className="details-content">
                    {kysData?.masked_aadhaar ?? "--"}
                  </p>
                </Col>
                <Col md="4">
                  <p className="detail-title">Gender</p>
                  <p className="details-content">{kysData?.gender ?? "--"}</p>
                </Col>
                <Col md="4">
                  <p className="detail-title">Country</p>
                  <p className="details-content">
                    {kysData?.address?.country ?? "--"}
                  </p>
                </Col>
                <Col md="4">
                  <p className="detail-title">PAN Card Number</p>
                  <p className="details-content">
                    {kysData?.pan_number ?? "--"}
                  </p>
                </Col>
                <Col md="4">
                  <p className="detail-title">Age</p>
                  <p className="details-content">{kysData?.age ?? "--"}</p>
                </Col>
                <Col md="4">
                  <p className="detail-title">Zip Code</p>
                  <p className="details-content">
                    {kysData?.address?.zip ?? "--"}
                  </p>
                </Col>
                <Col md="4">
                  <p className="detail-title">PAN & Aadhar Linked?</p>
                  <p className="details-content">
                    {kysData?.aadhaar_linked ? "Yes" : "No"}
                  </p>
                </Col>
                <Col md="4">
                  <p className="detail-title">Phone Number</p>
                  <p className="details-content">
                    {kysData?.phone_number ?? "--"}
                  </p>
                </Col>

                <Col md="4">
                  <p className="detail-title">State</p>
                  <p className="details-content">
                    {kysData?.address?.state ?? "--"}
                  </p>
                </Col>
                <Col md="4">
                  <p className="detail-title">Full Name</p>
                  <p className="details-content">
                    {kysData?.full_name ?? "--"}
                  </p>
                </Col>
                <Col md="4">
                  <p className="detail-title">Email</p>
                  <p className="details-content">{kysData?.email ?? "--"}</p>
                </Col>
                <Col md="4">
                  <p className="detail-title">City</p>
                  <p className="details-content">
                    {kysData?.address?.city ?? "--"}
                  </p>
                </Col>
                <Col md="4">
                  <p className="detail-title">First Name</p>
                  <p className="details-content">
                    {kysData?.full_name_split
                      ? kysData?.full_name_split[0]
                      : "--"}
                  </p>
                </Col>
                <Col md="4">
                  <p className="detail-title">DOB</p>
                  <p className="details-content">{kysData?.dob ?? "--"}</p>
                </Col>
                <Col md="4">
                  <p className="detail-title">Street Name</p>
                  <p className="details-content">
                    {kysData?.address?.street_name ?? "--"}
                  </p>
                </Col>
                <Col md="4">
                  <p className="detail-title">Middle Name</p>
                  <p className="details-content">
                    {kysData?.full_name_split
                      ? kysData?.full_name_split[1] !== ""
                        ? kysData?.full_name_split[1]
                        : "--"
                      : "--"}
                  </p>
                </Col>
                <Col md="4">
                  <p className="detail-title">DOB Check</p>
                  <p className="details-content">
                    {kysData?.dob_check ? "Yes" : "No"}
                  </p>
                </Col>
                <Col md="4">
                  <p className="detail-title">Address Line 1</p>
                  <p className="details-content">
                    {kysData?.address?.line_1 ?? "--"}
                  </p>
                </Col>
                <Col md="4">
                  <p className="detail-title">Last Name</p>
                  <p className="details-content">
                    {" "}
                    <p className="details-content">
                      {kysData?.full_name_split
                        ? kysData?.full_name_split[2]
                        : "--"}
                    </p>
                  </p>
                </Col>
                <Col md="4">
                  <p className="detail-title">DOB Verified</p>
                  <p className="details-content">
                    {kysData?.dob_verified ? "Yes" : "No"}
                  </p>
                </Col>
                <Col md="4">
                  <p className="detail-title">Address Line 2</p>
                  <p className="details-content">
                    {kysData?.address?.line_2 ?? "--"}
                  </p>
                </Col>
                <Col md="4">
                  <p className="detail-title">Category</p>
                  <p className="details-content">{kysData?.category ?? "--"}</p>
                </Col>
                <Col md="4">
                  <p className="detail-title">Is Minor ?</p>
                  <p className="details-content">
                    {kysData?.is_minor ? "Yes" : "No"}
                  </p>
                </Col>
                <Col md="4">
                  <p className="detail-title">Full Address</p>
                  <p className="details-content">
                    {kysData?.address?.full ?? "--"}
                  </p>
                </Col>
              </Row>
            </div>
          </Modal>
        </div>
      </React.Fragment>
    )
  }
}

export default AadharInfo
