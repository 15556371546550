import CustomSpinner from "components/customSpinner"
import HeaderTitle from "components/Common/headerTitleComponent"
import { BUTTON_LABELS, HEADER_LABELS } from "constants/label"
import { AUTH_ROUTES } from "constants/routes"
import { DEFAULT_CLASSES } from "constants/styles"
import React, { Suspense, lazy } from "react"
import { withRouter } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import PropTypes from "prop-types"

//Import Breadcrumb
const UserListing = lazy(() => import("./listing"))
const Users = props => {
  const headerActions = [
    // {
    //   type: "button",
    //   label: BUTTON_LABELS.EXPORT,
    //   className: DEFAULT_CLASSES.HEADER_BUTTON_SECONDARY,
    //   action: () => {
    //     props.history.push(AUTH_ROUTES.CREATE_NEW_USER)
    //   },
    // },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <HeaderTitle
              title={HEADER_LABELS.COMMISSION_MANAGEMENT}
              actions={headerActions}
            />
          </Row>
          <Row>
            <Col lg="12">
              <Suspense fallback={<CustomSpinner />}>
                <UserListing {...props} />
              </Suspense>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Users.propTypes = {
  history: PropTypes.object,
}

export default withRouter(Users)
