import moment from "moment"
import React, { lazy, Suspense, useEffect, useState } from "react"
import { Link, withRouter } from "react-router-dom"
import { useToasts } from "react-toast-notifications"

import CustomSpinner from "components/customSpinner"
import { AUTH_ROUTES } from "constants/routes"
import { getTeamMembers, updateReferrerCommision } from "helpers/api_actions"

import ChangeReferralCommission from "../../modal/changeReferralCommission"
import UserFilterComponent from "./filterComponent"

const CustomTable = lazy(() => import("components/customTable"))
function TeamMemberListing(props) {
  const [pageSize, setPageSize] = useState(15)
  const [data, setData] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [pageNo, setPageNo] = useState(1)
  const [search, setSearch] = useState("")
  const [filters, setFilters] = useState({})
  const [sortKey, setSortKey] = useState("&sort_by[invited_team_members.name]=ASC")
  const [appliedFilters, setAppliedFilters] = useState("")
  const [appliedSeach, setAppliedSearch] = useState("")
  const [isFetching, setIsFetching] = useState(false)
  const [activeRow, setActiveRow] = useState();
  const [changeCommission, setChangeCommission] = useState(false)
  const [submiting, setSubmiting] = useState(false)
  const { addToast } = useToasts()
  const navigateToDetails = (id, isEdit = false) => {
    console.log(`${AUTH_ROUTES.USER_DETAILS}${id}`)
    // props.history.push({
    //   pathname: `${AUTH_ROUTES.USER_DETAILS}${id}`,
    //   state: { edit: isEdit },
    // })
  }
  const listUsers = params => {
    let basicData = {
      select: `users.id,invited_team_members.name,invited_team_members.phone,invited_team_members.status as Member Status,user_addresses.city,ranks.name as rank_name,users.status,LeadCount,SaleCount,Prospect,TeamCount,my_earnings,team_earnings,Requestcount,invited_team_members.created_at,invited_team_members.updated_at,platform_users.referrer_commision`,
      include: `users,platform_users,ranks,user_addresses&find[invited_team_members.invited_by]=${props.parentID}`,
    }
    setIsFetching(true)
    getTeamMembers(basicData, appliedSeach, appliedFilters, sortKey)
      .then(res => {
        setData(res?.data?.data)
        setTotalCount(res?.data?.total || 0)
        setIsFetching(false)
      })
      .catch(err => {
        setIsFetching(false)
      })
  }
  useEffect(() => {
    listUsers()
  }, [pageSize, pageNo, appliedSeach, sortKey, appliedFilters])

  function handleCommissionEdit(rowData)
  {
    setActiveRow(rowData)
    setChangeCommission(true);
  }

  function handleCloseCommissionEdit()
  {
    setActiveRow(undefined)
    setChangeCommission(false);
  }

  function handleChangeReferrerCommission(data)
  {
    let payload = {
      percentage: data?.percentage
    }

    if (activeRow?.id)
    {
      setSubmiting(true)
      updateReferrerCommision(activeRow?.id, data)
      .then(res => {
        addToast(res?.message, { appearance: "success" })
        setSubmiting(false)
        handleCloseCommissionEdit()
        listUsers()
      })
      .catch(err => {
        setSubmiting(false)
        addToast(err.response?.data?.message, { appearance: "error" })
      })
    }
  }

  const handleDelete = () => {}
  const handleTableMenu = (action, rowData) => {
    switch (action.action) {
      case "edit_commission":
        handleCommissionEdit(rowData);
        break
      default:
        break
    }
  }
  const [columns, setColumns] = useState([
    {
      title: "Name",
      field: "name",
      minWidth: 180,
      width:180,
      wrap:true,
      sort_key: "invited_team_members.name",
      sort_direction: "ASC",
      render: (r, v) => {
        return r.id ? (
        <a className="text-link" href={`${AUTH_ROUTES.USER_DETAILS}${r.id}`}>{v}</a>
      ) : (<span>{v}</span>)},
    },
    {
      title: "Phone",

      field: "phone",
      minWidth: 150,
      width: 150,
      wrap:true,

      sort_key: "invited_team_members.phone",
    },
    {
      title: "City",
      field: "city",
      minWidth: 150,
      width: 150,
      wrap:true,
      sort_key: "user_addresses.city",
    },
    {
      title: "Rank",
      field: "rank_name",
      minWidth: 50,
      width: 50,
      wrap:true,
      sort_key: "ranks.name",
    },
    {
      title: "Leads",
      field: "leadcount",
      minWidth: 50,
      width: 50,
      wrap:true,
      align: "center",
      sort_key: "LeadCount",
      tooltip_text:"Shows the count of 'Closed Leads / Total Leads'",
    },
    {
      title: "Sale Generated",
      field: "salecount",
      minWidth: 90,
      width: 90,
      wrap: true,
      sort_key: "SaleCount",
      align: "center",
      tooltip_text: "Shows the count of 'Converted & Completed Leads'",
    },
    {
      title: "Prospect value",
      field: "prospect",
      minWidth: 90,
      width: 90,
      wrap: true,
      sort_key: "Prospect",
      align: "center",
      tooltip_text: "Prospect value",
    },
    {
      title: "My Team",
      field: "teamcount",
      minWidth: 135,
      width: 135,
      wrap:true,
      align: "center",
      sort_key: "TeamCount",
      tooltip_text:"Shows the number of team members"
    },
    {
      title: "Own Earnings",
      field: "my_earnings",
      sort_key: "my_earnings",
      minWidth: 160,
      width: 160,
      wrap:true,
      align: "center",
      tooltip_text:"Shows the lifetime commisisons generated by the user",
      render: (row, val) => (
        <div>
          {val ? parseFloat(val).toLocaleString('en-IN') : "--"}
        </div>
      ),
    },
    {
      title: "Team Earnings",
      field: "team_earnings",
      sort_key: "team_earnings",
      minWidth: 170,
      width: 170,
      wrap:true,
      align: "center",
      tooltip_text:"Shows the lifetime commisisons generated by the team members",
      render: (row, val) => (
        <div>
          {val ? parseFloat(val).toLocaleString('en-IN') : "--"}
        </div>
      ),
    },
    {
      title: "Requests",
      field: "requestcount",
      minWidth: 115,
      width: 115,
      wrap:true,
      align: "center",
      sort_key: "Requestcount",
      tooltip_text:"Shows the count of 'Processed Requests  / Total Requests'"
    },
    {
      title: "Status",
      field: "Member Status",
      minWidth: 80,
      width: 80,
      wrap:true,
      sort_key: "invited_team_members.status",
    },
    {
      title: "Updated On",
      field: "updated_at",
      minWidth: 135,
      width: 135,
      wrap:true,
      //sort_direction: "ASC",
      sort_key: "invited_team_members.updated_at",
      render: (row, val) => (
        <div>{val ? moment(val).format("DD-MMM-YYYY") : "--"}</div>
      ),
    },
    {
      title: "Created On",
      field: "created_at",
      minWidth: 135,
      width: 135,
      wrap:true,
      //sort_direction: "ASC",
      sort_key: "invited_team_members.created_at",
      render: (row, val) => (
        <div>{val ? moment(val).format("DD-MMM-YYYY") : "--"}</div>
      ),
    },
  ])

  const handleSort = row => {
    let nextDirection = row.sort_direction
      ? row?.sort_direction === "ASC"
        ? "DESC"
        : "ASC"
      : "ASC"
    columns.forEach(col => {
      if (col.field === row.field) {
        col["sort_direction"] = nextDirection
      } else {
        col["sort_direction"] = undefined
      }
    })
    setColumns(() => columns)

    let sortkey = `&sort_by[${row.sort_key}]=${nextDirection}`
    setSortKey(sortkey)
  }
  const handleSearch = val => {
    setSearch(val)
    let searchString =
      val && val !== ""
        ? `
        &search[invited_team_members.name]=${val}
        &search[invited_team_members.phone]=${val}
        &search[users.primary_phone]=${val}
        &search[user_addresses.city]=${val}
        &search[platform_users.secondary_phone]=${val}
        &search[platform_users.secondary_email]=${val}
        &search[platform_users.job]=${val}
        &search[platform_users.business_name]=${val}
        &search[user_addresses.address_line_1]=${val}
        &search[user_addresses.address_line_2]=${val}
        &search[user_addresses.state]=${val}
        &search[user_addresses.pincode]=${val}
        &search[user_addresses.country]=${val}
        &search[users.display_id]=${val}`
        : undefined
    setPageNo(1)
    setAppliedSearch(searchString)
  }

  const onFilterSubmission = fil => {
    setFilters(fil)
    let filterString = undefined
    filterString = `${
      fil?.city?.value ? "&filter[user_addresses.city]=" + fil?.city?.value : ""
    }${
      fil?.rank?.value ? "&filter[ranks.id]=" + fil?.rank?.value : ""
    }`
    setPageNo(1)
    setAppliedFilters(filterString ?? "")
  }
  const getTableActions = rowData => {
    return [
      {
        icon: <i className="mr-1" />,
        action: "edit_commission",
        name: "Change Referrer Commision",
        onClick: handleTableMenu,
      },
    ]
  }
  const handleOnPageChange = p => {
    setPageNo(p.page)
    setPageSize(p.pageSize)
  }
  if (isFetching) {
    return <CustomSpinner />
  }
  return (
    <Suspense fallback={<CustomSpinner />}>
      <CustomTable
        columns={columns}
        data={data}
        actions={rowData => {
          return getTableActions(rowData)
        }}
        currentPage={pageNo}
        totalCount={totalCount}
        pageSize={pageSize}
        search={search}
        filters={filters}
        onSearch={handleSearch}
        onFilterSubmission={onFilterSubmission}
        filterComponent={UserFilterComponent}
        pagination={{
          onPageChange: handleOnPageChange,
        }}
        isFetching={isFetching}
        onSort={handleSort}
        detailsTable={true}
      />
      {changeCommission && activeRow != undefined && (
        <ChangeReferralCommission
          submiting={submiting}
          onCancelAction={handleCloseCommissionEdit}
          onSubmitAction={handleChangeReferrerCommission}
          userData={activeRow}
        />
      )}
    </Suspense>
  )
}

export default withRouter(TeamMemberListing)
