import moment from "moment"
import React from "react"
import { Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from "reactstrap"

import TableIcons from "components/customTable/components/icons/tableIcon"
import { isCurrentUser } from "helpers/utilities"

function NoteCard({ data = {}, ...rest }) {
  return (
    <div className="w-100 my-2 log-card border-bottom pb-4">
      <Row className="bg-white">
        <Col xs={10}>
          <Row>
            <Col xs={12} className="p-2 note-title">
              {data?.title ?? "--"}
            </Col>
            <Col xs={12} className="pt-2">
              <p>{data?.note ?? "--"}</p>
            </Col>
           { data?.followup_date &&<Col xs={12}><span className="d-inline-block me-4">
                Followup Date:{" "}
                {data?.followup_date
                  ? moment(data?.followup_date).format("DD-MMM-YYYY")
                  : "--"}{" "}
              </span></Col>}
            <Col xs={12}>
              <span className="d-inline-block me-4">
                Last Updated At:{" "}
                {data?.modified_at
                  ? moment(data?.modified_at).format("DD-MMM-YYYY hh:mm a")
                  : "--"}{" "}
              </span>
              
              <span className="d-inline-block me-4">
                Created At:{" "}
                {data?.created_at
                  ? moment(data?.created_at).format("DD-MMM-YYYY hh:mm a")
                  : "--"}{" "}
              </span>
              <span className="d-inline-block">
                Created By:{" "}
                {data?.created_by?.full_name
                  ? data?.created_by?.full_name
                  : "--"}{" "}
              </span>
            </Col>
          </Row>
        </Col>
        {isCurrentUser(data?.created_by?.id) ? 
        <Col xs={2}>
          {(rest.handleEdit || rest.handleDelete) && (
            <div className="h-100 d-flex align-items-center justify-content-end ">
              {data?.is_auto ? "" :<UncontrolledDropdown className=" position-relative ">
                <DropdownToggle
                  tag="button"
                  className="btn  btn-link arrow-none text-muted p-0 "
                >
                  <p className="btnbtn-sm btn-light " color="primary">
                    <TableIcons icon="menu" iconClass="" />
                  </p>
                </DropdownToggle>
                <DropdownMenu end className=" w-100">
                  {rest.handleEdit && (
                    <DropdownItem
                      className="position-relative bg-white w-100"
                      onClick={() => {
                        rest.handleEdit(data)
                      }}
                    >
                      Edit
                    </DropdownItem>
                  )}
                  {rest.handleDelete && (
                    <DropdownItem
                      className="position-relative bg-white text-danger w-100"
                      onClick={() => {
                        rest.handleDelete(data)
                      }}
                    >
                      Delete
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>}
            </div>
          )}
        </Col>:""}
      </Row>
    </div>
  )
}

export default NoteCard
